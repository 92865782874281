import { useCallback, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import LoadingComponent from "../Loading";
import { approveContractNFT, listSellContractNFT, getProviderOrSigner } from "../../integrateContract/contract";
import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RefreshContext } from "../../context/RefreshContext";
import {
  ADDR_MKP_ARB_MAINNET,
  ADDR_MKP_BNB_MAINNET,
  ADDR_MKP_OPBNB_MAINNET,
  ADDR_MKP_ZETA_TESTNET,
  ARB_MKP_ABI_MAINNET,
  BNB_MKP_ABI_MAINNET,
  OPBNB_MKP_ABI_MAINNET,
  ZETA_MKP_ABI_TESTNET,
  address_NFT_LUFFY_ARB,
} from "../../integrateContract/ABI";
import ToastTyfile from "./Toastify";
import { LIST_NETWORK } from "../../constants/connectors";
import changeNetwork from "../../utils/SelectNetwork";
import { optionAddressContracts } from "../../constants/connectors/addressContract";
import { useChainId } from "wagmi";
const CardModal = (props) => {
  const [inputValue, setInputValue] = useState();
  const [isTime, setTime] = useState("2592000");
  const [isLoading, setIsLoading] = useState(false);
  const { handleGetOpBnbBalance } = useContext(RefreshContext);
  const chainId = useChainId();

  const handleInputChange = useCallback((e) => {
    e.preventDefault();
    const value = e.target.value;
    setInputValue(value);
  }, []);

  const onChangeTime = (event) => {
    event.preventDefault();
    const time = event.target.value;
    setTime(time);
  };
  const listSellNFT = async (tokenId, price, time, address_NFT_Chain, abi_NFT_Chain) => {
    setIsLoading(true);

    try {
      const parsePrice = ethers.utils.parseEther(price.toString());
      let addressMKP = null;
      let contractAbiMKP = null;
      switch (chainId) {
        case 56:
          addressMKP = ADDR_MKP_BNB_MAINNET;
          contractAbiMKP = BNB_MKP_ABI_MAINNET;
          break;
        case 204:
          addressMKP = ADDR_MKP_OPBNB_MAINNET;
          contractAbiMKP = OPBNB_MKP_ABI_MAINNET;
          break;
        case 42161:
          addressMKP = ADDR_MKP_ARB_MAINNET;
          contractAbiMKP = ARB_MKP_ABI_MAINNET;
          break;
        case 7001:
          addressMKP = ADDR_MKP_ZETA_TESTNET;
          contractAbiMKP = ZETA_MKP_ABI_TESTNET;
          break;
        default:
          addressMKP = null;
          contractAbiMKP = null;
          break;
      }
      const approvalResult = await approveContractNFT(addressMKP, tokenId, address_NFT_Chain, abi_NFT_Chain);

      if (approvalResult?.reason) {
        toast(<ToastTyfile type="error" description="You rejected transaction" />, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const res = await listSellContractNFT(address_NFT_Chain, tokenId, parsePrice, time.toString(), addressMKP, contractAbiMKP);
        if (res) {
          toast(<ToastTyfile type="sucsess" description="List NFT successfully" />, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

        props.onHide();
        props.onReFetch();
        await handleGetOpBnbBalance();
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleListSellNft = async (chainId, contractName) => {
    if (chainId !== "56" && chainId !== "204" && chainId !== "7001" && chainId !== "42161") {
      await changeNetwork(LIST_NETWORK[0].idText);
    }
    const provider = await getProviderOrSigner(false);
    const network = await provider.getNetwork();
    const selectedChain = optionAddressContracts.find((chain) => Number(chain.chainId) === Number(network.chainId));
    if (selectedChain) {
      console.log(selectedChain);
      const contracts = selectedChain.contract;
      const selectedContract = contracts.find((value) => value.addressMKP === props?.data?.tokenAddress);
      if (selectedContract) {
        const { addressMKP, abi } = selectedContract;
        listSellNFT(props?.data?.tokenId?.toString(), inputValue, Number(isTime), props?.data?.tokenAddress.toString(), abi);
      } else {
        console.error("Contract not found for the given contractName:", contractName);
      }
    } else {
      toast(<ToastTyfile type="error" description="Not supported on your current network." />, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <>
      <ToastContainer />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            zIndex: "33333333333333333333333333333333",
            top: "0px",
            left: "0px",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        ></div>
      )}
      <Modal show={props.show} onHide={props.onHide} onReFetch={props.onReFetch} data={props.data?.tokenId?.toString()}>
        <Modal.Header closeButton></Modal.Header>
        <div className="modal-body space-y-20 pd-40">
          <h3>List Items</h3>
          <p style={{marginTop: '5px'}} className="text-center">
            {props?.data?.collectionName} <span className="price color-popup">#{props.data?.tokenId?.toString()}</span>
          </p>
          <form>
            <p style={{marginBottom: '5px'}}>Setting Price</p>
            <input
              type="text"
              className="form-control"
              placeholder={chainId === 204 ? "0.00 BNB" : chainId === 42161 ? "0.00 ETH" : chainId === 7001 ? "AZETA" : ""}
              value={inputValue}
              onChange={handleInputChange}
            />
          </form>
          <p style={{margin: '10px 0 5px'}}>
            Duration <span className="color-popup">Time</span>
          </p>
          <select style={{margin: '5px 0'}} className="form-control inputSelect" defaultValue="2592000" onChange={onChangeTime}>
            <option value="2592000">1 month</option>
            <option value="5184000">2 month</option>
            <option value="7776000">3 month</option>
          </select>
          <div className="hr"></div>
          <div className="d-flex justify-content-between">
            <p style={{marginBottom: '0'}}> Listing Price:</p>
            <p style={{marginBottom: '0'}} className="text-right price color-popup">
              {inputValue} {props?.data?.chainId === "204" ? "BNB" : props?.data?.chainId === "42161" ? "ETH" : "AZETA"}{" "}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p style={{marginBottom: '0'}}> Creator Earnings:</p>
            <p style={{marginBottom: '0'}} className="text-right price color-popup"> 5% </p>
          </div>
          <div className="d-flex justify-content-between">
            <p style={{marginBottom: '0'}}> Service Fee:</p>
            <p style={{marginBottom: '0'}} className="text-right price color-popup"> 2% </p>
          </div>
          <div className="d-flex justify-content-between">
            <p style={{marginBottom: '0'}}> Total Receive Amount:</p>
            <p style={{marginBottom: '0'}} className="text-right price color-popup"> {inputValue - inputValue * 0.007 || 0} </p>
          </div>
          <button
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              handleListSellNft(
                chainId.toString(),
                //  props?.data?.tokenAddress
                address_NFT_LUFFY_ARB
              );
            }}
          >
            {isLoading ? <LoadingComponent /> : <div>List Sell</div>}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CardModal;

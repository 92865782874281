import Slider, { SliderMarks } from 'antd/lib/slider';
import { Radio, RadioChangeEvent } from "antd";
import InputCommon from "../../../../../components/common/input"
import { ButtonCommon, RadioCommon, SldierRange, TextCustom, TitleKey } from "../../../../../components/common/styled"
import { Case, ListSelect, SPBody, SPToken, SPV3, SetHeader, SetInputs, SetPrice, StartPriceContainer } from "./styled"
import { useState } from "react";

// Images
import tooltip from '../../../../../assets/images/liquidity/tooltip.svg'
import { ButtonAction } from '../../../../../components/common/form/selectNetwork/styled';

const StartPrice = ({ setStep }: any) => {
  const [valueCase, setValueCase] = useState("0.01%");
  const onChangeCase = (e: RadioChangeEvent) => {
    setValueCase(e.target.value);
  };
  const [clicked, setClicked] = useState<number>(0);
  const handleClicked = (id: any) => {
    setClicked(id)
  }

  return (
    <StartPriceContainer>
      <SPBody>
        <SPToken>
          <TitleKey>
            Adress Token
          </TitleKey>
          <InputCommon disabled value="0x70BA0144d67cc09BbBD2fa06a3BD235916fa6450" />
        </SPToken>
        <SPV3>
          <TitleKey>
            Liquidity V3
          </TitleKey>
          <RadioCommon>
            <Radio.Group onChange={onChangeCase} value={valueCase}>
              <Radio value="0.01%">0.01%</Radio>
              <Radio value="0.05%">0.05%</Radio>
            </Radio.Group>
          </RadioCommon>
          <Case>Fee Tier: <span>{valueCase}</span></Case>
        </SPV3>
        <SetPrice>
          <TitleKey>
            Set Price
          </TitleKey>
          <SetHeader>
            <TextCustom>
              Total token
              <img style={{ width: "18px", height: '18px' }} src={tooltip} alt="tooltip" />
            </TextCustom>
            <InputCommon value="100" placeHolder="Amount" />
          </SetHeader>
          <SetInputs>
            <InputCommon disabled addonBefore="Current Price" value="1 BUSD = 100 BSCS" />
            <InputCommon disabled addonBefore="Min Price" value="90 BSCS per BUSD" />
            <InputCommon disabled addonBefore="Max Price" value="110 BSCS per BUSD" />
          </SetInputs>
          <ListSelect>
            {[5, 10, 20, 100,].map((item, index: number) => {
              return (
                <li className={index === clicked ? "current" : ""} onClick={() => { handleClicked(index) }} key={index}>{item}%</li>
              )
            })}
          </ListSelect>
        </SetPrice>
        <div style={{ display: 'flex', justifyContent: "center" }}>
          <ButtonCommon background='#F313CE' textColor='#fff'>
            Save
          </ButtonCommon>
        </div>
      </SPBody>
      <ButtonAction>
        <ButtonCommon
          style={{
            border: '2px solid #F313CE',
            padding: "5px 30px",
            width: 'auto',
            fontWeight: "600"
          }}
          background="transparent" textColor="#F313CE"
          onClick={() => setStep(0)}>
          Prev
        </ButtonCommon>
        <ButtonCommon
          style={{
            border: '2px solid #F313CE',
            padding: "5px 30px",
            width: 'auto',
            fontWeight: "600"
          }}
          background="transparent" textColor="#F313CE"
          onClick={() => setStep(2)}>
          Next
        </ButtonCommon>
      </ButtonAction>
    </StartPriceContainer>
  )
}

export default StartPrice
import { message } from "antd";

const IconSuccess = () => {
  return (
    <div>
      <img src="../../../images/success.png" alt="" />
    </div>
  );
};
const IconError = () => {
  return (
    <div>
      <img src="../../../images/error.png" alt="" />
    </div>
  );
};
const IconWarning = () => {
  return (
    <div>
      <img src="../../../images/warning.png" alt="" />
    </div>
  );
};
const handleClose = () => {
  message.destroy();
};
const ContentSuccess = (data) => {
  return (
    <>
      <div className="content-cus">
        <div className="title-noti">Success!</div>
        <img
          src="../../../images/close.png"
          alt=""
          onClick={handleClose}
          style={{ padding: "3px" }}
        />
      </div>
      <p className="content-noti">{data}</p>
    </>
  );
};
const ContentWarning = (data) => {
  return (
    <>
      <div className="content-cus">
        <div className="title-noti">Warning!</div>
        <img
          src="../../../images/close.png"
          alt=""
          onClick={handleClose}
          style={{ padding: "3px" }}
        />
      </div>
      <p className="content-noti">{data}</p>
    </>
  );
};
const ContentError = (data) => {
  return (
    <>
      <div className="content-cus">
        <div className="title-noti">Error!</div>
        <img
          src="../../../images/close.png"
          alt=""
          onClick={handleClose}
          style={{ padding: "3px" }}
        />
      </div>
      <p className="content-noti">{data}</p>
    </>
  );
};

export const notiSuccess = (content, duration) => {
  return new Promise((resolve) => {
    message.success({
      type: "success",
      content: ContentSuccess(content),
      icon: IconSuccess(),
      className: "custom-class success",
      duration: duration,
    });
  });
};
export const notiWarning = (content, duration) => {
  return new Promise((resolve) => {
    message.warning({
      type: "warning",
      content: ContentWarning(content),
      icon: IconWarning(),
      className: "custom-class warning",
      duration: duration,
    });
  });
};
export const notiError = (content, duration) => {
  return new Promise((resolve) => {
    message.error({
      type: "error",
      content: ContentError(content),
      icon: IconError(),
      className: "custom-class error",
      duration: duration,
    });
  });
};

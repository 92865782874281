import styled from "styled-components";
import { TextCustom } from "../styled";

export const PCWContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  ${TextCustom} {
    margin-bottom: 0;
  }
`
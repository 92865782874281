import styled from "styled-components";

export default function LoadingScreen() {
  return (
    <BoxLoading
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: "33333333333333333333333333333333",
        top: "0px",
        left: "0px",
        background: "rgba(255,255,255,.7)",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <div
          style={{
            gap: "5px",
          }}
          class="middle d-flex"
        >
          <div class="bar bar1"></div>
          <div class="bar bar2"></div>
          <div class="bar bar3"></div>
          <div class="bar bar4"></div>
          <div class="bar bar5"></div>
          <div class="bar bar6"></div>
          <div class="bar bar7"></div>
          <div class="bar bar8"></div>
        </div>
      </div>
    </BoxLoading>
  );
}

const BoxLoading = styled.div`
  .middle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .bar {
    width: 10px;
    height: 60px;
    background: #fff;
    display: inline-block;
    transform-origin: bottom center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    animation: loader 1.2s linear infinite;
  }
  .bar1 {
    animation-delay: 0.1s;
  }
  .bar2 {
    animation-delay: 0.2s;
  }
  .bar3 {
    animation-delay: 0.3s;
  }
  .bar4 {
    animation-delay: 0.4s;
  }
  .bar5 {
    animation-delay: 0.5s;
  }
  .bar6 {
    animation-delay: 0.6s;
  }
  .bar7 {
    animation-delay: 0.7s;
  }
  .bar8 {
    animation-delay: 0.8s;
  }

  @keyframes loader {
    0% {
      transform: scaleY(0.1);
    }
    50% {
      transform: scaleY(1);
      background: #f612d1;
    }
    100% {
      transform: scaleY(0.1);
      background: transparent;
    }
  }
`;

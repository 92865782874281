
export const API_OKLINK_URL = "https://www.oklink.com/api";
export const API_GOPLUS_URL = "https://api.gopluslabs.io/api";
export const STANDAR_FEE_RECEIVE = "0x37fBb2916E6246bb217de21F762a0A2388888888";
export const SC_LOCK_LP_V2:any = {
  // 56: "0x25DE073A9031c2bd6BF6BEFf9FEA15968941037b",
  56: "0xFEbB9835a72B39AE2e3A85017c8b0F7Aabc6Ed95",
};

export const FEE_DEPLOY = (chainId: any) => {
  switch (chainId) {
    case 1:
      return "0.005";
    case 137:
      return "10";
    case 250:
      return "30";
    case 43114:
      return "0.5";
    case 42161:
      return "0.005";
    case 204:
      return "0.05";
    case 8453:
      return "0.005";
    case 97:
      return "0.05";
    default:
      return "0.05";
  }
};
export const status_difficulty = (number: any) => {
  const productValue = Math.pow(16, number);

  return productValue;
};




export const CHAINIDTOSYMBOL: any = {
  56: "BSC",
  1: "ETH",
  137: "POLYGON",
  250: "FTM",
  43114: "AVAX",
  42161: "ARBITRUM",
  204: "opbnb",
  8453: "BASE",
  97: "bsc-testnet",
};
export const ChainIdToName: any = {
  56: "smartchain",
  1: "ethereum",
  137: "polygon",
  250: "fantom",
  43114: "avax",
  42161: "arbitrum",
  204: "opbnb",
  8453: "base",
  97: "bsc-testnet",
};
export const TYPE_STT = (stt: any) => {
  switch (stt) {
    case 1:
      return "----";
    case 2:
      return "Waiting...";
    case 3:
      return "Success";
    case 4:
      return "Failed";
    default:
      return "----";
  }
};
export const ADD_LP_V2: any = {
  56: "0x0BA95153646ab97b0d27995f7408f078039aCFbd",
};
export const MULTISEND_CONTRACT: any = {
  56: "0xecDe658C50Ca6EDe6539154fb274C6170Da451b6",
  1: "0xecDe658C50Ca6EDe6539154fb274C6170Da451b6",
  137: "0xecDe658C50Ca6EDe6539154fb274C6170Da451b6",
  42161: "0xecDe658C50Ca6EDe6539154fb274C6170Da451b6",
  204: "0xecDe658C50Ca6EDe6539154fb274C6170Da451b6",
  8453: "0xecDe658C50Ca6EDe6539154fb274C6170Da451b6",
};
export const DEXVIEW: any = {
  56: "https://www.dexview.com/bsc/",
  1: "https://www.dexview.com/eth/",
  137: "https://www.dexview.com/polygon/",
  250: "https://www.dexview.com/",
  43114: "https://www.dexview.com/",
  42161: "https://www.dexview.com/arbitrum/",
  204: "https://www.dexview.com/",
  8453: "https://www.dexview.com/",
  97: "https://www.dexview.com/bsc/",
};
export const SCAN_URL: any = {
  56: "https://bscscan.com/",
  1: "https://etherscan.io/",
  137: "https://polygonscan.com/",
  250: "https://ftmscan.com/",
  43114: "https://avascan.info/",
  42161: "https://arbiscan.io/",
  204: "https://opbnbscan.com/",
  8453: "https://basescan.org/",
  97: "https://testnet.bscscan.com/",
};
export const HTTPS_NETWORK: any = {
  56: "https://bsc-dataseed.binance.org/",
  1: "https://mainnet.infura.io/v3/ffba1579926a470e98adb34ffb50bd4d",
  137: "https://polygon-rpc.com",
  250: "https://rpc.ftm.tools",
  43114: "https://avalanche.public-rpc.com",
  42161: "https://arb1.arbitrum.io/rpc",
  204: "https://opbnb-mainnet-rpc.bnbchain.org/",
  8453: "https://base.publicnode.com",
  97: "https://data-seed-prebsc-1-s3.binance.org:8545",
};


export const LOGO_BY_CHAINID = (chainId: any) => {
  switch (chainId) {
    case 1:
      return "../../../images/ether.png?v=5";

    case 137:
      return "../../../images/poly.png?v=5";

    case 250:
      return "../../../images/fantom.png?v=5";

    case 43114:
      return "../../../images/avax.png?v=5";

    case 42161:
      return "../../../images/arb.png?v=5";

    case 204:
      return "../../../images/bnb.png?v=5";

    case 8453:
      return "../../../images/base.png?v=5";

    case 97:
      return "../../../images/bnb.png?v=5";

    default:
      return "../../../images/bnb.png?v=5";
  }
};
export const token_network = (chainId: any) => {
  switch (chainId) {
    case 1:
      return "ETH";

    case 137:
      return "MATIC";

    case 250:
      return "FTM";

    case 43114:
      return "AVAX";

    case 42161:
      return "ETH";

    case 204:
      return "BNB";

    case 8453:
      return "ETH";

    case 97:
      return "tBNB";

    default:
      return "BNB";
  }
};
export const CHAINID_TO_VALUE = (chainId: any) => {
  switch (chainId) {
    case 1:
      return "erc";

    case 137:
      return "poly";

    case 250:
      return "fantom";

    case 43114:
      return "avax";

    case 42161:
      return "arb";

    case 204:
      return "opbnb";

    case 8453:
      return "base";

    case 97:
      return "bsc-testnet";

    default:
      return "bsc";
  }
};
export const CHAINID_TO_TEXTCHAIN = (chainId: any) => {
  switch (chainId) {
    case 1:
      return "0x1";

    case 137:
      return "0x89";

    case 250:
      return "0xfa";

    case 43114:
      return "0xa86a";

    case 42161:
      return "0xa4b1";

    case 204:
      return "0xcc";

    case 8453:
      return "0x2105";

    case 97:
      return "0x61";

    default:
      return "0x38";
  }
};
export const NAME_NETWORK = (chainId: any) => {
  switch (chainId) {
    case 1:
      return "Ethereum";

    case 137:
      return "Polygon";

    case 250:
      return "Fantom";

    case 43114:
      return "Avax";

    case 42161:
      return "Arbitrum One";

    case 204:
      return "opBNB Mainnet";

    case 8453:
      return "Base";

    case 97:
      return "BSC Testnet";

    default:
      return "BSC Mainnet";
  }
};


export const ERROR_CONTENT = (code: any) => {
  switch (code) {
    case 1:
      return "Complete data prepared";
    case 2:
      return "Partial data obtained. The complete data can be requested again in about 15 seconds.";
    case 2004:
      return "Contract address format error!";
    case 2018:
      return "ChainID not supported";
    case 2020:
      return "Non-contract address";
    case 2021:
      return "No info for this contract";
    case 2022:
      return "Non-supported chainId";
    case 2026:
      return "dApp not found";
    case 2027:
      return "ABI not found";
    case 2028:
      return "The ABI not support parsing";
    case 4010:
      return "App_key not exist";
    case 4011:
      return "Signature expiration (the same request parameters cannot be requested more than once)";
    case 4012:
      return "Wrong Signature";
    case 4023:
      return "Access token not found";
    case 4029:
      return "Request limit reached";
    case 5000:
      return "System error";
    case 5006:
      return "Param error!";
    default:
      return "Undefined";
  }
};

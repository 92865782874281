import styled from "styled-components";
import bg from "../../../assets/images/homenew/roadmap/roadmap_bg.png";
import { CommonTitle } from "../Features/styled";

export const RoadmapContainer = styled.div`
  position: relative;
  padding-bottom: 170px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${bg}) no-repeat center / 100% auto;
    mix-blend-mode: lighten;
  }
  &:hover {
    ${CommonTitle} {
      span {
        &:nth-child(1) {
          color: #fff;
          opacity: 1;
          text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 0px #fff;
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    padding-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    &::before {
      content: none;
    }
  }
`;
export const RoadmapInner = styled.div`
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 0;
    width: 463px;
    height: 694px;
    opacity: 0.4;
    background: #d335c3;
    filter: blur(143px);
  }
  &::before {
    left: -350px;
  }
  &::after {
    right: -350px;
  }
  @media screen and (max-width: 767px) {
    &::before {
      content: none;
      top: 0;
      left: -500px;
    }
    &::after {
      content: none;
      top: unset;
      bottom: 0;
      right: -500px;
    }
  }
`;
export const RoadmapWrapper = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 1023px) {
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
  }
`;
export const PolygonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    width: 30%;
    height: 2px;
    background: rgb(31, 42, 55);
    transform: rotate(-150deg);
    z-index: 0;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    pointer-events: none;
  }
  @media screen and (max-width: 1023px) {
    width: 50%;
    align-self: stretch;
    svg {
      transform: rotateZ(30deg);
    }
    &::before {
      width: calc((100% - 125px));
      height: calc((100% - 160px));
    }
  }
`;
export const RoadmapContent = styled.div`
  h3 {
    color: #eaecf0;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 15px;
    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  p {
    color: #667085;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 20px;
    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  ul {
    border-radius: 22px;
    background: rgba(38, 11, 34, 0.29);
    backdrop-filter: blur(5px);
    padding: 15px 28px;
    border: 1px solid transparent;
    border-radius: 16px;
    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
    & > li {
      color: #d6bbfb;
      font-size: 12px;
      font-weight: 500;
      list-style-type: disc;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    width: 50%;
    align-self: stretch;
  }
  @media screen and (max-width: 767px) {
    ul {
      padding: 15px 20px;
    }
  }
`;
export const RoadmapList = styled.ul`
  display: flex;
  justify-content: space-between;
  gap: 45px;
  & > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 21%;
    cursor: pointer;
    svg {
      pointer-events: none;
    }
    &.active {
      ${PolygonContainer} {
        &::before {
          background: #b692f6;
        }
      }
      ${RoadmapContent} {
        h3 {
          color: #d6bbfb;
        }
        p {
          color: #d0d5dd;
        }
        ul {
          border-color: #d6bbfb;
          background: rgba(66, 48, 125, 0.27);
          backdrop-filter: blur(3.5px);
        }
      }
    }
    &:last-child {
      ${PolygonContainer} {
        &::before {
          content: none;
        }
      }
    }
    &:nth-child(even) {
      margin-top: 175px;
      ${PolygonContainer} {
        &::before {
          top: 12%;
          left: 112%;
          transform: translateX(-50%) rotateZ(-30deg);
        }
      }
    }
    &:nth-child(odd) {
      ${PolygonContainer} {
        &::before {
          bottom: 12%;
          left: 112%;
          transform: translateX(-50%) rotateZ(30deg);
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    & > li {
      width: auto;
      height: auto;
      gap: 15px;
      ${PolygonContainer} {
        &::before {
          transform: rotate(-35deg);
        }
      }
      &:nth-child(even) {
        margin-top: 0;
        flex-direction: row;
        ${PolygonContainer} {
          &::before {
            bottom: -24px;
            top: auto;
            left: 100%;
            transform: translateX(-50%) translateY(50%) rotateX(86deg) rotateY(33deg);
          }
        }
      }
      &:nth-child(odd) {
        flex-direction: row-reverse;
        ${PolygonContainer} {
          &::before {
            bottom: -24px;
            left: 0%;
            transform: translateX(-50%) translateY(50%) rotateX(-86deg) rotateY(33deg);
          }
        }
      }
    }
  }
`;
export const PolygonWrapper = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -64px;
  max-width: 129px;
  width: 100%;
  z-index: 10;
  img {
    width: 100%;
    height: auto;
  }
`;

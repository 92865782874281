const WardKeyvisual = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.25L2.5 9.75M8.5 3.75L9.5 2.75M5.5 3.75L4.5 2.75M9.5 7.75L8.5 6.75M7 3.25V2.75M7 8.25V7.75M9 5.25H9.5M4 5.25H4.5"
        stroke="white"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default WardKeyvisual;

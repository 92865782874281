import axios from "axios";
import FormData from "form-data";

const JWT = `Bearer ${process.env.REACT_APP_JWT_NFT_PINATA}`;

export const uploadImageToPinata = async (urlImage, fileImage) => {
  if (!urlImage) return;
  try {
    const response = await axios.get(urlImage, {
      responseType: "arraybuffer",
    });
    let formatFileImageString = fileImage.replace(/\s+/g, "") + ".png";

    const formData = new FormData();
    formData.append("file", new Blob([response.data]), formatFileImageString);

    const pinataResponse = await axios.post(
      "https://api.pinata.cloud/pinning/pinFileToIPFS",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: JWT,
        },
      }
    );
    const ipfsHash = pinataResponse.data.IpfsHash;
    return ipfsHash;
  } catch (error) {
    console.error("Error uploading image to Pinata:", error);
  }
};

// export const uploadJSONToIPFS = async (JSONBody) => {
//   const url = "https://api.pinata.cloud/pinning/pinJSONToIPFS";
//   return axios
//     .post(url, JSONBody, {
//       headers: {
//         accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: JWT,
//       },
//     })
//     .then((res) => {
//       return {
//         success: true,
//         pinataURL:
//           "https://xrendernft.mypinata.cloud/ipfs/" + res.data.IpfsHash,
//       };
//     })
//     .catch((err) => {
//       console.log(err);
//       return {
//         success: false,
//         message: err.message,
//       };
//     });
// };

import React, { useState, useEffect } from "react";
import { Slider } from "antd";
import { ethers } from "ethers";
import { notiSuccess, notiError } from "../../../../utils/Tooldex/notication";
import { SliderMarks } from "antd/lib/slider";
import { BlockHeader, ContentBlock, PAContent } from "./styled";
import {
  ButtonAction,
  NetworkHeader,
} from "../../../../components/common/form/selectNetwork/styled";
import InputCommon from "../../../../components/common/input";
import {
  ButtonCommon,
  DatePickerCommon,
  SldierRange,
  TextCustom,
  TitleKey,
} from "../../../../components/common/styled";
import {
  DataFetch,
  FetchRow,
  IconInner,
  PABody,
  PairAddressContainer,
} from "../pairAddress/styled";
import { DatePicker } from "antd";
import moment from "moment";
import Web3 from "web3";
import PancakePairABI from "../../../../constants/Tooldex/abi/abiPancakePair.json";
import abiLock from "../../../../constants/Tooldex/abi/personal/abiLockLiquidity.json";
import { SC_LOCK_LP_V2 } from "../../../../constants/Tooldex/index";
import { AbiItem } from "web3-utils";
import { useAccount, useNetwork } from "wagmi";
import getTokenInfo from "../../../../utils/Tooldex/checkInfoByAccount";
import { convertToWei } from "../../../../utils/Tooldex/convertNumber";
import CurrencyLogo from "../../../../components/CurrencyLogo";
import Loading from "../../../../components/Tooldex/Loading";
import Value from "../../../../components/Tooldex/Value";

declare const window: Window & typeof globalThis & { ethereum: any };
const ConfigureLock = ({ setStep }: any) => {
  const web3 = new Web3(window.ethereum);
  const { address } = useAccount();
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const lockDex: any = localStorage.getItem("lockDex");
  const lockPair: any = localStorage.getItem("lockPair");
  const lockBalance: any = localStorage.getItem("lockBalance");
  const lockDecimals: any = localStorage.getItem("lockDecimals");
  const lockAddress: any = localStorage.getItem("lockAddress");
  const lockToken0: any = localStorage.getItem("lockToken0");
  const lockToken1: any = localStorage.getItem("lockToken1");

  const [title, setTitle] = useState<any>("");
  const [owner, setOwner] = useState<any>(address|| "");
  const [amountLock, setAmountLock] = useState<any>("");
  const [allowance, setAllowance] = useState<any>("");
  const [timeUnlock, setTimeUnlock] = useState<any>();
  const [pendingLock, setPendingLock] = useState<any>(false);
  const [pendingApprove, setPendingApprove] = useState<any>(false);

  const onChange = (date: any) => {
    setTimeUnlock(Math.floor(new Date(date).getTime() / 1000));
  };
  const getAllowance = async () => {
    try {
      const amountAllowance = await getTokenInfo(
        SC_LOCK_LP_V2[chain?.id],
        lockAddress,
        address
      );
      setAllowance(amountAllowance.allowance);
    } catch (error) {
      setAllowance("0");
      console.error("error", error);
    }
  };
  useEffect(() => {
    getAllowance();
  }, [address, amountLock]);

  const approveLock = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    try {
      setPendingApprove(true);
      const contract = new web3.eth.Contract(
        PancakePairABI as unknown as AbiItem as AbiItem,
        lockAddress
      );
      await contract.methods
        .approve(SC_LOCK_LP_V2[chainId], ethers.constants.MaxUint256.toString())
        .send({ from: address, gasPrice: web3.utils.toHex(String(gasPrice)) })
        .then((res: any) => {
          setPendingApprove(false);
        })
        .catch((err: any) => {
          console.error("Err approve", err);
          notiError(err?.message, 5);
          setPendingApprove(false);
        });
    } catch (error: any) {
      console.error("Error approve", error);
      notiError(error?.message, 5);
      setPendingApprove(false);
    }
  };

  const lockLiquidity = async () => {
    try {
      setPendingLock(true);
      const gasPrice = await web3.eth.getGasPrice();
      if (web3.utils.isAddress(owner)) {
        const contract = new web3.eth.Contract(
          abiLock as unknown as AbiItem as AbiItem,
          SC_LOCK_LP_V2[chainId]
        );
        const amountToWei = convertToWei(amountLock, lockDecimals);
        const fee = await contract.methods.feeLock().call();
        await contract.methods
          .lock(
            owner,
            lockAddress,
            true,
            amountToWei,
            timeUnlock,
            title
          )
          .send({ from: address, value: fee, gasPrice: web3.utils.toHex(String(gasPrice))  })
          .then((res: any) => {
            setPendingLock(false);
            getAllowance()
          })
          .catch((err: any) => {
            console.error("Err lock", err);
            notiError(err.message, 5);
            setPendingLock(false);
          });
      } else {
        notiError("Owner address not found", 5);
        setPendingLock(false);
      }
    } catch (error: any) {
      console.error("Error lock", error);
      notiError(error.message, 5);
      setPendingLock(false);
    }
  };

  return (
    <PairAddressContainer>
      <PABody>
        <NetworkHeader>
          <TextCustom color="#0D0C43" fontSize="24px" fontWeight="bold">
            Configure Lock
          </TextCustom>
        </NetworkHeader>
        <DataFetch>
          <FetchRow>
            <TitleKey>Dex:</TitleKey>
            <TextCustom fontSize="14px" fontWeight="600" color="#0D0C43">
              {lockDex}
            </TextCustom>
          </FetchRow>
          <FetchRow>
            <TitleKey>Pair:</TitleKey>
            <IconInner>
              {lockToken0 && lockToken1 && (
                <div>
                  <CurrencyLogo
                    style={{ margin: "0 -8px 5px" }}
                    address={lockToken0}
                  />
                  <CurrencyLogo address={lockToken1} />
                </div>
              )}

              <TextCustom fontSize="14px" fontWeight="600" color="#0D0C43">
                {lockPair}
              </TextCustom>
            </IconInner>
          </FetchRow>
          <FetchRow>
            <TitleKey>Balance:</TitleKey>
            <TextCustom fontSize="14px" fontWeight="600" color="#0D0C43">
              {lockBalance}
            </TextCustom>
          </FetchRow>
          <FetchRow>
            <TitleKey>Decimals:</TitleKey>
            <TextCustom fontSize="14px" fontWeight="600" color="#0D0C43">
              {lockDecimals}
            </TextCustom>
          </FetchRow>
        </DataFetch>
        <PAContent>
          <ContentBlock>
            <BlockHeader>
              <TitleKey>Title</TitleKey>
            </BlockHeader>
            <InputCommon
              placeHolder="Enter title"
              value={title}
              onChange={(e: any) => setTitle(e.target.value)}
            />
          </ContentBlock>
          <ContentBlock>
            <BlockHeader>
              <TitleKey>Owner</TitleKey>
            </BlockHeader>
            <InputCommon
              placeHolder="Enter Address"
              value={owner}
              onChange={(e: any) => setOwner(e.target.value)}
            />
          </ContentBlock>
          <ContentBlock>
            <BlockHeader>
              <TitleKey>Amount</TitleKey>
              <TextCustom
                fontSize="14px"
                fontWeight="500"
                color="rgba(13, 12, 67, 0.70)"
              >
                Balance:{" "}
                <span style={{ color: "#0D0C43" }}>
                  <Value value={Number(lockBalance)} size={14} />
                </span>
              </TextCustom>
            </BlockHeader>
            <InputCommon
              placeHolder="Enter Address"
              value={amountLock}
              onChange={(e: any) => setAmountLock(e.target.value)}
            />
          </ContentBlock>
          <ContentBlock>
            <BlockHeader>
              <TitleKey>Lock until (UTC time)</TitleKey>
            </BlockHeader>
            <DatePickerCommon>
              <DatePicker
                onChange={onChange}
                style={{ width: "100%" }}
                className="c-dateEdit"
                showTime={true}
                format={"DD/MM/YYYY HH:mm:ss"}
                placeholder="Select time"
                disabledDate={(current) => {
                  return (
                    moment().add(-1, "days") >= current ||
                    moment().add(1, "month") <= current
                  );
                }}
              />
            </DatePickerCommon>
          </ContentBlock>
        </PAContent>
        {Number(allowance) >= Number(amountLock) ? (
          <ButtonCommon
            disabled={
              pendingLock ||
              !address ||
              !title ||
              !owner ||
              !amountLock ||
              !timeUnlock
            }
            onClick={lockLiquidity}
            style={{ margin: "0 auto" }}
          >
            <Loading status={pendingLock} content="Lock" />
          </ButtonCommon>
        ) : (
          <ButtonCommon
            disabled={pendingApprove || !address}
            onClick={approveLock}
            style={{ margin: "0 auto" }}
          >
            <Loading status={pendingApprove} content="Approve" />
          </ButtonCommon>
        )}
      </PABody>
      <ButtonAction>
        <ButtonCommon
          style={{
            border: "2px solid #F313CE",
            padding: "5px 20px",
            width: "auto",
            fontWeight: "600",
          }}
          background="transparent"
          textColor="#F313CE"
          onClick={() => setStep(2)}
        >
          Prev
        </ButtonCommon>
      </ButtonAction>
    </PairAddressContainer>
  );
};

export default ConfigureLock;

import { useState } from "react";
import styled from "styled-components";
import CardModal from "../components/layouts/CardModal";
import { Modal } from "react-bootstrap";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import { ToastContainer, toast } from "react-toastify";
import ToastTyfile from "../components/layouts/Toastify";

const BtnShare = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div>
      <BtnGroup
        onClick={(event) => {
          event.preventDefault();
          setModalShow(true);
        }}
      >
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
          style={{ width: "12px", height: "12px" }}
        >
          <path d="M14.419 10.581a3.564 3.564 0 0 0-2.574 1.1l-4.756-2.49a3.54 3.54 0 0 0 .072-.71 3.55 3.55 0 0 0-.043-.428L11.67 6.1a3.56 3.56 0 1 0-.831-2.265c.006.143.02.286.043.428L6.33 6.218a3.573 3.573 0 1 0-.175 4.743l4.756 2.491a3.58 3.58 0 1 0 3.508-2.871Z" />
        </svg>
      </BtnGroup>
      <ModalShare show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};
export default BtnShare;

const BtnGroup = styled.button`
  padding: 0px;
  width: 40px;
  height: 25px;
  background-color: #f8f8f8;
  border: 1px solid #7a7a7a;
  svg {
    width: 12px;
    height: 12px;
    fill: #7a7a7a;
  }
  :hover {
    border: 1px solid var(--primary-color3);
    svg {
      width: 12px;
      height: 12px;
      fill: var(--primary-color3);
    }
  }
`;

const ModalShare = (props) => {
    const textToCopy = `${window.location.href}`
    const handleCopyToClipboard = () => {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast(<ToastTyfile type="sucsess" description="Copied to clipboard successfully." />, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  })
            })
            .catch((error) => {
                toast(<ToastTyfile type="error" description='Error when copying to clipboard.' />, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            });
        } else {
          toast(<ToastTyfile type="error" description='The browser does not support the Clipboard API' />, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
  return (
    <Modal show={props.show} onHide={props.onHide} onReFetch={props.onReFetch}>
      <PopupContainer>
        <Title>
          <h1>Share</h1>
          <button onClick={props.onHide}>X</button>
        </Title>
        <ListSoccical>
          <FacebookShareButton
            url={`${window.location.href}`}
            quote={   
              "XRender, AI NFT Generator - Generate any NFT collection powered AI"
            }
            hashtag={"#XRender"}
            description={"aiueo"}
            media='https://xrendernft.mypinata.cloud/ipfs/QmQUDCKyJQ47rXi1pmHazHZF9xjngTN98GK46U1kToYNWm'
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton
            title={
              "XRender, AI NFT Generator - Generate any NFT collection powered AI by @XRenderAI" 
            }
            url={`${window.location.href}`}
            hashtags={[ "XRender", "Nft"]}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </ListSoccical>
        <Title>
          <h1>Page Link</h1>
        </Title>
        <InputLink>
          <input type="text" value={`${window.location.href}`} />
          <button onClick={handleCopyToClipboard} >
            <i className="icon-fl-file-1"></i>
          </button>
        </InputLink>
      </PopupContainer>
    </Modal>
  );
};

const PopupContainer = styled.div`
  width: 90vw;
  min-height: 200px;
  max-width: 500px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-size: 20px;
  }
  button {
    padding: 0px;
    background-color: transparent;
    border: 1px solid transparent;
    color: #000;
  }
`;
const ListSoccical = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 15px;
  overflow-y: hidden;
  overflow-x: auto;
`;
const InputLink = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgba(138, 138, 160, 0.3);
  border: 1px solid rgba(138, 138, 160, 0.3);
  padding: 10px;
  border-radius: 10px;
  input {
    padding: 0px;
    border: 1px solid transparent !important;
    outline: none;
  }
  button {
    padding: 0px;
    background-color: transparent;
    border: 1px solid transparent !important;
  }
`;

import styled from "styled-components";
import { TextCustom } from "../../../../components/common/styled";

export const ContentBlock = styled.div`
  text-align: left;
`
export const PAContent = styled.div`
  margin-bottom: 35px;
  & > ${ContentBlock}:not(:last-child) {
    margin-bottom: 12px;
  }
`
export const BlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  ${TextCustom} {
    margin-bottom: 0;
  }
`
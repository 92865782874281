import {
  BonusContainer,
  BonusList,
  BonusWrapper,
  ButtonContract,
} from "./styled";
import bonus_1 from "../../../assets/images/homenew/bonus/bonus_1.png";
import bonus_2 from "../../../assets/images/homenew/bonus/bonus_2.png";
import bonus_3 from "../../../assets/images/homenew/bonus/bonus_3.png";
import arrow from "../../../assets/images/homenew/bonus/arrow.svg";

const Bonus = () => {
  return (
    <BonusContainer>
      <BonusWrapper>
        <h2>
          You might not want to miss out <br/> <span>because of</span>
        </h2>
        <ButtonContract>
          Contract now
          <img src={arrow} alt="arrow" />
        </ButtonContract>
        <BonusList>
          {bonusData.map((item, index) => {
            return (
              <li key={index}>
                <figure>
                  <img src={item.img} alt="" />
                </figure>
                <div>
                  <h3>{item.title}</h3>
                  <p>{item.describe}</p>
                </div>
              </li>
            );
          })}
        </BonusList>
      </BonusWrapper>
    </BonusContainer>
  );
};

const bonusData = [
  {
    img: bonus_1,
    title: "AI Empowered Creativity",
    describe:
      "XRender AI isn't just smart tech; it's a wellspring of inspiration. With AI Bot and AI Image Creation, unlock limitless creative possibilities, turning your ideas into unique realities.",
  },
  {
    img: bonus_2,
    title: "Unique NFT Marketplace",
    describe:
      "XRender NFT Marketplace is more than just a hub for buying and selling NFTs; it's a unique digital art world. Don't miss the chance to experience valuable and meaningful artworks in the booming NFT space.",
  },
  {
    img: bonus_3,
    title: "Smart Communication and Security",
    describe:
      "XRender is not just about technology; it's about intelligent and secure user experiences. AI Chat and Telegram Bot offer natural and flexible communication, while decentralized tools help you maintain control and security in every transaction.",
  },
];

export default Bonus;

import { getProviderOrSigner } from "./index";
import { ethers } from "ethers";
import { ERC20_ABI } from "../ABI";
import Decimal from "decimal.js";

export const approvalToken = async (address_USDT, address_ico, amount) => {
  try {
    const signer = await getProviderOrSigner(true);
    const contractUSDT = new ethers.Contract(address_USDT, ERC20_ABI, signer);
    const decimal = await contractUSDT.decimals();
    const t = await contractUSDT.approve(
      address_ico,
      new Decimal(amount).mul(new Decimal(10).pow(decimal)).toHex()
    );
    await t.wait();
  } catch (error) {
    console.log("Error", error);
  }
};

export const getAllowance = async (address_USDT, contract_address, account) => {
  try {
    const provider = await getProviderOrSigner(false);
    const contractUSDT = new ethers.Contract(address_USDT, ERC20_ABI, provider);
    const decimal = await contractUSDT.decimals();
    const singerAddress = account || ethers.constants.AddressZero;
    const allowance = await contractUSDT.allowance(
      singerAddress,
      contract_address
    );
    return new Decimal(allowance._hex || 0)
      .div(new Decimal(10).pow(decimal))
      .toNumber();
  } catch (error) {
    console.log("Error", error);
  }
};

export const getUSDCBalance = async (address_USDT, account) => {
  try {
    const provider = await getProviderOrSigner(true);
    const contractUSDT = new ethers.Contract(address_USDT, ERC20_ABI, provider);
    const decimal = await contractUSDT.decimals();
    const singerAddress = account || ethers.constants.AddressZero;
    const balance = await contractUSDT.balanceOf(singerAddress);
    return new Decimal(balance._hex || 0)
      .div(new Decimal(10).pow(decimal || 0))
      .toNumber();
  } catch (error) {
    console.log("Error", error);
  }
};

export const getXRaiBalance = async (address_XRAI, account) => {
  try {
    const provider = await getProviderOrSigner(true);
    const contractUSDT = new ethers.Contract(address_XRAI, ERC20_ABI, provider);
    const decimal = await contractUSDT.decimals();
    const singerAddress = account || ethers.constants.AddressZero;
    const balance = await contractUSDT.balanceOf(singerAddress);
    return new Decimal(balance._hex || 0)
      .div(new Decimal(10).pow(decimal || 0))
      .toNumber();
  } catch (error) {
    console.log("Error", error);
  }
};

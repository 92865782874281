import styled from "styled-components";
import { KeyvisualContainer } from "../../../components/common/keyvisual/styled";
import { TextCustom } from "../../../components/common/styled";
import { TabsContainer } from "../../../components/common/tabs/styled";

export const LiquidityContainer = styled.div`
  position: relative;
  z-index: 5;
  ${KeyvisualContainer} {
    ${TextCustom} {
      margin-bottom: 20px;
    }
  }
  ${TabsContainer} {
    .ant-tabs-tab {
      padding: 10px 35px;
    }
    .ant-tabs>.ant-tabs-nav {
      margin-bottom: 0;
    }
  }
`
export const LiquidityBody = styled.div`
  &.connect-wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vw - 400px);
  }
`
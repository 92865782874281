import styled from "styled-components";
import { TextCommon, TextCustom } from "../styled";

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px auto;
  ${TextCustom} {
    margin-bottom: 10px;
    line-height: normal;
  }
  ${TextCommon} {
    margin-bottom: 0;
  }
  img {
    margin-bottom: 25px;
  }
`
export const LoaderContainer = styled.div`
  width: 56px;
  height: 56px;
  margin: 0 auto;
  border-radius: 50%;
  border: 6px solid #C9DADA;
  border-top-color: #F313CE;
  animation: loader 1.5s linear infinite;
  @keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`
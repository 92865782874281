import styled from "styled-components";
import { TextCustom } from "../../../components/common/styled";
import { KeyvisualContainer } from "../../../components/common/keyvisual/styled";

export const PortfolioContainer = styled.div`
  ${KeyvisualContainer} {
    ${TextCustom} {
      margin-bottom: 0px;
    }
    .keyvisual-title {
      line-height: normal;
      margin-bottom: 15px;
      span {
        display: block;
        margin-left: 0;
      }
    }
    .ant-tabs-top>.ant-tabs-nav {
      margin-bottom: 0;
    }
  }
`
export const PortfolioBody = styled.div`
  position: relative;
  z-index: 5;
  padding: 25px;
  @media screen and (max-width: 1023px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`
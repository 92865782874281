import { CommonTitle } from "../Features/styled";
import {
  NextButton,
  OurTeamContainer,
  OurTeamSlide,
  OurTeamWrapper,
  PrevButton,
} from "./styled";
import team_1 from "../../../assets/images/homenew/ourteam/team_1.png";
import team_2 from "../../../assets/images/homenew/ourteam/team_2.png";
import team_3 from "../../../assets/images/homenew/ourteam/team_3.png";
import team_4 from "../../../assets/images/homenew/ourteam/team_4.png";
import team_5 from "../../../assets/images/homenew/ourteam/team_5.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";
import { useContext, useRef } from "react";
import SwipeTeamItem from "./Item";
import { ModalConfirmContext } from "../../../components/ProviderPopUp/confirm";

SwiperCore.use([Navigation]);

const OurTeam = () => {
  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);
  const { isDesktop } = useContext(ModalConfirmContext)!;
  return (
    <OurTeamContainer>
      <OurTeamWrapper>
        <CommonTitle>
          <span>Our Team</span>
          <span>XRender Ai Our Team description</span>
        </CommonTitle>
        <OurTeamSlide>
          <Swiper
            centeredSlides={isDesktop ? false : true}
            slidesPerView={!isDesktop ? "auto" : 4}
            spaceBetween={20}
            navigation={{
              prevEl: prevRef.current ? prevRef.current : undefined,
              nextEl: nextRef.current ? nextRef.current : undefined,
            }}
            onBeforeInit={(swiper: any) => {
              swiper.params.navigation.prevEl = prevRef.current!;
              swiper.params.navigation.nextEl = nextRef.current!;
            }}
            autoplay
            loop
            className="mySwiper"
          >
            {ourTeamData.map((item, index) => {
              return (
                <SwiperSlide>
                  <SwipeTeamItem item={item} key={index} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </OurTeamSlide>
        {isDesktop && (
          <>
            <PrevButton ref={prevRef} />
            <NextButton ref={nextRef} />
          </>
        )}
      </OurTeamWrapper>
    </OurTeamContainer>
  );
};

const ourTeamData = [
  {
    id: 1,
    img: team_1,
    title: "Cen",
    describe: "Founder & CEO",
    quote: `As the architect of our AI journey, I envision a future where innovation meets opportunity. Our code is the language that speaks prosperity, and our team, the authors of financial evolution`,
  },
  {
    id: 2,
    img: team_2,
    title: "Edric",
    describe: "Chief Marketing Officer",
    quote: `"In the vast digital landscape, we paint a portrait of our AI solutions. Marketing isn't just about selling; it's about telling the story of transformation, where algorithms redefine possibilities and images capture imagination."`,
  },
  {
    id: 3,
    img: team_3,
    title: "Cherry",
    describe: "Chief Strategy Officer",
    quote: `"In the chessboard of markets, we strategize, foreseeing moves before they happen. Our AI isn't just a tool; it's a strategic partner in navigating the complexities of trading, ensuring victory in every algorithmic play."`,
  },
  {
    id: 4,
    img: team_4,
    title: "Louis",
    describe: "Chief Technology Officer",
    quote: `"In the realm of ones and zeros, our technology isn't just cutting-edge; it's the frontier of possibilities. As the architects of innovation, we build bridges between algorithms and reality, where every line of code shapes the future."`,
  },
  {
    id: 5,
    img: team_5,
    title: "John",
    describe: "Project Developer",
    quote: `"Projects are the blueprints of our dreams. Each line of code and every milestone achieved is a step closer to our vision. In the crucible of development, we shape the future, one algorithm and image at a time."`,
  },
];

export default OurTeam;

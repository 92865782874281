import { useWeb3React } from "@web3-react/core";
import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ModalConfirmContext } from "../ProviderPopUp/confirm";
import { useActiveWeb3React } from "../../hooks";
import {
  STAKING_OPBNB_TESTNET,
  STAKING_ARB_MAINNET,
  ABI_STAKING,
} from "../../integrateContract/ABI";
import {
  calculateReward,
  harvest,
} from "../../integrateContract/contract/handleStaking";
import Decimal from "decimal.js";
import { ToastContainer, toast } from "react-toastify";
import ToastTyfile from "../../components/layouts/Toastify";

export const StakingReward = (props) => {
  const { poolIndex, tokenBalance } = props;
  const { onClose } = useContext(ModalConfirmContext);
  const context = useWeb3React();
  const { active } = context;
  const { chainId } = useWeb3React();
  const { account } = useActiveWeb3React();
  const [reward, setReward] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleHarvest = async () => {
    setIsLoading(true);
    try {
      let addressStaking = "";
      let contract = null;
      switch (chainId) {
        case 5611:
          addressStaking = STAKING_OPBNB_TESTNET;
          contract = ABI_STAKING;
          break;
        case 42161:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          break;
        default:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          break;
      }
      await harvest(poolIndex, addressStaking, contract);
      toast(
        <ToastTyfile type="sucsess" description={`Successful harvest $XRAI`} />,
        { progress: 0, hideProgressBar: true }
      );
    } catch (e) {
      toast(
        <ToastTyfile
          type="error"
          description={e?.reason?.replace("execution reverted: ", "")}
        />,
        { progress: 0, hideProgressBar: true }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!account) return;
    (async () => {
      let addressStaking = "";
      let contract = null;
      switch (chainId) {
        case 5611:
          addressStaking = STAKING_OPBNB_TESTNET;
          contract = ABI_STAKING;
          break;
        case 42161:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          break;
        default:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          break;
      }
      const reward = await calculateReward(
        poolIndex,
        addressStaking,
        contract,
        account
      );
      setReward(reward);
    })();
  }, [account, chainId, poolIndex]);

  return (
    <ModalStakingBox>
      <ToastContainer />
      <div className="box">
        <h6>Earned XRAI</h6>
        <div className="action d-flex justify-content-between align-items-center">
          <div className="token">
            <h6>
              {new Intl.NumberFormat().format(new Decimal(reward || 0))}{" "}
              <span>$XRAI</span>
            </h6>
            {/* <h6>
            $
            {new Intl.NumberFormat().format(
              new Decimal(reward || 0).mul(0.0003).toNumber()
            )}{" "}
          </h6> */}
          </div>
          <button
            style={{
              cursor:
                (!account || isLoading || new Decimal(reward || 0).eq(0)) &&
                "not-allowed",
            }}
            disable={!account || isLoading || new Decimal(reward || 0).eq(0)}
            onClick={() => {
              account && !new Decimal(reward || 0).eq(0) && handleHarvest();
            }}
            className="btn-harvest"
          >
            Harvest
          </button>
        </div>
      </div>
    </ModalStakingBox>
  );
};

const ModalStakingBox = styled.div`
  width: 490px;
  .input-amount {
    p {
      color: #000000;
      font-size: 16.496px;
      font-style: normal;
      font-weight: 500;
      line-height: 28.28px; /* 171.429% */
    }
    input {
      border: 1px solid #ed13d2;
      color: #000000;
    }
  }
  .action {
    gap: 20px;
    button {
      width: 50%;
      padding: 12.961px 23.566px;
      &:nth-child(1) {
        background-color: #ffffff;
        color: #ed13d2;
        &:hover {
          background-color: #ed13d2;
          color: #ffffff;
        }
      }
      &:nth-child(2) {
        &:hover {
          background-color: #ffffff;
          color: #ed13d2;
        }
      }
    }
  }
`;

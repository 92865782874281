import { useState } from "react";
import Progress from "./Progress";
import { Wapper, BoxOneElm, BoxTowElm, BtnGroup } from "./style";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../../../../utils/axios";
import ToastTyfile from "../../../../layouts/Toastify";
import { useAccount } from "wagmi";

const InputComponent = ({ onSetImage }: any) => {
  const [numOutPut, setNumOutpt] = useState<any>(1);
  const [steps, setSteps] = useState<any>(50);
  const [Scale, setScale] = useState<any>(7);
  const [chooseSize, setChooseSize] = useState<number>(2);
  const [showListSize, setShowListSize] = useState<boolean>(false);
  const [promptVal, setPromptVal] = useState<string>(
    "An astronaut riding a rainbow unicorn, cinematic, dramatic"
  );
  const [urlImage, setUrlImage] = useState<any>("");
  const [Negative, setNegative] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const { address: account } = useAccount();

  const getvalueOutPut = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue === "" || /^\d*$/.test(inputValue)) {
      if (Number(inputValue) > 4) {
        setNumOutpt(4);
      } else {
        setNumOutpt(inputValue);
      }
    }
  };
  const getvaluesteps = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue === "" || /^\d*$/.test(inputValue)) {
      if (Number(inputValue) > 200) {
        setSteps(200);
      } else {
        setSteps(inputValue);
      }
    }
  };
  const getvalueScale = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue === "" || /^\d*$/.test(inputValue)) {
      if (Number(inputValue) > 20) {
        setScale(20);
      } else {
        setScale(inputValue);
      }
    }
  };
  const handleBlur = (e: any, key: any) => {
    if (!e.target.value || Number(e.target.value) === 0) {
      switch (key) {
        case "outPut":
          setNumOutpt(1);
          break;
        case "steps":
          setSteps(1);
          break;
        case "scale":
          setScale(1);
          break;
        default:
          return null;
      }
    }
  };
  const handleReset = () => {
    setNumOutpt(1);
    setChooseSize(0);
    setSteps(10);
    setScale(1);
    setPromptVal("");
    setNegative("");
    setSubmitError(false);
  };
  const handleSubmit = async () => {
    try {
      if (promptVal) {
        setIsLoading(true);
        const params = {
          prompt: promptVal,
          imageDimensions: ListSizeImg[chooseSize].title,
          numOutputs: numOutPut,
          negativePrompt: Negative,
          numInferenceSteps: steps,
          guidanceScale: Scale,
          address: account || ''
        };
        const res: any = await axiosInstance.post(
          "/ai-image/text-to-image",
          params
        );
        setIsLoading(false);
        if (res) {
          const { data } = res;
          onSetImage(data?.images);
          setUrlImage(data?.images);
        }
      } else {
        setSubmitError(true);
      }
    } catch (error) {
      setIsLoading(false);
      toast(
        <ToastTyfile
          type="error"
          description="Generate image unsuccessfully !"
        />,
        { progress: 0, hideProgressBar: true }
      );
    }
  };

  return (
    <Wapper>
      <h1>Input</h1>
      <BoxOneElm required={true} submitError={submitError}>
        <h6>Prompt</h6>
        <input
          type="text"
          placeholder={submitError ? "is required" : ""}
          value={promptVal}
          onChange={(e) => {
            setPromptVal(e.target.value);
          }}
          onBlur={(e) => {
            e.target.value ? setSubmitError(false) : setSubmitError(true);
          }}
        />
        <p>Input prompt</p>
      </BoxOneElm>
      <BoxOneElm showListSize={showListSize}>
        <h6>Image Dimensions</h6>
        <button
          onClick={() => {
            setShowListSize(!showListSize);
          }}
        >
          {ListSizeImg[chooseSize].title}
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </button>
        <ul>
          {ListSizeImg.map((item, index) => {
            return (
              <li
                key={item.id}
                onClick={() => {
                  setChooseSize(index);
                  setShowListSize(false);
                }}
                style={{
                  fontSize: "16px",
                }}
              >
                {item.title}
              </li>
            );
          })}
        </ul>
        <p>Pixel dimensions of output image</p>
      </BoxOneElm>
      <BoxOneElm>
        <h6>Negative Prompt</h6>
        <input
          type="text"
          placeholder="blurry, ugly"
          value={Negative}
          onChange={(e) => {
            setNegative(e.target.value);
          }}
        />
        <p>Specify things to not see in the output</p>
      </BoxOneElm>
      <BoxTowElm>
        <h6>Num Outputs</h6>
        <input
          type="number"
          value={numOutPut}
          onChange={(e) => {
            getvalueOutPut(e);
          }}
          onBlur={(e) => {
            handleBlur(e, "outPut");
          }}
        />
        <Progress
          value={numOutPut}
          min={1}
          max={4}
          changeVal={getvalueOutPut}
        />
        <p>Number of images to output. (minimum: 1; maximum: 4)</p>
      </BoxTowElm>
      <BoxTowElm>
        <h6>Num Inference Steps</h6>
        <input
          type="number"
          value={steps}
          onChange={(e) => {
            getvaluesteps(e);
          }}
          onBlur={(e) => {
            handleBlur(e, "steps");
          }}
        />
        <Progress value={steps} min={10} max={200} changeVal={getvaluesteps} />
        <p>Number of denoising steps (minimum: 10; maximum: 200)</p>
      </BoxTowElm>
      <BoxTowElm>
        <h6>Guidance Scale</h6>
        <input
          type="number"
          value={Scale}
          onChange={(e) => {
            getvalueScale(e);
          }}
          onBlur={(e) => {
            handleBlur(e, "scale");
          }}
        />
        <Progress value={Scale} min={1} max={20} changeVal={getvalueScale} />
        <p>Scale for classifier-free guidance (minimum: 1; maximum: 20)</p>
      </BoxTowElm>
      <BtnGroup>
        <button onClick={handleReset}>
          <img
            style={{
              marginRight: "10px",
            }}
            src="/assets/icon/refresh.png"
            alt="refresh"
          />{" "}
          Reset
        </button>
        <button
          onClick={handleSubmit}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        >
          <img
            style={{
              marginRight: "10px",
            }}
            src="/assets/icon/submit.png"
            alt="submit"
          />{" "}
          Submit
          {isLoading && (
            <i
              style={{
                marginLeft: "10px",
              }}
              className="fa fa-spinner fa-spin"
            ></i>
          )}
        </button>
      </BtnGroup>
    </Wapper>
  );
};
export default InputComponent;

const ListSizeImg = [
  {
    id: 1,
    type: 1,
    title: "512x512",
  },
  {
    id: 2,
    type: 2,
    title: "768x768",
  },
  {
    id: 3,
    type: 3,
    title: "1024x1024",
  },
];

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ToastTyfile from "../components/layouts/Toastify";
import {
  Container,
  ContentBox,
  Layout,
  GifLayout,
  BoxCoppyContrac,
  ButnSubMit,
  BtnGroup,
} from "./MinNftStyle";
import { ToastContainer, toast } from "react-toastify";
import { useState, useMemo, useEffect, useContext, useRef } from "react";

import { ethers } from "ethers";
import { ModalConfirmContext } from "../components/ProviderPopUp/confirm";
import img3 from "../assets/images/avatar/avt-9.jpg";
import { SignerContractNFT } from "../integrateContract/contract";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import {
  ARB_NFT_Lufffy_ABI,
  OpBNB_NFT_LUFFY_ABI,
  ZETA_NFT_Luffy_ABI,
  address_NFT_LUFFY_ARB,
  address_NFT_LUFFY_OPBNB_MainNet,
  address_NFT_LUFFY_Zeta_TestNet,
} from "../integrateContract/ABI";
import { LIST_NETWORK } from "../constants/connectors";
import { useWeb3React } from "@web3-react/core";
import { ModalMintNFT } from "../components/AbrNftAI/Body/minComponent/ModalMintNFT";
import Web3 from "web3";
import { BoxNotice, Notice } from "../components/layouts/home-5/TodayPicks";
import { shortenAddress } from "../utils/formartAddress";
import dayjs from "dayjs";
import { defineName } from "../utils/defineName";
import axiosInstance from "../utils/axios";
import { useAccount, useChainId } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { switchNetwork } from "@wagmi/core";
import { WrapperBg } from "../components/layouts/styled";
const web3 = new Web3(window.ethereum);

const MinNft = () => {
  const { open } = useWeb3Modal();
  const context = useWeb3React();
  const [ContractMint, setContractMint] = useState("");
  const { address: account } = useAccount();
  const chainId = useChainId();
  const { onOpen, onClose } = useContext(ModalConfirmContext);
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const [netWorkActive, setNetWorkActive] = useState();
  const [curentPage, setCurentPage] = useState(1);
  const [totalpage, setTotalPage] = useState(0);
  const TitleMint = useMemo(() => {
    if (account) {
      if (isLoading) {
        return "Loading...";
      } else {
        return "Mint NFT";
      }
    } else {
      return "Connect Wallet";
    }
  }, [account, isLoading]);

  const handleClickSubmit = async (address_NFT_Chain, abi_NFT_Chain) => {
    if (account) {
      setIsLoading(true);
      const contract = await SignerContractNFT(
        address_NFT_Chain,
        abi_NFT_Chain
      );

      contract
        .getPriceMint()
        .then((price) => {
          contract
            .mint({
              ...(chainId === 42161
                ? { gasLimit: 500000 }
                : { gasLimit: 300000 }),
              value: price.toString(),
            })
            .then((res) => {
              if (res.hash) {
                handleValidateMint(res.hash);
              }
            })
            .catch((err) => {
              if (err && err.code === 4001) {
                toast(
                  <ToastTyfile
                    type="error"
                    description="You reject transition"
                  />,
                  {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
              } else {
                toast(
                  <ToastTyfile
                    type="error"
                    description="An error occurred while processing your request. Please try again later."
                  />,
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
              }
              setIsLoading(false);
            });
        })
        .catch((err) => {
          console.log("error", err);
          setIsLoading(false);
        });
    } else {
      open();
    }
  };

  const [dataHistory, setDataHistory] = useState([]);

  const [trains, setTrains] = useState([]);
  const handleValidateMint = async (hash) => {
    const defaultNetwork = LIST_NETWORK.find(
      (item) => Number(item.chainId) === Number(chainId)
    );
    const provider = new ethers.providers.JsonRpcProvider(
      defaultNetwork?.params?.rpcUrls[0]
    );
    const receipt = await provider.waitForTransaction(hash);
    if (receipt && receipt.status === 1) {
      const hex = receipt?.logs[0]?.topics[3] || "";
      const tokenIdFromHash = await web3.utils.hexToNumber(hex);
      const transactionHash = receipt.transactionHash;
      onOpen(
        <ModalMintNFT
          onCreate={() => {
            onClose();
          }}
          image={"urlImage"}
          isNotice={true}
          token={{
            id: tokenIdFromHash,
            link: `${defaultNetwork?.params.blockExplorerUrls}tx/${transactionHash}`,
            hash: transactionHash,
            image: defaultNetwork.minBanner,
            collection: "XRender Luffy Colletion",
          }}
        />
      );

      toast(
        <ToastTyfile type="sucsess" description="Mint Nft successfully" />,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } else if (receipt && receipt.status === 0) {
      toast(
        <ToastTyfile type="error" description="You have minted NFTs before." />,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      toast(
        <ToastTyfile
          type="error"
          description="Unsuccessful NFT minting, please try again."
        />,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
    setIsLoading(false);
  };
  const handleChainMint = async () => {
    const params = search.split("?network=");
    if (params && params[1]) {
      // switch network
      const network = LIST_NETWORK.find(
        (item) => Number(item.chainId) === Number(params[1])
      );
      if (Number(network?.chainId) !== chainId) {
        await switchNetwork({
          chainId: Number(network?.chainId),
        });
      } else {
        if (chainId === 204) {
          handleClickSubmit(
            address_NFT_LUFFY_OPBNB_MainNet,
            OpBNB_NFT_LUFFY_ABI
          );
        } else if (chainId === 7001) {
          handleClickSubmit(address_NFT_LUFFY_Zeta_TestNet, ZETA_NFT_Luffy_ABI);
        } else if (chainId === 42161) {
          handleClickSubmit(address_NFT_LUFFY_ARB, ARB_NFT_Lufffy_ABI);
        } else {
          toast(
            <ToastTyfile
              type="error"
              description="Please switch networks before continuing with Mint."
            />,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      }
    }
  };

  useEffect(() => {
    const params = search.split("?network=");
    if (params && params[1]) {
      const defaultNetwork = LIST_NETWORK.find(
        (item) => Number(item.chainId) === Number(params[1])
      );
      if (defaultNetwork) {
        setNetWorkActive(defaultNetwork);
      } else {
        setNetWorkActive(LIST_NETWORK[0]);
      }
    }
  }, [account, search]);
  const [updateChainId, setUpdateChainId] = useState(false);
  useEffect(() => {
    if (!updateChainId && context.chainId) {
      setUpdateChainId(true);
    }
  }, [context]);

  const scrollContainerRef = useRef(null);
  const postDataToAPI = async () => {
    try {
      const dataToSend = {
        limit: 10,
        page: curentPage,
        sortBy: {
          _id: -1,
        },
        filterBy: {
          tokenAddress: ContractMint,
          transactionType: "MINT",
        },
      };
      const params = search.split("?network=");
      if (params && params[1]) {
        const { data } = await axiosInstance.post(
          `/nft/history-nft?chainId=${params[1]}`,
          dataToSend
        );
        setDataHistory([...dataHistory, ...data.nftList]);
        setCurentPage(curentPage + 1);
        if (!totalpage) {
          setTotalPage(data.totalPage);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (
      context &&
      chainId &&
      ContractMint
      // ContractMint !== "Don't support"
    ) {
      setDataHistory([]);
      postDataToAPI();
    }
  }, [chainId, context, ContractMint]);

  const handleScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const isAtBottom =
        curentPage <= totalpage &&
        scrollContainer.scrollHeight - scrollContainer.scrollTop ===
          scrollContainer.clientHeight;
      if (isAtBottom) {
        postDataToAPI();
      }
    }
  };

  return (
    <div>
      <WrapperBg style={{ padding: "0" }}>
        <Container style={{ position: "relative", zIndex: "5" }}>
          {netWorkActive && (
            <ContentBox>
              <Layout>
                <GifLayout>
                  <img
                    style={{
                      borderRadius: "32px",
                    }}
                    src={netWorkActive?.minBanner}
                    alt=""
                  />
                </GifLayout>
              </Layout>
              <div style={{ width: "100%", position: "relative" }}>
                <div style={{ maxWidth: "600px", margin: "0px auto" }}>
                  <h3 style={{ color: "#fff", fontSize: "25px" }}>
                    {`XRender Luffy ${netWorkActive?.name}`}{" "}
                  </h3>
                  <ul>
                    <div style={{ paddingBottom: "5px" }}>
                      <li style={{ fontFamily: "unset", color: "#fff" }}>
                        {`${netWorkActive?.detail.text1} `}
                      </li>
                    </div>
                    <div style={{ paddingBottom: "5px" }}>
                      <li style={{ fontFamily: "unset", color: "#fff" }}>
                        {`${netWorkActive?.detail.text2} `}
                      </li>
                    </div>
                    <div style={{ paddingBottom: "5px" }}>
                      <li style={{ fontFamily: "unset" }}>
                        <span style={{ color: "#fff" }}>Contract:</span>{" "}
                        <a
                          href={`${netWorkActive?.detail.contract}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ wordBreak: "break-word" }}
                        >{`${netWorkActive?.detail.contract} `}</a>
                      </li>
                    </div>
                    <div style={{ paddingBottom: "5px" }}>
                      <li style={{ fontFamily: "unset", color: "#fff" }}>
                        {`${netWorkActive?.detail.text3} `}
                      </li>
                    </div>
                  </ul>
                  <BoxNotice
                    style={{
                      background: `rgb(255, 0, 0,  0.1)`,
                      border: "1px solid red",
                    }}
                    className="d-flex align-items-center mb-4"
                  >
                    <div
                      className="img-notice"
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      <img src="/assets/icon/icon-sound.png" alt="" />
                    </div>
                    <Notice
                      style={{
                        color: "red",
                      }}
                    >
                      Note: Each wallet can only mint one NFT
                    </Notice>
                  </BoxNotice>
                  <BtnGroup>
                    <BoxCoppyContrac>
                      <img src="/assets/icon/icon-colection.svg" alt="" />
                      <div>
                        <h4>Collection Name</h4>
                        <h3>XRender Luffy Colletion</h3>
                      </div>
                    </BoxCoppyContrac>
                    <BoxCoppyContrac>
                      <img src={img3} alt="" />
                      <div>
                        <h4>Type:</h4>
                        <h3>
                          {" "}
                          {netWorkActive.chainId === 42161
                            ? "0.0015 ETH"
                            : netWorkActive.chainId === 204
                            ? "0.0015 BNB"
                            : "Free mint"}{" "}
                        </h3>
                      </div>
                    </BoxCoppyContrac>
                  </BtnGroup>
                  <BtnGroup>
                    <BoxCoppyContrac>
                      <img src={netWorkActive?.image} alt="" />
                      <div>
                        <h4>Symbol</h4>
                        <h3> XRender </h3>
                      </div>
                    </BoxCoppyContrac>
                    <BoxCoppyContrac>
                      <img src="/assets/icon/icon-total.svg" alt="" />
                      <div>
                        <h4>Total Supply</h4>
                        <h3>9,000</h3>
                      </div>
                    </BoxCoppyContrac>
                  </BtnGroup>
                  <div style={{ marginBottom: "12px" }}>
                    <ButnSubMit
                      className={`sc-button style ${
                        account ? "bag" : "wallet"
                      } fl-button pri-3 `}
                      onClick={() => handleChainMint()}
                    >
                      <span>{TitleMint}</span>
                    </ButnSubMit>
                  </div>
                  <div style={{ paddingTop: "12px" }}>
                    <div className="flat-tabs themesflat-tabs">
                      <Tabs>
                        <TabList>
                          <Tab>Activity</Tab>
                        </TabList>
                        <TabPanel>
                          <ul
                            className="bid-history-list"
                            ref={scrollContainerRef}
                            style={{ maxHeight: "300px", overflowX: "auto" }}
                            onScroll={handleScroll}
                          >
                            {dataHistory?.map((item, index) => (
                              <li key={index} item={item}>
                                <div
                                  className="content"
                                  style={{ gap: "10px" }}
                                >
                                  <Activity className="client">
                                    <Activity
                                      className="sc-author-box style-2"
                                      style={{ gap: "10px" }}
                                    >
                                      <div className="author-avatar m-0">
                                        <Link to="#">
                                          <img
                                            src={
                                              defineName(item?.transactionType)
                                                ?.image
                                            }
                                            style={{
                                              maxWidth: "44px",
                                              maxHeight: "44px",
                                            }}
                                            alt=""
                                            className="avatar event-activity"
                                          />
                                        </Link>
                                      </div>
                                      <div className="author-infor">
                                        <div
                                          className="name"
                                          style={{
                                            justifyContent: "flex-start",
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h6 style={{ textAlign: "left" }}>
                                            <Link
                                              style={{
                                                color: "#f612d1 ",
                                              }}
                                              to="#"
                                            >
                                              {defineName(item?.transactionType)
                                                ?.name || ""}{" "}
                                              #{item.tokenId}
                                            </Link>
                                          </h6>
                                        </div>
                                        <Link
                                          to={`${netWorkActive?.params.blockExplorerUrls}tx/${item.transactionHash}`}
                                          target="_blank"
                                          className="time"
                                          style={{
                                            textAlign: "left",
                                            display: "flex",
                                          }}
                                        >
                                          {item.transactionHash
                                            ? `Tnx Hash:  ${shortenAddress(
                                                item.transactionHash
                                              )}`
                                            : ""}
                                        </Link>

                                        <span
                                          className="time"
                                          style={{
                                            textAlign: "left",
                                            display: "flex",
                                          }}
                                        >
                                          {item.createdAt
                                            ? `Time: ${dayjs(
                                                item.createdAt
                                              ).format("HH:mm MM/DD/YYYY")}`
                                            : ""}
                                          {/* &#160;
                                      {item.to ? `- To: ${item.to}` : ""} */}
                                        </span>
                                      </div>
                                    </Activity>
                                    {/* <div className="price">
                                  <h5>price: {item.price || "--"}</h5>
                                  <span>
                                    {item.priceChange
                                      ? `=${item.priceChange}`
                                      : ""}
                                  </span>
                                  <span>{item.createdAt || ""}</span>
                                </div> */}
                                  </Activity>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </TabPanel>
                        <TabPanel>
                          <ul className="bid-history-list">
                            <li>
                              <div className="content">
                                <div className="client">
                                  <div className="sc-author-box style-2">
                                    <div className="author-avatar"></div>
                                    <TransTab className="author-infor">
                                      {trains.map((item, index) => {
                                        return (
                                          <>
                                            {item.value && (
                                              <BoxTrani>
                                                <div
                                                  className="name"
                                                  key={index}
                                                >
                                                  {/* {handleLogo(item.trait_type)} */}
                                                  <h6> {item.trait_type} </h6>{" "}
                                                  {/* <span> place a bid</span> */}
                                                </div>
                                                <span className="time">
                                                  {item.value}
                                                </span>
                                              </BoxTrani>
                                            )}
                                          </>
                                        );
                                      })}
                                    </TransTab>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </ContentBox>
          )}
        </Container>
      </WrapperBg>
      <ToastContainer />
    </div>
  );
};

const TransTab = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

const BoxTrani = styled.div`
  width: calc((100% - 45px) / 4);
  align-self: stretch;
  display: flex;
  border-radius: 10px;
  background: #ffeffc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    img {
      margin-bottom: 10px;
      max-width: 43px;
      border-radius: 12px;
    }
  }
  span {
    color: #000 !important ;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  h6 {
    color: #7b7b7b;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
`;

const Activity = styled.div`
  display: flex !important;
`;

export default MinNft;

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Footer from "../components/footer/Footer";
import CardModal from "../components/layouts/CardModal";
import { message } from "antd";
import { unListNFT } from "../../src/integrateContract/contract/";
import { ethers } from "ethers";
import CardModalUpdatePrice from "../components/layouts/CardModalUpdatePrice";
import { Nodata } from "../components/layouts/home-5/TodayPicksStyle";
import NodataImg from "../assets/images/logo/No-nft.svg";
import { RefreshContext } from "../context/RefreshContext";
import { ToastContainer, toast } from "react-toastify";
import Countdown from "react-countdown";
import {
  ADDR_MKP_ARB_MAINNET,
  ADDR_MKP_BNB_MAINNET,
  ADDR_MKP_OPBNB_MAINNET,
  ADDR_MKP_ZETA_TESTNET,
  ARB_MKP_BuySell_Contract_ABI,
  BNB_MKP_ABI_MAINNET,
  OPBNB_MKP_BuySell_Contract_ABI,
  ZETA_MKP_BuySell_Contract_ABI,
} from "../integrateContract/ABI";
import axiosInstance from "../utils/axios";
import EditProfile from "./EditProfile";
import styled from "styled-components";
import { formartImage } from "../utils/formartImage";
import { isUrlImage } from "../utils/checkTypeImg";
import { LIST_NETWORK } from "../constants/connectors";
import ToastTyfile from "../components/layouts/Toastify";
import LoadingScreen from "./Loading";
import { useAccount, useChainId } from "wagmi";
import CopyToClipboard from "react-copy-to-clipboard";
import { WrapperBg } from "../components/layouts/styled";

const Authors02 = () => {
  const { address: account } = useAccount();
  const chainId = useChainId();
  const accountEllipsis = account
    ? `${account?.substring(0, 7)}...${account?.substring(account.length - 7)}`
    : "Connect Wallet ";

  const { getOpBnbBalance, handleGetOpBnbBalance } = useContext(RefreshContext);

  const [tabIndex, setTabIndex] = useState(0);
  const [menuTab] = useState([
    {
      class: "active",
      name: "Items",
    },
    {
      class: "",
      name: "On Sale",
    },
    {
      class: "",
      name: "Edit Profile",
    },
  ]);

  const [modalShow, setModalShow] = useState(false);
  const [modalShowUpdatePice, setModalShowUpdatePice] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [idPopup, setIdPopup] = useState(0);

  const [dataNFT, setDataNFT] = useState([]);
  const [dataOnSaleNFT, setDataOnSaleNFT] = useState([]);
  const [infoUser, setInfoUser] = useState(null);
  const [currentPageItems, setCurrentPageItems] = useState(1);
  const [currentPageOnSale, setCurrentPageOnSale] = useState(1);
  const [totalDataNFT, setTotalDataNFT] = useState(0);
  const [totalDataOnSaleNFT, setTotalDataOnSaleNFT] = useState(0);

  // const ENSName = useEnsName();
  const getItemsNFTProfile = async (chainId, page, type) => {
    try {
      const params = {
        address: account,
        chainId:
          chainId === 56 ||
          chainId === 204 ||
          chainId === 7001 ||
          chainId === 42161
            ? chainId
            : 42161,
        page,
        limit: 12,
      };
      const { data } = await axiosInstance.get(`/nft/list-nft`, {
        params,
      });
      const oldNFT = type === "all" ? [] : dataNFT;
      const newData = data?.listNft?.map((item) => {
        return {
          ...item,
          tokenUri: isUrlImage(item.image)
            ? JSON.parse(item.tokenUri)
            : item.tokenUri,
        };
      });
      setDataNFT(oldNFT.concat(newData));
      setTotalDataNFT(data.total);
    } catch (error) {
      console.log(error);
    }
  };
  const getItemsNFTOnsale = async (chainId, page, type) => {
    try {
      const params = {
        address: account,
        chainId:
          chainId === 56 ||
          chainId === 204 ||
          chainId === 7001 ||
          chainId === 42161
            ? chainId
            : 42161,
        page,
        limit: 12,
      };
      const { data } = await axiosInstance.get(`/item/list-items`, { params });
      const oldNFT = type === "all" ? [] : dataOnSaleNFT;
      const newData = data?.items?.map((item) => {
        return {
          ...item,
          tokenUri: item.metadata
            ? JSON.parse(item.metadata)
            : JSON.parse(item.tokenUri),
        };
      });
      setDataOnSaleNFT(oldNFT.concat(newData));
      setTotalDataOnSaleNFT(data.total);
    } catch (error) {
      console.log("getItemsNFTOnSale", error);
    }
  };
  const unListNFTOnSale = async (orderid, address_MKP_Chain, abi_MKP_Chain) => {
    setIsLoading(true);
    try {
      const res = await unListNFT(orderid, address_MKP_Chain, abi_MKP_Chain);
      await res.wait();
      handleGetOpBnbBalance();
      if (res) {
        await handleGetAllNFT();
        setIsLoading(false);
        toast(
          <ToastTyfile type="sucsess" description="UnList NFT successfully" />,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleUnListNFTOnSale = (orderid) => {
    if (chainId === 56) {
      unListNFTOnSale(orderid, ADDR_MKP_BNB_MAINNET, BNB_MKP_ABI_MAINNET);
    } else if (chainId === 204) {
      unListNFTOnSale(
        orderid,
        ADDR_MKP_OPBNB_MAINNET,
        OPBNB_MKP_BuySell_Contract_ABI
      );
    } else if (chainId === 7001) {
      unListNFTOnSale(
        orderid,
        ADDR_MKP_BNB_MAINNET,
        ZETA_MKP_BuySell_Contract_ABI
      );
    } else if (chainId === 42161) {
      unListNFTOnSale(
        orderid,
        ADDR_MKP_ARB_MAINNET,
        ARB_MKP_BuySell_Contract_ABI
      );
    }
  };

  const getProfileInfo = async () => {
    try {
      const { data } = await axiosInstance.get(`/user/info?address=${account}`);
      setInfoUser(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getIconChain = (chain) => {
    const defaultChain = LIST_NETWORK.find(
      (item) => Number(item.chainId) === Number(chain)
    );
    return defaultChain?.image;
  };

  const handleGetAllNFT = async () => {
    if (chainId) {
      Promise.all([
        getItemsNFTProfile(chainId, 1, "all"),
        getItemsNFTOnsale(chainId, 1, "all"),
      ]);
    }
  };

  const handleLoadMore = async (type) => {
    if (type === "items") {
      getItemsNFTProfile(chainId, currentPageItems + 1);
      setCurrentPageItems(currentPageItems + 1);
    } else {
      getItemsNFTOnsale(chainId, currentPageOnSale + 1);
      setCurrentPageOnSale(currentPageOnSale + 1);
    }
  };

  useEffect(() => {
    setDataNFT([]);
    setDataOnSaleNFT([]);
    handleGetAllNFT();
  }, [chainId, account]);

  useEffect(() => {
    if (!infoUser && account) {
      getProfileInfo();
    } else {
      setInfoUser(null);
    }
  }, [account]);

  return (
    <>
      <ToastContainer />
      {isloading && <LoadingScreen />}
      <div className="authors-2">
        {/* <HeaderStyle2 /> */}
        <section className="flat-title-page inner">
          <div className="overlay"></div>
          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">My Profile</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <WrapperBg style={{ paddingTop: "0", paddingBottom: "0" }}>
          <section className="tf-section authors">
            <div className="themesflat-container">
              <div className="flat-tabs tab-authors">
                <Banner
                  className="author-profile flex"
                  bg={
                    infoUser?.banner ||
                    "https://xrender.ai/assets/images/backgroup-secsion/option1_bg_profile.jpg"
                  }
                >
                  <BoxAvatar>
                    <img
                      src={"/assets/images/avt-author-tab.png"}
                      alt="Axiess"
                    />
                  </BoxAvatar>
                  <div className="infor-profile">
                    <h2 className="title">
                      {" "}
                      {infoUser?.username || "XRender"}{" "}
                    </h2>
                    <span>
                      {" "}
                      {infoUser?.bio ||
                        "XRender uses artificial intelligence so that users can experience our platform completely free of charge. Users will experience XRender AI with all the most uutstanding features."}{" "}
                    </span>
                    <form>
                      {account && (
                        <input
                          type="text"
                          className="inputcopy"
                          defaultValue={accountEllipsis}
                          value={accountEllipsis}
                          readOnly
                        />
                      )}
                      <CopyToClipboard
                        text={account}
                        onCopy={() => {
                          message.success("Copied", 0.4);
                        }}
                      >
                        <button type="button" className="btn-copycode">
                          <i className="icon-fl-file-1"></i>
                        </button>
                      </CopyToClipboard>
                    </form>
                  </div>
                  <div className="widget-social style-3">
                    <ul>
                      <li>
                        <a
                          href={infoUser?.twitter}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="style-2">
                        <a
                          href={infoUser?.facebook}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <svg
                            viewBox="0 0 36 36"
                            className="x1lliihq x1k90msu x2h7rmj x1qfuztq x5e5rjt"
                            height="28"
                            width="28"
                          >
                            <path d="M20.181 35.87C29.094 34.791 36 27.202 36 18c0-9.941-8.059-18-18-18S0 8.059 0 18c0 8.442 5.811 15.526 13.652 17.471L14 34h5.5l.681 1.87Z"></path>
                            <path
                              className="xe3v8dz"
                              d="M13.651 35.471v-11.97H9.936V18h3.715v-2.37c0-6.127 2.772-8.964 8.784-8.964 1.138 0 3.103.223 3.91.446v4.983c-.425-.043-1.167-.065-2.081-.065-2.952 0-4.09 1.116-4.09 4.025V18h5.883l-1.008 5.5h-4.867v12.37a18.183 18.183 0 0 1-6.53-.399Z"
                            ></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href={infoUser?.discord}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <svg
                            width="33"
                            height="30"
                            viewBox="0 0 30 30"
                            className="logo-TA52YN"
                          >
                            <g fill="currentColor">
                              <path d="M26.0015 6.9529C24.0021 6.03845 21.8787 5.37198 19.6623 5C19.3833 5.48048 19.0733 6.13144 18.8563 6.64292C16.4989 6.30193 14.1585 6.30193 11.8336 6.64292C11.6166 6.13144 11.2911 5.48048 11.0276 5C8.79575 5.37198 6.67235 6.03845 4.6869 6.9529C0.672601 12.8736 -0.41235 18.6548 0.130124 24.3585C2.79599 26.2959 5.36889 27.4739 7.89682 28.2489C8.51679 27.4119 9.07477 26.5129 9.55525 25.5675C8.64079 25.2265 7.77283 24.808 6.93587 24.312C7.15286 24.1571 7.36986 23.9866 7.57135 23.8161C12.6241 26.1255 18.0969 26.1255 23.0876 23.8161C23.3046 23.9866 23.5061 24.1571 23.7231 24.312C22.8861 24.808 22.0182 25.2265 21.1037 25.5675C21.5842 26.5129 22.1422 27.4119 22.7621 28.2489C25.2885 27.4739 27.8769 26.2959 30.5288 24.3585C31.1952 17.7559 29.4733 12.0212 26.0015 6.9529ZM10.2527 20.8402C8.73376 20.8402 7.49382 19.4608 7.49382 17.7714C7.49382 16.082 8.70276 14.7025 10.2527 14.7025C11.7871 14.7025 13.0425 16.082 13.0115 17.7714C13.0115 19.4608 11.7871 20.8402 10.2527 20.8402ZM20.4373 20.8402C18.9183 20.8402 17.6768 19.4608 17.6768 17.7714C17.6768 16.082 18.8873 14.7025 20.4373 14.7025C21.9717 14.7025 23.2271 16.082 23.1961 17.7714C23.1961 19.4608 21.9872 20.8402 20.4373 20.8402Z"></path>
                            </g>
                          </svg>
                        </a>
                      </li>
                      <li className="mgr-none">
                        <Link to="#">
                          <i className="icon-fl-tik-tok-2"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="btn-profile">
                      <Link to="#" className="sc-button style-1 follow">
                        Follow
                      </Link>
                    </div>
                  </div>
                </Banner>
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => setTabIndex(index)}
                >
                  <TabList>
                    {menuTab.map((item, index) => (
                      <Tab key={index}>{item.name}</Tab>
                    ))}
                  </TabList>

                  <div className="content-tab">
                    <div className="content-inner">
                      <div className="row">
                        <TabPanel>
                          {dataNFT?.length ? (
                            <>
                              {dataNFT?.map((item, index) => (
                                <Link
                                  to={`/details?id=${
                                    item?.tokenId
                                  }&tokenAddress=${
                                    item?.tokenAddress
                                  }&chainId=${chainId}&type=${"nft"}`}
                                  key={index}
                                  className="col-xl-3 col-lg-4 col-md-6 col-12"
                                >
                                  <div className="sc-card-product explode ">
                                    <div className="card-media">
                                      <img
                                        src={
                                          item?.tokenUri?.image
                                            ? formartImage(
                                                item?.tokenUri?.image
                                              )
                                            : formartImage(item?.image)
                                        }
                                        alt="NFTImage"
                                        width="200px"
                                        height="200px"
                                      />
                                    </div>
                                    <div className="card-title mg-bt-16">
                                      <h5>{item?.collectionName}</h5>
                                      <div className="">
                                        <div
                                          style={{
                                            width: "24px",
                                          }}
                                        >
                                          <img
                                            src={getIconChain(item.chainId)}
                                            alt="images"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <h5>#{item?.tokenId}</h5>
                                    <div className="meta-info">
                                      <div className="author">
                                        <div className="info">
                                          <h6
                                            style={{
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {item?.name}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="button-place-bid "
                                      style={{ width: "100%" }}
                                    >
                                      <button
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setModalShow(true);
                                          setIdPopup(index);
                                        }}
                                        className="sc-button style-place-bid style bag fl-button pri-3"
                                        style={{
                                          width: "100%",
                                          border: "1px solid #f612d1",
                                          marginTop: "20px",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        <span>List Sell</span>
                                      </button>
                                    </div>
                                  </div>
                                </Link>
                              ))}
                              {dataNFT.length < totalDataNFT && (
                                <div className="col-md-12 wrap-inner load-more text-center">
                                  <button
                                    id="load-more"
                                    className="sc-button loadmore fl-button pri-3"
                                    onClick={() => handleLoadMore("items")}
                                  >
                                    <span>Load More</span>
                                  </button>
                                </div>
                              )}
                            </>
                          ) : (
                            <Nodata>
                              <img src={NodataImg} alt="" />
                              <Link to="/mint-nft">
                                <button>Go To Min-Nft</button>
                              </Link>
                            </Nodata>
                          )}
                        </TabPanel>

                        <TabPanel>
                          {dataOnSaleNFT?.length ? (
                            <>
                              {dataOnSaleNFT?.map((item, index) => (
                                <Link
                                  to={`/details?id=${item?.tokenId}&tokenAddress=${item?.tokenAddress}&chainId=${chainId}`}
                                  key={index}
                                  className="col-xl-3 col-lg-4 col-md-6 col-12"
                                >
                                  <div className="sc-card-product explode ">
                                    <div className="card-media">
                                      <img
                                        src={
                                          item?.tokenUri?.image
                                            ? formartImage(
                                                item?.tokenUri?.image
                                              )
                                            : formartImage(item?.image)
                                        }
                                        alt="NFTImage"
                                        width="200px"
                                        height="200px"
                                      />
                                      <div className="featured-countdown">
                                        <span className="slogan"></span>
                                        <Countdown
                                          date={Number(item?.endTime) * 1000}
                                        >
                                          <span>End Of Sale!</span>
                                        </Countdown>
                                      </div>
                                    </div>
                                    <div
                                      className="card-title"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <h5>{item?.tokenUri?.collection}</h5>
                                      <div className="">
                                        <div
                                          style={{
                                            width: "24px",
                                          }}
                                        >
                                          <img
                                            src={getIconChain(item.chainId)}
                                            alt="images"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                      }}
                                    >
                                      <h5>#{item?.tokenId}</h5>
                                    </div>
                                    <h6
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item?.tokenUri?.name}
                                    </h6>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "8px",
                                        marginTop: "12px",
                                        paddingTop: "12px",
                                        borderTop: "1px solid #DBDBDB",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <div
                                        className="price-details"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "5px",
                                          width: "100%",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "800",
                                          }}
                                        >
                                          Price :
                                        </span>
                                        <h6>
                                          {item.price
                                            ? ethers.utils.formatEther(
                                                item.price.toLocaleString(
                                                  "fullwide",
                                                  { useGrouping: false }
                                                )
                                              )
                                            : ""}
                                        </h6>
                                        <span
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            color: "#8D8D8D",
                                          }}
                                        >
                                          {item?.token}
                                        </span>
                                      </div>
                                      <button
                                        onClick={(event) => {
                                          event.preventDefault();
                                          handleUnListNFTOnSale(item?.orderId);
                                          setIdPopup(item?.orderId);
                                        }}
                                        className="sc-button style-place-bid style bag fl-button pri-3"
                                        style={{
                                          padding: "10px 5px 11px",
                                          width: "40%",
                                          border:
                                            "1px solid var(--primary-color3)",
                                        }}
                                      >
                                        <span>Unlist</span>
                                      </button>
                                      <button
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setModalShowUpdatePice(true);
                                          setIdPopup(index);
                                        }}
                                        style={{
                                          border:
                                            "1px solid var(--primary-color3)",
                                          width: "calc(60% - 8px)",
                                        }}
                                        className="sc-button style-place-bid style bag fl-button pri-3"
                                      >
                                        <span> Update</span>
                                      </button>
                                    </div>
                                  </div>
                                </Link>
                              ))}
                              {dataOnSaleNFT.length < totalDataOnSaleNFT && (
                                <div className="col-md-12 wrap-inner load-more text-center">
                                  <button
                                    id="load-more"
                                    className="sc-button loadmore fl-button pri-3"
                                    onClick={() => handleLoadMore("onSale")}
                                  >
                                    <span>Load More</span>
                                  </button>
                                </div>
                              )}
                            </>
                          ) : (
                            <Nodata>
                              <img src={NodataImg} alt="" />
                              <button
                                onClick={(event) => {
                                  event.preventDefault();
                                  setTabIndex(0);
                                }}
                              >
                                List Nft
                              </button>
                            </Nodata>
                          )}
                        </TabPanel>
                        <TabPanel>
                          <EditProfile
                            userName={infoUser?.username || "XRender"}
                            avatar={
                              infoUser?.avatar ||
                              "https://xrender.ai/static/media/avt-author-tab.b9900bd5278562d046ff.png"
                            }
                            banner={
                              infoUser?.banner ||
                              "https://xrender.ai/assets/images/item-background/bg-authors.jpg"
                            }
                            bio={
                              infoUser?.bio ||
                              "XRender uses artificial intelligence so that users can experience our platform completely free of charge. Users will experience XRender AI with all the most uutstanding features."
                            }
                            facebook={infoUser?.facebook || ""}
                            twitter={infoUser?.twitter || ""}
                            discord={infoUser?.discord || ""}
                            handleReLoad={() => {
                              setTabIndex(0);
                              getProfileInfo();
                            }}
                          />
                        </TabPanel>
                      </div>
                    </div>
                  </div>
                </Tabs>
              </div>
            </div>
          </section>
        </WrapperBg>
        <CardModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onReFetch={handleGetAllNFT}
          showAction={setModalShow}
          data={dataNFT ? dataNFT[idPopup] : {}}
        />
        <CardModalUpdatePrice
          onReFetch={handleGetAllNFT}
          show={modalShowUpdatePice}
          onHide={() => setModalShowUpdatePice(false)}
          showAction={setModalShowUpdatePice}
          data={dataOnSaleNFT ? dataOnSaleNFT[idPopup] : {}}
        />
      </div>
    </>
  );
};

export default Authors02;

const Banner = styled.div`
  background-image: url(${(props) => props.bg}) !important;
`;
const BoxAvatar = styled.div`
  max-width: 200px;
  @media only screen and (max-width: 440px) {
    max-width: calc(100% - 125px);
  }
`;

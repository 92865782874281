import "./App.css";
import "antd/dist/antd.min.css";
import { Routes, Route, useLocation } from "react-router-dom";
import routes from "./pages/index";
import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary, Web3ProviderNetwork } from "./context/web3provider";
import { RefreshContextProvider } from "./context/RefreshContext.jsx";
import { Suspense, useEffect, useState } from "react";
import Loader from "./components/Loader";
import { ModalConfirmProvider } from "./components/ProviderPopUp/confirm";
import HeaderStyle2 from "./components/header/HeaderStyle2";
import { Helmet } from "react-helmet";
import {
  ROUTE_MARKETPLACE,
  ROUTE_NFT_AI,
  ROUTE_PRESALE,
  ROUTE_TOOLDEX,
} from "./constants/routes";
import { Provider } from "react-redux";
import store from "./state";
import PopupEvent from "./components/Popup";

import { WagmiConfig } from "wagmi";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import {
  arbitrum,
  bsc,
  opBNB,
  zetachainAthensTestnet,
} from "wagmi/chains";
import { ModalProvider } from "./components/Tooldex/modal";
import Sidebar from "./components/Tooldex/sidebar/Sidebar";
import { TooldexContent, TooldexWrapper } from "./styled";
import Footer from "./components/footer/Footer";
import { WrapperBg } from "./components/layouts/styled";

const projectId = "3a1eba421630b4028ff59600db61d251";
if (!projectId) {
  throw new Error("PROJECT_ID is not set");
}
const chains = [
  bsc,
  arbitrum,
  opBNB,
  zetachainAthensTestnet,
];
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "dark",
  themeVariables: {
    "--w3m-z-index": 9999,
  },
  chainImages: {
    7001: "/assets/icon/icon-zeta.png",
    204: "/assets/icon/opbnb.png",
  },
});

function App() {
  const { pathname } = useLocation();
  const [metaActive, setMetaActive] = useState({
    title: "XRender, AI NFT Generator - Generate any NFT collection powered AI",
    descriptions:
      "XRender AI Generator is a breakthrough in the NFT ecosystem when integrating artificial intelligence technology to generate works of art in the form of NFT and rendered completely on-chain.",
    icon: "",
  });

  const handleSetMetaData = () => {
    switch (pathname) {
      case ROUTE_MARKETPLACE:
        setMetaActive({
          title: "AI NFT Generator Platforms - XRender",
          descriptions:
            "XRender Marketplace is an exchange to buy/sell, create, auction, and stake multi-chain NFTs. Users can create their own collection and passive income from NFT trading on XRender Marketplace.",
          icon: "",
        });
        break;
      case ROUTE_NFT_AI:
        setMetaActive({
          title: "NFT Art Generator | AI NFT Generator",
          descriptions:
            "XRender, AI NFT Generator is a combination of artificial intelligence and limitless human creativity to create the most amazing NFT Collections that users can own.",
          icon: "",
        });
        break;
      case ROUTE_PRESALE:
        setMetaActive({
          title: "XRender Pre-sale",
          descriptions:
            "XRender, AI NFT Generator is a combination of artificial intelligence and limitless human creativity to create the most amazing NFT Collections that users can own.",
          icon: "",
        });
        break;
      case ROUTE_TOOLDEX:
        setMetaActive({
          title: "XRender Tooldex",
          descriptions:
            "XRender, AI NFT Generator is a combination of artificial intelligence and limitless human creativity to create the most amazing NFT Collections that users can own.",
          icon: "",
        });
        break;
      default:
        setMetaActive({
          title:
            "XRender, AI NFT Generator - Generate any NFT collection powered AI",
          descriptions:
            "XRender AI Generator is a breakthrough in the NFT ecosystem when integrating artificial intelligence technology to generate works of art in the form of NFT and rendered completely on-chain.",
          icon: "",
        });
        break;
    }
  };

  useEffect(() => {
    handleSetMetaData();
  }, [pathname]);

  return (
    <WagmiConfig config={wagmiConfig}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <ModalProvider>
            <Provider store={store}>
              <RefreshContextProvider>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title> {metaActive.title} </title>
                  <meta name="description" content={metaActive.descriptions} />
                </Helmet>
                <Suspense fallback={<Loader />}>
                  <ModalConfirmProvider>
                    <Container />
                  </ModalConfirmProvider>
                </Suspense>
              </RefreshContextProvider>
            </Provider>
          </ModalProvider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </WagmiConfig>
  );
}

export default App;

const Container = () => {
  const location = useLocation();
  const path = location.pathname;
  const isTooldexPath = path.includes("/tooldex");
  // const [eventShow, setEventShow] = useState(false);
  const [openToolDexMenu, setOpenToolDexMenu] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setEventShow(true);
  //   }, 2000);
  // }, []);

  return (
    <>
      {/* <PopupEvent eventShow={eventShow} setEventShow={setEventShow} /> */}
      <HeaderStyle2
        setOpenToolDexMenu={setOpenToolDexMenu}
        openToolDexMenu={openToolDexMenu}
        isTooldexPath={isTooldexPath}
      />
      {isTooldexPath ? (
        <>
          <TooldexWrapper>
            <Sidebar openToolDexMenu={openToolDexMenu} setOpenToolDexMenu={setOpenToolDexMenu}/>
            <TooldexContent>
              <WrapperBg>
                <Routes>
                  {routes.map((data, index) => (
                    <Route
                      onUpdate={() => window.scrollTo(0, 0)}
                      exact={true}
                      path={data.path}
                      element={data.component}
                      key={index}
                    />
                  ))}
                </Routes>
              </WrapperBg>
            </TooldexContent>
            <Footer isTooldexPath={isTooldexPath} />
          </TooldexWrapper>
        </>
      ) : (
        <>
          <Routes>
            {routes.map((data, index) => (
              <Route
              onUpdate={() => window.scrollTo(0, 0)}
                exact={true}
                path={data.path}
                element={data.component}
                key={index}
              />
            ))}
          </Routes>
          <Footer isTooldexPath={isTooldexPath} />
        </>
      )}
    </>
  );
};

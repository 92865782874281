import { useState } from "react";
import {
  GenContainer,
  GenBlock,
  GenItem,
  GenTop,
  GenInfo,
  GenWrapperTitle,
} from "../Premium/styled";
import {
  ButtonCommon,
  TableCommon,
  TextCustom,
  TitleKey,
  V2Buttons,
} from "../../../../components/common/styled";
import Web3 from "web3";

import InputCommon from "../../../../components/common/input";
import { Table } from "antd";
import { WalGenTable } from "../styled";
import CopyToClipboard from "react-copy-to-clipboard";
import XLSX from "xlsx";
import { notiSuccess } from "../../../../utils/Tooldex/notication";
import download from "../../../../assets/images/common/form/download.svg";

// Images
import copy from "../../../../assets/images/common/table/copy.svg";

const PreGen = () => {
  const { Column } = Table;
  const web3 = new Web3("https://bsc-dataseed1.binance.org");
  const [wallets, setWallets] = useState<any>([]);
  const [numWallets, setNumWallets] = useState<any>(null);

  const generateWallets = async () => {
    try {
      const generatedWallets = [];
      for (let i = 0; i < numWallets; i++) {
        const wallet = web3.eth.accounts.create();
        generatedWallets.push(wallet);
      }
      setWallets(generatedWallets);
      setNumWallets("");
      notiSuccess("Multi wallet create successfully!", 5);
    } catch (error) {
      console.error("error", error);
      notiSuccess("Multi wallet create failed!", 5);
    }
  };

  const exportToExcel = () => {
    const data = wallets.map((wallet: any, index: any) => ({
      Address: wallet.address,
      PrivateKey: wallet.privateKey,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Wallets");
    XLSX.writeFile(workbook, "wallets.xlsx");
  };
  const exportToCSV = () => {
    const data = wallets.map((wallet: any, index: any) => ({
      Address: wallet.address,
      PrivateKey: wallet.privateKey,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Wallets");
    XLSX.writeFile(workbook, "wallets.csv");
  };
  return (
    <GenContainer>
      <GenWrapperTitle>
        <TextCustom fontWeight="bold" fontSize="24px" color="#fff">
          Address Generator
        </TextCustom>
      </GenWrapperTitle>
      <GenBlock>
        <GenItem>
          <GenTop>
            <TitleKey>Enter number wallet create</TitleKey>
            <span>Ex:123</span>
          </GenTop>
          <InputCommon
            value={numWallets}
            onChange={(e: any) => setNumWallets(Number(e.target.value))}
          />
        </GenItem>

        <GenInfo>
          <TextCustom
            style={{ marginTop: "5px", fontStyle: "italic" }}
            fontSize="14px"
            fontWeight="500"
            color="#717696"
          >
            *Tip: The wallet generation process is all done in the local
            environment, we don't have access to any information about the
            wallet! <br />* This Dapp is run locally, for security, please
            disconnect from the internet and run this Dapp on an unmarked
            browser!
          </TextCustom>
        </GenInfo>
        <ButtonCommon
          background="rgba(0, 178, 200, 0.90)"
          textColor="#fff"
          disabled={!numWallets}
          onClick={generateWallets}
        >
          Start Wallet Generator
        </ButtonCommon>
      </GenBlock>
      <V2Buttons style={{ margin: "10px", justifyContent: "space-between" }}>
        <ButtonCommon
          className="action-button"
          background={"#fff"}
          textColor={"#0D0C43"}
          onClick={exportToExcel}
        >
          <img
            style={{ width: "20px", height: "20px" }}
            src={download}
            alt="download"
          />
          Export Excel
        </ButtonCommon>
        <ButtonCommon
          className="action-button"
          background={"#fff"}
          textColor={"#0D0C43"}
          onClick={exportToCSV}
        >
          <img
            style={{ width: "20px", height: "20px" }}
            src={download}
            alt="download"
          />
          Export CSV
        </ButtonCommon>
      </V2Buttons>
      <WalGenTable style={{ marginTop: "20px" }}>
        <TableCommon>
          <Table dataSource={wallets}>
            <Column
              title="STT"
              key="index"
              render={(text, record, index) => index + 1}
            />
            <Column
              title="Address"
              dataIndex="address"
              key="address"
              render={(address, index: number) => (
                <>
                  {`${address?.substring(0, 16)}..........${address?.substring(
                    address.length - 12
                  )}`}
                  <span
                    key={index}
                    style={{
                      display: "inline-block",
                      position: "relative",
                      top: "-2px",
                      marginLeft: "8px",
                    }}
                  >
                    <CopyToClipboard
                      text={address}
                      onCopy={() => {
                        notiSuccess(
                          `Copied ${address?.substring(
                            0,
                            6
                          )}.....${address?.substring(address.length - 8)}`,
                          5
                        );
                      }}
                    >
                      <span className="img-copy">
                        <img width={20} height={20} src={copy} alt="copy" />
                      </span>
                    </CopyToClipboard>
                  </span>
                </>
              )}
            />
            <Column
              title="Private Key"
              dataIndex="privateKey"
              key="privateKey"
              render={(privateKey, index: number) => (
                <>
                  <span key={privateKey} style={{ color: "#0D0C43" }}>
                    {`${privateKey?.substring(
                      0,
                      16
                    )}**********${privateKey?.substring(
                      privateKey.length - 18
                    )}`}
                  </span>
                  <span
                    key={index}
                    style={{
                      display: "inline-block",
                      position: "relative",
                      top: "-2px",
                      marginLeft: "8px",
                    }}
                  >
                    <CopyToClipboard
                      text={privateKey}
                      onCopy={() => {
                        notiSuccess(
                          `Copied ${privateKey?.substring(
                            0,
                            6
                          )}.....${privateKey?.substring(
                            privateKey.length - 8
                          )}`,
                          5
                        );
                      }}
                    >
                      <span className="img-copy">
                        <img width={20} height={20} src={copy} alt="copy" />
                      </span>
                    </CopyToClipboard>
                  </span>
                </>
              )}
            />
          </Table>
        </TableCommon>
      </WalGenTable>
    </GenContainer>
  );
};

export default PreGen;

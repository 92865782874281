import React, { useState, useEffect } from "react";
import {
  ButtonCommon,
  TableCommon,
  TextCustom,
  TitleKey,
} from "../../../../components/common/styled";
import moment from "moment";

import {
  ReportTransactions,
  PortfolioContainer,
  TransactionsHeader,
  DataCol,
  OverviewData,
  OverviewHeader,
  OverviewTitle,
  PortfolioOverview,
} from "./styled";
import { Table, Empty, Skeleton } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  API_OKLINK_URL,
  CHAINIDTOSYMBOL,
  SCAN_URL,
  HTTPS_NETWORK,
  token_network,
  NAME_NETWORK,
  LOGO_BY_CHAINID,
} from "../../../../constants/Tooldex/index";
import { getDataOklink } from "../../../../constants/Tooldex/store";
import XLSX from "xlsx";
import Web3 from "web3";
import { useAccount, useNetwork } from "wagmi";
// Images
import download from "../../../../assets/images/common/form/download.svg";
import search from "../../../../assets/images/volume/search.svg";
import copy from "../../../../assets/images/common/table/copy.svg";
import { notiSuccess } from "../../../../utils/Tooldex/notication";
import InputCommon from "../../../../components/common/input";
import Value from "../../../../components/Tooldex/Value";
import CurrencyLogo from "../../../../components/CurrencyLogo";

const Portfolio = () => {
  const { Column } = Table;

  const { address, isConnected } = useAccount();
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const account: any = address;
  const web3 = new Web3(HTTPS_NETWORK[chainId]);

  let chainSymbol: any = CHAINIDTOSYMBOL[chainId];
  let currentTime: any = new Date(Date.now()).getTime();
  const [listToken, setListToken] = useState<any>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [balanceBase, setBalanceBase] = useState("0");

  const accountEllipsisRow = isConnected
    ? `${account?.substring(0, 4)}.....${account?.substring(
        account.length - 6
      )}`
    : "-------";

  const checkBalance = async () => {
    const balance = await web3.eth.getBalance(account);
    const balanceFromWei = web3.utils.fromWei(balance, "ether");
    setBalanceBase(balanceFromWei);
  };
  useEffect(() => {
    if (account) {
      checkBalance();
    } else {
      setBalanceBase("0");
    }
  }, [account, chainId]);

  const handleSearch = (event: any) => {
    const { value } = event.target;
    setSearchKeyword(value);
  };
  const filteredListToken = listToken.filter((item: any) =>
    item.token.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const getTokenByAccount = async () => {
    if (chainId && account) {
      setLoadingData(true);
      await getDataOklink(
        `${API_OKLINK_URL}/v5/explorer/address/address-balance-fills?chainShortName=${chainSymbol}&address=${account}&protocolType=token_20&limit=50&t=${currentTime}`
      )
        .then((responseData: any) => {
          if (responseData?.data?.length > 0) {
            setListToken(responseData?.data[0]?.tokenList);
            setLoadingData(false);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setLoadingData(false);
        });
    }
  };
  useEffect(() => {
    if (!account) {
      setListToken([]);
    }
    getTokenByAccount();
  }, [!listToken, chainId, account]);
  const exportToExcel = () => {
    const data = listToken.map((item: any, index: any) => ({
      STT: index + 1,
      Symbol_Token: item.token,
      Contract_Token: item.tokenContractAddress,
      Price_Token: `$${item.priceUsd}`,
      Change_24hr: `${Number(item.change24h * 100).toFixed(2)}%`,
      Balance_Token: `${item.holdingAmount} ${item.token}`,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Token_view");
    XLSX.writeFile(workbook, "Token_view.xlsx");
  };
  return (
    <PortfolioContainer>
      <PortfolioOverview>
        <OverviewHeader>
          <OverviewTitle>
            <TextCustom fontSize="20px" fontWeight="bold" color="#0D0C43">
              Overview
            </TextCustom>
          </OverviewTitle>
        </OverviewHeader>
        <OverviewData>
          <DataCol>
            <TitleKey>Wallet address</TitleKey>
            <TextCustom
              fontSize="18px"
              fontWeight="bold"
              color="#0D0C43"
              style={{ display: "flex" }}
            >
              {accountEllipsisRow}
              {isConnected && (
                <CopyToClipboard
                  text={account}
                  onCopy={() => {
                    notiSuccess(
                      `Copied ${account?.substring(
                        0,
                        6
                      )}.....${account?.substring(account.length - 8)}`,
                      5
                    );
                  }}
                >
                  <span className="img-copy">
                    <img width={20} height={20} src={copy} alt="copy" />
                  </span>
                </CopyToClipboard>
              )}
            </TextCustom>
          </DataCol>
          <DataCol>
            <TitleKey> Balance</TitleKey>
            <TextCustom fontSize="18px" fontWeight="bold" color="#F313CE">
              <Value
                size={18}
                color="#F313CE"
                unitafter={token_network(chainId)}
                value={Number(balanceBase)}
              />
            </TextCustom>
          </DataCol>
          <DataCol>
            <TitleKey>Current Time (+UTC)</TitleKey>
            <TextCustom
              fontSize="18px"
              fontWeight="300"
              color="rgba(13, 12, 67, 0.70)"
            >
              {moment(currentTime).format("MMM-DD-YYYY hh:mm:ss A")}
            </TextCustom>
          </DataCol>
          <DataCol>
            <TitleKey>Symbol</TitleKey>
            <TextCustom
              fontSize="18px"
              fontWeight="bold"
              color="#0D0C43"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                style={{ width: 20, marginRight: "3px", borderRadius: "50px" }}
                src={LOGO_BY_CHAINID(chainId)}
                alt="logo"
              />{" "}
              {token_network(chainId)}
            </TextCustom>
          </DataCol>
          <DataCol>
            <TitleKey>Network Connect</TitleKey>
            <TextCustom fontSize="18px" fontWeight="bold" color="#0D0C43">
              {NAME_NETWORK(chainId)}
            </TextCustom>
          </DataCol>
        </OverviewData>
      </PortfolioOverview>
      <ReportTransactions>
        <TextCustom fontSize="20px" fontWeight="bold" color="#0D0C43">
          Token Overview
        </TextCustom>
        <TransactionsHeader>
          <ButtonCommon
            disabled={false}
            background={"#fff"}
            textColor={"#0D0C43"}
            style={{
              width: "auto",
              padding: "10px",
              border: "1px solid #0D0C43",
              gap: "10px",
            }}
            onClick={exportToExcel}
          >
            <img
              style={{ width: "20px", height: "20px" }}
              src={download}
              alt="download"
            />
            Export Token List
          </ButtonCommon>

          <InputCommon
            placeHolder="Search token"
            prefix={<img src={search} alt="search" />}
            onChange={handleSearch}
          />
        </TransactionsHeader>
        <TableCommon>
          {loadingData ? (
            <>
              {" "}
              <Skeleton active paragraph={{ rows: 3 }} />
              <Skeleton active paragraph={{ rows: 3 }} />
              <Skeleton active paragraph={{ rows: 3 }} />
            </>
          ) : (
            <>
              {listToken.length > 0 ? (
                <>
                  <Table dataSource={filteredListToken}>
                    <Column
                      title="STT"
                      key="index"
                      render={(text, record, index) => index + 1}
                    />
                    <Column
                      title="Token Symbol"
                      dataIndex="token"
                      key="token"
                      render={(token, record: any) => (
                        <>
                          <CurrencyLogo
                            address={record?.tokenContractAddress}
                          />
                          <span
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "-2px",
                              marginLeft: "3px",
                            }}
                          >
                            {token}
                          </span>
                        </>
                      )}
                    />
                    <Column
                      title="Contract Address"
                      dataIndex="tokenContractAddress"
                      key="tokenContractAddress"
                      render={(tokenContract) => (
                        <>
                          {`${tokenContract?.substring(
                            0,
                            6
                          )}.....${tokenContract?.substring(
                            tokenContract.length - 8
                          )}`}
                          <span
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "-2px",
                              marginLeft: "8px",
                            }}
                          >
                            <CopyToClipboard
                              text={tokenContract}
                              onCopy={() => {
                                notiSuccess(
                                  `Copied ${tokenContract?.substring(
                                    0,
                                    6
                                  )}.....${tokenContract?.substring(
                                    tokenContract.length - 8
                                  )}`,
                                  5
                                );
                              }}
                            >
                              <span className="img-copy">
                                <img
                                  width={20}
                                  height={20}
                                  src={copy}
                                  alt="copy"
                                />
                              </span>
                            </CopyToClipboard>
                          </span>
                        </>
                      )}
                    />

                    <Column
                      title="Price Token"
                      dataIndex="priceUsd"
                      key="priceUsd"
                      render={(priceUsd, record: any) => (
                        <>
                          <span
                            style={{
                              color:
                                Number(record.change24h * 100) > 0
                                  ? "#F313CE"
                                  : "#F45E5E",
                              fontWeight: 800,
                            }}
                          >
                            <Value
                              size={14}
                              unit={"$"}
                              color={
                                Number(record.change24h * 100) > 0
                                  ? "#F313CE"
                                  : "#F45E5E"
                              }
                              value={Number(priceUsd)}
                            />
                          </span>
                        </>
                      )}
                    />
                    <Column
                      title="Change (24hr)"
                      dataIndex="change24h"
                      key="change24h"
                      render={(change24h) => (
                        <>
                          <span
                            style={{
                              color:
                                Number(change24h * 100) > 0
                                  ? "#F313CE"
                                  : "#F45E5E",
                            }}
                          >
                            <Value
                              size={14}
                              unitafter={"%"}
                              color={
                                Number(change24h * 100) > 0
                                  ? "#F313CE"
                                  : "#F45E5E"
                              }
                              value={Number(change24h * 100)}
                            />
                          </span>
                        </>
                      )}
                    />
                    <Column
                      title="Balance"
                      dataIndex="holdingAmount"
                      key="holdingAmount"
                      render={(holdingAmount, record: any) => (
                        <>
                          <Value
                            size={14}
                            unit={"$"}
                            color={"#F313CE"}
                            value={Number(record.valueUsd)}
                          />
                          <Value
                            size={14}
                            unitafter={record.token}
                            value={Number(holdingAmount)}
                          />
                        </>
                      )}
                    />

                    <Column
                      title="Action"
                      dataIndex="tokenContractAddress"
                      key="tokenContractAddress"
                      render={(tokenContract) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "max-content",
                              color: "#E68D07",
                              fontSize: "14px",
                              padding: "5px 10px",
                              borderRadius: "10px",
                              border: "1px solid",
                              borderColor: "#E68D07",
                              background: "rgba(230, 141, 7, 0.10)",
                            }}
                          >
                            <a
                              style={{ color: "#E68D07" }}
                              href={`${
                                SCAN_URL[chainId]
                              }token/${tokenContract.toLowerCase()}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View Scan
                            </a>
                          </div>
                        </>
                      )}
                    />
                  </Table>
                </>
              ) : (
                <>
                  <Empty />
                </>
              )}
            </>
          )}
        </TableCommon>
      </ReportTransactions>
    </PortfolioContainer>
  );
};

export default Portfolio;

import styled from "styled-components";
import halfBg from "../../../assets/images/aside/halfBg.svg";

export const SidebarContainer = styled.div`
  position: sticky;
  top: 80px;
  left: 0;
  z-index: 5;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(11px);
  }
  @media screen and (max-width: 1023px) {
    position: absolute;
    z-index: 1000;
    transition: all .5s ease-in-out;
    width: 100%;
    background: rgba(0, 0, 0, .8);
  }
`;
export const DonateBanner = styled.div`
  position: relative;
  width: calc(100% - 30px);
  margin: 0 auto;
  min-height: 210px;
  padding: 30px 20px;
  border-radius: 20px;
  background: linear-gradient(230deg, #334270 -54.35%, #192139 73.76%);
  overflow: hidden;
  line-height: normal;
  p {
    margin-bottom: 0;
    z-index: 2;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: -32px;
    width: 100%;
    height: 100%;
    background: url(${halfBg}) no-repeat center / 100% auto;
    z-index: 0;
  }
  .title {
    margin-bottom: 15px;
  }
`;

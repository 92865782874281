import { ReactNode, useState,useEffect } from "react";
import FormConnectWallet from "../../../components/common/form/connectWallet";
import SelectNetwork from "../../../components/common/form/selectNetwork";
import KeyvisualCommon from "../../../components/common/keyvisual";
import ProgressCommon from "../../../components/common/progress";
import { TextCustom } from "../../../components/common/styled";
import { VolumeBody } from "../TokenCreation/styled";
import { LiquidityLockupContainer } from "./styled";
import PairAddress from "./pairAddress";
import ConfigureLock from "./configureLock";
import { useAccount } from "wagmi";


const LiquidityLockup = () => {
  const { address } = useAccount();
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (address) {
      setStep(1);
    } else {
      setStep(0);
    }
  }, [address]);

  const handleChangeComponent = (step: number): ReactNode => {
    switch (step) {
      case 0:
        return <FormConnectWallet />;
      case 1:
        return <SelectNetwork setStep={setStep} />;
      case 2:
        return <PairAddress setStep={setStep} />;
      case 3:
        return <ConfigureLock setStep={setStep} />;
      default:
        return null;
    }
  };

  return (
    <LiquidityLockupContainer>
      <KeyvisualCommon
        text="Create your own custom token lock instantly"
        content={
          <TextCustom color="#fff" fontSize="16px" fontWeight="300">
            All tokens are locked into our audited smart contract and can only
            be withdrawn by you
            <br /> after lock time expires.
          </TextCustom>
        }
      />
      <VolumeBody>
        <ProgressCommon widthCustom={"400px"} data={progressData} step={step} />
        {handleChangeComponent(step)}
      </VolumeBody>
    </LiquidityLockupContainer>
  );
};

const progressData = [
  "Connect wallet",
  "Select nework",
  "Enter pair address",
  "Configure Lock",
];

export default LiquidityLockup;

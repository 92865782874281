import styled from "styled-components";

export const WrapperBg = styled.div`
  background-color: #000;
  position: relative;
  padding: 30px 0 150px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 463px;
    height: 694px;
    opacity: 0.4;
    background: #d335c3;
    filter: blur(143px);
    pointer-events: none;
  }
  &::before {
    top: 100px;
    left: -100px;
  }
  &::after {
    bottom: 200px;
    right: -100px;
  }
`
import { useState } from "react";
import Progress from "./Progress";
import { Wapper, BoxOneElm, BoxTowElm, BtnGroup } from "./style";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../../../../utils/axios";
import ToastTyfile from "../../../../layouts/Toastify";
import styled from "styled-components";

const ExampleInput = ({ prompt }: any) => {
  const KEY = [
    'prompt', 'width', 'height',
    'negativePrompt', 'output', 'numInferenceSteps', 'scale'
  ]
  return (
    <Wapper >
      <ToastContainer />
      <h1 style={{
        marginBottom: 0
      }}>Input</h1>
      {
        KEY.map((item: any, index: any) => {
          return (
            <BoxPrompt key={index}>
              <h5 style={{
                color: '#fff'
              }}>{item}</h5>
              <div className="content" style={{
                background: index % 2 === 0 ? 'rgb(0 ,0 ,0 , 0.2)' : 'rgb(255 ,255 ,255 , 0.2)'
              }}>
                <p
                  style={{
                    color: index % 2 === 0 ? '#fff' : '#232121',
                    marginBottom: "0"
                  }}
                >
                  {prompt[`${item}`]}
                </p>
              </div>
            </BoxPrompt>

          )
        })
      }

    </Wapper>
  );
};

const BoxPrompt = styled.div`
width: 100%;
h5 {
  color: #fff;
  text-align: center;
  font-style: italic;
  width: fit-content;
  padding: 2px 10px;
  padding-top: 5px;
  font-weight: 500;
  font-size: 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-top: 7px;
}
.content {
  padding: 10px 10px;
    width: 100%;
    border:none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  p {
    color: #fff;
    font-style: italic;
  }
}
`;
export default ExampleInput;



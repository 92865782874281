import styled from "styled-components";
import { ButtonCommon, RadioCommon, TextCommon, TextCustom } from "../../styled";

export const SelectNetworkContainer = styled.div`
`
export const NetworkHeader = styled.div`
  & > ${TextCustom} {
    margin-bottom: 10px;
  }
  ${TextCommon} {
    margin-bottom: 20px;
  }
  ${ButtonCommon} {
    min-width: 344px
  }
  @media screen and (max-width: 743px) {
    ${ButtonCommon} {
      min-width: auto;
    }
  }
`
export const SelectNetworkBody = styled.div`
  background-color: #fff;
  max-width: 675px;
  margin: 0 auto 25px;
  padding: 35px 70px;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #DCE2E9;
  border-radius: 20px;
  @media screen and (max-width: 743px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`
export const NetworkList = styled.ul`
  margin-bottom: 30px;
  padding-left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  & > li {
    display: flex;
    width: calc(50% - 15px);
    padding: 10px;
    list-style-type: none;
    border-radius: 10px;
    border: 1px solid #81829B;
    background: #FFF;
    text-align: left;
    p {
      margin-bottom: 5px;
    }
    span {
      font-weight: 400;
    }
    img {
      width: 33px;
      height: 35px;
      margin-right: 10px;
    }
    &.current {
      border-color: #F313CE;
    }
  }
  .ant-radio-group {
    width: 100%;
  }
  .ant-radio-wrapper {
    flex-direction: row-reverse;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &::after {
      content: none;
    }
  }
  ${RadioCommon} {
    width: 100%;
  }
  .ant-radio-inner {
    border-color: #717696;
  }
  .ant-radio-wrapper-checked {
    color: #0D0C43;
  }
  span.ant-radio + *{
    padding: 0;
  }
  @media screen and (max-width: 743px) {
    & > li {
      width: 100%;
    }
  }
`
export const NetworkInner = styled.div`
  display: flex;
  align-items: center;
  line-height: normal;
`
export const ButtonAction = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`
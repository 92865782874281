import {
  ButtonMint,
  ButtonTry,
  ExternalButton,
  ExternalIcon,
  ExternalLeft,
  ExternalWrapper,
  KeyvisualAction,
  KeyvisualBgLine,
  KeyvisualExternal,
  KeyvisualLeft,
  KeyvisualRight,
  KeyvisualWrapper,
  KeyvisualXContainer,
  Line,
  RightImage,
  RightRobot,
} from "./styled";
import avatar from "../../../assets/images/homenew/keyvisual/avatar.png";
import robot from "../../../assets/images/homenew/keyvisual/keyvisual_robot.png";
import robot_sp from "../../../assets/images/homenew/keyvisual/sp/keyvisual_robot.png";
import orbit_1 from "../../../assets/images/homenew/keyvisual/orbit_1.jpg";
import orbit_2 from "../../../assets/images/homenew/keyvisual/orbit_2.jpg";
import orbit_3 from "../../../assets/images/homenew/keyvisual/orbit_3.jpg";
import orbit_1_sp from "../../../assets/images/homenew/keyvisual/sp/orbit_1.jpg";
import orbit_2_sp from "../../../assets/images/homenew/keyvisual/sp/orbit_2.jpg";
import orbit_3_sp from "../../../assets/images/homenew/keyvisual/sp/orbit_3.jpg";
import ArrowKeyvisual from "../../../assets/svg/Keyvisual/Arrow";
import WardKeyvisual from "../../../assets/svg/Keyvisual/Ward";
import { useContext } from "react";
import { ModalConfirmContext } from "../../../components/ProviderPopUp/confirm";

const KeyvisualX = () => {
  const { isMobile } = useContext(ModalConfirmContext)!;
  const orbitImage = [
    {
      img: isMobile ? orbit_1_sp : orbit_1,
    },
    {
      img: isMobile ? orbit_2_sp : orbit_2,
    },
    {
      img: isMobile ? orbit_3_sp : orbit_3,
    },
  ];
  return (
    <KeyvisualXContainer>
      <KeyvisualBgLine>
        <Line />
        <Line />
        <Line />
        <Line />
        <Line />
      </KeyvisualBgLine>
      <KeyvisualWrapper>
        <KeyvisualLeft>
          <h1>XRender AI - The Power of Artificial Intelligence Web3</h1>
          <p>
            XRender is an advanced project that brings artificial intelligence
            (AI) into the realm of Web3 and blockchain, aiming to seamlessly
            integrate smart technologies into everyday life. With a mission to
            infuse AI intelligence into daily experiences, XRender offers a
            range of unique applications in the realm of cutting-edge
            technology.
          </p>
          <KeyvisualAction>
            <ButtonMint>XRender AI Generator</ButtonMint>
            <ButtonTry>XRender AI Chat</ButtonTry>
          </KeyvisualAction>
          <KeyvisualExternal>
            {externalData.map((item: any, index) => {
              return (
                <li key={index}>
                  <div style={{ position: "relative", zIndex: "2" }}>
                    <figure>
                      <img src={item.img} alt="external" />
                    </figure>
                    <ExternalWrapper>
                      <ExternalLeft>
                        <h2>{item.title}</h2>
                        <p>{item.describe}</p>
                      </ExternalLeft>
                      <ExternalButton>
                        <p>{item.button.title}</p>
                        <ExternalIcon>{item.button.img}</ExternalIcon>
                      </ExternalButton>
                    </ExternalWrapper>
                  </div>
                </li>
              );
            })}
          </KeyvisualExternal>
        </KeyvisualLeft>
        <KeyvisualRight>
          <RightRobot>
            <img src={isMobile ? robot_sp : robot} alt="robot" />
          </RightRobot>
          <RightImage>
            {orbitImage.map((item, index) => {
              return (
                <li key={index}>
                  <img src={item.img} alt="orbit" />
                </li>
              );
            })}
          </RightImage>
        </KeyvisualRight>
      </KeyvisualWrapper>
    </KeyvisualXContainer>
  );
};

const externalData = [
  {
    id: 1,
    img: avatar,
    title: "XRender Tooldex",
    describe: "Decentralized Toolkit",
    href: "/tooldex/portfolio",
    button: {
      title: "Start Now",
      img: <ArrowKeyvisual />,
    },
  },
  {
    id: 2,
    img: avatar,
    title: "XRender AI Tool",
    describe: "Making your ideas come true",
    href: "#",
    button: {
      title: "Use Now",
      img: <WardKeyvisual />,
    },
  },
];

export default KeyvisualX;

import {
  Container,
  BoxModal,
  BoxHeader,
  BoxPd,
  BoxModalContent,
  BoxPdDisconnect,
  BoxModalDisConnect,
} from "./PopUpConnectStyle";
import { NEED_A_PLACEHOLDER, WALLET_LIST } from "../../constants";
import React, { useContext, useEffect, useState } from "react";
import { ModalConfirmContext } from "../ProviderPopUp/confirm";
import WalletItem from "../Connect-wallet/WalletItem";
import { useWeb3React } from "@web3-react/core";
import { BoxOneElm } from "../AbrNftAI/Body/minComponent/input/style";
import styled from "styled-components";
import { BoxNotice, Notice } from "../layouts/home-5/TodayPicks";
import { ToastContainer, toast } from "react-toastify";
import ToastTyfile from "../../components/layouts/Toastify";
import { useActiveWeb3React } from "../../hooks";
import {
  STAKING_OPBNB_TESTNET,
  STAKING_ARB_MAINNET,
  ABI_STAKING,
  XRAI_OPBNB_TESTNET,
  XRAI_ARB_MAINET,
} from "../../integrateContract/ABI";
import {
  handleStaking,
  handleUnStaking,
  getUserInfo
} from "../../integrateContract/contract/handleStaking";
import {
  approvalToken,
  getAllowance,
} from "../../integrateContract/contract/handleErc20";
import Decimal from "decimal.js";
import dayjs from "dayjs";

export const ModalStaking = (props) => {
  const { poolIndex, balance, type, onRefetchStake, locksDay } = props;
  const { onClose } = useContext(ModalConfirmContext);
  const context = useWeb3React();
  const { active } = context;
  const [isLoading, setIsLoading] = useState(false);
  const [isApproval, setIsApproval] = useState(false);

  const [amount, setAmount] = useState(0);
  const [userInfo, setUserInfo] = useState({});

  const { chainId } = useWeb3React();
  const { account } = useActiveWeb3React();

  const handleStake = async () => {
    if (!amount || !(amount >= 100_000 && amount <= 10_000_000)) {
      toast(
        <ToastTyfile
          type="error"
          description={`Amount must be between 100,000 and 10,000,000`}
        />,
        { progress: 0, hideProgressBar: true }
      );
      return;
    }

    setIsLoading(true);
    try {
      let addressStaking = "";
      let contract = null;
      switch (chainId) {
        case 5611:
          addressStaking = STAKING_OPBNB_TESTNET;
          contract = ABI_STAKING;
          break;
        case 42161:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          break;
        default:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          break;
      }
      await handleStaking(poolIndex, amount, addressStaking, contract);
      setAmount(0);
      toast(
        <ToastTyfile
          type="sucsess"
          description={`Successful stake ${new Intl.NumberFormat().format(
            amount
          )} $XRAI`}
        />,
        { progress: 0, hideProgressBar: true }
      );
      onClose();
      onRefetchStake();
      setAmount(0);
    } catch (e) {
      toast(
        <ToastTyfile
          type="error"
          description={e?.reason?.replace("execution reverted: ", "")}
        />,
        { progress: 0, hideProgressBar: true }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnStake = async () => {
    setIsLoading(true);
    try {
      let addressStaking = "";
      let contract = null;
      switch (chainId) {
        case 5611:
          addressStaking = STAKING_OPBNB_TESTNET;
          contract = ABI_STAKING;
          break;
        case 42161:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          break;
        default:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          break;
      }
      await handleUnStaking(poolIndex, addressStaking, contract);
      setAmount(0);
      toast(
        <ToastTyfile
          type="sucsess"
          description={`Successful unstake ${new Intl.NumberFormat().format(
            amount
          )} $XRAI`}
        />,
        { progress: 0, hideProgressBar: true }
      );
      setAmount(0);
    } catch (e) {
      toast(
        <ToastTyfile
          type="error"
          description={e?.reason?.replace("execution reverted: ", "")}
        />,
        { progress: 0, hideProgressBar: true }
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!account) return;
    (async () => {
      let addressStaking = "";
      let contract;
      let tokenAddress = "";
      switch (chainId) {
        case 5611:
          addressStaking = STAKING_OPBNB_TESTNET;
          contract = ABI_STAKING;
          tokenAddress = XRAI_OPBNB_TESTNET;
          break;
        case 42161:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          tokenAddress = XRAI_ARB_MAINET;
          break;
        default:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          tokenAddress = XRAI_ARB_MAINET;
          break;
      }
      const allowance = await getAllowance(
        tokenAddress,
        addressStaking,
        account
      );
      if (new Decimal(allowance || 0).lt(amount || "0")) {
        setIsApproval(false);
      } else {
        setIsApproval(true);
      }

      const userInfo = await getUserInfo(poolIndex,addressStaking,contract,account)
      setUserInfo(userInfo)
    })();
  }, [account, amount, chainId, poolIndex]);

  const handleApproval = async () => {
    setIsLoading(true);
    try {
      let addressStaking = "";
      let contract;
      let tokenAddress = "";
      switch (chainId) {
        case 5611:
          addressStaking = STAKING_OPBNB_TESTNET;
          contract = ABI_STAKING;
          tokenAddress = XRAI_OPBNB_TESTNET;
          break;
        case 42161:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          tokenAddress = XRAI_ARB_MAINET;
          break;
        default:
          addressStaking = STAKING_ARB_MAINNET;
          contract = ABI_STAKING;
          tokenAddress = XRAI_ARB_MAINET;
          break;
      }
      await approvalToken(tokenAddress, addressStaking, amount);
      setIsApproval(true);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  console.log({stakingTime:userInfo?.stakingTime})

  const unlockDay = new Decimal(userInfo?.stakingTime?._hex || 0).greaterThan(0) ?  dayjs(new Decimal(userInfo?.stakingTime._hex || 0).mul(1000).toNumber()).add(locksDay,"day") : null

  return (
    <ModalStakingBox>
      <Container>
        <BoxModal isactive={active}>
          <BoxHeader
            style={{
              borderBottom: 0,
              paddingBottom: 0,
            }}
          >
            {type === "staking" ? (
              <h6 className="text-center w-100">Stake $XRAI</h6>
            ) : (
              <h6 className="text-center w-100">Unstake $XRAI</h6>
            )}
          </BoxHeader>
          <BoxPd>
            <>
              <BoxModalContent>
                {type === "staking" ? (
                  <>
                    <div className="input-amount">
                      <div className="title d-flex justify-content-between mt-1">
                        <p>Amount</p>
                        <p>
                          Your Balance:{" "}
                          {new Intl.NumberFormat().format(
                            Number(balance).toFixed(2)
                          )}{" "}
                          $XRAI
                        </p>
                      </div>
                      <div className="input-value">
                        <BoxOneElm
                          className="w-100"
                          required={true}
                          submitError={false}
                        >
                          <input
                            type="text"
                            placeholder={"Input Amount"}
                            value={amount}
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                          />
                        </BoxOneElm>
                      </div>
                    </div>
                    <div className="action d-flex justify-content-between mt-3">
                      <button onClick={onClose}>Cancel</button>
                      {isApproval ? (
                        <button
                          style={{
                            cursor: isLoading && "not-allowed",
                          }}
                          disabled={isLoading}
                          onClick={handleStake}
                        >
                          Stake
                          {isLoading && (
                            <i
                              style={{
                                marginLeft: "10px",
                              }}
                              className="fa fa-spinner fa-spin"
                            ></i>
                          )}
                        </button>
                      ) : (
                        <button
                          style={{
                            cursor: isLoading && "not-allowed",
                          }}
                          disabled={isLoading}
                          onClick={handleApproval}
                        >
                          Approval
                          {isLoading && (
                            <i
                              style={{
                                marginLeft: "10px",
                              }}
                              className="fa fa-spinner fa-spin"
                            ></i>
                          )}
                        </button>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="unstaking">
                      {unlockDay && (
                        <div>Unlock day:{unlockDay.toString()}</div>
                      )}
                      <BoxNotice
                        style={{
                          background: `rgb(255, 0, 0,  0.1)`,
                          border: "1px solid red",
                        }}
                        className="d-flex align-items-center mb-4"
                      >
                        <div
                          className="img-notice"
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M13.016 8.88398C13.0133 8.39592 12.6154 8.00248 12.1273 8.00522C11.6393 8.00797 11.2458 8.40584 11.2486 8.89391L13.016 8.88398ZM11.2706 12.8154C11.2733 13.3035 11.6712 13.6969 12.1593 13.6942C12.6474 13.6914 13.0408 13.2935 13.038 12.8055L11.2706 12.8154ZM13.016 15.7526C13.016 15.2646 12.6204 14.8689 12.1323 14.8689C11.6442 14.8689 11.2486 15.2646 11.2486 15.7526H13.016ZM11.2486 15.7624C11.2486 16.2505 11.6442 16.6462 12.1323 16.6462C12.6204 16.6462 13.016 16.2505 13.016 15.7624H11.2486ZM10.4327 3.96915L9.66792 3.52636V3.52636L10.4327 3.96915ZM3.03773 16.7423L2.27292 16.2995H2.27292L3.03773 16.7423ZM21.2269 16.7423L20.4621 17.1851L21.2269 16.7423ZM13.8319 3.96915L13.0671 4.41194V4.41194L13.8319 3.96915ZM11.2486 8.89391L11.2706 12.8154L13.038 12.8055L13.016 8.88398L11.2486 8.89391ZM11.2486 15.7526V15.7624H13.016V15.7526H11.2486ZM9.66792 3.52636L2.27292 16.2995L3.80254 17.1851L11.1975 4.41193L9.66792 3.52636ZM4.7373 20.5739H19.5273V18.8064H4.7373V20.5739ZM21.9917 16.2995L14.5967 3.52637L13.0671 4.41194L20.4621 17.1851L21.9917 16.2995ZM19.5273 20.5739C21.7209 20.5739 23.0907 18.1979 21.9917 16.2995L20.4621 17.1851C20.879 17.9052 20.3594 18.8064 19.5273 18.8064V20.5739ZM2.27292 16.2995C1.17385 18.1979 2.5437 20.5739 4.7373 20.5739V18.8064C3.90525 18.8064 3.38565 17.9052 3.80254 17.1851L2.27292 16.2995ZM11.1975 4.41193C11.6136 3.69335 12.651 3.69335 13.0671 4.41194L14.5967 3.52637C13.4999 1.63191 10.7647 1.6319 9.66792 3.52636L11.1975 4.41193Z"
                              fill="#D92D20"
                            />
                          </svg>
                        </div>
                       
                        <Notice
                          style={{
                            color: "#F04438",
                            fontSize: "14.14px",
                            fontStyle: "normal",
                          }}
                        >
                          Unstake before unlock time, unstake fee is 5%
                        </Notice>
                      </BoxNotice>
                      <div
                        style={{
                          marginTop: "25px",
                        }}
                        className="input-amount time-unlock d-flex align-item-center"
                      >
                        <div
                          className="img"
                          style={{
                            marginTop: "2px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_528_5284)">
                              <path
                                d="M10.9544 21.9216C13.0519 21.9216 15.1022 21.2996 16.8462 20.1343C18.5901 18.969 19.9494 17.3128 20.752 15.375C21.5547 13.4372 21.7647 11.305 21.3555 9.24784C20.9463 7.19071 19.9363 5.30111 18.4532 3.818C16.9701 2.33489 15.0805 1.32488 13.0233 0.915687C10.9662 0.506498 8.83392 0.716509 6.89614 1.51916C4.95836 2.32182 3.30212 3.68106 2.13684 5.42502C0.971571 7.16897 0.349609 9.2193 0.349609 11.3167C0.35265 14.1284 1.47092 16.824 3.45905 18.8121C5.44718 20.8002 8.14279 21.9185 10.9544 21.9216ZM10.9544 5.13059C11.2166 5.13059 11.4729 5.20834 11.6909 5.354C11.9089 5.49966 12.0788 5.70669 12.1791 5.94891C12.2795 6.19113 12.3057 6.45767 12.2546 6.71481C12.2034 6.97195 12.0772 7.20815 11.8918 7.39354C11.7064 7.57893 11.4702 7.70518 11.213 7.75633C10.9559 7.80748 10.6894 7.78122 10.4471 7.68089C10.2049 7.58056 9.99789 7.41066 9.85223 7.19266C9.70657 6.97467 9.62883 6.71837 9.62883 6.4562C9.62883 6.10462 9.76849 5.76745 10.0171 5.51885C10.2657 5.27025 10.6029 5.13059 10.9544 5.13059ZM10.0707 9.54927H10.9544C11.4232 9.54927 11.8728 9.73548 12.2042 10.0669C12.5357 10.3984 12.7219 10.848 12.7219 11.3167V16.6191C12.7219 16.8535 12.6288 17.0783 12.4631 17.244C12.2973 17.4098 12.0725 17.5029 11.8382 17.5029C11.6038 17.5029 11.379 17.4098 11.2133 17.244C11.0475 17.0783 10.9544 16.8535 10.9544 16.6191V11.3167H10.0707C9.83631 11.3167 9.61153 11.2236 9.4458 11.0579C9.28007 10.8922 9.18696 10.6674 9.18696 10.433C9.18696 10.1986 9.28007 9.97384 9.4458 9.80811C9.61153 9.64238 9.83631 9.54927 10.0707 9.54927Z"
                                fill="#1D2939"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_528_5284">
                                <rect
                                  width="21.2096"
                                  height="21.2096"
                                  fill="white"
                                  transform="translate(0.349609 0.711914)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <p
                          style={{
                            marginLeft: "8px",
                          }}
                        >
                          Unstake when enough time to unlock 0% fee.
                        </p>
                      </div>
                    </div>
                    <div className="action d-flex justify-content-between mt-3">
                      <button onClick={onClose}>Cancel</button>
                      <button
                        style={{
                          cursor: (isLoading || new Decimal(userInfo?.stakingTime?._hex || 0).eq(0)) && "not-allowed",
                        }}
                        disabled={isLoading}
                        onClick={handleUnStake}
                      >
                        Unstake{" "}
                        {isLoading && (
                          <i
                            style={{
                              marginLeft: "10px",
                            }}
                            className="fa fa-spinner fa-spin"
                          ></i>
                        )}
                      </button>
                    </div>
                  </>
                )}
              </BoxModalContent>
            </>
          </BoxPd>
        </BoxModal>
      </Container>
    </ModalStakingBox>
  );
};

const ModalStakingBox = styled.div`
  width: 490px;
  .input-amount {
    p {
      color: #000000;
      font-size: 16.496px;
      font-style: normal;
      font-weight: 500;
      line-height: 28.28px; /* 171.429% */
    }
    input {
      border: 1px solid #ed13d2;
      color: #000000;
    }
  }
  .action {
    gap: 20px;
    button {
      width: 50%;
      padding: 12.961px 23.566px;
      &:nth-child(1) {
        background-color: #ffffff;
        color: #ed13d2;
        &:hover {
          box-shadow: 0px 3px 16px rgb(47 83 109 / 20%);
        }
      }
      &:nth-child(2) {
        &:hover {
          background-color: #ed13d2;
          color: #ffffff;
          box-shadow: 0px 3px 16px rgb(47 83 109 / 30%);
        }
      }
    }
  }
`;

import styled from "styled-components";
import x from "../../assets/images/panner/X.png";
import v from "../../assets/images/panner/v.png";

const ToastTyfile = ({ type, title, description }: any) => {
  return (
    <ToastContainer>
      <TypeBox type={type}>
        {" "}
        {type === "error" ? (
          <img src={x} alt="icon" />
        ) : (
          <img src={v} alt="icon" />
        )}{" "}
      </TypeBox>
      <BoxDescription type={type}>
        <h6>
          {title
            ? title
            : type === "error"
            ? "Error Message"
            : "Success Message"}
        </h6>
        <p>{description || ""}</p>
      </BoxDescription>
    </ToastContainer>
  );
};
export default ToastTyfile;
const ToastContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 10px;
  padding-right: 30px;
  min-width: 320px;
  width: fit-content;
  gap: 16px;
  ::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: calc(100%);
    left: 0px;
    top: 0px;
    height: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  }
`;
const BoxDescription = styled.div`
  padding: 0px 5px;
  h6 {
    color: ${(props) => (props.type === "error" ? "#D92D20" : "#039855")};
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.6px;
  }
  p {
    color: #000 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
`;
const TypeBox = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 9.6px;
  background-color: ${(props) =>
    props.type === "error"
      ? "#FFE4E8"
      : props.type === "sucsess"
      ? "#D1FADF"
      : "#FFC700"};
  box-shadow: 0px 2px 16px
    ${(props) =>
      props.type === "error"
        ? "#FFE4E8"
        : props.type === "sucsess"
        ? "#D1FADF"
        : "#FFC700"};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 18px;
    max-height: 18px;
  }
`;

import styled from "styled-components";
import { ButtonCommon, TitleKey } from "../../../../components/common/styled";

export const CreateContractContainer = styled.div`
  ${ButtonCommon} {
    min-width: 344px;
  }
  @media screen and (max-width: 743px) {
    ${ButtonCommon} {
      min-width: auto;
    }
  }
`
export const CCBody = styled.div`
  background-color: #fff;
  max-width: 750px;
  margin: 0 auto 25px;
  padding: 35px 20px;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #DCE2E9;
  border-radius: 20px;
`
export const CCConfirm = styled.div`
  display: flex;
  text-align: left;
  border-radius: 10px;
  background: #F5F5F5;
  padding: 30px 20px;
  gap: 50px;
  margin-bottom: 20px;
  @media screen and (max-width: 743px) {
    display: block;
  }
`
export const ConfirmCol = styled.div`
  ${TitleKey} {
    color: #717696;
    margin-bottom: 10px;
  }
  p {
    display: flex;
    align-items: center;
    color: #0D0C43;
    margin-bottom: 0;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  @media screen and (max-width: 743px) {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    p {
      font-size: 15px;
    }
    ${TitleKey} {
      color: #000;
      margin-bottom: 5px;
      font-size: 18px;
    }
  }
`


export const FormContainer = styled.div`
  max-width: 620px;
  margin: 0 auto;
  text-align: left;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  margin-bottom: 20px;

 
`

export const ShowBlock = styled.div`
  margin-bottom: 15px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #F313CE;
  background: rgba(0, 178, 200, 0.05);
  `
export const ShowRow = styled.div`
  color: #0D0C43;
  font-weight: 600;
  letter-spacing: 0.2px;
  span {
    color: #F313CE;
    margin-left: 5px;
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`

import React, { useContext, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { Container } from "react-bootstrap";
import {
  BoxHeader,
  BoxModal,
  BoxModalContent,
  BoxModalDisConnect,
  BoxPd,
  BoxPdDisconnect,
} from "../../../Modal/PopUpConnectStyle";
import { ModalConfirmContext } from "../../../ProviderPopUp/confirm";
import { RefreshContext } from "../../../../context/RefreshContext";
import { useActiveWeb3React, useConnectWallet } from "../../../../hooks";
import axiosInstance from "../../../../utils/axios";
import { BoxOneElm } from "./input/style";
import styled from "styled-components";
import { optionAddressContracts } from "../../../../constants/connectors/addressContract";
import { shortenAddress } from "../../../../utils/formartAddress";
import changeNetwork from "../../../../utils/SelectNetwork";
import { LIST_NETWORK } from "../../../../constants/connectors";
import { Link } from "react-router-dom";
import { useAccount, useChainId } from "wagmi";

export const ModalMintNFT = (props) => {
  const { onCreate, image, type, isLoading, isNotice, token } = props;
  const { onClose } = useContext(ModalConfirmContext);
  const context = useWeb3React();
  const { address: account } = useAccount();
  const { active, error } = context;
  const [nftName, setNFTName] = useState("");
  const [chainSelected, setChainSelected] = useState();
  const [collectionNFT, setCollectionNFT] = useState(null);
  const [nftDescriptions, setNFTDescriptions] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [listCollection, setListCollection] = useState([]);
  const [web3, setWeb3] = useState(new Web3(window.ethereum));
  const [showListSize, setShowListSize] = useState(false);
  const [showListNetwork, setShowListNetwork] = useState(false);
  const [isMinting, setisMinting] = useState(false);
  const chainId = useChainId();
  const { networkLocal } = useContext(RefreshContext);

  const verifyUser = async (signature) => {
    try {
      const res = await axiosInstance.post("/user/verify", {
        address: account,
        signature,
        message: "Hello, please sign this message for verification.",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignMessage = async () => {
    try {
      if (web3) {
        const message = "Hello, please sign this message for verification.";
        // @ts-ignore
        const signature = await web3.eth.personal.sign(message, account);
        localStorage.setItem("signature", signature);
        verifyUser(signature);
      } else {
        console.log("Connect to MetaMask first.");
      }
    } catch (err) {
      // @ts-ignore
      console.log("Connect to MetaMask first.");
    }
  };

  const getAllCollections = (chainId) => {
    const contractActive = optionAddressContracts.find(
      (item) => Number(item.chainId) === Number(chainId)
    );
    if (contractActive) {
      setListCollection(contractActive.contract);
    }
  };

  const handleSelectChainId = (chain) => {
    setShowListNetwork(false);
    if (chain.chainId !== chainId) {
      changeNetwork(chain.idText);
    }
    setChainSelected(chain);
  };

  useEffect(() => {
    const sign = localStorage.getItem("signature");
    if (!sign) {
      handleSignMessage();
    }
  }, [account]);

  useEffect(() => {
    if (chainId) {
      getAllCollections(chainId);
    } else {
      getAllCollections(networkLocal.chainId);
    }
  }, [chainId, networkLocal]);

  return (
    <Container>
      <BoxModal isactive={active}>
        <>
          <BoxHeader
            style={{
              border: "none",
              padding: "0 20px",
              paddingTop: "20px",
            }}
          >
            <p>
              {token?.collection
                ? token?.collection
                : "Mint NFT XRender AI Collection"}
            </p>
            <button onClick={onClose}>
              <img src="/assets/images/closeIconC3.svg" alt="" />
            </button>
          </BoxHeader>
        </>
        <BoxPd
          style={{
            paddingBottom: "0",
          }}
        >
          <BoxModalContent
            style={{
              gap: 0,
            }}
          >
            <BoxImage>
              <div className="box-img">
                <img src={token?.image ? token?.image : image} alt="images" />
              </div>
            </BoxImage>
            {isNotice ? (
              <>
                <div className="meta-info">
                  <div className="author">
                    <div className="info my-3">
                      <div className="flex">
                        <i
                          style={{
                            color: "green",
                            fontSize: "16px",
                            marginTop: "5px",
                            marginRight: "5px",
                            display: "inline-block",
                          }}
                          class="fa fa-check-circle"
                        ></i>{" "}
                        <h6
                          style={{
                            color: "#000",
                          }}
                          className=""
                        >
                          Congratulations on your successful minting (
                          {token?.collection
                            ? token?.collection
                            : collectionNFT
                            ? collectionNFT?.name
                            : " NFT XRender AI Collection"}{" "}
                          ) with
                          <span
                            style={{
                              color: "#f612d1",
                              fontWeight: 600,
                            }}
                          >
                            {" "}
                            {nftName ? nftName : "TokenId"} #{token?.id}
                          </span>
                          .
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="info mb-3">
                    <h6
                      className="text-center"
                      style={{
                        color: "#000",
                      }}
                    >
                      Transaction:{" "}
                      <a
                        style={{
                          color: "#f612d1",
                        }}
                        href={token?.link}
                        onClick={(e) => e.stopPropagation()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{shortenAddress(token?.hash)}</span>
                      </a>
                    </h6>
                  </div>
                </div>
              </>
            ) : (
              <>
                {type === "admin" && (
                  <BoxInput>
                    <BoxOneElm showListSize={showListSize}>
                      <h6>Collection Name</h6>
                      <button
                        onClick={() => {
                          setShowListSize(!showListSize);
                        }}
                      >
                        {collectionNFT?.name}
                        <i
                          color="#000"
                          className="fa fa-caret-down"
                          aria-hidden="true"
                        ></i>
                      </button>
                      {showListSize && (
                        <ul>
                          {listCollection.map((item, index) => {
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  setShowListSize(false);
                                  setCollectionNFT(item);
                                }}
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                }}
                              >
                                {item.name}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </BoxOneElm>
                  </BoxInput>
                )}
                {type !== "admin" && (
                  <BoxInput>
                    <BoxOneElm showListSize={showListNetwork}>
                      <h6>Chain</h6>
                      <button
                        onClick={() => {
                          setShowListNetwork(!showListNetwork);
                        }}
                      >
                        {chainSelected?.name}
                        <i
                          color="#000"
                          className="fa fa-caret-down"
                          aria-hidden="true"
                        ></i>
                      </button>
                      {showListNetwork && (
                        <ul>
                          {LIST_NETWORK.map((item, index) => {
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  handleSelectChainId(item);
                                }}
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                }}
                              >
                                <Link className={"box-widget-filter"}>
                                  <img
                                    style={{
                                      width: "24px",
                                      marginRight: "10px",
                                    }}
                                    src={item.image}
                                    alt={item.name}
                                  />
                                  <span>{item.name}</span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </BoxOneElm>
                  </BoxInput>
                )}
                <BoxInput>
                  <BoxOneElm required={true} submitError={submitError}>
                    <h6>NFT Name</h6>
                    <input
                      type="text"
                      placeholder={"Input your NFT name (300 characters)"}
                      value={nftName}
                      onChange={(e) => {
                        setNFTName(e.target.value);
                      }}
                    />
                  </BoxOneElm>
                </BoxInput>
                <BoxInput>
                  <BoxOneElm required={true} submitError={submitError}>
                    <h6>Description</h6>
                    <textarea
                      type="text"
                      rows={3}
                      placeholder={"Input description (300 characters)"}
                      value={nftDescriptions}
                      onChange={(e) => {
                        setNFTDescriptions(e.target.value);
                      }}
                    />
                  </BoxOneElm>
                </BoxInput>
              </>
            )}

            <BoxButton>
              <div className="style2 flex">
                <button
                  to="/mint-nft"
                  className="sc-button header-slider style style-1 rocket fl-button pri-1"
                  onClick={() => {
                    onClose();
                    setisMinting(false);
                  }}
                  style={{
                    cursor: isMinting && "not-allowed",
                  }}
                  disabled={isMinting}
                >
                  {isNotice ? "OK" : "Cancel"}
                </button>

                {!isNotice && (
                  <button
                    to="/nft-ai"
                    className="sc-button header-slider style style-1 note fl-button pri-1"
                    onClick={async () => {
                      setisMinting(true);
                      await onCreate(
                        image,
                        nftName,
                        nftDescriptions,
                        collectionNFT,
                        chainSelected?.chainId
                      );
                      setisMinting(false);
                    }}
                    disabled={
                      !nftName ||
                      !nftDescriptions ||
                      isMinting ||
                      !chainSelected ||
                      chainSelected?.chainId !== chainId
                    }
                  >
                    Save
                    {isMinting && (
                      <i
                        style={{
                          marginLeft: "10px",
                        }}
                        className="fa fa-spinner fa-spin"
                      ></i>
                    )}
                  </button>
                )}
              </div>
            </BoxButton>
          </BoxModalContent>
        </BoxPd>
      </BoxModal>
    </Container>
  );
};

export const BoxInput = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000;
    top: 0px;
    left: 8px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    background-color: #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    &::before {
      content: "*";
      background-color: transparent;
      color: red;
    }
  }
  input,
  textarea,
  button {
    color: #000;
    border: 1px solid #000;
    font-size: 15px;
    &:focus {
      border: 1px solid #ed13d2 !important;
    }
    ::placeholder {
      color: #c4c4c4 !important;
    }

    ::-ms-input-placeholder {
      color: #c4c4c4 !important;
    }
  }

  ul {
    bottom: -5px;
    height: auto;
    padding: 10px;
    li {
      padding: 5px 20px;
      border-radius: 8px;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  button {
    font-weight: 400;
  }

  input,
  button {
    height: 42px;
  }
`;

export const BoxButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 32px;
  margin-bottom: 28px;
  margin-top: 12px;
  button {
    border-radius: 16px !important;
    border: 1px solid #ed13d2 !important;
    color: #ed13d2 !important;
    width: 148px;
    padding: 12px 32px;
    :nth-child(2) {
      background: #ed13d2;
      color: #fff !important;
      margin-left: 15px;
      :hover {
        background: #ed13d2;
        color: #fff !important;
      }
      :disabled {
        cursor: not-allowed;
      }
    }
  }
`;

export const BoxImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  .box-img {
    max-width: 300px;
    img {
      border-radius: 12px;
    }
  }
`;

import Home05 from "./Home05";
import ItemDetails01 from "./ItemDetails01";
import Authors02 from "./Authors02";
import ArbNFTPage from "./ArbNFTPage";
import MinNft from "./MinNft";
import Test from "./Test";
import MarketPlace from "./MarketPlace";
import CountDown from "./CountDown";
import ArbAdminPage from "./ArbAdminPage";
import ICOPage from "./ICOPage";
import DetailCollection from "../components/layouts/detailColection";
import StakingPage from "./StakingPage";
import Portfolio from "./Tooldex/Portfolio";
import TokenCreation from "./Tooldex/TokenCreation";
import CheckBalance from "./Tooldex/CheckBalance";
import WalletGenerator from "./Tooldex/WalletGenerator";
import MultiWalletCreate from "./Tooldex/MultiWalletCreate";
import MultiSender from "./Tooldex/MultiSender";
import TokenCollection from "./Tooldex/TokenCollection";
import AuditContract from "./Tooldex/AuditContract";
import Liquidity from "./Tooldex/Liquidity";
import LiquidityLockup from "./Tooldex/LiquidityLockup";

const routes = [
  { path: "/", component: <Home05 /> },
  // { path: "/marketplace", component: <Explore01 /> },
  // { path: "/explore-02", component: <Explore02 /> },
  // { path: "/explore-03", component: <Explore03 /> },
  // { path: "/explore-04", component: <Explore04 /> },
  // { path: "/live-auctions", component: <LiveAuctions /> },
  {
    path: "/details",
    component: <ItemDetails01 />,
  },
  {
    path: "/history",
    component: <ItemDetails01 />,
  },
  // { path: "/item-details-02", component: <ItemDetails02 /> },
  // { path: "/activity-01", component: <Activity01 /> },
  // { path: "/activity-02", component: <Activity02 /> },
  // { path: "/blog", component: <Blog /> },
  // { path: "/blog-details", component: <BlogDetails /> },
  // { path: "/help-center", component: <HelpCenter /> },
  // { path: "/authors-01", component: <Authors01 /> },
  { path: "/profile", component: <Authors02 /> },
  // { path: "/wallet-connect", component: <WalletConnect /> },
  // { path: "/create-item", component: <CreateItem /> },
  // { path: "/edit-profile", component: <EditProfile /> },
  // { path: "/ranking", component: <Ranking /> },
  // { path: "/no-result", component: <NoResult /> },
  // TODO
  { path: "/mint-nft", component: <CountDown /> },
  // { path: "/contact-01", component: <Contact01 /> },
  // { path: "/profile-edit", component: <Contact02 /> },
  { path: "/nft-ai", component: <ArbNFTPage /> },
  { path: "/cms/:type", component: <ArbAdminPage /> },
  { path: "/marketplace", component: <MarketPlace /> },
  { path: "/pre-Sale", component: <ICOPage /> },
  { path: "/staking", component: <StakingPage /> },
  { path: "/test", component: <MinNft /> },
  { path: "/collection", component: <DetailCollection /> },

  // Tooldex page
  { path: "/tooldex/portfolio", component: <Portfolio /> },
  { path: "/tooldex/createToken", component: <TokenCreation /> },
  { path: "/tooldex/checkBalance", component: <CheckBalance /> },
  { path: "/tooldex/walletGenerator", component: <WalletGenerator /> },
  { path: "/tooldex/multiWalletGenerator", component: <MultiWalletCreate /> },
  { path: "/tooldex/multiSender", component: <MultiSender /> },
  { path: "/tooldex/tokenCollection", component: <TokenCollection /> },
  { path: "/tooldex/audit", component: <AuditContract /> },
  { path: "/tooldex/liquidity", component: <Liquidity /> },
  { path: "/tooldex/liquidity-lockup", component: <LiquidityLockup /> },
  { path: "/tooldex/testNew", component: <Test /> },
];

export default routes;

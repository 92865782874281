import React, { useState, useEffect } from 'react'
import CountUp from 'react-countup'

import styled from 'styled-components'

interface ValueProps {
	value: string | number
	decimals?: number
	unit?: string
	size?: number
	color?: string
	unitafter?: string
	fontweight?: string
}

const Value: React.FC<ValueProps> = ({ value, decimals, unit, size, color,unitafter, fontweight }) => {
	const [start, updateStart] = useState(0)
	const [end, updateEnd] = useState(0)
	useEffect(() => {
		if (typeof value === 'number') {
			updateStart(end)
			updateEnd(value)
		}
	}, [value])

	return (
		<StyledValue size={size} color={color} fontweight={fontweight}>
			{typeof value == 'string' ? (
				value
			) : (
				<>
					{unit}
					<CountUp
						start={start}
						end={end}
						decimals={
							decimals !== undefined
								? decimals
								: end < 0.0000001 // nếu value < 0.00000001 
								? 2  // thì show giá trị 1 sốthập phân
								: end < 1 // nếu value < 1
								? 7 // thì show giá trị 7 số thập phân
								: end > 1e5 // nếu value lớn hơn 1e5 (100000)
								? 0 // thì show giá trị nguyên
								: 2 // ngược lại show 2 số thập phân
						}
						duration={1}
						separator=","
					/>
					{unitafter}
				</>
			)}
		</StyledValue>
	)
}

interface StyledValueProps {
	size?: number
	color?: string
	fontweight?: string
}

const StyledValue = styled.span<StyledValueProps>`
	font-size: ${(props) => (props.size ? `${props.size}px` : '18px')};
	color: ${(props) => (props.color ? `${props.color}` : '#333333')};
	font-weight: ${(props) => (props.fontweight && `${props.fontweight}`)};
	span {
		padding-right: 3px;
	}
`

export default Value

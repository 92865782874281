import { useEffect, useState } from "react";
import InputComponent from "../components/AbrNftAI/Body/minComponent/input";
import OutputComponent from "../components/AbrNftAI/Body/minComponent/output";
import { Wapper } from "../components/AbrNftAI/Body/styled";
import { ContainerGb } from "../components/container";
import Footer from "../components/footer/Footer";
import { useNavigate, useParams } from "react-router-dom";

interface Props { }

function ArbAdminPage(props: Props) {
  const { type } = useParams()
  const navigate = useNavigate()

  const [imageNFT, setImageNFT] = useState<any>(null);

  const onSetImage = (images: any) => {
    setImageNFT(images);
  };

  useEffect(() => {
    if (type !== '9759') {
      navigate('/not-found')
    }
  }, [type])

  return (
    <div className="nft-ai-page" >
      {/* <HeaderStyle2 /> */}
      <ContainerGb>
        <Wapper>
          <InputComponent onSetImage={onSetImage} />
          <OutputComponent imageOutput={imageNFT} type={'admin'} />
        </Wapper>
      </ContainerGb>
      {/* <Footer /> */}
    </div>
  );
}

export default ArbAdminPage;

import React, { useState, useEffect } from "react";
import Slider, { SliderMarks } from "antd/lib/slider";
import InputCommon from "../../../../../components/common/input";
import {
  ButtonCommon,
  SldierRange,
  TitleKey,
} from "../../../../../components/common/styled";
import {
  AddLP,
  BlockPair,
  ItemTitle,
  PairItem,
  PairWrapper,
  TPBody,
  TokenPairContainer,
  TransferSuccess,
  CCConfirm,
  ConfirmCol,
} from "./styled";
import { Select } from "antd";
import { SelectContainer } from "../../../../../components/common/selectInput/styled";

// Images
import tooltip from "../../../../../assets/images/liquidity/tooltip.svg";
import { ButtonAction } from "../../../../../components/common/form/selectNetwork/styled";
import arrow from "../../../../../assets/images/arrow.svg";

// copmmon
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { useAccount, useNetwork } from "wagmi";
import {
  ADD_LP_V2,
  SCAN_URL,
  token_network,
  LOGO_BY_CHAINID,
  NAME_NETWORK,
} from "../../../../../constants/Tooldex/index";
import abiPancakeAddLPV2 from "../../../../../constants/Tooldex/abi/abiPancakeAddLPV2.json";
import tokenABI from "../../../../../constants/Tooldex/abi/abiToken.json";
import {
  convertFromWei,
  convertToWei,
} from "../../../../../utils/Tooldex/convertNumber";
import { formatAddress } from "../../../../../utils/Tooldex/formats";
import {
  notiError,
  notiSuccess,
  notiWarning,
} from "../../../../../utils/Tooldex/notication";
import Value from "../../../../../components/Tooldex/Value";
import Loading from "../../../../../components/Tooldex/Loading";
// end common

declare const window: Window & typeof globalThis & { ethereum: any };

const marks: SliderMarks = {
  0: "0%",
  25: "25%",
  50: "50%",
  75: "75%",
  100: "100%",
};

const TokenPair = ({ setStep }: any) => {
  const { address } = useAccount();
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const account: any = address;
  const web3 = new Web3(window.ethereum);
  const { Option } = Select;

  const [transferTokenA, setTransferTokenA] = useState(false);
  const [transferTokenB, setTransferTokenB] = useState(false);

  const [amountA, setAmountA] = useState<any>("0");
  const [amountB, setAmountB] = useState<any>("0");

  const [contractTokenA, setContractTokenA] = useState("");
  const [contractTokenB, setContractTokenB] = useState("");

  const [symbolTokenA, setSymbolTokenA] = useState("Token A");
  const [symbolTokenB, setSymbolTokenB] = useState("Token B");

  const [depositedTokenA, setDepositedTokenA] = useState("0");
  const [depositedTokenB, setDepositedTokenB] = useState("0");

  const [balanceTokenA, setBalanceTokenA] = useState<any>("0");
  const [balanceTokenB, setBalanceTokenB] = useState<any>("0");

  const [allowanceA, setAllowanceA] = useState<any>("0");
  const [allowanceB, setAllowanceB] = useState<any>("0");

  const [approveTokenA, setApproveTokenA] = useState(false);
  const [approveTokenB, setApproveTokenB] = useState(false);

  const contractA = new web3.eth.Contract(
    tokenABI as unknown as AbiItem,
    contractTokenA
  );
  const contractB = new web3.eth.Contract(
    tokenABI as unknown as AbiItem,
    contractTokenB
  );
  const contract = new web3.eth.Contract(
    abiPancakeAddLPV2 as unknown as AbiItem,
    ADD_LP_V2[chainId]
  );

  const handleSelectPercentA = (percentage: any) => {
    if (percentage === 100) {
      setAmountA(balanceTokenA);
    } else {
      const balancePercent = (Number(balanceTokenA) * percentage) / 100;
      setAmountA(balancePercent);
    }
  };
  const handleSelectPercentB = (percentage: any) => {
    if (percentage === 100) {
      setAmountB(balanceTokenB);
    } else {
      const balancePercent = (Number(balanceTokenB) * percentage) / 100;
      setAmountB(balancePercent);
    }
  };
  const handelSellectBase = (value: any) => {
    setContractTokenB(value);
  };

  const checkInfoTokenA = async () => {
    try {
      if (contractTokenA) {
        const symbol = await contractA.methods.symbol().call();
        setSymbolTokenA(symbol);

        if (account) {
          const decimals = await contractA.methods.decimals().call();
          const balanceTokenByAccount = await contractA.methods
            .balanceOf(account)
            .call();
          const balanceFromWei: any = convertFromWei(
            balanceTokenByAccount.toString(),
            Number(decimals)
          );
          setBalanceTokenA(balanceFromWei);
          window.localStorage.setItem("decimalsA", decimals);
        }
        window.localStorage.setItem("symbolTokenA", symbol);

        window.localStorage.setItem("contractTokenA", contractTokenA);
      }
    } catch (error) {
      console.error("error check token A", error);
    }
  };
  useEffect(() => {
    if (contractTokenA) {
      checkInfoTokenA();
    }
  }, [contractTokenA, account]);

  const checkInfoTokenB = async () => {
    try {
      if (contractTokenB) {
        const symbol = await contractB.methods.symbol().call();
        setSymbolTokenB(symbol);
        if (account) {
          const decimals = await contractB.methods.decimals().call();
          const balanceTokenByAccount = await contractB.methods
            .balanceOf(account)
            .call();
          const balanceFromWei: any = convertFromWei(
            balanceTokenByAccount.toString(),
            Number(decimals)
          );
          setBalanceTokenB(balanceFromWei);
          window.localStorage.setItem("decimalsB", decimals);
        }
        window.localStorage.setItem("symbolTokenB", symbol);
        window.localStorage.setItem("contractTokenB", contractTokenB);
      }
    } catch (error) {
      console.error("error check token B", error);
    }
  };
  useEffect(() => {
    if (contractTokenB) {
      checkInfoTokenB();
    }
  }, [contractTokenB, account]);

  const handelTransferTokenA = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    try {
      setTransferTokenA(true);
      const decimals = await contractA.methods.decimals().call();
      const valueAToWei: any = convertToWei(
        amountA.toString(),
        Number(decimals)
      );
      await contract.methods
        .depositToken1(contractTokenA, valueAToWei)
        .send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
        .then((res: any) => {
          if (res.status) {
            notiSuccess("Success", 5);
            setTransferTokenA(false);
            checkInfoTokenA();
            setAmountA("0");
            // checkAmountA();
          }
        });
    } catch (error: any) {
      setTransferTokenA(false);
      notiError(error?.message, 5);
    }
  };

  const handelTransferTokenB = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    try {
      setTransferTokenB(true);
      const decimals = await contractB.methods.decimals().call();
      const valueBToWei: any = convertToWei(
        amountB.toString(),
        Number(decimals)
      );

      await contract.methods
        .depositBaseToken(contractTokenB, valueBToWei)
        .send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
        .then((res: any) => {
          if (res.status) {
            notiSuccess("Success", 5);
            setTransferTokenB(false);
            checkInfoTokenB();
            setAmountB("0");
            // checkAmountB();
          }
        });
    } catch (error: any) {
      setTransferTokenB(false);
      notiError(error?.message, 5);
    }
  };

  const checkApproveTokenA = async () => {
    try {
      if (contractTokenA) {
        const decimals = await contractA.methods.decimals().call();
        await contractA.methods
          .allowance(account, ADD_LP_V2[chainId])
          .call()
          .then((res: any) => {
            const allowA: any = convertFromWei(res, Number(decimals));
            setAllowanceA(allowA);
          });
      }
    } catch (error) {
      console.error("error check token A", error);
    }
  };
  useEffect(() => {
    checkApproveTokenA();
  }, [account, contractTokenA, amountA]);

  const checkApproveTokenB = async () => {
    try {
      if (contractTokenB) {
        const decimals = await contractB.methods.decimals().call();
        await contractB.methods
          .allowance(account, ADD_LP_V2[chainId])
          .call()
          .then((res: any) => {
            const allowB: any = convertFromWei(res, Number(decimals));
            setAllowanceB(allowB);
          });
      }
    } catch (error) {
      console.error("error check token A", error);
    }
  };
  useEffect(() => {
    checkApproveTokenB();
  }, [account, contractTokenB, amountB]);

  const handleApproveTokenA = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    try {
      setApproveTokenA(true);
      const decimals = await contractA.methods.decimals().call();
      const amountAToWei: any = convertToWei(
        amountA.toString(),
        Number(decimals)
      );
      await contractA.methods
        .approve(ADD_LP_V2[chainId], amountAToWei)
        .send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
        .then((res: any) => {
          if (res.status) {
            notiSuccess("Success", 5);
            setApproveTokenA(false);
            checkInfoTokenA();
            checkApproveTokenA();
          }
        });
    } catch (error: any) {
      setApproveTokenA(false);
      notiError(error?.message, 5);
    }
  };
  const handleApproveTokenB = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    try {
      setApproveTokenB(true);
      const decimals = await contractB.methods.decimals().call();
      const amountBToWei: any = convertToWei(
        amountB.toString(),
        Number(decimals)
      );
      await contractB.methods
        .approve(ADD_LP_V2[chainId], amountBToWei)
        .send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
        .then((res: any) => {
          if (res.status) {
            notiSuccess("Success", 5);
            setApproveTokenB(false);
            checkInfoTokenB();
            checkApproveTokenB();
          }
        });
    } catch (error: any) {
      setApproveTokenB(false);
      notiError(error?.message, 5);
    }
  };

  const checkBalanceDepositA = async () => {
    try {
      if (contractTokenA) {
        const decimals = await contractA.methods.decimals().call();
        await contract.methods
          .userToken1s(account, contractTokenA)
          .call()
          .then((res: any) => {
            const valueFromWei: any = convertFromWei(
              res.token1Amount.toString(),
              Number(decimals)
            );
            setDepositedTokenA(valueFromWei);
            window.localStorage.setItem("depositedTokenA", valueFromWei);
          })
          .catch((err: any) => {
            console.error("err", err);
          });
      }
    } catch (error) {
      console.error("error checkBalanceDepositA", error);
    }
  };
  useEffect(() => {
    checkBalanceDepositA();
  }, [account, contractTokenA, amountA]);

  const checkBalanceDepositB = async () => {
    try {
      if (contractTokenB) {
        const decimals = await contractB.methods.decimals().call();
        await contract.methods
          .userBaseTokens(account, contractTokenB)
          .call()
          .then((res: any) => {
            const valueFromWei: any = convertFromWei(
              res.baseTkAmount.toString(),
              Number(decimals)
            );
            setDepositedTokenB(valueFromWei);
            window.localStorage.setItem("depositedTokenB", valueFromWei);
          });
      }
    } catch (error) {
      console.error("error checkBalanceDepositB", error);
    }
  };
  useEffect(() => {
    checkBalanceDepositB();
  }, [account, contractTokenB, amountB]);

  return (
    <TokenPairContainer>
      <TPBody>
        <AddLP>
          <TitleKey>Smart contract add LP</TitleKey>
          <InputCommon
            disabled
            placeHolder="Add Smart Contract"
            defaultValue={ADD_LP_V2[chainId]}
          />
        </AddLP>

        <CCConfirm>
          <ConfirmCol>
            <TitleKey>{symbolTokenA} Deposited</TitleKey>
            <p>
              <Value
                size={14}
                color="#F313CE"
                fontweight="700"
                value={Number(depositedTokenA)}
                unitafter={symbolTokenA}
              />
            </p>
          </ConfirmCol>
          <ConfirmCol>
            <TitleKey>{symbolTokenB} Deposited</TitleKey>
            <p>
              <Value
                size={14}
                color="#F313CE"
                fontweight="700"
                value={Number(depositedTokenB)}
                unitafter={symbolTokenB}
              />
            </p>
          </ConfirmCol>
          <ConfirmCol>
            <TitleKey>Liquidity Pair</TitleKey>
            <p>
              {symbolTokenA}/{symbolTokenB}
            </p>
          </ConfirmCol>
          <ConfirmCol>
            <TitleKey>DEX</TitleKey>
            <p>
              <img
                width={20}
                height={20}
                src="../../../images/pancake.png?v=5"
                alt="logo"
              />
              <span>PancakeSwap V2</span>
            </p>
          </ConfirmCol>
        </CCConfirm>
        <PairWrapper>
          <BlockPair>
            <TitleKey>Select pair</TitleKey>
            <PairItem>
              <ItemTitle>
                <div>
                  <TitleKey>Address token A (TD, ...)</TitleKey>
                  <img width={18} height={18} src={tooltip} alt="tooltip" />
                </div>
                <div>
                  <TitleKey>{symbolTokenA}</TitleKey>
                </div>
              </ItemTitle>
              <InputCommon
                placeHolder="Token Address"
                value={contractTokenA}
                onChange={(e: any) =>
                  web3.utils.isAddress(e.target.value)
                    ? setContractTokenA(e.target.value)
                    : setContractTokenA("")
                }
              />
            </PairItem>
            <PairItem>
              <ItemTitle>
                <div>
                  <TitleKey>
                    {symbolTokenA} in{" "}
                    <span>{formatAddress(account, 4, 6) || " Wallet"}</span>
                  </TitleKey>
                </div>
                <p>
                  <Value
                    size={14}
                    unitafter={symbolTokenA}
                    value={Number(balanceTokenA)}
                  />
                </p>
              </ItemTitle>
              <InputCommon
                placeHolder="0.0"
                value={amountA}
                onChange={(e: any) => setAmountA(e.target.value)}
                suffix={symbolTokenA}
              />
              <SldierRange>
                <Slider
                  marks={marks}
                  step={25}
                  onChange={handleSelectPercentA}
                />
              </SldierRange>
              <TransferSuccess>
                <TitleKey>
                  Deposit{" "}
                  {symbolTokenA && (
                    <Value
                      size={15}
                      unitafter={symbolTokenA}
                      value={Number(amountA)}
                    />
                  )}
                  to contract
                </TitleKey>
              </TransferSuccess>

              {Number(allowanceA) < amountA ? (
                <ButtonCommon
                  disabled={
                    !account || approveTokenA || !contractTokenA || amountA <= 0
                  }
                  onClick={handleApproveTokenA}
                >
                  <Loading status={approveTokenA} content="Approve" />
                </ButtonCommon>
              ) : (
                <ButtonCommon
                  disabled={
                    !account ||
                    transferTokenA ||
                    !contractTokenA ||
                    amountA <= 0 ||
                    amountA > Number(balanceTokenA)
                  }
                  onClick={handelTransferTokenA}
                >
                  <Loading status={transferTokenA} content="Deposit" />
                </ButtonCommon>
              )}
            </PairItem>
          </BlockPair>
          <BlockPair>
            <TitleKey>Token B</TitleKey>
            <PairItem>
              <ItemTitle>
                <div>
                  <TitleKey>Choose token B (WBNB,...)</TitleKey>
                  <img width={18} height={18} src={tooltip} alt="tooltip" />
                </div>
                <div>
                  <TitleKey>{symbolTokenB}</TitleKey>
                </div>
              </ItemTitle>

              <SelectContainer>
                <Select
                  suffixIcon={<img src={arrow} alt="arrow" />}
                  placeholder="Please sellect token B"
                  onChange={handelSellectBase}
                  style={{ width: "100%" }}
                >
                  {data.map((item: any, index: number) => (
                    <Option key={index} value={item.value}>
                      <div>
                        {item.img && <img src={item.img} alt="icon" />}
                        {item.title}
                      </div>
                    </Option>
                  ))}
                </Select>
              </SelectContainer>
            </PairItem>
            <PairItem>
              <ItemTitle>
                <div>
                  <TitleKey>
                    {symbolTokenB} in{" "}
                    <span>{formatAddress(account, 4, 6) || " Wallet"}</span>
                  </TitleKey>
                </div>
                <p>
                  <Value
                    size={14}
                    unitafter={symbolTokenB}
                    value={Number(balanceTokenB)}
                  />
                </p>
              </ItemTitle>
              <InputCommon
                placeHolder="0.0"
                value={amountB}
                onChange={(e: any) => setAmountB(e.target.value)}
                suffix={symbolTokenB}
              />
              <SldierRange>
                <Slider
                  marks={marks}
                  step={25}
                  onChange={handleSelectPercentB}
                />
              </SldierRange>
              <TransferSuccess>
                <TitleKey>
                  Deposit{" "}
                  {symbolTokenB && (
                    <Value
                      size={15}
                      unitafter={symbolTokenB}
                      value={Number(amountB)}
                    />
                  )}{" "}
                  to contract
                </TitleKey>
              </TransferSuccess>
              {Number(allowanceB) < amountB ? (
                <ButtonCommon
                  disabled={
                    !account || approveTokenB || !contractTokenB || amountB <= 0
                  }
                  onClick={handleApproveTokenB}
                >
                  <Loading status={approveTokenB} content="Approve" />
                </ButtonCommon>
              ) : (
                <ButtonCommon
                  disabled={
                    !account ||
                    transferTokenB ||
                    !contractTokenB ||
                    amountB <= 0 ||
                    amountB > Number(balanceTokenB)
                  }
                  onClick={handelTransferTokenB}
                >
                  <Loading status={transferTokenB} content="Deposit" />
                </ButtonCommon>
              )}
            </PairItem>
          </BlockPair>
        </PairWrapper>
      </TPBody>
      <ButtonAction>
        {chainId === 56 ? (
          <ButtonCommon
            style={{
              border:
                account &&
                contractTokenA &&
                contractTokenB &&
                Number(depositedTokenA) > 0 &&
                Number(depositedTokenB) > 0 &&
                "2px solid #F313CE",
              padding: "5px 30px",
              width: "auto",
              fontWeight: "600",
            }}
            background="transparent"
            textColor="#F313CE"
            disabled={
              !account ||
              !contractTokenA ||
              !contractTokenB ||
              Number(depositedTokenA) <= 0 ||
              Number(depositedTokenB) <= 0
            }
            onClick={() => setStep(1)}
          >
            Next
          </ButtonCommon>
        ) : (
          <ButtonCommon
            style={{
              padding: "5px 30px",
              width: "auto",
              fontWeight: "600",
            }}
            disabled
          >
            Comming soon
          </ButtonCommon>
        )}
      </ButtonAction>
    </TokenPairContainer>
  );
};
const data = [
  {
    title: "WBNB",
    value: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    img: "../../../images/bnb-token.png?v=5",
  },
];
export default TokenPair;

import styled from "styled-components";
import { ButtonCommon, TextCommon, TextCustom } from "../../styled";

export const FormCW = styled.div`
  background-color: #fff;
  max-width: 675px;
  margin: 0 auto;
  padding: 35px 70px;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #dce2e9;
  border-radius: 20px;
  text-align: center;
  ul {
    margin-bottom: 0;
  }
  ${TextCustom} {
    margin-bottom: 10px;
  }
  ${TextCommon} {
    margin-bottom: 20px;
  }
  ${ButtonCommon} {
    min-width: 360px;
  }
  @media screen and (max-width: 743px) {
    padding: 20px;
    ${ButtonCommon} {
      min-width: auto;
    }
  }
`;
export const ListWallet = styled.ul`
  margin-bottom: 30px;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  & > li {
    width: calc(50% - 20px);
    padding: 10px;
    list-style-type: none;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid #81829b;
    background: #fff;
    color: #0d0c43;
    font-size: 16px;
    font-weight: 500;
    transition: 0.25s;
    p {
      transition: color 0.25s;
      margin-bottom: 0;
    }
    img {
      width: 48px;
      height: 48px;
      margin-bottom: 10px;
    }
    &.current {
      border-color: #F313CE;
      p {
        color: #F313CE;
      }
    }
    &:hover {
      border-color: #F313CE;
      p {
        color: #F313CE;
      }
    }
  }
  @media screen and (max-width: 743px) {
    & > li {
      width: calc(50% - 10px);
      p {
        font-size: 14px;
      }
    }
  }
`;

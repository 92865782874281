import { PortfolioBody, PortfolioContainer } from "./styled";
import PortfolioDetails from "./Details";
import KeyvisualCommon from "../../../components/common/keyvisual";
import { TextCustom } from "../../../components/common/styled";

const Portfolio = () => {
  return (
    <PortfolioContainer>
      <KeyvisualCommon
        text="Portfolio By Wallet"
        content={
          <TextCustom color="#fff" fontSize="16px" fontWeight="300">
            All token in your wallet are not displayed in this investment
            portfolio.
          </TextCustom>
        }
      />
      <PortfolioBody>
        <PortfolioDetails />
      </PortfolioBody>
    </PortfolioContainer>
  );
};

export default Portfolio;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import avt from "../assets/images/avatar/avata_profile.jpg";
import bg1 from "../assets/images/backgroup-secsion/option1_bg_profile.jpg";
import bg2 from "../assets/images/backgroup-secsion/option2_bg_profile.jpg";
import styled from "styled-components";
import axios from "axios";
import { useActiveWeb3React } from "../hooks";
import axiosInstance from "../utils/axios";
const optionsBanner1 =
  "https://xrender.ai/assets/images/backgroup-secsion/option1_bg_profile.jpg";
const optionsBanner2 =
  "https://xrender.ai/assets/images/backgroup-secsion/option2_bg_profile.jpg";

const apiUrl = "/user/update-user?address=";

const EditProfile = ({
  userName,
  avatar,
  banner,
  bio,
  facebook,
  twitter,
  discord,
  email,
  handleReLoad,
}) => {
  const { account, chainId } = useActiveWeb3React();
  const [paramUpdate, setParamUpdate] = useState({
    username: userName || "",
    avatar: avatar || "",
    banner: banner || "",
    bio: bio || "",
    facebook: facebook || "",
    twitter: twitter || "",
    discord: discord || "",
    email: email || "",
  });
  const handleGetValues = (key, value) => {
    setParamUpdate({ ...paramUpdate, [key]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`/user/update-user?address=${account}`, paramUpdate)
      .then((response) => {
        handleReLoad();
      })
      .catch((error) => {
        console.error("Lỗi:", error);
      });
  };
  return (
    <StyleWapper className="tf-create-item tf-section">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6 col-12">
            <h4 style={{ color: "#fff" }} className="title-create-item">
              Your Avartar
            </h4>
            <div className="sc-card-profile text-center">
              <div className="card-media">
                <img
                  id="profileimg"
                  src={"/assets/images/avt-author-tab.png"}
                  alt="Axies"
                />
              </div>
              {/* <div id="upload-profile">
                <Link to="#" className="btn-upload">
                  Upload New Photo{" "}
                </Link>
                <input
                  id="tf-upload-img"
                  type="file"
                  name="profile"
                  required=""
                />
              </div> */}
            </div>
          </div>
          <div className="col-xl-9 col-lg-8 col-md-12 col-12">
            <div className="form-upload-profile">
              <h4 style={{ color: "#fff" }} className="title-create-item">
                Choice your Cover image
              </h4>
              <div className="option-profile clearfix">
                <form>
                  <CurentBanner bg={paramUpdate.banner}>
                    <label className="uploadFile">
                      <input
                        type="file"
                        className="inputfile form-control"
                        name="file"
                      />
                    </label>
                  </CurentBanner>
                </form>
                <ActiveSelecBanner
                  className="image"
                  active={paramUpdate.banner === optionsBanner1}
                  onClick={() => {
                    handleGetValues("banner", optionsBanner1);
                  }}
                >
                  <img src={optionsBanner1} alt="xrender" />
                </ActiveSelecBanner>
                <ActiveSelecBanner
                  className="image style2"
                  active={paramUpdate.banner === optionsBanner2}
                  onClick={() => {
                    handleGetValues("banner", optionsBanner2);
                  }}
                >
                  <img src={optionsBanner2} alt="xrender" />
                </ActiveSelecBanner>
              </div>

              <form className="form-profile">
                <div className="form-infor-profile">
                  <div className="info-account">
                    <h4 style={{ color: "#fff" }} className="title-create-item">
                      Account info
                    </h4>
                    <fieldset>
                      <h4
                        style={{ color: "#fff" }}
                        className="title-infor-account"
                      >
                        Display name
                      </h4>
                      <input
                        style={{ color: "#fff" }}
                        type="text"
                        placeholder="Trista Francis"
                        onChange={(e) => {
                          handleGetValues("username", e.target.value);
                        }}
                        value={paramUpdate.username}
                        required
                      />
                    </fieldset>
                    {/* <fieldset>
                      <h4 className="title-infor-account">Custom URL</h4>
                      <input
                        type="text"
                        placeholder="Axies.Trista Francis.com/"
                        required
                      />
                    </fieldset> */}
                    {/* <fieldset>
                      <h4 className="title-infor-account">Email</h4>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        required
                      />
                    </fieldset> */}
                    <fieldset>
                      <h4
                        style={{ color: "#fff" }}
                        className="title-infor-account"
                      >
                        Bio
                      </h4>
                      <textarea
                      style={{color: "#fff"}}
                        tabIndex="4"
                        rows="5"
                        required
                        onChange={(e) => {
                          handleGetValues("bio", e.target.value);
                        }}
                        value={paramUpdate.bio}
                      ></textarea>
                    </fieldset>
                  </div>
                  <InforSocical className="info-social">
                    <h4 style={{ color: "#fff" }} className="title-create-item">
                      Your Social media
                    </h4>
                    <fieldset>
                      <h4
                        style={{ color: "#fff" }}
                        className="title-infor-account"
                      >
                        Facebook
                      </h4>
                      <input
                        style={{ color: "#fff" }}
                        type="text"
                        placeholder="Facebook username"
                        onChange={(e) => {
                          handleGetValues("facebook", e.target.value);
                        }}
                        value={paramUpdate.facebook}
                      />
                      {/* <Link to="#" className="connect">
                        <i className="fab fa-facebook"></i>Connect to face book
                      </Link> */}
                    </fieldset>
                    <fieldset>
                      <h4
                        style={{ color: "#fff" }}
                        className="title-infor-account"
                      >
                        Twitter
                      </h4>
                      <input
                        style={{ color: "#fff" }}
                        type="text"
                        placeholder="Twitter username"
                        onChange={(e) => {
                          handleGetValues("twitter", e.target.value);
                        }}
                        value={paramUpdate.twitter}
                      />
                      {/* <Link to="#" className="connect">
                        <i className="fab fa-twitter"></i>Connect to Twitter
                      </Link> */}
                    </fieldset>
                    <fieldset>
                      <h4
                        style={{ color: "#fff" }}
                        className="title-infor-account"
                      >
                        Discord
                      </h4>
                      <input
                        style={{ color: "#fff" }}
                        type="text"
                        placeholder="Discord username"
                        onChange={(e) => {
                          handleGetValues("discord", e.target.value);
                        }}
                        value={paramUpdate.discord}
                      />
                      {/* <Link to="#" className="connect">
                        <i className="icon-fl-vt"></i>Connect to Discord
                      </Link> */}
                    </fieldset>
                  </InforSocical>
                </div>
                <button
                  className="tf-button-submit mg-t-15"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Update Profile
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </StyleWapper>
  );
};

export default EditProfile;

const StyleWapper = styled.div`
  padding-top: 0px;
`;
const CurentBanner = styled.div`
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
`;
const ActiveSelecBanner = styled.div`
  background: ${(props) =>
    props.active
      ? "linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%)"
      : "#8a8aa0"} !important;
  padding: ${(props) => (props.active ? "3px" : "1px")} !important;
`;
const InforSocical = styled.div`
  input {
    margin-bottom: 0px !important;
  }
`;

import styled from "styled-components";

export const CollectionContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 120px auto 50px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 0px 30px;
  .react-tabs__tab {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
  }
`;
export const CollectionPanner = styled.div`
  width: 100%;
  position: relative;
  border-radius: 18px;
  background: #d9d9d9;
  min-height: 120px;
  height: 27vw;
  max-height: 333px;
  ::after {
    content: "";
    background-image: url(${(props) => props.bg});
    background-size: cover;
    background-position: center;
    position: absolute;
    width: calc(100% + 30px);
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 18px;
  }
`;
export const CollectionDetailBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 28px;
  p {
    width: 100%;
    color: #9998a5;
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  @media only screen and (max-width: 744px) {
    flex-direction: column;
    align-items: center;
    gap: 12px;
    p {
      font-size: 12px;
    }
  }
`;
export const CollectionAvt = styled.div`
  align-items: stretch;
  width: 26%;
  max-width: 257px;
  min-width: 109px;
  align-self: stretch;
  position: relative;
  img {
    border-radius: 20px;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.09);
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  @media only screen and (max-width: 744px) {
    margin: 0px auto;
    min-height: 55px;
    padding-top: 13%;
    img {
      top: 0px;
      position: absolute;
      max-height: calc(200%);
      transform: translateY(-50%);
    }
  }
`;
export const CollectionDes = styled.div`
  display: flex;
  justify-content: space-around;
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  gap: 22px;
  @media only screen and (max-width: 991px) {
    gap: 0px;
  }
`;
export const CollectionDesTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    flex-direction: column;
    display: flex;
    width: fit-content;
    h1 {
      color: var(--colors-text-on-background, #0f172a);
      font-family: Urbanist;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.9px;
    }
    .Text-Collection {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-direction: row;
      display: flex;
      min-width: fit-content;
      margin-top: 10px;
      p {
        color: #9998a5;
        leading-trim: both;
        text-edge: cap;
        font-family: Urbanist;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
      }
      span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 3px solid #000;
        display: flex;
        min-width: 10px;
      }
      h6 {
        color: #7356e6;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.72px;
        display: inline-block;
        white-space: nowrap;
      }
    }
  }
  button {
    padding: 8px 16px;
    border-radius: 40px;
    border: 1px solid var(--base-black, #000);
    background: #fff !important ;
    max-height: 40px;
    color: var(--base-black, #000);

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      width: 22px;
    }
    p {
      color: var(--base-black, #000);

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }
  @media only screen and (max-width: 991px) {
    div {
      h1 {
        font-size: 20px;
      }
      .Text-Collection {
        p {
          color: #9998a5;
          leading-trim: both;
          text-edge: cap;
          font-family: Urbanist;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }
        span {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 3px solid #000;
          display: flex;
          min-width: 10px;
        }
        h6 {
          color: #7356e6;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: 0.72px;
          display: inline-block;
          white-space: nowrap;
        }
      }
    }
  }
  @media only screen and (max-width: 744px) {
    flex-direction: column;
    margin-bottom: 10px;
    div {
      h1 {
        text-align: center;
      }
    }
  }
`;
export const CollectionBoxNumGroup = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: #9998a5;
      text-align: center;
      leading-trim: both;
      text-edge: cap;

      font-size: 22px;
      font-style: normal;
      font-weight: 500;
    }
    h5 {
      color: var(--colors-text-on-background, #0f172a);
      text-align: center;
      leading-trim: both;
      text-edge: cap;

      font-size: 32px;
      font-style: normal;
      font-weight: 500;
    }
  }
  @media only screen and (max-width: 991px) {
    div {
      p {
        font-size: 12px;
        line-height: 100%;
      }
      h5 {
        font-size: 16px;
      }
    }
  }
`;
export const BoxActivityDefaults = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  border: 1px solid #9998a5;
  display: flex;
  gap: 12px;
  align-items: center;
  div {
    width: 42px;
    height: 42px;
    border-radius: 8px;
    background: #ebeaf3;
  }
  span {
    color: #8b898b;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-left: auto;
    display: inline-block;
    white-space: nowrap;
  }
  p {
    color: #4b4b4b;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    line-height: 100%;
    span {
      margin: 0px;
    }
  }
  @media only screen and (max-width: 640px) {
    div {
      :first-child {
        display: none;
      }
      flex-wrap: wrap;
    }
    p,
    span {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    span {
      position: absolute;
      top: 24px;
      right: 10px;
    }
    p {
      span {
        position: relative;
        top: 0px;
        right: 0px;
      }
    }
  }
`;
export const BoxContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

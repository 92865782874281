import React, { useState, useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";
import InputCommon from "../../../../../components/common/input";
import {
  ButtonCommon,
  TextAreaCommon,
  TitleKey,
} from "../../../../../components/common/styled";
import {
  HeaderBox,
  LiqV2Container,
  V2AddressList,
  V2Header,
  CCConfirm,
  ConfirmCol,
  RowHash,
} from "./styled";
import { ButtonAction } from "../../../../../components/common/form/selectNetwork/styled";
import { Upload } from "antd";
// Images
import upload from "../../../../../assets/images/common/form/upload.svg";
import download from "../../../../../assets/images/common/form/download.svg";
import styled from "styled-components";
// copmmon
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { useAccount, useNetwork } from "wagmi";
import { ADD_LP_V2, SCAN_URL, DEXVIEW } from "../../../../../constants/Tooldex/index";
import abiPancakeAddLPV2 from "../../../../../constants/Tooldex/abi/abiPancakeAddLPV2.json";
import tokenABI from "../../../../../constants/Tooldex/abi/abiToken.json";
import {
  convertFromWei,
  convertToWei,
} from "../../../../../utils/Tooldex/convertNumber";
import {
  notiError,
  notiSuccess,
  notiWarning,
} from "../../../../../utils/Tooldex/notication";
// end common
import * as XLSX from "xlsx";
import Value from "../../../../../components/Tooldex/Value";
import Loading from "../../../../../components/Tooldex/Loading";

declare const window: Window & typeof globalThis & { ethereum: any };

const LiqV2 = ({ setStep }: any) => {
  const decimalsA: any = localStorage.getItem("decimalsA");
  const decimalsB: any = localStorage.getItem("decimalsB");
  const symbolTokenA: any = localStorage.getItem("symbolTokenA");
  const symbolTokenB: any = localStorage.getItem("symbolTokenB");
  const contractTokenA: any = localStorage.getItem("contractTokenA");
  const contractTokenB: any = localStorage.getItem("contractTokenB");
  const depositedTokenA: any = localStorage.getItem("depositedTokenA");
  const depositedTokenB: any = localStorage.getItem("depositedTokenB");
  const { address } = useAccount();
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const web3 = new Web3(window.ethereum);
  const [amountA, setAmountA] = useState<any>("");
  const [amountB, setAmountB] = useState<any>("");
  const [listAddress, setListAddress] = useState("");
  const [loadingAddLP, setLoadingAddLP] = useState<any>(false);
  const [totalValueAddLP, setTotalValueAddLP] = useState<any>(0);
  const [isTransactionHash, setTransactionHash] = useState("");

  const list: any = listAddress
    .trim()
    .split("\n")
    .map((row) => {
      const [address, value] = row.split(",");
      return { address, value };
    });
  const addresses = list.map((item: any) =>
    web3.utils.isAddress(item?.address?.trim())
      ? item?.address?.trim()
      : ADD_LP_V2[chainId]
  );
  const values = list.map((item: any) => {
    const trimmedValue = item?.value?.trim();

    if (trimmedValue && !isNaN(Number(trimmedValue))) {
      const toWeiValue = convertToWei(
        trimmedValue.toString(),
        Number(decimalsB)
      );
      return toWeiValue.toString();
    } else {
      return "0";
    }
  });

  const verifyNumberInput = async () => {
    if (listAddress) {
      let totalAddLP;
      const valueB = values.reduce(
        (total: any, value: any) =>
          total + Number(convertFromWei(value.toString(), Number(decimalsB))),
        0
      );
      totalAddLP = Number(valueB) + Number(amountB);
      setTotalValueAddLP(totalAddLP);
    }
  };
  useEffect(() => {
    if (listAddress) {
      verifyNumberInput();
    }
  }, [listAddress, amountB]);

  const contract = new web3.eth.Contract(
    abiPancakeAddLPV2 as unknown as AbiItem,
    ADD_LP_V2[chainId]
  );
  const handleFileUpload = (file: any) => {
    try {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const filteredData = jsonData.filter(
          (row: any) =>
            web3.utils.isAddress(row[0]?.trim()) &&
            !isNaN(row[1]?.toString().trim())
        );

        const formattedData = filteredData.map((row: any, index: any) => {
          const [address, amountToken]: any = row;
          return `${address.trim()}, ${amountToken.toString().trim()}`;
        });

        setListAddress(formattedData.join("\n"));
      };

      reader.readAsArrayBuffer(file);
      notiSuccess("Upload File Successfully!", 5);
    } catch (error: any) {
      console.error(error);
      notiError("Upload File Failed", 5);
    }
  };

  const exportToExcel = () => {
    const data = [
      {
        Address: "0x075AA49136664628E588493671d323928FD8835A",
        AmountToken: "0.01",
      },
      {
        Address: "0xc2E311e9FA6B43002f02d5835D560f03c59604D7",
        AmountToken: "0.01",
      },
      {
        Address: "0xD9888a6A6dA9A05091adb798e382E8a499C03F71",
        AmountToken: "0.01",
      },
      {
        Address: "0x9dfeb78168826d95C75306832414705D19096979",
        AmountToken: "0.01",
      },
      {
        Address: "0xa901FB223f7d5deff626E7A3E78f77344df42b0E",
        AmountToken: "0.01",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");
    XLSX.writeFile(workbook, "Sample.xlsx");
  };

  const addLiquidity = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    try {
      setLoadingAddLP(true);
      if (totalValueAddLP <= Number(depositedTokenB)) {
        const valueToToWei: any = convertToWei(
          amountA.toString(),
          Number(decimalsA)
        );
        const valueFormFromWei: any = convertToWei(
          amountB.toString(),
          Number(decimalsB)
        );

        await contract.methods
          .addSwapLiquidity(
            contractTokenB,
            contractTokenA,
            valueFormFromWei,
            valueToToWei,
            addresses,
            values
          )
          .send({
            from: address,
            gasPrice: web3.utils.toHex(String(gasPrice)),
          })
          .then((res: any) => {
            if (res.status) {
              notiSuccess("Success", 5);
              setTransactionHash(res.transactionHash);
              setLoadingAddLP(false);
              window.localStorage.removeItem("depositedTokenA");
              window.localStorage.removeItem("depositedTokenB");
            }
          });
      } else {
        notiWarning(
          `Please make sure: Your ${symbolTokenB} deposited > Amount ${symbolTokenB} + Total amount ${symbolTokenB} from address list.`,
          10
        );
        setLoadingAddLP(false);
      }
    } catch (error: any) {
      notiError(error?.message, 5);
      console.error("error", error);
      setLoadingAddLP(false);
    }
  };

  return (
    <>
      <LiqV2Container>
        <CCConfirm>
          <ConfirmCol>
            <TitleKey>{symbolTokenA} Deposited</TitleKey>
            <p>
              <Value
                size={15}
                color="#F313CE"
                fontweight="700"
                value={Number(depositedTokenA)}
                unitafter={symbolTokenA}
              />
            </p>
          </ConfirmCol>
          <ConfirmCol>
            <TitleKey>{symbolTokenB} Deposited</TitleKey>
            <p>
              <Value
                size={14}
                color="#F313CE"
                fontweight="700"
                value={Number(depositedTokenB)}
                unitafter={symbolTokenB}
              />
            </p>
          </ConfirmCol>
          <ConfirmCol>
            <TitleKey>Liquidity Pair</TitleKey>
            <p>
              {symbolTokenA}/{symbolTokenB}
            </p>
          </ConfirmCol>
          <ConfirmCol>
            <TitleKey>DEX</TitleKey>
            <p>
              <img
                width={20}
                height={20}
                src="../../../images/pancake.png?v=5"
                alt="logo"
              />
              <span>PancakeSwap V2</span>
            </p>
          </ConfirmCol>
        </CCConfirm>
        <V2Header>
          <HeaderBox>
            <TitleKey>
              Amount <span>{symbolTokenA}</span> want to add LP
            </TitleKey>
            <InputCommon
              placeHolder="0.0"
              value={amountA}
              onChange={(e: any) => setAmountA(e.target.value)}
              suffix={symbolTokenA}
            />
          </HeaderBox>
          <HeaderBox>
            <TitleKey>
              Amount <span>{symbolTokenB}</span> want to add LP
            </TitleKey>
            <InputCommon
              placeHolder="0.0"
              value={amountB}
              onChange={(e: any) => setAmountB(e.target.value)}
              suffix={symbolTokenB}
            />
          </HeaderBox>
        </V2Header>
        <V2AddressList>
          <TitleKey>Address List</TitleKey>
          <TextAreaCommon>
            <TextArea
              value={listAddress}
              onChange={(e) => setListAddress(e.target.value)}
              placeholder="Enter one address and amount on each line.&#10;Supports only format.&#10;0x19c5a9d732431633CF357579f9CA05466F94d3f8, 3.141592&#10;0xEa0bED9473D811eFd39E8C080d9cF8413394eb58, 1.41421"
              style={{ height: 220, resize: "none" }}
            />
          </TextAreaCommon>
        </V2AddressList>
        {isTransactionHash && (
          <RowHash>
            <a
              href={`${[DEXVIEW[chainId]]}${contractTokenA.toLowerCase()}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src="../../../images/chart.png?v=5" alt="logo" /> View chart
            </a>
            <a
              href={`${[
                SCAN_URL[chainId],
              ]}tx/${isTransactionHash.toLowerCase()}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src="../../../images/bscscan.png?v=5" alt="logo" /> View on
              bscscan
            </a>
          </RowHash>
        )}

        <V2Buttons>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Upload
              accept=".xlsx, .xls"
              showUploadList={false}
              beforeUpload={handleFileUpload}
            >
              <ButtonCommon
                className="action-button"
                disabled={false}
                background={"#fff"}
                textColor={"#0D0C43"}
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={upload}
                  alt="upload"
                />
                Upload Excel
              </ButtonCommon>
            </Upload>
            <ButtonCommon
              className="action-button"
              disabled={false}
              background={"#fff"}
              textColor={"#0D0C43"}
              onClick={exportToExcel}
            >
              <img
                style={{ width: "20px", height: "20px" }}
                src={download}
                alt="download"
              />
              Sample file
            </ButtonCommon>
          </div>
          <ButtonCommon
            disabled={
              !address ||
              loadingAddLP ||
              !amountA ||
              !amountB ||
              !listAddress ||
              Number(depositedTokenA) <= 0 ||
              Number(depositedTokenB) <= 0
            }
            className="add-button"
            onClick={addLiquidity}
          >
            <Loading status={loadingAddLP} content="Add liquidity" />
          </ButtonCommon>
        </V2Buttons>
      </LiqV2Container>
      <ButtonAction>
        <ButtonCommon
          style={{
            border: "2px solid #F313CE",
            padding: "5px 30px",
            width: "auto",
            fontWeight: "600",
          }}
          background="transparent"
          textColor="#F313CE"
          onClick={() => setStep(0)}
        >
          Prev
        </ButtonCommon>
      </ButtonAction>
    </>
  );
};

export const V2Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .add-button {
    min-width: 344px;
    margin-left: auto;
  }
  .action-button {
    min-width: 140px;
    padding: 10px;
    border: 1px solid #0d0c43;
    gap: 10px;
  }
  @media screen and (max-width: 743px) {
    ${ButtonCommon} {
      min-width: auto;
      margin-left: 0;
      width: auto;
    }
  }
`;

export default LiqV2;

import React, { useMemo } from "react";
import styled from "styled-components";
import Web3 from "web3";
import { HTTPS_NETWORK, ChainIdToName } from "../../constants/Tooldex/index";
import { useNetwork } from "wagmi";
import Logo from "../Tooldex/Logo";
const getTokenLogoURL = (address: string, chainID: any) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${ChainIdToName[chainID]}/assets/${address}/logo.png`;
const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  color: #F313CE;
  margin: 0 3px 5px;
  border-radius: 50px;
`;

export default function CurrencyLogo({
  address,
  size = "20px",
  style,
}: {
  address: any;
  size?: string;
  style?: React.CSSProperties;
}) {
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const web3 = new Web3(HTTPS_NETWORK[chainId]);
  const srcs: string[] = useMemo(() => {
    const tokenAddress = web3.utils.toChecksumAddress(address);
    if (address) {
      return [getTokenLogoURL(tokenAddress, chainId)];
    }
    return [];
  }, [address,chainId,web3.utils]);

  return <StyledLogo size={size} srcs={srcs} alt="Token logo" style={style} />;
}

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ToastContainer } from "react-bootstrap";
import {
  approveContractNFT,
  listSellContractNFT,
  // address_MKP_LISTBUYSELL_OPBNB_TESTNET,
  upDatePriceNFTMarketPlace,
} from "../../integrateContract/contract";
import { ethers } from "ethers";
import LoadingComponent from "../Loading";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { RefreshContext } from "../../context/RefreshContext";
import { useWeb3React } from "@web3-react/core";
import {
  ADDR_MKP_ARB_MAINNET,
  ADDR_MKP_BNB_MAINNET,
  ADDR_MKP_OPBNB_MAINNET,
  ADDR_MKP_ZETA_TESTNET,
  ARB_MKP_ABI_MAINNET,
  BNB_MKP_ABI_MAINNET,
  OPBNB_MKP_ABI_MAINNET,
  OpBNB_NFT_LUFFY_ABI,
  ZETA_MKP_ABI_TESTNET,
} from "../../integrateContract/ABI";
import ToastTyfile from "./Toastify";

// import { RefreshContext } from "../context/RefreshContext";

const CardModalUpdatePrice = (props) => {
  const [inputValue, setInputValue] = useState();
  const [isTime, setTime] = useState("2592000");
  const [isLoading, setIsLoading] = useState(false);
  const [valueTimes, setValueTimes] = useState(0);
  const { getOpBnbBalance, handleGetOpBnbBalance } = useContext(RefreshContext);
  const { chainId } = useWeb3React();
  const handleInputChange = useCallback((e) => {
    e.preventDefault();
    const value = e.target.value;
    setInputValue(value);
  }, []);

  const updatelistSellNFT = async (
    orderID,
    price,
    addressContract,
    abiContract
  ) => {
    setIsLoading(true);
    try {
      const parsePrice = ethers.utils.parseEther(price.toString());
      // const parsePrice = price.toString();
      console.log("parsePrice", parsePrice);
      await upDatePriceNFTMarketPlace(
        orderID,
        parsePrice,
        addressContract,
        abiContract
      ).then((res) => {
        setIsLoading(false);
        props.showAction(false);
        handleGetOpBnbBalance();
        props.onReFetch();
        console.log("call ");
        toast(
          <ToastTyfile
            type="sucsess"
            description="Update Price Nft successfully"
          />,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        ).catch((err) => {
          if (err && err.code === 4001) {
            toast(
              <ToastTyfile
                type="error"
                description="You rejected transaction"
              />,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          } else {
            toast(
              <ToastTyfile
                type="error"
                description="An error occurred while processing your request. Please try again later."
              />,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }
          setIsLoading(false);
        });
        setIsLoading(false);
      });
    } catch (error) {
      console.log("fail Error", error);
    }
  };

  const handleUpdatePriceSelNFt = () => {
    if (chainId === 56) {
      updatelistSellNFT(
        props?.data?.orderId?.toString(),
        inputValue,
        ADDR_MKP_BNB_MAINNET,
        BNB_MKP_ABI_MAINNET
      );
    } else if (chainId === 204) {
      updatelistSellNFT(
        props?.data?.orderId?.toString(),
        inputValue,
        ADDR_MKP_OPBNB_MAINNET,
        OPBNB_MKP_ABI_MAINNET
      );
    } else if (chainId === 7001) {
      updatelistSellNFT(
        props?.data?.orderId.toString(),
        inputValue,
        ADDR_MKP_ZETA_TESTNET,
        ZETA_MKP_ABI_TESTNET
      );
    } else if (chainId === 42161) {
      updatelistSellNFT(
        props?.data?.orderId.toString(),
        inputValue,
        ADDR_MKP_ARB_MAINNET,
        ARB_MKP_ABI_MAINNET
      );
    }
  };

  return (
    <>
      <ToastContainer />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            zIndex: "33333333333333333333333333333333",
            top: "0px",
            left: "0px",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        ></div>
      )}
      <Modal
        show={props.show}
        onHide={props.onHide}
        data={props.data?.token_id?.toString()}
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="modal-body space-y-20 pd-40">
          <h3>Update Price Items</h3>
          <p style={{marginTop: '5px'}} className="text-center">
            XRender Luffy OnePiece{" "}
            <span className="price color-popup">
              #{props.data?.token_id?.toString()}
            </span>
          </p>
          <form>
            <p style={{marginBottom: '5px'}}>Setting Price</p>
            <input
              type="text"
              className="form-control "
              placeholder="0.00 BNB"
              value={inputValue}
              onChange={handleInputChange}
            />
          </form>
          <div className="hr"></div>
          <div className="d-flex justify-content-between">
            <p style={{marginBottom: '0'}}> Listing Price:</p>
            <p style={{marginBottom: '0'}} className="text-right price color-popup">{inputValue} BNB </p>
          </div>
          <div className="d-flex justify-content-between">
            <p style={{marginBottom: '0'}}> Creator Earnings:</p>
            <p style={{marginBottom: '0'}} className="text-right price color-popup"> 5% </p>
          </div>
          <div className="d-flex justify-content-between">
            <p style={{marginBottom: '0'}}> Service Fee:</p>
            <p style={{marginBottom: '0'}} className="text-right price color-popup"> 2% </p>
          </div>
          <div className="d-flex justify-content-between">
            <p style={{marginBottom: '0'}}> Total Receive Amount:</p>
            <p style={{marginBottom: '0'}} className="text-right price color-popup">
              {" "}
              {inputValue - inputValue * 0.007 || 0}{" "}
            </p>
          </div>
          <button
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              handleUpdatePriceSelNFt();
            }}
          >
            {isLoading ? <LoadingComponent /> : "Update Price Sell"}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CardModalUpdatePrice;

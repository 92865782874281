import {
  MarketHint,
  MarketplaceContainer,
  MarketplaceList,
  MarketplaceWrapper,
} from "./styled";
import swipe_cursor from "../../../assets/images/homenew/market/cursor_swipe.png";

import { useContext, useEffect, useState } from "react";
import { ModalConfirmContext } from "../../../components/ProviderPopUp/confirm";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";

// Import Swiper styles
import "swiper/css/effect-cards";

// import required modules
import MarketItem from "./Item";
import { ButtonContract } from "../Bonus/styled";
import { CommonTitle } from "../Features/styled";
import { Link } from "react-router-dom";
import { FilterBox } from "../../../components/layouts/home-5/TodayPicks";
import { LIST_NETWORK } from "../../../constants/connectors";
import axiosInstance from "../../../utils/axios";

const Marketplace = () => {
  const [listNftFull, setListNftFull] = useState([]);
  const [clickedChain, setClickedChain] = useState<any>(42161);
  const { isMobile } = useContext(ModalConfirmContext)!;
  const getListNFT = async (chainId: any) => {
    setClickedChain(chainId);
    try {
      const chainActive = LIST_NETWORK.find(
        (item) => Number(item.chainId) === Number(chainId)
      );
      if (!chainActive) return;
      const res = await axiosInstance.post(`/item/market?chainId=${chainId}`);
      const { data } = res;
      setListNftFull(data.itemList?.splice(0, 8));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListNFT(clickedChain);
  }, []);

  return (
    <MarketplaceContainer>
      <MarketplaceWrapper>
        <CommonTitle>
          <span>Marketplace</span>
        </CommonTitle>
        <FilterBox>
          <div className="widget widget-filter style-2 mgbt-0">
            <ul className="box-check justify-content-end">
              {LIST_NETWORK.map((item, index) => (
                <li
                  onClick={() => getListNFT(item.chainId)}
                  key={index}
                  style={{
                    marginBottom: 0,
                    display: "flex",
                  }}
                >
                  <div
                    className={
                      clickedChain === item.chainId
                        ? "box-widget-filter active-chain"
                        : "box-widget-filter"
                    }
                  >
                    <img
                      style={{
                        width: "35px",
                      }}
                      src={item.image}
                      alt={item.name}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </FilterBox>
        {isMobile ? (
          <>
          {listNftFull.length > 0 && <Swiper
              effect={"cards"}
              lazy={true}
              longSwipes={false}
              modules={[EffectCards]}
              className="marketPlace"
            >
              {listNftFull && listNftFull.map((item: any, index: any) => {
                return (
                  <SwiperSlide>
                    <MarketItem key={index} item={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>}
            <MarketHint>
              Swipe to slide
              <img src={swipe_cursor} alt="swipe_cursor" />
            </MarketHint>
          </>
        ) : (
          <>
            <MarketplaceList>
              {listNftFull.map((item: any, index: any) => {
                return <MarketItem key={index} item={item} />;
              })}
            </MarketplaceList>
          </>
        )}
        <ButtonContract
          style={{ margin: "50px auto 0", justifyContent: "center" }}
        >
          <Link to={`marketplace?network=${clickedChain}`}>Explore More</Link>
        </ButtonContract>
      </MarketplaceWrapper>
    </MarketplaceContainer>
  );
};

export default Marketplace;

import { PCWContainer } from './styled'
import { TextCustom } from '../styled'

// Images
import pcw from '../../../assets/images/common/pcw/icon-pcw.svg'

const PleaseConnectWallet = ({text}: any) => {
  return (
    <PCWContainer>
      <img src={pcw} alt="pcw" />
      <TextCustom fontSize='14px' fontWeight='500' color='#717696'>
        {text || 'Please connect wallet'}
      </TextCustom>
    </PCWContainer>
  )
}

export default PleaseConnectWallet
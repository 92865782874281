import { useState, useEffect } from "react";
import Web3 from "web3";
import { Skeleton } from "antd";
import {
  ButtonAction,
  NetworkHeader,
} from "../../../../components/common/form/selectNetwork/styled";
import InputCommon from "../../../../components/common/input";
import {
  ButtonCommon,
  TextCommon,
  TextCustom,
  TitleKey,
} from "../../../../components/common/styled";
import {
  DataFetch,
  FetchRow,
  IconInner,
  PABody,
  PAForm,
  PairAddressContainer,
} from "./styled";
import { useAccount, useNetwork } from "wagmi";
import PancakePairABI from "../../../../constants/Tooldex/abi/abiPancakePair.json";
import { getLiquidityInformation } from "../../../../hooks/Tooldex/getLiquidityListByAccount";
import { notiError } from "../../../../utils/Tooldex/notication";
import CurrencyLogo from "../../../../components/CurrencyLogo";
import Value from "../../../../components/Tooldex/Value";

declare const window: Window & typeof globalThis & { ethereum: any };

const PairAddress = ({ setStep }: any) => {
  const web3 = new Web3(window.ethereum);
  const lockDex = localStorage.getItem("lockDex");
  const lockPair = localStorage.getItem("lockPair");
  const lockBalance = localStorage.getItem("lockBalance");
  const lockDecimals = localStorage.getItem("lockDecimals");
  const lockAddress = localStorage.getItem("lockAddress");

  const { address } = useAccount();
  const { chain }: any = useNetwork();
  const [value, setValue] = useState<string>("");
  const [inforLP, setInforLP] = useState<any>();
  const [pendingFind, setPendingFind] = useState(false);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  const findLP = async () => {
    try {
      setPendingFind(true);
      if (web3.utils.isAddress(value)) {
        await getLiquidityInformation(PancakePairABI, value, address, chain?.id)
          .then((res: any) => {
            setInforLP(res);
            window.localStorage.setItem("lockDex", res?.dex);
            window.localStorage.setItem("lockPair", res?.pair);
            window.localStorage.setItem("lockBalance", res?.balance);
            window.localStorage.setItem("lockDecimals", res?.decimals);
            window.localStorage.setItem("lockAddress", res?.lpAddress);
            window.localStorage.setItem("lockToken0", res?.token0);
            window.localStorage.setItem("lockToken1", res?.token1);
            setPendingFind(false);
          })
          .catch((err: any) => {
            console.error("Err find LP", err);
            notiError("LP token not found", 5);
            setPendingFind(false);
          });
      } else {
        setPendingFind(false);
        setInforLP(undefined);
      }
    } catch (error) {
      console.error("Error find LP", error);
      notiError("LP token not found", 5);
      setInforLP(undefined);
      setPendingFind(false);
    }
  };

  useEffect(() => {
    if (value) {
      findLP();
    }
  }, [value]);

  return (
    <PairAddressContainer>
      <PABody>
        <NetworkHeader>
          <TextCustom color="#0D0C43" fontSize="24px" fontWeight="bold">
            Enter the pair address
          </TextCustom>
          <TextCommon>
            Enter the pair address you would like to lock
            <br className="sm" /> liquidity for
          </TextCommon>
        </NetworkHeader>
        <PAForm style={{ marginBottom: value !== "" ? "13px" : "32px" }}>
          <TitleKey>Pair address</TitleKey>
          <InputCommon
            placeHolder="Pair address"
            onChange={(e: any) => handleChange(e)}
          />
        </PAForm>
        {pendingFind && <Skeleton active paragraph={{ rows: 3 }} />}
        {inforLP && (
          <>
            <DataFetch>
              <FetchRow>
                <TitleKey>Dex:</TitleKey>
                <TextCustom fontSize="14px" fontWeight="600" color="#0D0C43">
                  {inforLP?.dex}
                </TextCustom>
              </FetchRow>
              <FetchRow>
                <TitleKey>Pair:</TitleKey>
                <IconInner>
                  <div>
                    <CurrencyLogo
                      style={{ margin: "0 -8px 5px" }}
                      address={inforLP?.token0}
                    />
                    <CurrencyLogo address={inforLP?.token1} />
                  </div>

                  <TextCustom fontSize="14px" fontWeight="600" color="#0D0C43">
                    {inforLP?.pair}
                  </TextCustom>
                </IconInner>
              </FetchRow>
              <FetchRow>
                <TitleKey>Balance:</TitleKey>
                <TextCustom fontSize="14px" fontWeight="600" color="#0D0C43">
                  <Value value={Number(inforLP?.balance)} size={14} />
                </TextCustom>
              </FetchRow>
              <FetchRow>
                <TitleKey>Decimals:</TitleKey>
                <TextCustom fontSize="14px" fontWeight="600" color="#0D0C43">
                  <Value
                    value={Number(inforLP?.decimals)}
                    decimals={0}
                    size={14}
                  />
                </TextCustom>
              </FetchRow>
            </DataFetch>
          </>
        )}
        <ButtonCommon
          onClick={() => setStep(3)}
          style={{ margin: "0 auto" }}
          disabled={!address || !value || !inforLP}
        >
          Comfirm
        </ButtonCommon>
      </PABody>
      <ButtonAction>
        <ButtonCommon
          style={{
            border: "2px solid #F313CE",
            padding: "5px 20px",
            width: "auto",
            fontWeight: "600",
          }}
          background="transparent"
          textColor="#F313CE"
          onClick={() => setStep(1)}
        >
          Prev
        </ButtonCommon>
        <ButtonCommon
          style={{
            border:
              address &&
              value &&
              inforLP &&
              lockDex &&
              lockPair &&
              lockBalance &&
              lockDecimals &&
              lockAddress &&
              "2px solid #F313CE",
            padding: "5px 20px",
            width: "auto",
            fontWeight: "600",
          }}
          background="transparent"
          textColor="#F313CE"
          onClick={() => setStep(3)}
          disabled={
            !address ||
            !value ||
            !inforLP ||
            !lockDex ||
            !lockPair ||
            !lockBalance ||
            !lockDecimals ||
            !lockAddress
          }
        >
          Next
        </ButtonCommon>
      </ButtonAction>
    </PairAddressContainer>
  );
};

export default PairAddress;

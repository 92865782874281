import {
  EcoSystemContainer,
  EcoSystemDescribe,
  EcoSystemList,
  EcoSystemNumber,
  EcoSystemTitle,
  EcoSystemWrapper,
} from "./styled";
import { CommonTitle } from "../Features/styled";
import { UseCaseWrapper } from "../UseCase/styled";

const EcoSystem = () => {
  return (
    <EcoSystemContainer>
      <UseCaseWrapper>
        <CommonTitle>
          <span>Ecosystem</span>
        </CommonTitle>
        <EcoSystemList>
          {EcoSystemData.map((item, index) => {
            return (
              <li key={index}>
                <EcoSystemNumber>0{item.id}</EcoSystemNumber>
                <EcoSystemTitle>{item.title}</EcoSystemTitle>
                <EcoSystemDescribe>{item.describe}</EcoSystemDescribe>
              </li>
            );
          })}
        </EcoSystemList>
      </UseCaseWrapper>
    </EcoSystemContainer>
  );
};

const EcoSystemData = [
  {
    id: 1,
    title: "XRender AI Tool",
    describe:
      "Experience the power of artificial intelligence with our AI Tool, a multitasking partner and intelligent companion",
  },
  {
    id: 2,
    title: "Ai Generator",
    describe:
      "Unleash limitless creativity with AI Image Creation, delivering vibrant and unique visuals",
  },
  {
    id: 3,
    title: "Marketplace",
    describe:
      "Explore the digital art world with our NFT Marketplace, where creativity and secure transactions go hand in hand",
  },
  {
    id: 4,
    title: "XRender Tooldex",
    describe:
      "Manage everything effortlessly with our decentralized tools, ensuring control and security",
  },
];

export default EcoSystem;

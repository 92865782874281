import {
  useState,
  Fragment,
  useContext,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import {
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Nodata } from "./TodayPicksStyle";
import { shortenAddress } from "../../../utils/formartAddress";
import Countdown from "react-countdown";
import NodataImg from "../../../assets/images/logo/No-nft.svg";
import styled from "styled-components";
import { RefreshContext } from "../../../context/RefreshContext";
import avat1 from "../../../assets/images/avatar/avt-11.jpg";
import "./style.css";
import { LIST_NETWORK } from "../../../constants/connectors";
import { useActiveWeb3React } from "../../../hooks";
import { useWeb3React } from "@web3-react/core";
import { SelectNetWork } from "../../header/HeaderStyle2";
import { Dropdown } from "react-bootstrap";
import axiosInstance from "../../../utils/axios";
import { formartImage } from "../../../utils/formartImage";
import { BoxActivity } from "../detailColection";
import TableActivities from "../../Marketplace/TableActivities";

import { EVENT_NAME, defineName } from "../../../utils/defineName";
import { formatPriceNFT } from "../../../utils/formatPriceNFT";

const TABLE_HEADER = ["Type", "Item", "Price", "From", "To", "Date"];

const TodayPicks = (props) => {
  const data = props.data;
  const isMarketPlace = props.isMarketPlace;
  const [visible, setVisible] = useState(12);

  const showMoreItems = () => {
    const { onLoadMore } = props;
    onLoadMore();
  };

  const [modalShow, setModalShow] = useState(false);

  const { account } = useActiveWeb3React();
  const { chainId } = useWeb3React();
  const {
    collection,
    networkLocal,
    handleChangeNetworkLocal,
    handleChangeCollection,
  } = useContext(RefreshContext);
  const navigate = useNavigate();
  const { search } = useLocation();

  const [dataParse, setDataParse] = useState(null);
  const [tabActive, setTabActive] = useState({
    name: "All",
  });
  const [listCollection, setListCollection] = useState([]);
  const [chainActive, setChainActive] = useState(1);
  const [historyOfMarketPlace, setHistoryOfMarketPlace] = useState([]);
  const [optionActive, setOptionActive] = useState("items");
  const [optionSelected, setOptionSelected] = useState([]);
  const [curentPage, setCurentPage] = useState(1);
  const [totalpage, setTotalPage] = useState(0);
  const [isGettingHistory, setIsGettingHistory] = useState(false);
  const [isCalled, setIsCalled] = useState(false);

  const handleFilter = (item) => {
    setTabActive(item);
    if (optionActive === "items") {
      handleChangeCollection(item);
    } else {
      setHistoryOfMarketPlace([]);
      getHistoryByChainId(1, item);
    }
  };

  const handleSelectChain = (network) => {
    handleChangeNetworkLocal(network);
    setChainActive(network);
    if (isMarketPlace) {
      navigate(`/marketplace?network=${network.chainId}`);
    }
  };

  const handleParseMetaData = (metaData) => {
    if (metaData) {
      const newData = metaData.map((item) => {
        return {
          ...item,
          metadata: item?.metadata ? JSON.parse(item?.metadata) : null,
        };
      });
      setDataParse(newData);
    }
  };

  const getAllCollections = async (chainId) => {
    try {
      const params = search.split("?network=");
      if (params && params[1]) {
        chainId = params[1];
      }
      const { data } = await axiosInstance.get(
        `/collection/all-collection?chainId=${chainId}`
      );
      const { collections } = data;

      setListCollection(
        [
          {
            name: "All",
          },
        ].concat(collections)
      );
      setTabActive({
        name: "All",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getHistoryOfMarketPlace = async (chainId, page = 1, collection) => {
    try {
      setIsGettingHistory(true);
      let params = {
        page: page || 1,
        limit: 10,
      };
      if (collection && collection.key) {
        params.filterBy = {
          transactionType: collection.key,
        };
      }
      const { data } = await axiosInstance.post(
        `/item/history-item?chainId=${chainId}`,
        params
      );
      const oldList = page === 1 ? [] : historyOfMarketPlace;
      const list = oldList?.concat(data?.itemList).map((item) => {
        return {
          ...item,
          collections: listCollection.find(
            (col) => col.tokenAddress === item.tokenAddress
          ),
        };
      });
      setHistoryOfMarketPlace(list);
      setTotalPage(data?.totalPage);
      setIsGettingHistory(false);
    } catch (error) {
      console.log(error);
      setIsGettingHistory(false);
    }
  };

  const handleLoadMore = async () => {
    getHistoryByChainId(curentPage + 1, tabActive);
    setCurentPage(curentPage + 1);
  };

  const getHistoryByChainId = (page = 1, collection) => {
    if (listCollection.length === 0) return;
    const params = search.split("?network=");
    if (params && params[1]) {
      getHistoryOfMarketPlace(params[1], page, collection);
    }
  };

  const handleSelectTab = (type) => {
    setOptionActive(type);
  };

  useEffect(() => {
    if (!isMarketPlace) return;
    getHistoryByChainId(1);
    setIsCalled(true);
  }, [listCollection]);

  useEffect(() => {
    let defaultNetwork = null;
    const params = search.split("?network=");
    if (params && params[1] && isMarketPlace) {
      defaultNetwork = LIST_NETWORK.find(
        (item) => Number(item.chainId) === Number(params[1])
      );
    } else if (chainId && !isMarketPlace) {
      defaultNetwork = LIST_NETWORK.find(
        (item) => Number(item.chainId) === Number(chainId)
      );
    }
    if (defaultNetwork) {
      setChainActive(defaultNetwork);
    } else if (chainId) {
      handleSelectChain(LIST_NETWORK[0]);
    } else if (!account) {
      handleSelectChain(LIST_NETWORK[0]);
      setChainActive(networkLocal);
    }
  }, [account, chainId, networkLocal]);

  useEffect(() => {
    const { total } = props;
    setVisible(total);
  }, [props.total]);

  useEffect(() => {
    if (!isMarketPlace) return;
    getAllCollections(networkLocal.chainId);
    handleChangeCollection(null);
    setHistoryOfMarketPlace([]);
    setCurentPage(1);
    setListCollection([]);
  }, [networkLocal.chainId]);

  useEffect(() => {
    handleParseMetaData(data);
  }, [data]);

  useEffect(() => {
    setOptionSelected(
      optionActive === "items"
        ? listCollection
        : [{ name: "All" }].concat(EVENT_NAME)
    );
    setTabActive({
      name: "All",
    });
  }, [optionActive, listCollection]);

  useEffect(() => {
    if (tabActive.name === "All" && optionActive === "items") {
      handleChangeCollection(null);
    }
  }, [tabActive]);

  useEffect(() => {
    if (tabActive.name === "All") {
      getHistoryByChainId(1);
    }
  }, [collection]);

  return (
    <Fragment>
      <section style={{
        position: "relative",
        zIndex: '5'
      }} className="today-pick pt-0">
        <div className="themesflat-container">
          {isMarketPlace && (
            <div className="row">
              <div className="col-md-12 mt-5 mb-3">
                {historyOfMarketPlace && historyOfMarketPlace[0] && (
                  <BoxActivity
                    NFTname={historyOfMarketPlace[0]?.name}
                    OwnerName={shortenAddress(historyOfMarketPlace[0]?.to)}
                    Chain={
                      historyOfMarketPlace[0]?.from
                        ? shortenAddress(historyOfMarketPlace[0]?.from)
                        : ""
                    }
                    time={historyOfMarketPlace[0]?.createdAt}
                    icon={
                      defineName(historyOfMarketPlace[0]?.transactionType).image
                    }
                    avatar={historyOfMarketPlace[0]?.image}
                    type={
                      defineName(historyOfMarketPlace[0]?.transactionType).name
                    }
                  />
                )}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12 mt-3 p-0">
              {!isMarketPlace ? (
                <div className="heading-live-auctions px-4 mt-4">
                  <h2 className="tf-title">Marketplace</h2>
                  <Link to={`/marketplace`} className="exp style2">
                    EXPLORE MORE
                  </Link>
                </div>
              ) : (
                <>
                  <FilterBox>
                    <div className="sc-explore-2 mb-2">
                      <div className="title-market">
                        <h2
                        style={{color: "#fff"}}
                        className="">Marketplace</h2>
                        <p style={{color: "#fff"}}>{visible} items listed</p>
                      </div>
                    </div>
                    <div className="widget widget-filter style-2 mgbt-0">
                      <ul className="box-check justify-content-end">
                        {LIST_NETWORK.map((item, index) => (
                          <li
                            onClick={() => handleSelectChain(item)}
                            key={index}
                            style={{
                              marginBottom: 0,
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <div
                              className={
                                chainActive.chainId === item.chainId
                                  ? "box-widget-filter active-chain"
                                  : "box-widget-filter"
                              }
                              style={{
                                padding:
                                  chainActive.chainId !== item.chainId &&
                                  "0px 0px",
                              }}
                            >
                              <img
                                style={{
                                  width: "24px",
                                  marginRight:
                                    chainActive.chainId === item.chainId &&
                                    "10px",
                                }}
                                src={item.image}
                                alt={item.name}
                              />
                              <span>
                                {chainActive.chainId === item.chainId &&
                                  item.name}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </FilterBox>
                  <div
                    style={{
                      position: "relative",
                    }}
                    className="flat-tabs themesflat-tabs "
                  >
                    <FilterBox>
                      <div className="flat-tabs explore-tab">
                        <div id="artworks" className="dropdown">
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                              <SelectNetWork
                                className="sc-btn-top mg-r-12"
                                id="site-header"
                              >
                                <div className="d-flex sc-button fl-button pri-1  style-1 wallet select-network">
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {tabActive.name}
                                  </span>
                                </div>
                              </SelectNetWork>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ margin: 0 }}>
                              {optionSelected.map((item, index) => {
                                return (
                                  <Dropdown.Item key={index}>
                                    <SelectNetWork
                                      className="sc-btn-top mg-r-12"
                                      id="site-header"
                                      onClick={() => {
                                        handleFilter(item);
                                      }}
                                    >
                                      <div className="d-flex justify-content-start sc-button fl-button pri-1  style-1 wallet select-network">
                                        <span
                                          style={{
                                            fontSize: "15px",
                                          }}
                                        >
                                          {item.name}
                                        </span>
                                      </div>
                                    </SelectNetWork>
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </FilterBox>

                    <Tabs>
                      <TabList
                        style={{
                          padding: "20px",
                        }}
                      >
                        <Tab
                          onClick={() => {
                            handleSelectTab("items");
                          }}
                        >
                          Items
                        </Tab>
                        <Tab
                          onClick={() => {
                            handleSelectTab("activities");
                          }}
                        >
                          Activities
                        </Tab>
                      </TabList>

                      <TabPanel>
                        <div className="themesflat-container">
                          <div className="row">
                            <div className="col-md-12 p-0 d-flex flex-wrap mt-4">
                              {isMarketPlace &&
                                chainActive.chainId === 7001 && (
                                  <BoxNotice className="d-flex align-items-center mb-4">
                                    <div
                                      className="img-notice"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    >
                                      <img
                                        src="/assets/icon/icon-sound.png"
                                        alt=""
                                      />
                                    </div>
                                    <Notice>
                                      ZetaChain Marketplace is launching on the
                                      testnet. Users can claim $ZETA testnet{" "}
                                      <a
                                        style={{
                                          color: "#ed13d2",
                                        }}
                                        href="https://labs.zetachain.com/get-zeta"
                                      >
                                        Here
                                      </a>
                                      .
                                    </Notice>
                                  </BoxNotice>
                                )}
                              <>
                                {dataParse && dataParse.length ? (
                                  <>
                                    {dataParse.map((item, index) => {
                                      return (
                                        <Link
                                          to={`/details?id=${item?.tokenId}&tokenAddress=${item?.tokenAddress}&chainId=${item?.chainId}`}
                                          key={index}
                                          className="fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6"
                                        >
                                          <div
                                            className={`sc-card-product ${
                                              item.feature ? "comingsoon" : ""
                                            } `}
                                          >
                                            <div className="card-media">
                                              <img
                                                src={formartImage(
                                                  item?.metadata?.image
                                                )}
                                                alt="NFT Image"
                                                width="200px"
                                                height="200px"
                                              />
                                              <a
                                                className="wishlist-button heart"
                                                style={{ color: "#fff" }}
                                              >
                                                <span className="number-like">
                                                  {item?.like}
                                                </span>
                                              </a>
                                              <div
                                                className="featured-countdown"
                                                style={{
                                                  width: "170px",
                                                }}
                                              >
                                                <span className="slogan"></span>
                                                <Countdown
                                                  date={
                                                    Number(item?.endTime) * 1000
                                                  }
                                                >
                                                  <span>End Of Sale!</span>
                                                </Countdown>
                                              </div>
                                            </div>
                                            <div className="card-title">
                                              <h5 className="style2">
                                                {item?.metadata?.name +
                                                  " #" +
                                                  item?.tokenId}
                                              </h5>
                                              <div className="">
                                                <div
                                                  style={{
                                                    width: "24px",
                                                  }}
                                                >
                                                  <img
                                                    src={chainActive?.image}
                                                    alt="image"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="meta-info">
                                              <div className="author">
                                                <div className="avatar">
                                                  <img
                                                    src={avat1}
                                                    alt="axies"
                                                  />
                                                </div>
                                                <div className="info">
                                                  <span>Seller</span>
                                                  <h6>
                                                    {item?.seller && (
                                                      <span>
                                                        {shortenAddress(
                                                          item?.seller
                                                        )}
                                                      </span>
                                                    )}
                                                  </h6>
                                                </div>
                                              </div>
                                              <div className="price">
                                                <span>Price List</span>
                                                <h6>
                                                  {" "}
                                                  {formatPriceNFT(
                                                    item?.price
                                                  )}{" "}
                                                  {
                                                    chainActive.params
                                                      .nativeCurrency.symbol
                                                  }
                                                </h6>
                                              </div>
                                            </div>
                                            <div className="card-bottom">
                                              <Link
                                                to={`/details?id=${item?.tokenId}&tokenAddress=${item?.tokenAddress}&chainId=${item?.chainId}`}
                                              >
                                                <button
                                                  onClick={() =>
                                                    setModalShow(true)
                                                  }
                                                  className="sc-button style bag fl-button pri-3 no-bg"
                                                >
                                                  <span>
                                                    {item?.owner
                                                      ? "Buy NFT"
                                                      : "View NFT"}
                                                  </span>
                                                </button>
                                              </Link>
                                              <Link
                                                to={`/history?id=${item?.tokenId}&tokenAddress=${item?.tokenAddress}&chainId=${item?.chainId}`}
                                                className="view-history reload"
                                              >
                                                View History
                                              </Link>
                                            </div>
                                          </div>
                                        </Link>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <Nodata>
                                    <img src={NodataImg} alt="" />{" "}
                                    <Link to="/mint-nft">
                                      <button>Go To Mint-Nft</button>
                                    </Link>
                                  </Nodata>
                                )}
                              </>
                              {visible > data.length && (
                                <div className="col-md-12 wrap-inner load-more text-center">
                                  <button
                                    id="load-more"
                                    className="sc-button loadmore fl-button pri-3"
                                    onClick={showMoreItems}
                                  >
                                    <span>Load More</span>
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <TableActivities
                          dataTable={historyOfMarketPlace}
                          headers={TABLE_HEADER}
                          hanleScrollToLoadMore={handleLoadMore}
                          isLoading={isGettingHistory}
                          isMax={curentPage <= totalpage}
                        />
                      </TabPanel>
                    </Tabs>
                  </div>
                </>
              )}
              {!isMarketPlace && (
                <>
                  <div className="themesflat-container">
                    <div className="row">
                      <div className="col-md-12 p-0 d-flex flex-wrap mt-4">
                        {isMarketPlace && chainActive.chainId === 7001 && (
                          <BoxNotice className="d-flex align-items-center mb-4">
                            <div
                              className="img-notice"
                              style={{
                                marginRight: "10px",
                              }}
                            >
                              <img src="/assets/icon/icon-sound.png" alt="" />
                            </div>
                            <Notice>
                              ZetaChain Marketplace is launching on the testnet.
                              Users can claim $ZETA testnet{" "}
                              <a
                                style={{
                                  color: "#ed13d2",
                                }}
                                href="https://labs.zetachain.com/get-zeta"
                              >
                                Here
                              </a>
                              .
                            </Notice>
                          </BoxNotice>
                        )}
                        <>
                          {dataParse && dataParse.length ? (
                            <>
                              {dataParse.map((item, index) => {
                                return (
                                  <Link
                                    to={`/details?id=${item?.tokenId}&tokenAddress=${item?.tokenAddress}&chainId=${item?.chainId}`}
                                    key={index}
                                    className="fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6"
                                  >
                                    <div
                                      className={`sc-card-product ${
                                        item.feature ? "comingsoon" : ""
                                      } `}
                                    >
                                      <div className="card-media">
                                        <img
                                          src={formartImage(
                                            item?.metadata?.image
                                          )}
                                          alt="NFT Image"
                                          width="200px"
                                          height="200px"
                                        />
                                        <a
                                          className="wishlist-button heart"
                                          style={{ color: "#fff" }}
                                        >
                                          <span className="number-like">
                                            {item?.like}
                                          </span>
                                        </a>
                                        <div
                                          className="featured-countdown"
                                          style={{
                                            width: "170px",
                                          }}
                                        >
                                          <span className="slogan"></span>
                                          <Countdown
                                            date={Number(item?.endTime) * 1000}
                                          >
                                            <span>End Of Sale!</span>
                                          </Countdown>
                                        </div>
                                      </div>
                                      <div className="card-title">
                                        <h5 className="style2">
                                          {item?.metadata?.name +
                                            " #" +
                                            item?.tokenId}
                                        </h5>
                                        <div className="">
                                          <div
                                            style={{
                                              width: "24px",
                                            }}
                                          >
                                            <img
                                              src={chainActive?.image}
                                              alt="image"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="meta-info">
                                        <div className="author">
                                          <div className="avatar">
                                            <img src={avat1} alt="axies" />
                                          </div>
                                          <div className="info">
                                            <span>Seller</span>
                                            <h6>
                                              {item?.seller && (
                                                <span>
                                                  {shortenAddress(item?.seller)}
                                                </span>
                                              )}
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="price">
                                          <span>Price List</span>
                                          <h6>
                                            {" "}
                                            {formatPriceNFT(item?.price)}{" "}
                                            {
                                              chainActive.params.nativeCurrency
                                                .symbol
                                            }
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="card-bottom">
                                        <Link
                                          to={`/details?id=${item?.tokenId}&tokenAddress=${item?.tokenAddress}&chainId=${item?.chainId}`}
                                        >
                                          <button
                                            onClick={() => setModalShow(true)}
                                            className="sc-button style bag fl-button pri-3 no-bg"
                                          >
                                            <span>
                                              {item?.owner
                                                ? "Buy NFT"
                                                : "View NFT"}
                                            </span>
                                          </button>
                                        </Link>
                                        <Link
                                          to={`/history?id=${item?.tokenId}&tokenAddress=${item?.tokenAddress}&chainId=${item?.chainId}`}
                                          className="view-history reload"
                                        >
                                          View History
                                        </Link>
                                      </div>
                                    </div>
                                  </Link>
                                );
                              })}
                            </>
                          ) : (
                            <Nodata>
                              <img src={NodataImg} alt="" />{" "}
                              <Link to="/mint-nft">
                                <button>Go To Mint-Nft</button>
                              </Link>
                            </Nodata>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

TodayPicks.propTypes = {
  data: PropTypes.array.isRequired,
};

export const FilterBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .flat-tabs .btn-primary,
  .flat-tabs .btn-primary:hover,
  .flat-tabs .btn-primary:focus,
  .flat-tabs .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border: unset;
    background-image: unset;
    outline: none;
    box-shadow: none;
  }
  .flat-tabs .dropdown {
    position: absolute;
    right: 0;
  }

  .box-check {
    margin-bottom: 20px;
    li {
      div {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px;
        border-radius: 20px;
        background-color: transparent;
        border: 1px solid transparent;
        box-shadow: none;
        transition: all .25s;
        &:hover {
          background-color: transparent;
          border-color: #f612d1;
        }
      }
      .active-chain {
        position: relative;
        background-color: transparent !important;
        color: #f612d1 !important;
        border-color: #f612d1;
      }
    }
  }

  .title-market {
    h2 {
      color: #0f172a;
      font-family: Urbanist;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px; /* 200% */
      letter-spacing: 0.9px;
    }
    p {
      color: #8b898b;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
    }
  }

  .flat-tabs .show > .dropdown-menu {
    display: flex;
    width: 240px;
    padding: 8px 7px 5px 7px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid #f612d1;
    background: rgba(17,19,21,0.44);
    backdrop-filter: blur(11px);
  }

  .flat-tabs .show > .dropdown-menu span {
    color: #fff;
  }

  .flat-tabs .dropdown-menu .sc-btn-top .sc-button {
    padding: 10px 0px !important;
  }

  .flat-tabs .sc-button.style-1 {
    border: 1px solid #f612d1;
    color: #fff;
    background: transparent;
    box-shadow: 0px 2px 6px 0px rgb(47 83 109 / 10%);
    border-radius: 12px;
    padding: 10px 20px;
    min-width: 156px;
  }

  .dropdown-menu .sc-button.style-1 {
    border: none !important;
    border-radius: 0px !important;
    background-color: transparent !important;
    box-shadow: unset;
  }
  .dropdown-item {
    font-weight: normal;
    &:hover {
      background-color: rgba(255, 255, 255, .5);
      span {
        color: #0f172a !important;
      }
    }
  }
  .dropdown-item:active {
    background-color: rgba(255, 255, 255, .5);
  }
  @media only screen and (max-width: 460px) {
    .title-market {
      h2 {
        font-size: 20px;
        line-height: 30px;
      }
      p {
        font-size: 14px;
      }
    }
    .box-check li .active-chain {
      padding: 7px;
      img {
        margin-right: 0 !important;
      }
      span {
        display: none;
      }
    }

    .flat-tabs .dropdown {
      top: 5px;
    }

    .flat-tabs .sc-button.style-1 {
      height: 35px;
      min-width: 100px;
    }
  }
`;

export const Notice = styled.p`
  color: #343444;
  font-style: italic;
  font-size: 16px;
  margin-bottom: 0;
  &::after {
    content: "*";
    color: red;
  }
`;

export const BoxNotice = styled.div`
  padding: 5px 12px;
  background: #f3f3f3;
  width: 100%;
  border-radius: 12px;
  .img-notice {
    margin-right: 10px;
    width: 24px;
    padding-right: 4px;
  }
`;

export default TodayPicks;

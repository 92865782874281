import { LoaderContainer, LoaderWrapper } from "./styled";
import load_success from "../../../assets/images/common/loader/load-success.svg";
import { TextCommon, TextCustom } from "../styled";

const Loader = ({ success }: any) => {
  return (
    <>
      {success ? (
        <>
          <LoaderWrapper>
            <img width={56} height={56} src={load_success} alt="success" />
            <TextCustom fontSize="24px" fontWeight="bold" color="#0D0C43">
               Wallet Generator
            </TextCustom>
            <TextCommon>
              Please ensure the following details are correct
            </TextCommon>
          </LoaderWrapper>
        </>
      ) : (
        <LoaderWrapper>
          <LoaderContainer></LoaderContainer>
        </LoaderWrapper>
      )}
    </>
  );
};

export default Loader;

import styled from "styled-components";
import { TextCustom, TitleKey } from "../../../../../components/common/styled";

export const StartPriceContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  @media screen and (max-width: 1023px) {
    max-width: 100%;
  }
`
export const SPBody = styled.div`
  padding: 15px 40px;
  margin-bottom: 20px;
  text-align: left;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  background-color: #fff;
  border: 1px solid #DCE2E9;
  border-radius: 20px;
  ${TitleKey} {
    margin-bottom: 5px;
  }
  @media screen and (max-width: 1023px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const SPToken = styled.div`
  margin-bottom: 20px;
`
export const SPV3 = styled.div`
  margin-bottom: 20px;
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    .ant-radio-wrapper:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  ${TitleKey} {
    margin-bottom: 20px;
  }
`
export const Case = styled.div`
  margin-top: 10px;
  color: rgba(13, 12, 67, 0.70);
  font-style: italic;
  span {
    color: #F313CE;
  }
`
export const SetPrice = styled.div`
  padding: 20px;
  border-radius: 10px;
  background: #F5F5F5;
  margin-bottom: 15px;
`
export const SetHeader = styled.div`
  margin-bottom: 12px;
  ${TextCustom} {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    img {
      margin-left: 5px;
    }
  }
`
export const SetInputs = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
  .ant-input {
    text-align: right;
    color: #717696;
  }
  @media screen and (max-width: 1023px) {
    display: block;
    & > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`
export const ListSelect = styled.ul`
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  & > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding: 10px;
    list-style-type: none;
    border-radius: 10px;
    border: 1px solid #0D0C43;
    cursor: pointer;
    letter-spacing: 0.14px;
    font-weight: bold;
    transition: all .25s;
    &.current {
      border-color: #F313CE;
      color: #F313CE;
    }
  }
  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
    & > li {
      width: calc(50% - 6px);
    }
  }
`
import styled from "styled-components";
import CountDownItem from "../components/layouts/countdown/CountDownItem";
import { useEffect, useState } from "react";
import { LIST_NETWORK } from "../constants/connectors";
import { useLocation, useNavigate } from "react-router-dom";
import { useActiveWeb3React } from "../hooks";
import MinNft from "./MinNft";
import axiosInstance from "../utils/axios";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useChainId } from "wagmi";

const LIST_COUNTDOWN = [
  {
    image: "/assets/images/png/c-opBNB.png",
    name: "/assets/images/png/c-opBNB-name.png",
    time: "10/06/2023 08:00",
    chainId: 204,
  },
  {
    image: "/assets/images/png/c-arb.png",
    name: "/assets/images/png/c-arb-name.png",
    time: "10/10/2023 15:00",
    chainId: 42161,
  },
  {
    image: "/assets/images/png/c-zeta.png",
    name: "/assets/images/png/c-zeta-name.png",
    time: "10/08/2023 08:00",
    chainId: 7001,
  },
];

export default function CountDown() {
  const navigate = useNavigate();
  const [netWorkActive, setNetWorkActive] = useState();
  const { account, chainId } = useActiveWeb3React();
  const [updateChainId, setUpdateChainId] = useState(false);
  const [listTotalNFTMinted, setListTotalNFTMinted] = useState([]);
  const { search } = useLocation();

  const [networkId, setNetworkId] = useState(null);

  const getAllNFTMinted = async () => {
    try {
      const res = await Promise.all(
        LIST_NETWORK.map(async (item) => {
          const { data } = await axiosInstance.post(
            `/nft/all-nft?chainId=${item.chainId}`
          );
          return {
            data,
            chainId: item.chainId,
          };
        })
      );
      setListTotalNFTMinted(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllNFTMinted();
  }, []);

  useEffect(() => {
    const params = search.split("?network=");
    if (params && params[1]) {
      setNetworkId(params[1]);
    } else {
      setNetworkId(null);
    }
  }, [search]);

  useEffect(() => {
    const params = search.split("?network=");
    if (chainId && params && params[1]) {
      navigate(`/mint-nft?network=${chainId}`);
    }
  }, [chainId]);

  useEffect(() => {
    const defaultNetwork = LIST_NETWORK.find(
      (item) => item.chainId === chainId
    );
    if (defaultNetwork) {
      setNetWorkActive(defaultNetwork);
    } else {
      setNetWorkActive(LIST_NETWORK[0]);
    }
  }, [account, chainId]);
  useEffect(() => {
    if (!updateChainId) {
      setUpdateChainId(true);
    }
  }, [updateChainId]);

    // Web3Modal + wagmi
    const { address } = useAccount();
    const chainIdNew = useChainId();
    const { open } = useWeb3Modal();

  return (
    <>
      {networkId ? (
        <>{networkId && <MinNft />}</>
      ) : (
        <CountDownMain>
          <div className="title">
            <h2>Mint NFT XRenderAI Luffy Collection</h2>
            <p>
              XRender User Artificial Intelligence So That Users Can Experience
              our platform completely free of charge. Users will
            </p>
            <p>Experience XRender Ai with all the most outstanding feature.</p>
          </div>
          <div className="content d-flex align-items-center flex-column">
            {LIST_COUNTDOWN.map((item, index) => {
              return (
                <CountDownItem
                  key={index}
                  data={item}
                  dataNFT={listTotalNFTMinted.find(
                    (nft) => nft.chainId === item.chainId
                  )}
                />
              );
            })}
          </div>
        </CountDownMain>
      )}
    </>
  );
}
const CountDownMain = styled.div`
  min-height: 100vh;
  background-image: url("/assets/images/backgroup-secsion/bg-countdown.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  padding-top: 104px;
  h2 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 15.82043170928955px rgba(255, 148, 148, 0.67),
      0px 2px 0px #ff00d7;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 68.329px; /* 142.352% */
    letter-spacing: -0.48px;
    margin-bottom: 8px;
    margin-top: 93px;
  }
  p {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-transform: capitalize;
  }
  .content {
    max-width: 1040px;
    margin: auto;
    margin-top: 60px;
  }
`;

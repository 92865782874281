import { Steps } from "antd";
import { ProgressContainer } from "./styled"

const ProgressCommon = ({ data, step, widthCustom, ...props }: any) => {
  const { Step } = Steps;
  return (
    <ProgressContainer style={{ minWidth: widthCustom }}>
      <Steps current={step} {...props}>
        {data.map((item: string, index: number) => {
          return (
            <Step key={index} title={item} />
          )
        })}
      </Steps>
    </ProgressContainer>
  )
}

export default ProgressCommon
import { CommonTitle } from "../Features/styled";
import { UseCaseContainer, UseCaseList, UseCaseWrapper } from "./styled";
import useCase_1 from "../../../assets/images/homenew/usecase/useCase_1.png";
import useCase_2 from "../../../assets/images/homenew/usecase/useCase_2.png";
import useCase_3 from "../../../assets/images/homenew/usecase/useCase_3.png";
import useCase_1_sp from "../../../assets/images/homenew/usecase/sp/useCase_1.png";
import useCase_2_sp from "../../../assets/images/homenew/usecase/sp/useCase_2.png";
import useCase_3_sp from "../../../assets/images/homenew/usecase/sp/useCase_3.png";
import { useContext } from "react";
import { ModalConfirmContext } from "../../../components/ProviderPopUp/confirm";
import { EcoSystemContainer, EcoSystemWrapper } from "../EcoSystem/styled";

const UseCase = () => {
  const { isMobile } = useContext(ModalConfirmContext)!;
  const useCaseData = [
    {
      id: 1,
      title: "AI Chat",
      img: isMobile ? useCase_1_sp : useCase_1,
      describe:
        "Engage in natural conversations with artificial intelligence through AI Chat. Experience flexible and unique chats, bringing utility and creativity together.",
    },
    {
      id: 2,
      title: "Telegram Bot",
      img: isMobile ? useCase_2_sp : useCase_2,
      describe:
        "A multitasking bot on Telegram, managing Web3 wallets and executing decentralized tasks. Interact with the blockchain conveniently and securely.",
    },
    {
      id: 3,
      title: "AI Generator",
      img: isMobile ? useCase_3_sp : useCase_3,
      describe:
        "Explore limitless creativity with AI Generator. Generate vibrant and unique visual artworks, enriching your overall experience.",
    },
  ];
  return (
    <UseCaseContainer>
      <EcoSystemWrapper>
        <CommonTitle>
          <span>
            AI BOT
          </span>
          {/* <span>
            XRender operates in strict adherence to European laws, ensuring the
            <br /> legitimacyand reliability of our operations
          </span> */}
        </CommonTitle>
        <UseCaseList>
          {useCaseData.map((item, index) => {
            return (
              <li key={index}>
                <h3>{item.title}</h3>
                <figure>
                  <img src={item.img} alt="useCase" />
                </figure>
                <p>{item.describe}</p>
              </li>
            );
          })}
        </UseCaseList>
      </EcoSystemWrapper>
    </UseCaseContainer>
  );
};

export default UseCase;

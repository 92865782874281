import { useState } from "react";
import { WalGenBody, WalGenContainer } from "./styled";
import PreGen from "./Premium";

const WalletGenerator = () => {
 

  return (
    <WalGenContainer>
      <WalGenBody className="gen">
        <PreGen />
      </WalGenBody>
    </WalGenContainer>
  );
};

export default WalletGenerator;

import { message } from "antd";
import axios from "axios";
export async function getData(url) {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
export async function getDataOklink(url) {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Ok-Access-Key": "f66ae46a-9fdc-4030-8f21-63c8ee2ab957",
    },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function postData(url, body) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    message.error({
      type: "error",
      content: error.message,
      className: "custom-class",
      duration: 5,
    });
    throw error;
  }
}

export async function putData(url, body) {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    message.error({
      type: "error",
      content: error,
      className: "custom-class",
      duration: 5,
    });
    throw error;
  }
}
export async function deleteData(url, body) {
  try {
    const response = await fetch(url, {
      method: "DELETE", 
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    message.error({
      type: "error",
      content: error,
      className: "custom-class",
      duration: 5,
    });
    throw error;
  }
}
import { Select } from "antd";
import { SelectContainer } from "./styled";
import arrow from "../../../assets/images//arrow.svg";
const { Option } = Select;

const SelectCommon = ({ data }: any) => {
  return (
    <SelectContainer>
      <Select
        suffixIcon={<img src={arrow} alt="arrow" />}
        placeholder="Please select chain"
        defaultValue={data[0].title}
        style={{ width: "100%" }}
      >
        {data.map((item: any, index: number) => (
          <Option key={index} value={item.title} label={item.title}>
            <div>
              {item.img === "" ? "" : <img src={item.img} alt="icon" />}
              {item.title}
            </div>
          </Option>
        ))}
      </Select>
    </SelectContainer>
  );
};

export default SelectCommon;

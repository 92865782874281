import { useEffect, useState } from "react";
import AccordionSvg from "../../../../assets/svg/Accordion";
import { FaqContent, FaqTop } from "../styled";

const FaqItem = ({ item }: any) => {
  const [open, setOpen] = useState(0);
  const [currentOpen, setCurrentOpen] = useState(false);
  const [height, setHeight] = useState<any>(0);
  useEffect(() => {
    if (currentOpen) {
      setHeight(document.getElementById(`faq-item-${open}`)?.scrollHeight);
    }
  }, [currentOpen, open]);
  return (
    <li
      className="faq-item"
      onClick={() => {
        setOpen(item.id);
        setCurrentOpen(!currentOpen);
      }}
    >
      <FaqTop>
        <h3>{item.title}</h3>
        <AccordionSvg />
      </FaqTop>
      <FaqContent
        id={`faq-item-${open}`}
        style={{ height: currentOpen ? height : "0" }}
        className={
          open === item.id && currentOpen
            ? "open"
            : open === item.id && !currentOpen
            ? ""
            : ""
        }
      >
        {item.content}
      </FaqContent>
    </li>
  );
};

export default FaqItem;

import styled from 'styled-components';

export const ModalContainer = styled.div<{ showModal?: boolean }>`
  position: fixed;
  background-color: rgba(17, 25, 39, 0.5);
  top: 0px;
  left: 0px;
  height: 100vh;
  transition: opacity 0.25s linear;
  z-index: 10000;
  overflow: hidden;
  font-family: 'Sofia Sans', sans-serif;
`;
export const ModalContainerMenu = styled.div`
  position: fixed;
  background-color: rgba(17, 25, 39, 0.5);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  overflow: hidden;
  font-family: 'Sofia Sans', sans-serif;
  transform: translateY(-2000px);
  transition: transform .5s;
  @media screen and (max-width: 1023px) {
    &.menu {
      margin-top: 72px;
      transform: translateY(0);
    }
  }
`
export const Wrapper = styled.div<{ showModal?: boolean, showModalMenu?: boolean }>`
  width: 100%;
  height: ${({ showModal, showModalMenu }) => (showModal || showModalMenu ? '100vh' : 'fit-content')};
  ${ModalContainer} {
    width: ${({ showModal }) => (showModal ? '100vw' : 'auto')};;
    opacity: ${({ showModal }) => (showModal ? '1' : '0')};
  }
`;

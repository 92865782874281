import styled from "styled-components";

export const LPV2Container = styled.div`
  padding: 30px;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  @media screen and (max-width: 1023px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`
export const RecentButton = styled.div`
  position: absolute;
  right: 45px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 36px;
  padding: 6px 10px;
  border-radius: 1000px;
  background: #FFF;
  box-shadow: 0px 4px 20px 0px rgba(113, 143, 150, 0.26);
  color: #F313CE;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  @media screen and (max-width: 1270px) {
    position: relative;
    right: 0;
    top: 0;
    margin-left: 20px;
    margin-bottom: 20px;
  }
`

const AccordionSvg = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 6V9M9.75 9V12M9.75 9H12.75M9.75 9H6.75M17.25 9C17.25 13.1421 13.8921 16.5 9.75 16.5C5.60786 16.5 2.25 13.1421 2.25 9C2.25 4.85786 5.60786 1.5 9.75 1.5C13.8921 1.5 17.25 4.85786 17.25 9Z"
        stroke="url(#paint0_linear_176_360)"
        stroke-width="1.8"
        stroke-linecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_176_360"
          x1="2.63527"
          y1="1.5"
          x2="21.1085"
          y2="4.18998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CEB6FF" />
          <stop offset="1" stop-color="#864AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AccordionSvg;

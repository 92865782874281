import React, { useState } from 'react'
import { LiquidityBody, LiquidityContainer } from './styled';
import KeyvisualCommon from '../../../components/common/keyvisual';
import TabsCommon from '../../../components/common/tabs';
import LPV2 from './LPV2';
import LPV3 from './LPV3';

const Liquidity = () => {
  const [tabs, setTabs] = useState<string>("1");
  const handleChangeTabs = (key: string) => {
    setTabs(key)
  }

  return (
    <LiquidityContainer>
      <KeyvisualCommon
        text="Liquidity’s"
        content={
          <TabsCommon activeKey={tabs} dataTabs={dataTabs} onChange={handleChangeTabs} />
        }
      />
      <LiquidityBody>
      {tabs === "1" ? <LPV2 /> : <LPV3 />}
      </LiquidityBody>
    </LiquidityContainer>
  )
}

const dataTabs = [
  {
    key: "1",
    label: "Add LP V2",
    component: "",
  },
  {
    key: "2",
    label: "Add LP V3",
    component: "",
  },
];

export default Liquidity
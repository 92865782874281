import {
  CommonTitle,
  FeatureNumber,
  FeatureSubList,
  FeatureTitle,
  FeaturesContainer,
  FeaturesContent,
  FeaturesList,
  FeaturesWrapper,
} from "./styled";
import feature_1 from "../../../assets/images/homenew/features/feature_1.jpg";
import feature_1_sp from "../../../assets/images/homenew/features/sp/feature_1.jpg";
import { useContext } from "react";
import { ModalConfirmContext } from "../../../components/ProviderPopUp/confirm";
import { ButtonContract } from "../Bonus/styled";

const Features = () => {
  const { isMobile } = useContext(ModalConfirmContext)!;
  return (
    <FeaturesContainer>
      <FeaturesWrapper>
        <CommonTitle>
          <span>Tooldex</span>
          <ButtonContract style={{margin:'10px auto' , justifyContent:'center'}} >Try Now</ButtonContract>
        </CommonTitle>
        <FeaturesContent>
          <figure>
            <img src={isMobile ? feature_1_sp : feature_1} alt="features" />
          </figure>
        </FeaturesContent>
        <FeaturesList>
          {featureData.map((item, index) => {
            return (
              <li key={index}>
                <FeatureNumber>0{item.id}</FeatureNumber>
                <FeatureTitle>{item.title}</FeatureTitle>
                <FeatureSubList>
                  {item.list.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </FeatureSubList>
              </li>
            );
          })}
        </FeaturesList>
      </FeaturesWrapper>
    </FeaturesContainer>
  );
};

const featureData = [
  {
    id: 1,
    title: "Token Swapping and Trading",
    list: [
      "Enjoy decentralized trading by leveraging XRender Tooldex's token swapping features. Execute trades securely and efficiently without relying on centralized exchanges.",
    ],
  },
  {
    id: 2,
    title: "Smart Contract Interaction",
    list: [
      "Engage with smart contracts effortlessly. XRender Tooldex allows users to interact with and deploy smart contracts directly, streamlining decentralized operations.",
    ],
  },
  {
    id: 3,
    title: "Wallet Management",
    list: [
      "Seamlessly manage your Web3 wallets directly through XRender Tooldex. Have full control over your assets and execute transactions with ease.",
    ],
  },
  {
    id: 4,
    title: "Decentralized Task Execution",
    list: [
      "Execute decentralized tasks autonomously. XRender Tooldex empowers users to perform various actions on the blockchain without intermediaries.",
    ],
  },
];

export default Features;

// ABI_NFT_LUFFY
import OpBNB_NFT_LUFFY_ABI from "./OPBNB_NFT_Lufffy_ABI.json";
import ARB_NFT_Lufffy_ABI from "./ARB_NFT_Lufffy_ABI.json";
import ZETA_NFT_Luffy_ABI from "./ZETA_NFT_Luffy_ABI.json";
// MKP
import BNB_MKP_ABI_MAINNET from "./BNB_MKP_ABI_MAINNET.json";
import OPBNB_MKP_ABI_MAINNET from "./OPBNB_MKP_ABI_MAINNET.json";
import ARB_MKP_ABI_MAINNET from "./ARB_MKP_ABI_MAINNET.json";
import ZETA_MKP_ABI_TESTNET from "./ZETA_MKP_ABI_TESTNET.json";

// ABI_MKP_LUFFY
import OPBNB_MKP_BuySell_Contract_ABI from "./OPBNB_MKP_BuySell_Contract_ABI.json";
import ARB_MKP_BuySell_Contract_ABI from "./ARB_MKP_BuySell_Contract_ABI.json";
import ZETA_MKP_BuySell_Contract_ABI from "./ZETA_MKP_BuySell_Contract_ABI.json";

// ABI_NFT_GEN
import BNB_NFT_GEN from "./BNB_NFT_GEN.json";
import OPBNB_NFT_GEN from "./OPBNB_NFT_GEN.json";
import ARB_NFT_GEN from "./ARB_NFT_GEN.json";
import ZETA_NFT_GEN from "./ZETA_NFT_GEN.json";

// ABI_ANIMAL_NFT
import BNB_ANIMAL_NFT_ABI from "./BNB_ANIMAL_NFT_ABI.json";
import OPBNB_ANIMAL_NFT_ABI from "./OPBNB_ANIMAL_NFT_ABI.json";
import ARB_ANIMAL_NFT_ABI from "./ARB_ANIMAL_NFT_ABI.json";
import ZETA_ANIMAL_NFT_ABI from "./ZETA_ANIMAL_NFT_ABI.json";

// ABI_ART_NFT
import BNB_ART_NFT_ABI from "./BNB_ART_NFT_ABI.json";
import OPBNB_ART_NFT_ABI from "./OPBNB_ART_NFT_ABI.json";
import ARB_ART_NFT_ABI from "./ARB_ART_NFT_ABI.json";
import ZETA_ART_NFT_ABI from "./ZETA_ART_NFT_ABI.json";

// ABI_NATURE_NFT
// BNB_NATURE_NFT_ABI
import BNB_NATURE_NFT_ABI from "./BNB_NATURE_NFT_ABI.json";
import OPBNB_NATURE_NFT_ABI from "./OPBNB_NATURE_NFT_ABI.json";
import ARB_NATURE_NFT_ABI from "./ARB_NATURE_NFT_ABI.json";
import ZETA_NATURE_NFT_ABI from "./ZETA_NATURE_NFT_ABI.json";

// ABI_ROBOT_NFT
import BNB_ROBOT_NFT_ABI from "./BNB_ROBOT_NFT_ABI.json";
import OPBNB_ROBOT_NFT_ABI from "./OPBNB_ROBOT_NFT_ABI.json";
import ARB_ROBOT_NFT_ABI from "./ARB_ROBOT_NFT_ABI.json";
import ZETA_ROBOT_NFT_ABI from "./ZETA_ROBOT_NFT_ABI.json";
// ABI_SPORT_NFT

import BNB_SPORT_NFT_ABI from "./BNB_SPORT_NFT_ABI.json";
import OPBNB_SPORT_NFT_ABI from "./OPBNB_SPORT_NFT_ABI.json";
import ARB_SPORT_NFT_ABI from "./ARB_SPORT_NFT_ABI.json";
import ZETA_SPORT_NFT_ABI from "./ZETA_SPORT_NFT_ABI.json";

import GoerliArb_MintGenAi_Contract_ABI from "./GoerliArb_MintGenAi_Contract_ABI";
import OPBNB_TESTNET_MintGenAI_Contract_ABI from "./OPBNB_TESTNET_MintGenAI_Contract_ABI.json";
import ARB_GEN_AI from "./ARB_GEN_AI.json";
import OPBNB_GEN_AI from "./OPBNB_GEN_AI.json";
import ZETA_GEN_AI from "./ZETA_GEN_AI.json";
import ICO_ABI from "./ICO_ABI.json";
import ERC20_ABI from "./ERC20_ABI.json";
// OPBNB_MULTICHAIN_TESTNET
import MKP_MULTICHAIN_ABI from "./MKP_MULTICHAIN_ABI.json";
import OPBNB_GEN_AI_LUFFY from "./OPBNB_GEN_AI_LUFFY.json";
import OPBNB_GEN_AI_ANIMAL from "./OPBNB_GEN_AI_ANIMAL.json";

import ABI_MKP_opbnb_TestNet from "./ABI_MKP_opbnb_TestNet.json";

// CARTOON
import BNB_CARTOON_NFT_ABI from "./BNB_CARTOON_NFT_ABI.json";
import OPBNB_CARTOON_NFT_ABI from "./OPBNB_CARTOON_NFT_ABI.json";
import ARB_CARTOON_NFT_ABI from "./ARB_CARTOON_NFT_ABI.json";
import ZETA_CARTOON_NFT_ABI from "./ZETA_CARTOON_NFT_ABI.json";

// CELERITY
import BNB_CELERITY_NFT_ABI from "./BNB_CELERITY_NFT_ABI.json";
import OPBNB_CELERITY_NFT_ABI from "./OPBNB_CELERITY_NFT_ABI.json";
import ARB_CELERITY_NFT_ABI from "./ARB_CELERITY_NFT_ABI.json";
import ZETA_CELERITY_NFT_ABI from "./ZETA_CELERITY_NFT_ABI.json";

// MUSIC
import BNB_MUSIC_NFT_ABI from "./BNB_MUSIC_NFT_ABI.json";
import OPBNB_MUSIC_NFT_ABI from "./OPBNB_MUSIC_NFT_ABI.json";
import ARB_MUSIC_NFT_ABI from "./ARB_MUSIC_NFT_ABI.json";
import ZETA_MUSIC_NFT_ABI from "./ZETA_MUSIC_NFT_ABI.json";

import BNB_NFT_CAR from "./BNB_CAR_NFT_ABI.json";
// STAKING

import ABI_STAKING from "./ABI_STAKING.json";

// NFT Luffy OPBNB
export const address_NFT_LUFFY_OPBNB_MainNet = "0xFbFea005196bB09DcFABae4d33c2C2b567167c42";

// MKP OPBNB
export const address_MKP_LISTBUYSELL_OPBNB_MainNet = "0x85F0BE0D4827027473925eeC6704E25E846E99f7";

//NFT Luffy Zeta_TestNet
export const address_NFT_LUFFY_Zeta_TestNet = "0x8407Fff6031C9ef5E4B732006B3fb73088610De8";

// MKP Zeta_TestNet
export const address_NFT_MKP_LISTBUYSELL_Zeta_TestNet = "0x969b3bFFAc2Ca1E9B5a528455c0a7eb5bE098F16";

// NFT Luffy ARB
export const address_NFT_LUFFY_ARB = "0x8407Fff6031C9ef5E4B732006B3fb73088610De8";

// MKP ARB
export const address_MKP_LISTBUYSELL_ARB = "0x91EEE803C164B2A8a2BC9e42FE439603bF8E7b15";

// MKP MULTICHAIN
export const ADDRESS_MKP_MULTICHAIN = "0xf12C25a062A564552225B75CE90CF331652CA1Ba";

// NFT_Gen_AI_Goerli

export const address_NFT_GenAI_Goerli = "";

export const address_NFT_GENAI_OPBNB_TestNet = "0x320aDb4fCCD7c66dd96bF346900e5d88E9a35Ec5";

// NFT_GEN_AI

export const address_ICO_OPBNB_TESTNET = "0x9961b6d4f8be9553Bd4613Ca43FB01bA126Cc670";

export const address_ICO_ARB_MAINNET = "0x950285f261ad1941bbf9226c245b74dc777b794f";

export const USDC_OPBNB_TESTNET = "0x36C663e92255762A46da24583EeA4aCa04159134";

export const USDC_ARB_MAINNET = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";

export const XRAI_ARB_MAINET = "0x617B76412bD9f3f80FE87d1533dc7017Defa8AD1";
export const XRAI_OPBNB_TESTNET = "0x65B9e16865AaEeBC0126Cd1a177F54A95901FbA0";

export const STAKING_OPBNB_TESTNET = "0x876fBa215545b05A51870d3eF792C2C8f788CD7d";
export const STAKING_ARB_MAINNET = "0x64425e959C5f69802Fa900F226a7E9dce30f40B3";

// NFT_GEN_AI_LUFFY
export const address_gen_ai_Luffy = "0x8b135D5c8e3F1acec30E1bBEf521C4d29C176796";

// NFT_GEN_AI_ANIMAL
export const address_gen_ai_Animal = "0x4a7122e71E2646ccd0a3577852a6232F72a8e41E";

// This is Main Production
// NFT LUFFY
export const ADDR_NFT_LUFFY_OPBNB_MAINNET = "0xFbFea005196bB09DcFABae4d33c2C2b567167c42";
export const ADDR_NFT_LUFFY_ARB_MAINNET = "0x8407Fff6031C9ef5E4B732006B3fb73088610De8";
export const ADDR_NFT_LUFFY_ZETA_MAINNET = "0x8407Fff6031C9ef5E4B732006B3fb73088610De8";
// MKP

export const ADDR_NFT_CAR_MAINNET = "0xdfc0541194684C88AdcE224a63C5fC031d74e208";

export const ADDR_MKP_BNB_MAINNET = "0x4F86Caec5ce3432222DF0e00ac534773d5a38bA1";
export const ADDR_MKP_OPBNB_MAINNET = "0xD07B20bb950286E74EdbFf7F5EC37EcaFdAC40bE";
export const ADDR_MKP_ARB_MAINNET = "0x736D37693Cb32c9AA071c160a4bE451c31C9dDeC";
export const ADDR_MKP_ZETA_TESTNET = "0x67e56041f18de4203309a70b4b5aB3CE0abC2eA8";
// NFT GENAI
export const address_NFT_GenAI_OPBNB_MAINNET = "0xc7e0206712E99274EBF853605E41bE1c63bdf0ed";
export const address_NFT_GenAi_BNB_MAINNET = "0xe004b84bc77AeEe7bB911f00b487D6Ca3aCbB934";
export const address_NFT_GENAI_ARB_MAINNET = "0x6831618b5580bBf4eC9086629575F138975B4b3D";
export const address_NFT_ZETA_TESTNET = "0x6831618b5580bBf4eC9086629575F138975B4b3D";

// NFT Animal
export const ADDR_NFT_ANIMAL_BNB_MAINNET = "0x632707FE77F2e43aB4E803b218FBC00FCefE1dE8";
export const ADDR_NFT_ANIMAL_OPBNB_MAINNET = "0x5f317a32d9ceaa64864f3457fE80535e2E4dCc77";
export const ADDR_NFT_ANIMAL_ARB_MAINNET = "0xBC2b186c813513022a33a5307642C5c7F5AD290e";
export const ADDR_NFT_ANIMAL_ZETA_TESTNET = "0xBbEa9FEf570903c25b11Be95C607972e88B1631C";

// NFT Cartoon
export const ADDR_NFT_CARTOON_BNB_MAINNET = "0xAC2C46660F362b94F2A0f31a327201BA78be5352";
export const ADDR_NFT_CARTOON_OPBNB_MAINNET = "0x5D7F126eCb3d6213fF65059909132464E9d0f204";
export const ADDR_NFT_CARTOON_ARB_MAINNET = "0xD0f34cBfB30D4080d0FB0EE2B0c7161101A765b8";
export const ADDR_NFT_CARTOON_ZETA_TESTNET = "0x1f4Dd5562A634Cdb03873dd9f5BBA6C765c06A76";

// NFT Celerity
export const ADDR_NFT_CELERIRY_BNB_MAINNET = "0xd81B3760e972752FF3Df1384B1E6018e0a96e8Ac";
export const ADDR_NFT_CELERIRY_OPBNB_MAINNET = "0xb2852725828C342dcc4fB2F8f04E2AaeEbF86D0D";
export const ADDR_NFT_CELERIRY_ARB_MAINNET = "0x87B71563D9aA13817149cb11C4b9Fedfb9A5aE63";
export const ADDR_NFT_CELERIRY_ZETA_TESTNET = "0xB214f7279E05072EB89cF1105c19d1A2F0bc7234";

// NFT Music
export const ADDR_NFT_MUSIC_BNB_MAINNET = "0x65FFA7889f41AC0aCD41166a441eE1488cf38a33";
export const ADDR_NFT_MUSIC_OPBNB_MAINNET = "0x1f4Dd5562A634Cdb03873dd9f5BBA6C765c06A76";
export const ADDR_NFT_MUSIC_ARB_MAINNET = "0x3f22b0e3cc59e31f7F3D80E4Dd0d01c397e8f86d";
export const ADDR_NFT_MUSIC_ZETA_TESTNET = "0xc2cABD1be285e32b98B138F563b73642A068C9cE";

// NFT ART

export const ADDR_NFT_ART_BNB_MAINNET = "0x5D7F126eCb3d6213fF65059909132464E9d0f204";
export const ADDR_NFT_ART_OPBNB_MAINNET = "0xBC2b186c813513022a33a5307642C5c7F5AD290e";
export const ADDR_NFT_ART_ARB_MAINNET = "0xC68790673A1DbF99EA5C5850FAfcB203E6D2191B";
export const ADDR_NFT_ART_ZETA_TESTNET = "0xBC2b186c813513022a33a5307642C5c7F5AD290e";

// NFT NATURE
export const ADDR_NFT_NATURE_BNB_MAINNET = "0xb2852725828C342dcc4fB2F8f04E2AaeEbF86D0D";
export const ADDR_NFT_NATURE_OPBNB_MAINNET = "0xf2bd7C4aC6C3f43A92786E423631C384924274f8";
export const ADDR_NFT_NATURE_ARB_MAINNET = "0xf2bd7C4aC6C3f43A92786E423631C384924274f8";
export const ADDR_NFT_NATURE_ZETA_TESTNET = "0xb48129b920BA6255B912bd30450362333499aC50";

//NFT ROBOT
export const ADDR_NFT_ROBOT_BNB_MAINNET = "0x1f4Dd5562A634Cdb03873dd9f5BBA6C765c06A76";
export const ADDR_NFT_ROBOT_OPBNB_MAINNET = "0xC68790673A1DbF99EA5C5850FAfcB203E6D2191B";
export const ADDR_NFT_ROBOT_ARB_MAINNET = "0x18256eD3b3042808DDAc6E61A2Ff00C619f84f42";
export const ADDR_NFT_ROBOT_ZETA_TESTNET = "0xC68790673A1DbF99EA5C5850FAfcB203E6D2191B";

//NFT SPORT
export const ADDR_NFT_SPORT_BNB_MAINNET = "0xB214f7279E05072EB89cF1105c19d1A2F0bc7234";
export const ADDR_NFT_SPORT_OPBNB_MAINNET = "0xb48129b920BA6255B912bd30450362333499aC50";
export const ADDR_NFT_SPORT_ARB_MAINNET = "0xb48129b920BA6255B912bd30450362333499aC50";
export const ADDR_NFT_SPORT_ZETA_TESTNET = "0xf2bd7C4aC6C3f43A92786E423631C384924274f8";

export {
  OpBNB_NFT_LUFFY_ABI,
  OPBNB_MKP_BuySell_Contract_ABI,
  ZETA_NFT_Luffy_ABI,
  ZETA_MKP_BuySell_Contract_ABI,
  GoerliArb_MintGenAi_Contract_ABI,
  OPBNB_TESTNET_MintGenAI_Contract_ABI,
  ARB_NFT_Lufffy_ABI,
  ARB_MKP_BuySell_Contract_ABI,
  MKP_MULTICHAIN_ABI,
  ARB_GEN_AI,
  OPBNB_GEN_AI,
  ZETA_GEN_AI,
  ICO_ABI,
  ERC20_ABI,
  OPBNB_GEN_AI_LUFFY,
  OPBNB_GEN_AI_ANIMAL,

  /// STAKING
  ABI_STAKING,
  // Mainnet
  // MarketPlace
  BNB_MKP_ABI_MAINNET,
  OPBNB_MKP_ABI_MAINNET,
  ARB_MKP_ABI_MAINNET,
  ZETA_MKP_ABI_TESTNET,
  BNB_NFT_CAR,

  // Gen_AI
  BNB_NFT_GEN,
  OPBNB_NFT_GEN,
  ARB_NFT_GEN,
  ZETA_NFT_GEN,
  // Animal
  BNB_ANIMAL_NFT_ABI,
  OPBNB_ANIMAL_NFT_ABI,
  ARB_ANIMAL_NFT_ABI,
  ZETA_ANIMAL_NFT_ABI,
  // Art
  BNB_ART_NFT_ABI,
  OPBNB_ART_NFT_ABI,
  ARB_ART_NFT_ABI,
  ZETA_ART_NFT_ABI,
  // Nature
  BNB_NATURE_NFT_ABI,
  OPBNB_NATURE_NFT_ABI,
  ARB_NATURE_NFT_ABI,
  ZETA_NATURE_NFT_ABI,
  // Robot
  BNB_ROBOT_NFT_ABI,
  OPBNB_ROBOT_NFT_ABI,
  ARB_ROBOT_NFT_ABI,
  ZETA_ROBOT_NFT_ABI,
  // Sport
  BNB_SPORT_NFT_ABI,
  OPBNB_SPORT_NFT_ABI,
  ARB_SPORT_NFT_ABI,
  ZETA_SPORT_NFT_ABI,

  //
  ABI_MKP_opbnb_TestNet,
  // Catoon
  BNB_CARTOON_NFT_ABI,
  OPBNB_CARTOON_NFT_ABI,
  ARB_CARTOON_NFT_ABI,
  ZETA_CARTOON_NFT_ABI,

  // CELERITY
  BNB_CELERITY_NFT_ABI,
  OPBNB_CELERITY_NFT_ABI,
  ARB_CELERITY_NFT_ABI,
  ZETA_CELERITY_NFT_ABI,

  // MUSIC
  BNB_MUSIC_NFT_ABI,
  OPBNB_MUSIC_NFT_ABI,
  ARB_MUSIC_NFT_ABI,
  ZETA_MUSIC_NFT_ABI,
};

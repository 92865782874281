import { ReactNode, useState } from "react";
import ProgressCommon from "../../../../components/common/progress"
import { LPV3Container } from "./styled"
import { RecentButton } from "../LPV2/styled";
import TokenPairV3 from "./TokenPair";

// Images
import recent from '../../../../assets/images/liquidity/recent.svg'
import StartPrice from "./setStartPrice";
import LiqV3 from "./LiqV3";

const LPV3 = () => {
  const [step, setStep] = useState<number>(0);

  const handleChangeComponent = (step: number): ReactNode => {
    switch (step) {
      case 0:
        return <TokenPairV3 setStep={setStep} />;
      case 1:
        return <StartPrice setStep={setStep} />;
      case 2:
        return <LiqV3 setStep={setStep} />;
      default:
        return null;
    }
  }
  return (
    <LPV3Container>
      <ProgressCommon
        style={{ maxWidth: '700px', margin: '0 auto 20px' }}
        widthCustom={"200px"}
        data={progressData}
        step={step}
      />
      <RecentButton>
        <img width={24} height={24} src={recent} alt="recent" />
        Recent Transactions
      </RecentButton>
      {handleChangeComponent(step)}
    </LPV3Container>
  )
}

const progressData = ["Choose token pair", "Set start price", "Add liquidity V3"];

export default LPV3
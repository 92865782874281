import styled from "styled-components";
import { CommonTitle } from "../Features/styled";

export const FaqContainer = styled.div`
  position: relative;
  padding-bottom: 90px;
`;
export const FaqWrapper = styled.div`
  position: relative;
  max-width: 1728px;
  margin: 0 auto;
  display: flex;
  padding: 0 144px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 55px;
  ${CommonTitle} {
    text-align: left;
  }
  &:hover {
    ${CommonTitle} {
      span {
        &:nth-child(1) {
          color: #fff;
          opacity: 1;
          text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 0px #fff;
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 1023px) {
    gap: 20px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;
export const FaqLeft = styled.div`
  padding-left: 30px;
  max-width: 380px;
  width: 100%;
  h2 {
    color: #fff;
    font-size: 45px;
    margin-bottom: 0;
  }
  p {
    color: #98a2b3;
    font-size: 15px;
    line-height: 18px;
  }
  @media screen and (max-width: 1023px) {
    padding-left: 0;
  }
  @media screen and (max-width: 767px) {
    h2 {
      margin-bottom: 10px;
    }
  }
`;
export const FaqRight = styled.div``;
export const FaqTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    margin-bottom: 0;
    color: #eaecf0;
    font-size: 18px;
    font-weight: 500;
    padding-right: 20px;
  }
  svg {
    flex-shrink: 0;
  }
`;
export const FaqContent = styled.div`
  overflow: hidden;
  padding-right: 40px;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  &.open {
    margin-top: 20px;
  }
`;
export const FaqList = styled.ul`
  & > li {
    border-radius: 9px 9px 0px 0px;
    background: rgba(25, 17, 28, 0.6);
    backdrop-filter: blur(9px);
    padding: 20px;
    cursor: pointer;
    color: #fff;
    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }
`;

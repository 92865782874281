import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderContainer = styled.header<{isTooldexPath?: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid ${({ isTooldexPath }) => (isTooldexPath ? "transparent" : "#2d2d2d;")};
  background: ${({ isTooldexPath }) => (isTooldexPath ? "#fff" : "rgba(17, 19, 21, 0.44)")};
  backdrop-filter: blur(11px);
  z-index: 1005;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px 10px;
  gap: 10px;
`;
export const NavigationWrapper = styled.div`
  display: flex;
  gap: 24px;
  a {
    position: relative;
    color: #fff;
    font-size: 15px;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      left: 0;
      bottom: -3px;
      background-color: transparent;
      transition: all .7s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &:hover {
      &::before {
        width: 100%;
        background-color: #cd09e7;
      }
    }
    &.active {
      &::before {
        width: 100%;
        background-color: #cd09e7;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    gap: 10px;
    a {
      font-size: 1.55vw;
    }
  }
`;
export const HeaderNavigationContainer = styled.div``;
export const HeaderAction = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
  @media screen and (max-width: 1023px) {
    gap: 15px;
  }
`;
export const ButtonConnect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  border-radius: 9px;
  color: #fff;
  background: linear-gradient(90deg, #6650ff 0%, #9818aa 100%);
  cursor: pointer;
`;
export const HeaderStickyMobile = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  z-index: 1000;
  transition: all .5s cubic-bezier(0.19, 1, 0.22, 1);
  &.open {
    width: calc(100% - 10px);
  }
`;
export const StickyMobileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  border-radius: 18px;
  background: rgba(54, 54, 54, 0.53);
  backdrop-filter: blur(2px);
  ${ButtonConnect} {
    width: 50%;
    padding: 15px;
    font-size: 12px;
    font-weight: 600;
  }
`;
export const HamburgerMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  gap: 10px;
  border-radius: 9px;
  background: #7a787d;
  padding: 15px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
`;
export const HamburgerSpan = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 20px;
  &.active {
    & > span {
      &:nth-child(1) {
        top: 4px;
        transform: rotateZ(45deg);
      }
      &:nth-child(2) {
        top: -5px;
        transform: rotateZ(-45deg);
      }
    }
  }
  span {
    position: relative;
    display: block;
    width: 20px;
    height: 2px;
    background-color: #fff;
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }
`;
export const StickyMobileInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  border-radius: 12px;
  background: #1c1c1c;
`;
export const StickMobileAction = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
`;
export const StickyMobileContent = styled.div<{ openMobile?: boolean }>`
  width: 100%;
  height: ${({ openMobile }) => (openMobile ? "422px" : "0")};
  visibility: ${({ openMobile }) => (openMobile ? "visibility" : "hidden")};
  opacity: ${({ openMobile }) => (openMobile ? "1" : "0")};
  transition: all 0.2s ease-in-out;
  padding: 0 10px;
  ${NavigationWrapper} {
    padding: 10px 0;
    flex-direction: column;
    border-bottom: 2px solid #fff;
    margin-bottom: 10px;
    gap: 0;
    a {
      padding: 16px 10px;
      color: #e8e8e8;
      font-size: 16px;
      border-radius: 8px;
      &.active {
        color: #1c1c1c !important;
        font-weight: bold;
        background-color: #fcfdfe;
      }
    }
  }
`;
export const HeaderSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  p {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
  }
`;
export const StickyMobileSocial = styled.ul`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 0;
  margin-right: 10px;
  & > li {
    a {
      display: block;
      width: 15px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
export const HeaderLogo = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 14.2vw;
  }
  @media screen and (max-width: 767px) {
    max-width: 110px;
  }
`;
export const HeaderIcon = styled.div`
  max-width: 30px;
  width: 100%;
  animation: rotate 5s cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
  animation-delay: 2s;
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0);
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 3.2vw;
  }
  @media screen and (max-width: 767px) {
    max-width: 25px;
  }
`
export const HeaderText = styled.div`
  max-width: 125px;
  width: 100%;
`
import Web3 from "web3";
import { LIST_NETWORK } from "../constants/connectors";
const web3 = new Web3(window.ethereum);
const changeNetwork = async (chainId) => {
  const network = LIST_NETWORK.find((item) => item.idText === chainId);
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }],
      });
    } catch (error) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [network.params],
          });
        } catch (addError) {
          console.error("Add BNB netword Error", addError);
        }
     
    }
  } else {
    console.error("Metamask is underfine");
  }
};

export default changeNetwork;

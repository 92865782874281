import styled from "styled-components";
import { ButtonCommon } from "../../styled";

export const TokenInfoContainer = styled.div`
  ${ButtonCommon} {
    min-width: 344px;
  }
  @media screen and (max-width: 743px) {
    ${ButtonCommon} {
      min-width: auto;
    }
  }
`
export const InfoBody = styled.div`
  background-color: #fff;
  max-width: 675px;
  margin: 0 auto 25px;
  padding: 35px 70px;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #DCE2E9;
  border-radius: 20px;
  @media screen and (max-width: 743px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const InputRow = styled.div`
`
export const InfoContent = styled.div`
  text-align: left;
  margin-bottom: 30px;
  ${InputRow}:not(:last-child) {
    margin-bottom: 15px;
  }
  .ant-select-selector {
    height: 48px !important;
  }
`
export const RowHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  span {
    color: #717696;
    font-weight: 300;
  }
`

export const SelectContainer = styled.div`
  .ant-select {
    color: #717696;
  }
  .ant-select-selector {
    border-radius: 10px !important;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #F313CE !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }
`
import React, { useState, useEffect, useRef } from "react";
import {
  GenContainer,
  GenBlock,
  GenItem,
  GenTop,
  GenEg,
  InfoRow,
  GenInfo,
  GenRadio,
  CopyInput,
  FormGenContainer,
  GenInput,
  GenQrCode,
  GenWrapper,
  InputBlock,
  InputTitle,
} from "./styled";

import QRCode from "react-qr-code";
import Web3 from "web3";
import { Radio } from "antd";
import  { RadioChangeEvent } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import copy_right from "../../../../assets/images/walGen/formGen/copy-right.svg";
import { notiError, notiSuccess, notiWarning } from "../../../../utils/Tooldex/notication";
import { ButtonCommon, RadioCommon, TextCustom, TitleKey } from "../../../../components/common/styled";
import InputCommon from "../../../../components/common/input";
import { TYPE_STT, status_difficulty } from "../../../../constants/Tooldex";
import Loader from "../../../../components/common/loader";
import Loading from "../../../../components/Tooldex/Loading";
import { GenWrapperTitle } from "../../MultiWalletCreate/Premium/styled";

const FreeGen = () => {
  const web3 = new Web3("https://bsc-dataseed1.binance.org");
  const [valueMode, setValueMode] = useState(2);
  const [valueCase, setValueCase] = useState(1);
  const [vanity, setVanity] = useState<any>("");
  const [status, setStatus] = useState(1);
  const [countWallets, setCountWallets] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [wallets, setWallets] = useState<any>(null);

  const characterCount = vanity?.length;
  const isMounted = useRef(true);

  const onChangeMode = (e: RadioChangeEvent) => {
    setValueMode(e.target.value);
  };
  const onChangeCase = (e: RadioChangeEvent) => {
    setValueCase(e.target.value);
  };

  const renderText = () => {
    if (valueMode === 1) {
      return (
        <>
          <p>
            <span style={{ color: "#0D0C43", marginRight: "5px" }}>Eg:</span>0x
            <span style={{ color: "#F313CE" }}>{vanity}</span>
            3D6C5Bb35051B666c659d29D97eA20b848
          </p>
        </>
      );
    }
    if (valueMode === 2) {
      return (
        <>
          <p>
            <span style={{ color: "#0D0C43", marginRight: "5px" }}>Eg:</span>
            0xD9173D6C5Bb35051B666c659d29D97eA20b
            <span style={{ color: "#F313CE" }}>{vanity}</span>
          </p>
        </>
      );
    }
  };

  const checkNetworkStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("online", checkNetworkStatus);
    window.addEventListener("offline", checkNetworkStatus);

    return () => {
      window.removeEventListener("online", checkNetworkStatus);
      window.removeEventListener("offline", checkNetworkStatus);
    };
  }, []);

  const createWallet = async () => {
    try {
      if (isOnline) {
        setStatus(2);
        let count = 0;
        let wallet;
        const checkAddress = () => {
          wallet = web3.eth.accounts.create();
          const address = wallet.address.toLowerCase();
          if (vanity && valueMode === 2) {
            if (address.endsWith(vanity)) {
              setWallets({
                address: wallet.address,
                privateKey: wallet.privateKey,
                tries: count,
              });
              notiSuccess("New wallet generated successfully!", 5);
              setStatus(3);
            } else {
              count++;
              setCountWallets(count);
              if (isMounted.current) {
                setTimeout(checkAddress, 0); // Continue checking in next tick
              }
            }
          }

          if (vanity && valueMode === 1) {
            if (address.slice(2).startsWith(vanity)) {
              setWallets({
                address: wallet.address,
                privateKey: wallet.privateKey,
                tries: count,
              });
              notiSuccess("New wallet generated successfully!", 5);
              setStatus(3);
            } else {
              count++;
              setCountWallets(count);
              if (isMounted.current) {
                setTimeout(checkAddress, 0); // Continue checking in next tick
              }
            }
          }
        };
        checkAddress();
      } else {
        notiWarning("Please disconnect from the internet", 10);
      }
    } catch (error: any) {
      console.error("Error creating wallet:", error);
      notiError(error.message, 5);
      setStatus(4);
    }
  };
  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  return (
    <GenContainer>
      <GenWrapperTitle>
        <TextCustom fontWeight="bold" fontSize="24px" color="#fff">
          Address Generator
        </TextCustom>
      </GenWrapperTitle>
      <GenBlock>
        <GenItem>
          <GenTop>
            <TitleKey>Enter your vanity string</TitleKey>
            <span>Ex:123</span>
          </GenTop>
          <InputCommon
            placeHolder={valueMode === 1 ? "Prefix" : "Suffix"}
            value={vanity}
            onChange={(e: any) => setVanity(e.target.value)}
          />
        </GenItem>
        <GenEg>{vanity && renderText()}</GenEg>
        <GenRadio>
          <div>
            <TitleKey>Case Sensitive</TitleKey>
            <RadioCommon>
              <Radio.Group onChange={onChangeCase} value={valueCase}>
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Radio.Group>
            </RadioCommon>
          </div>
          <div>
            <TitleKey>Mode</TitleKey>
            <RadioCommon>
              <Radio.Group onChange={onChangeMode} value={valueMode}>
                <Radio value={1}>Prefix</Radio>
                <Radio value={2}>Suffix</Radio>
              </Radio.Group>
            </RadioCommon>
          </div>
        </GenRadio>
        <GenInfo>
          <InfoRow>
            <p>Difficulty:</p>
            <span>{status_difficulty(characterCount)}</span>
          </InfoRow>
          <InfoRow>
            <p>Generate:</p>
            <span>{countWallets} Address</span>
          </InfoRow>
          <InfoRow>
            <p>Probability:</p>
            <span>{characterCount < 5 ? "100%" : "50%"}</span>
          </InfoRow>
          <InfoRow>
            <p>Status:</p>
            <span>{TYPE_STT(status)}</span>
          </InfoRow>
        </GenInfo>
        <ButtonCommon
          disabled={status === 2 || !vanity || characterCount > 5}
          onClick={createWallet}
        >
          <Loading status={status === 2} content={"Start Wallet Generator"} />
        </ButtonCommon>
      </GenBlock>
      {status === 2 && <Loader />}
      {(status === 3 && wallets ) && (
        <>
          <Loader success />
          <FormGenContainer>
            <GenWrapper>
              <GenQrCode>
                <QRCode value={wallets?.address} size={140} />
              </GenQrCode>
              <GenInput>
                <InputBlock>
                  <InputTitle>Address</InputTitle>
                  <InputCommon
                    disabled={true}
                    defaultValue={wallets?.address}
                  />
                  <CopyInput>
                    <CopyToClipboard
                      text={wallets?.address}
                      onCopy={() => {
                        notiSuccess(
                          `Copied ${wallets?.address?.substring(
                            0,
                            6
                          )}.....${wallets?.address?.substring(wallets?.address.length - 8)}`,
                          5
                        );
                      }}
                    >
                      <span className="img-copy">
                        <img src={copy_right} alt="copy" />
                      </span>
                    </CopyToClipboard>
                  </CopyInput>
                </InputBlock>
                <InputBlock>
                  <InputTitle>PrivateKey</InputTitle>
                  <InputCommon
                    disabled={true}
                    defaultValue={wallets?.privateKey}
                  />
                  <CopyInput>
                    <CopyToClipboard
                      text={wallets?.privateKey}
                      onCopy={() => {
                        notiSuccess(
                          `Copied ${wallets?.privateKey?.substring(
                            0,
                            6
                          )}.....${wallets?.privateKey?.substring(wallets?.privateKey.length - 8)}`,
                          5
                        );
                      }}
                    >
                      <span className="img-copy">
                        <img src={copy_right} alt="copy" />
                      </span>
                    </CopyToClipboard>
                  </CopyInput>
                </InputBlock>
              </GenInput>
            </GenWrapper>
          </FormGenContainer>
        </>
      )}
    </GenContainer>
  );
};

export default FreeGen;

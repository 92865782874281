import styled from "styled-components";
import { TitleKey, V2Buttons, TextCustom } from "../../../components/common/styled";
export const CheckBalanceContainer = styled.div`
  position: relative;
  z-index: 5;
`
export const BalanceBody = styled.div`
  padding: 50px 20px;
  ${TextCustom} {
    text-align: center;
    margin-bottom: 30px;
  }
`
export const TableBlock = styled.div`
  margin-top: 30px;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #DCE2E9;
  ${TextCustom} {
    margin-bottom: 15px;
  }
`
export const FormContainer = styled.div`
  max-width: 720px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 20px;
  text-align: left;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #DCE2E9;
  ${TitleKey} {
    margin-bottom: 5px;
  }
  ${V2Buttons} {
    justify-content: space-between;
    margin-bottom: 20px;
  }
`
export const AddBlock = styled.div`
  margin-bottom: 15px;
`
export const AddWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
  }
`
export const ShowBlock = styled.div`
  margin-bottom: 15px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #F313CE;
  background: rgba(0, 178, 200, 0.05);
  `
export const ShowRow = styled.div`
  color: #0D0C43;
  font-weight: 600;
  letter-spacing: 0.2px;
  span {
    color: #F313CE;
    margin-left: 5px;
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`
export const AddressBlock = styled.div`
  margin-bottom: 18px;
`
import styled from "styled-components";
import { WrapperBg } from "./components/layouts/styled";

export const TooldexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 80px;
  background-color: #0c000d;
`;
export const TooldexContent = styled.div`
  width: calc(100% - 250px);
  margin-left: auto;
  ${WrapperBg} {
    padding: 0;
    &::before {
      top: 0;
      left: -290px;
    }
    &::after {
      bottom: -430px;
      right: -280px;
    }
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    margin-left: 0;
  }
`
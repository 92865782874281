import styled from "styled-components";

export const FormGenContainer = styled.div`
  position: relative;
  max-width:550px;
  margin: 0 auto;
  padding: 34px;
  background-color: #fff;
  border: 1px solid #DCE2E9;
  border-radius: 20px;
  @media screen and (max-width: 743px) {
    padding: 15px;
  }
`
export const GenWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  padding: 10px 0px;
  @media screen and (max-width: 743px) {
    display: block;
   
  }
`
export const GenQrCode = styled.div`
  width: 190px;
  height: 190px;
  @media screen and (max-width: 743px) {
    margin: 0 auto;
  }
`
export const GenInput = styled.div`
  gap: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  @media screen and (max-width: 743px) {
    gap: 15px;
  }
`
export const InputBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  input,
  .ant-input-password {
    border-radius: 10px;
  }
  .ant-input[disabled] {
    background-color: #F3F4F9;
    color: #717696;
  }
`
export const InputTitle = styled.div`
  padding: 12px 16px;
  border-radius: 10px ;
  background: #CAD2D9;
  color: rgba(13, 12, 67, 0.80);
  letter-spacing: 0.14px;
  font-weight: 500;
  white-space: nowrap;
  border: 1px solid #CAD2D9;
`
export const CopyInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 10px;
  margin-left: 10px;
  border-radius: 10px;
  background: #0c000d;
  img {
    width: 20px;
    height: 20px;
  }
`
export const CloseModal = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 20px;
  cursor: pointer;
`
import { WalGenBody, WalGenContainer } from "./styled";
import FreeGen from "./Free";

const WalletGenerator = () => {

  return (
    <WalGenContainer>
      <WalGenBody className="gen">
        <FreeGen />
      </WalGenBody>
    </WalGenContainer>
  );
};

export default WalletGenerator;

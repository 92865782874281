import { useState } from "react";
import { Wapper, ImgBox } from "./style";
import { useWeb3React } from "@web3-react/core";

import { ToastContainer, toast } from "react-toastify";

const ExampleOutput = ({ imageOutput, type }) => {
  const [fakeData, setFakeData] = useState([imageOutput]);

  return (
    <Wapper>
      <ToastContainer />
      <h1>Output</h1>
      <ImgBox style={{}} imgs={fakeData && fakeData.length > 0}>
        {fakeData && fakeData.length > 0 ? (
          <>
            {fakeData &&
              fakeData.map((item, index) => {
                return (
                  <div
                    style={{
                      width: "100%",
                    }}
                    key={index}
                  >
                    <img src={item} alt="" />
                    <label htmlFor={`imgOutput${index}`}></label>
                  </div>
                );
              })}
          </>
        ) : (
          <img src="/assets/images/png/defauARBNFT.png" alt="defauARBNFT" />
        )}
      </ImgBox>
    </Wapper>
  );
};
export default ExampleOutput;

import { RoadmapContainer, RoadmapInner, RoadmapList, RoadmapWrapper } from "./styled";
import RoadmapItem from "./Item";
import roadmap_1 from "../../../assets/images/homenew/roadmap/roadmap_1.png";
import roadmap_2 from "../../../assets/images/homenew/roadmap/roadmap_2.png";
import roadmap_3 from "../../../assets/images/homenew/roadmap/roadmap_3.png";
import roadmap_4 from "../../../assets/images/homenew/roadmap/roadmap_4.png";
import { useState } from "react";
import { CommonTitle } from "../Features/styled";

const Roadmap = () => {
  const [curentHover, setcurentHover] = useState(null);
  return (
    <RoadmapContainer>
      <RoadmapInner>
        <RoadmapWrapper>
          <CommonTitle>
            <span>Roadmap</span>
          </CommonTitle>
          <RoadmapList>
            {roadmapData.map((item, index) => {
              return (
                <RoadmapItem
                  item={item}
                  key={index}
                  setcurentHover={setcurentHover}
                  curentHover={curentHover}
                />
              );
            })}
          </RoadmapList>
        </RoadmapWrapper>
      </RoadmapInner>
    </RoadmapContainer>
  );
};

const roadmapData = [
  {
    id: 1,
    image: roadmap_1,
    title: "Q1/2024",
    describe: "Lorem Ipsum is simply dummy text of the printing",
    list: [
      "XRender AI Chat Bot",
      "UI New Version",
      "Rewards For Creators",
      "Update Marketplace V2",
      "XRender Tooldex",
    ],
  },
  {
    id: 2,
    image: roadmap_2,
    title: "Q2/2024",
    describe: "Lorem Ipsum is simply dummy text of the printing",
    list: [
      "XRender Snip Bot",
      "XRender Airdrop Bot",
      "Integrated PFP",
      "Integrate New Chain",
    ],
  },
  {
    id: 3,
    image: roadmap_3,
    title: "Q3/2024",
    describe: "Lorem Ipsum is simply dummy text of the printing",
    list: [
      "XRender Metaverse",
      "Swap NFT Cross-chain",
      "Update Bot Feature",
      "XRender Tooldex V2",
    ],
  },
  {
    id: 4,
    image: roadmap_4,
    title: "Q4/2024",
    describe: "Lorem Ipsum is simply dummy text of the printing",
    list: [
      "Update AI Bot Model",
      "Public API Powerful",
      "Integrate Tooldex",
    ],
  },
];

export default Roadmap;

import styled from "styled-components";
import KeyvisualX from "./HomeNew/KeyvisualX";
import UseCase from "./HomeNew/UseCase";
import Bonus from "./HomeNew/Bonus";
import EcoSystem from "./HomeNew/EcoSystem";
import Marketplace from "./HomeNew/Marketplace";
import Features from "./HomeNew/Features";
import Roadmap from "./HomeNew/Roadmap";
import OurTeam from "./HomeNew/OurTeam";
import Partner from "./HomeNew/Partner";
import Faq from "./HomeNew/Faq";
import block_bg from "../assets/images/homenew/usecase/block_bg.png";
import mesh_bg from "../assets/images/homenew/ecosystem/mesh_bg.png";
import block_bg_sp from "../assets/images/homenew/usecase/sp/block_bg.png";
import mesh_bg_sp from "../assets/images/homenew/ecosystem/sp/mesh_bg.png";
import meshour_bg_sp from "../assets/images/homenew/ecosystem/sp/meshour_bg.png";

const Home05 = () => {
  return (
    <HomeContainer>
      <KeyvisualX />
      <CombineWrapper>
        <EcoSystem />
        <Bonus />
      </CombineWrapper>
      <BgMesh>
        <UseCase />
        <Marketplace />
      </BgMesh>
      <Features />
      <Roadmap />
      <BgWave>
        <OurTeam />
        <Partner />
        <Faq />
      </BgWave>
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  background-color: #0d000c;
  padding-top: 79px;
`;
const CombineWrapper = styled.div`
  position: relative;
  max-width: 1728px;
  padding: 0px 20px;
  margin: auto;
  &::before {
    content: "";
    position: absolute;
    top: -600px;
    left: 50%;
    background: url(${block_bg}) no-repeat center / 100% auto;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
    animation: opacity 5s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  @keyframes opacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1440px) {
  padding: 0px 20px;
  }
  @media screen and (max-width: 919px) {
  padding: 0px 20px;
  }
  @media screen and (max-width: 767px) {
    &::before {
      top: unset;
      bottom: -200px;
      background-image: url(${block_bg_sp});
    }
    &::after {
      content: "";
      position: absolute;
      top: -200px;
      left: 0;
      background: url(${mesh_bg_sp}) no-repeat center / 100% auto;
      width: 100%;
      height: 100%;
      mix-blend-mode: difference;
      opacity: .8;
    }
  }
`;
const BgMesh = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url(${mesh_bg}) no-repeat center / 100% auto;
    width: 100%;
    height: 100%;
    mix-blend-mode: difference;
    opacity: .8;
  }
  @media screen and (max-width: 767px) {
    &::before {
      top: -200px;
      background-image: url(${meshour_bg_sp});
    }
  }
`
const BgWave = styled.div`
  position: relative;
`

export default Home05;

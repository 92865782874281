import InputCommon from "../../input";
import SelectCommon from "../../selectInput";
import { ButtonCommon, TextCommon, TextCustom, TitleKey } from "../../styled";
import { ButtonAction, NetworkHeader } from "../selectNetwork/styled";
import {
  InfoBody,
  InfoContent,
  InputRow,
  RowHeader,
  TokenInfoContainer,
  SelectContainer,
} from "./styled";
import React, { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import arrow from "../../../../assets/images/arrow.svg";

const TokenInfo = ({ setStep }: any) => {
  const { Option } = Select;
  const nameTokenLocal = localStorage.getItem("tokenName");
  const symbolTokenLocal = localStorage.getItem("tokenSymbol");
  const totalSupplyLocal = localStorage.getItem("tokenSupply");
  const decimalsTokenLocal = localStorage.getItem("tokenDecimals");

  const [nameToken, setNameToken] = useState<any>("");
  const [symbolToken, setSymbolToken] = useState<any>("");
  const [totalSupply, setTotalSupply] = useState<any>("");
  const [decimalsToken, setDecimalsToken] = useState<any>("18");

  function handleSellectDecimals(value: any) {
    setDecimalsToken(value);
  }

  useEffect(() => {
    if (nameTokenLocal || symbolTokenLocal|| totalSupplyLocal || decimalsTokenLocal) {
      setNameToken(localStorage.getItem("tokenName"));
      setSymbolToken(localStorage.getItem("tokenSymbol"));
      setTotalSupply(localStorage.getItem("tokenSupply"));
      setDecimalsToken(localStorage.getItem("tokenDecimals"));
    }
  }, [nameTokenLocal, symbolTokenLocal, totalSupplyLocal, decimalsTokenLocal]);
  
  const saveData = async () => {
    try {
      window.localStorage.setItem("tokenName", nameToken);
      window.localStorage.setItem("tokenSymbol", symbolToken);
      window.localStorage.setItem("tokenSupply", totalSupply);
      window.localStorage.setItem("tokenDecimals", decimalsToken);
      setStep(3);
    } catch (e: any) {
      console.error(e.message);
    }
  };

  return (
    <TokenInfoContainer>
      <InfoBody>
        <NetworkHeader>
          <TextCustom color="#0D0C43" fontSize="24px" fontWeight="bold">
            Enter token info
          </TextCustom>
          <TextCommon>Please provide the following information:</TextCommon>
        </NetworkHeader>
        <InfoContent>
          <InputRow>
            <RowHeader>
              <TitleKey>Token Name</TitleKey>
            </RowHeader>
            <InputCommon
              placeHolder="Tooldex Token"
              value={nameToken}
              onChange={(e: any) => setNameToken(e.target.value)}
            />
          </InputRow>
          <InputRow>
            <RowHeader>
              <TitleKey>Symbol</TitleKey>
              <span>Ex:TD</span>
            </RowHeader>
            <InputCommon
              value={symbolToken}
              onChange={(e: any) => setSymbolToken(e.target.value)}
            />
          </InputRow>
          <InputRow>
            <RowHeader>
              <TitleKey>Total Supply</TitleKey>
              <span>Ex:1000000000</span>
            </RowHeader>
            <InputCommon
              value={totalSupply}
              onChange={(e: any) => setTotalSupply(e.target.value)}
            />
          </InputRow>
          <InputRow>
            <RowHeader>
              <TitleKey>Decimals</TitleKey>
            </RowHeader>

            <SelectContainer>
              <Select
                suffixIcon={<img src={arrow} alt="arrow" />}
                placeholder="Please sellect decimals"
                defaultValue="18"
                onChange={handleSellectDecimals}
                style={{ width: "100%" }}
              >
                {data.map((item: any, index: number) => (
                  <Option key={index} value={item.value}>
                    <div>{item.title}</div>
                  </Option>
                ))}
              </Select>
            </SelectContainer>
          </InputRow>
        </InfoContent>
        <ButtonCommon
          onClick={() => saveData()}
          style={{ margin: "0 auto" }}
          background={"#F313CE"}
          textColor={"#fff"}
          disabled={
            !nameToken || !symbolToken || !Number(totalSupply) || !decimalsToken
          }
        >
          Comfirm
        </ButtonCommon>
      </InfoBody>
      <ButtonAction>
        <ButtonCommon
          style={{
            border: "2px solid #F313CE",
            padding: "5px 20px",
            width: "auto",
            fontWeight: "600",
          }}
          background="transparent"
          textColor="#F313CE"
          onClick={() => setStep(1)}
        >
          Prev
        </ButtonCommon>
        <ButtonCommon
          style={{
            border: (nameToken && symbolToken&& totalSupply && decimalsToken) && ("2px solid #F313CE"),
            padding: "5px 20px",
            width: "auto",
            fontWeight: "600",
          }}
          background="transparent"
          textColor="#F313CE"
          onClick={() => saveData()}
          disabled={!nameToken || !symbolToken|| !totalSupply || !decimalsToken}
        >
          Next
        </ButtonCommon>
      </ButtonAction>
    </TokenInfoContainer>
  );
};
const data = [
  { title: "Decimals 18", value: "18" },
  { title: "Decimals 12", value: "12" },
  { title: "Decimals 9", value: "9" },
  { title: "Decimals 6", value: "6" },
];
export default TokenInfo;

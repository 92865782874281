import {
  PartnerContainer,
  PartnerInner,
  PartnerItem,
  PartnerList,
  PartnerWrapper,
} from "./styled";
import partner_1 from "../../../assets/images/homenew/partner/partner_1.png";
import partner_2 from "../../../assets/images/homenew/partner/partner_2.png";
import partner_3 from "../../../assets/images/homenew/partner/partner_3.png";
import partner_4 from "../../../assets/images/homenew/partner/partner_4.png";
import partner_5 from "../../../assets/images/homenew/partner/partner_5.png";
import partner_6 from "../../../assets/images/homenew/partner/partner_6.png";
import partner_7 from "../../../assets/images/homenew/partner/partner_7.png";
import partner_8 from "../../../assets/images/homenew/partner/partner_8.png";
import partner_9 from "../../../assets/images/homenew/partner/partner_9.png";
import partner_10 from "../../../assets/images/homenew/partner/partner_10.png";
import partner_11 from "../../../assets/images/homenew/partner/partner_11.png";
import partner_12 from "../../../assets/images/homenew/partner/partner_12.png";
import partner_13 from "../../../assets/images/homenew/partner/partner_13.png";
import partner_14 from "../../../assets/images/homenew/partner/partner_14.png";
import partner_15 from "../../../assets/images/homenew/partner/partner_15.png";
import partner_16 from "../../../assets/images/homenew/partner/partner_16.png";
import partner_17 from "../../../assets/images/homenew/partner/partner_17.png";
import partner_18 from "../../../assets/images/homenew/partner/partner_18.png";
import partner_19 from "../../../assets/images/homenew/partner/partner_19.png";
import partner_20 from "../../../assets/images/homenew/partner/partner_20.png";
import Marquee from "react-fast-marquee";
import { CommonTitle } from "../Features/styled";

const Partner = () => {
  return (
    <PartnerContainer>
      <PartnerInner>
        <PartnerWrapper>
        <CommonTitle>
          <span>Partners & Investors</span>
        </CommonTitle>
          <PartnerList>
            <Marquee direction="left">
              {partnerData_1.map((item, index) => {
                return (
                  <PartnerItem key={index}>
                    <img src={item.img} alt="logo" />
                  </PartnerItem>
                );
              })}
            </Marquee>
          </PartnerList>
          <PartnerList>
            <Marquee direction="right">
              {partnerData_2.map((item, index) => {
                return (
                  <PartnerItem key={index}>
                    <img src={item.img} alt="logo" />
                  </PartnerItem>
                );
              })}
            </Marquee>
          </PartnerList>
          <PartnerList>
            <Marquee direction="left">
              {partnerData_3.map((item, index) => {
                return (
                  <PartnerItem key={index}>
                    <img src={item.img} alt="logo" />
                  </PartnerItem>
                );
              })}
            </Marquee>
          </PartnerList>
        </PartnerWrapper>
      </PartnerInner>
    </PartnerContainer>
  );
};

const partnerData_1 = [
  {
    img: partner_1,
    href: "#",
  },
  {
    img: partner_2,
    href: "#",
  },
  {
    img: partner_3,
    href: "#",
  },
  {
    img: partner_4,
    href: "#",
  },
  {
    img: partner_5,
    href: "#",
  },
  {
    img: partner_6,
    href: "#",
  },
  {
    img: partner_7,
    href: "#",
  },
];

const partnerData_2 = [
  {
    img: partner_8,
    href: "#",
  },
  {
    img: partner_9,
    href: "#",
  },
  {
    img: partner_10,
    href: "#",
  },
  {
    img: partner_11,
    href: "#",
  },
  {
    img: partner_12,
    href: "#",
  },
  {
    img: partner_13,
    href: "#",
  },
  {
    img: partner_14,
    href: "#",
  },
];

const partnerData_3 = [
  {
    img: partner_15,
    href: "#",
  },
  {
    img: partner_16,
    href: "#",
  },
  {
    img: partner_17,
    href: "#",
  },
  {
    img: partner_18,
    href: "#",
  },
  {
    img: partner_19,
    href: "#",
  },
  {
    img: partner_20,
    href: "#",
  },
];

export default Partner;

import { useContext } from "react";
import { HeaderNavigationContainer, NavigationWrapper } from "../styled";
import { NavLink } from "react-router-dom";
import { ModalConfirmContext } from "../../ProviderPopUp/confirm";

const HeaderNavigation = ({ isTooldexPath }: any) => {
  const {isDesktop} = useContext(ModalConfirmContext)!
  return (
    <HeaderNavigationContainer>
      <NavigationWrapper>
        {navigationData.map((item, index) => {
          return (
            <>
              {item.externalLink ? (
                <>
                  <a
                    style={{
                      color: isDesktop && isTooldexPath ? "#000" : "#fff",
                    }}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.text}
                  </a>
                </>
              ) : (
                <>
                  <NavLink
                    style={{
                      color: isDesktop && isTooldexPath ? "#000" : "#fff",
                    }}
                    key={index}
                    to={item.link}
                  >
                    {item.text}
                  </NavLink>
                </>
              )}
            </>
          );
        })}
      </NavigationWrapper>
    </HeaderNavigationContainer>
  );
};

const navigationData = [
  {
    link: "/marketplace",
    text: "Marketplace",
    externalLink: false,
  },
  {
    link: "/nft-ai",
    text: "AI Tool",
    externalLink: false,
  },
  {
    link: "/tooldex/portfolio",
    text: "Tooldex",
    externalLink: false,
  },
  {
    link: "/mint-nft",
    text: "Mint NFT",
    externalLink: false,
  },
  {
    link: "/staking",
    text: "Staking",
    externalLink: false,
  },
  {
    link: "https://docs.xrender.ai/",
    text: "Docs",
    externalLink: true,
  },
];

export default HeaderNavigation;

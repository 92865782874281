import styled from "styled-components";

export const WrapperLogo = styled.div`
  a {
    color: #0d0c43;
    font-size: 20.992px;
    font-weight: 900;
    letter-spacing: 1.784px;
    span {
      color: #F313CE;
    }
  }
`;
export const ButtonCommon = styled.a<{
  background?: string,
  textColor?: string,
  disabled?: boolean,
}>`
  display: flex;
  width: 150px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${({ disabled, background }) =>
    disabled ? "#CFD0D6" : background || "linear-gradient(90deg,#6650ff 0%,#9818aa 100%)"};
  color: ${({ disabled, textColor }) =>
    disabled ? "rgba(13, 12, 67, 0.40)" : textColor || "#fff"} !important;
  font-size: 16px;
  font-weight: 400;
  pointer-events: ${({ disabled }) => disabled && "none"};
  &:hover {
    color: ${({ disabled, textColor }) =>
      disabled ? "rgba(13, 12, 67, 0.40)" : textColor || "#fff"};
  }
  @media screen and (max-width: 743px) {
    font-size: 14px;
  }
`;
export const TitleCommon = styled.h2<{ color?: string }>`
  color: ${({ color }) => color};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.4px;
  @media screen and (max-width: 743px) {
    font-size: 35px;
  }
`;
export const TextCommon = styled.p`
  color: rgba(13, 12, 67, 0.7);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-bottom: 0;
`;
export const TextCustom = styled.p<{
  fontSize?: string;
  color?: string;
  fontWeight?: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin-bottom: 0;
`;
export const WrapperContainer = styled.div`
  max-width: 1258px;
  margin: 0 auto;
  padding: 0 60px;
  @media screen and (max-width: 1023px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
export const TableCommon = styled.div`
  th,
  td {
    font-weight: 600;
    padding: 15px 10px !important;
  }
  .ant-table-thead {
    th {
      background: #F3E0F4;
      border-bottom: 1px solid #dce2e9;
      border-top: 1px solid #dce2e9;
      &::before {
        content: none !important;
      }
      &:first-child {
        text-align: center;
        border-left: 1px solid #dce2e9;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
      &:last-child {
        border-right: 1px solid #dce2e9;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }
  }
  .ant-table-tbody {
    td {
      border-bottom: 0;
      &:first-child {
        text-align: center;
      }
    }
  }
  .text-strong {
    font-weight: bold;
  }
  .color-orange {
    color: #e68d07;
  }
  .color-green {
    color: #F313CE;
  }
  .ant-pagination {
    .ant-pagination-item-link {
      border: none;
    }
  }
  .ant-pagination-item-active {
    border-radius: 6px;
    border: 1px solid rgba(214, 217, 220, 0);
    background-color: #e9ecef;
    a {
      color: #F313CE;
    }
  }
  .ant-table-pagination-right {
    justify-content: center;
  }
  .ant-table-container {
    &::after {
      box-shadow: none !important;
    }
  }
  @media screen and (max-width: 1450px) {
    .ant-table-content {
      overflow-x: auto;
    }
    .ant-table-wrapper {
      table {
        min-width: max-content;
      }
    }
  }
`;
export const TitleKey = styled.div`
  color: #0d0c43;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.15px;
  span {
    margin-left: 5px;
  }
`;
export const TextAreaCommon = styled.div`
  textarea.ant-input {
    padding: 15px;
    border-radius: 10px;
    background-color: rgba(13, 12, 67, 0.03);
    border-color: #cad2d9;
    color: #717696;
    &:hover {
      border-color: #F313CE;
    }
  }
  .ant-input-focused,
  .ant-input:focus {
    box-shadow: none;
    border-color: #F313CE;
  }
`;
export const RadioCommon = styled.div`
  .ant-radio-wrapper {
    font-weight: bold;
    margin-right: 15px;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #F313CE;
    box-shadow: none;
  }
  .ant-radio-inner:after {
    background-color: #F313CE;
  }
  .ant-radio-wrapper-checked {
    color: #F313CE;
  }
`;
export const SldierRange = styled.div`
  margin-top: 35px;
  .ant-slider-mark {
    top: -25px;
  }
  .ant-slider-rail {
    background-color: rgba(13, 12, 67, 0.24);
  }
  .ant-slider-handle {
    width: 16px;
    height: 16px;
    background-color: #F313CE;
    border: 2px solid #fff;
    filter: drop-shadow(0px 1px 4px rgba(13, 12, 67, 0.31));
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #fff;
  }
  .ant-slider-step {
    display: none;
  }
  .ant-slider-track,
  .ant-slider:hover .ant-slider-track {
    background-color: #F313CE;
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: rgba(13, 12, 67, 0.24);
  }
  .ant-slider-rail,
  .ant-slider-track {
    border-radius: 100px;
    height: 6px;
  }
`;
export const DatePickerCommon = styled.div`
  .ant-picker {
    width: 100%;
    padding: 12px 14px;
    border-radius: 10px;
    border: 1px solid #cad2d9;
  }
  .ant-picker-focused {
    box-shadow: none;
  }
  .ant-picker-focused,
  .ant-picker:hover {
    border-color: #F313CE;
  }
`;
export const OpenMenuMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 38px;
  height: 38px;
  border-radius: 8px;
  background: #344054;
  cursor: pointer;
  margin-left: 10px;
  span {
    position: relative;
    width: 20px;
    height: 2px;
    background-color: #fff;
    border-radius: 10px;
    transition: transform 0.25s;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  &.open {
    span:nth-child(1) {
      top: 3px;
      transform: rotateZ(45deg);
    }
    span:nth-child(2) {
      top: -4px;
      transform: rotateZ(-45deg);
    }
  }
`;
export const V2Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .add-button {
    min-width: 344px;
    margin-left: auto;
  }
  .action-button {
    min-width: 180px;
    padding: 10px;
    border: 1px solid #0d0c43;
    gap: 10px;
  }
  @media screen and (max-width: 743px) {
    ${ButtonCommon} {
      min-width: auto;
      margin-left: 0;
      width: auto;
    }
  }
`;

import styled from "styled-components";
import partner_before from "../../../assets/images/homenew/partner/partner_before.png";
import partner_before_sp from "../../../assets/images/homenew/partner/sp/partner_before.png";
import { CommonTitle } from "../Features/styled";

export const PartnerContainer = styled.div`
  position: relative;
  padding-bottom: 100px;
`;
export const PartnerWrapper = styled.div`
  position: relative;
  border-radius: 15px;
  background: rgba(18, 18, 18, 0.82);
  box-shadow: 0px 0px 0px 0.688px rgba(255, 255, 255, 0.1),
    0px 0px 0px 1.375px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(34px);
  padding: 20px 0 60px;
  &:hover {
    ${CommonTitle} {
      span {
        &:nth-child(1) {
          color: #fff;
          opacity: 1;
          text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 0px #fff;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
    ${CommonTitle} {
      span {
        &:nth-child(1) {
          font-size: 34px;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: 374px;
      height: 102px;
      top: 0;
      left: 50%;
      margin-left: -187px;
      background: #6c2b67;
      filter: blur(45px);
      z-index: -1;
    }
  }
`;
export const PartnerInner = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -230px;
    left: 50%;
    background: url(${partner_before}) no-repeat center / 100% auto;
    width: 1334px;
    height: 594px;
    margin-left: -667px;
    z-index: 0;
  }
  @media screen and (max-width: 1440px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 767px) {
    &::before {
      background-image: url(${partner_before_sp});
      width: 185px;
      height: 219px;
      left: 50%;
      margin-left: -93px;
      top: -93px;
    }
  }
`;
export const PartnerList = styled.ul`
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;
export const PartnerItem = styled.div`
  width: 100%;
  max-width: 281px;
  margin: 0 30px;
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    max-width: 202px;
    margin: 0 10px;
  }
`;

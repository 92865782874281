/* eslint-disable react-hooks/rules-of-hooks */

const switchNetworkChain = async (chainId: any, value: any) => {
  const provider: any = (window as Window).ethereum;
  if (provider) {
    try {
      if (provider?.selectedAddress && chainId) {
        await provider
          .request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId,
              },
            ],
          })
          .then((res: any) => {
            localStorage.setItem("chainName", value);
          });
      }
    } catch (switchError: any) {
      try {
        if (chainId === "0x1") {
          // ethereum
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "Ethereum Chain Mainnet",
                chainId: "0x1",
                rpcUrls: ["https://mainnet.infura.io/v3"],
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://etherscan.io/"],
              },
            ],
          });
        } else if (chainId === "0x89") {
          // polygon
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "Polygon Chain Mainnet",
                chainId: "0x89",
                rpcUrls: ["https://polygon-rpc.com"],
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://polygonscan.com/"],
              },
            ],
          });
        } else if (chainId === "0xfa") {
          // fantom
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "Fantom Chain Mainnet",
                chainId: "0xfa",
                rpcUrls: ["https://rpc.ftm.tools"],
                nativeCurrency: {
                  name: "FTM",
                  symbol: "FTM",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://ftmscan.com/"],
              },
            ],
          });
        } else if (chainId === "0xa86a") {
          // avax
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "Avax Chain Mainnet",
                chainId: "0xa86a",
                rpcUrls: ["https://avalanche.public-rpc.com"],
                nativeCurrency: {
                  name: "AVAX",
                  symbol: "AVAX",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://avascan.info/"],
              },
            ],
          });
        } else if (chainId === "0xa4b1") {
          // arbitrum
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "Arbitrum One",
                chainId: "0xa4b1",
                rpcUrls: ["https://arb1.arbitrum.io/rpc"],
                nativeCurrency: {
                  name: "Arbitrum One",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://arbiscan.io/"],
              },
            ],
          });
        } else if (chainId === "0xcc") {
          // opbnb
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "opBNB Chain Mainnet",
                chainId: "0xcc",
                rpcUrls: ["https://opbnb-mainnet-rpc.bnbchain.org/"],
                nativeCurrency: {
                  name: "opBNB Chain Mainnet",
                  symbol: "BNB",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://opbnbscan.com/"],
              },
            ],
          });
        } else if (chainId === "0x2105") {
          // base
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "Base Chain Mainnet",
                chainId: "0x2105",
                rpcUrls: ["https://base.publicnode.com"],
                nativeCurrency: {
                  name: "Base Chain Mainnet",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://basescan.org/"],
              },
            ],
          });
        } else if (chainId === "0x61") {
          //bnb testnet
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "BNB Testnet",
                chainId: "0x61",
                rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://testnet.bscscan.com/"],
              },
            ],
          });
        } else {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "BNB Chain Mainnet",
                chainId: "0x38",
                rpcUrls: ["https://bsc-dataseed.binance.org"],
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://bscscan.com/"],
              },
            ],
          });
        }
        return false;
      } catch (addError) {
        console.error(addError);
        return false;
      }
      // console.error(switchError);
      // return false;
    }
  } else {
    return false;
  }
};

export default switchNetworkChain;

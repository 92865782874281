import styled from "styled-components";
import { CommonTitle } from "../Features/styled";
import before from "../../../assets/images/homenew/ecosystem/title_before.png";

export const EcoSystemContainer = styled.div`
position: relative;
margin-bottom: 60px;
z-index: 2;
&::before {
  content: "";
  z-index: 50;
  height: 100px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0) 92%);
  inset: auto 0% 0%;
  top: -10vw;
  left: 50%;
  width: 100vw;
  margin-top: -2px;
  position: absolute;
  transform: translateX(-50%) rotate(180deg);
}
&:hover {
  ${CommonTitle} {
    span {
      &:nth-child(1) {
        color: #fff;
        opacity: 1;
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 0px #fff;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  &::before {
    top: -90px;
  }
}
`;
export const EcoSystemWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 10px;
  ${CommonTitle} {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -50px;
      left: 50%;
      background: url(${before}) no-repeat center / 100% auto;
      width: 50px;
      height: 56px;
      margin-left: -25px;
      animation: rotate 5s cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
      animation-delay: 2s;
      @keyframes rotate {
        0% {
          transform: rotate(0);
        }
        50% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(0);
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    ${CommonTitle} {
      &::before {
        top: -60px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    ${CommonTitle} {
      &::before {
        top: -70px;
        animation: rorate 3s infinite;
      }
    }
  }
  @keyframes rorate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0);
    }
  }
`;
export const EcoSystemNumber = styled.p`
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 0;
`;
export const EcoSystemTitle = styled.h3`
  color: #eaecf0;
  font-size: 19.5px;
  font-weight: 500;
  margin-bottom: 0;
`;
export const EcoSystemDescribe = styled.p`
  color: #98a2b3;
  font-size: 12px;
`;
export const EcoSystemList = styled.ul`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 45px;
  margin-bottom: 0;
  & > li {
    width: 25%;
    p {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 25px;
    & > li {
      width: calc(50% - 15px);
    }
  }
  @media screen and (max-width: 440px) {
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 25px;
    & > li {
      width: 100%;
    }
  }
`;

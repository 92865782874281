import styled from "styled-components";

const Arr1 = [
  "/assets/images/bn-3.jpg",
  "/assets/images/bn-2.jpg",
  "/assets/images/bn-12.jpg",
  "/assets/images/slider/img2.png",
  "/assets/images/bn-4.jpg",
  "/assets/images/bn-5.jpg",
  "/assets/images/bn-6.jpg",
  "/assets/images/bn-12.jpg",
  "/assets/images/bn-4.jpg",
  "/assets/images/bn-9.jpg",
];

export const SliderMarketPlace = () => {
  return (
    <SliderBox>
      <SliderWapper>
        <SliderContainer>
          <SliderFloor>
            <SliderItem>
              {Arr1.map((img, index) => {
                return (
                  <img
                    style={{
                      borderRadius: "8px",
                    }}
                    key={`${img}${index}`}
                    src={img}
                    alt=""
                  />
                );
              })}
            </SliderItem>
            <SliderItem>
              {Arr1.map((img, index) => {
                return (
                  <img
                    style={{
                      borderRadius: "8px",
                    }}
                    key={`${img}${index}`}
                    src={img}
                    alt=""
                  />
                );
              })}
            </SliderItem>
            <SliderItem>
              {Arr1.map((img, index) => {
                return (
                  <img
                    style={{
                      borderRadius: "8px",
                    }}
                    key={`${img}${index}`}
                    src={img}
                    alt=""
                  />
                );
              })}
            </SliderItem>
          </SliderFloor>
        </SliderContainer>
        <SliderContainer>
          <SliderFloor>
            <SliderItem className="rever">
              {Arr1.map((img, index) => {
                return (
                  <img
                    style={{
                      borderRadius: "8px",
                    }}
                    key={`${img}${index}`}
                    src={img}
                    alt=""
                  />
                );
              })}
            </SliderItem>
            <SliderItem className="rever">
              {Arr1.map((img, index) => {
                return (
                  <img
                    style={{
                      borderRadius: "8px",
                    }}
                    key={`${img}${index}`}
                    src={img}
                    alt=""
                  />
                );
              })}
            </SliderItem>
            <SliderItem className="rever">
              {Arr1.map((img, index) => {
                return (
                  <img
                    style={{
                      borderRadius: "8px",
                    }}
                    key={`${img}${index}`}
                    src={img}
                    alt=""
                  />
                );
              })}
            </SliderItem>
          </SliderFloor>
        </SliderContainer>
      </SliderWapper>
    </SliderBox>
  );
};

const SliderBox = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;
const SliderWapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 130px;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
`;
const SliderContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  max-width: 2560px;
  margin: 0px auto;
`;
const SliderFloor = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  min-height: 90px;
  position: relative;
  width: 100%;
  min-width: 1708px;
  transform-style: preserve-3d;
  .rever {
    animation: SliderAnimation2 30s linear infinite;
    left: 0px;
    transform-style: preserve-3d;
    :first-child {
      animation-delay: -10s;
    }
    :last-child {
      animation-delay: 10s;
    }
    @keyframes SliderAnimation2 {
      0% {
        opacity: 1;
        transform: translateX(-100%);
      }
      33% {
        opacity: 1;
        transform: translateX(0%);
      }
      66% {
        opacity: 1;
        transform: translateX(100%);
      }
      67% {
        opacity: 0;
        transform: translateX(100%);
      }
      68% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }
`;
const SliderItem = styled.div`
  display: flex;
  gap: 12px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  transform: translateX(100%);
  animation: SliderAnimation 30s linear infinite;
  transform-style: preserve-3d;
  :first-child {
    animation-delay: -10s;
  }
  :last-child {
    animation-delay: 10s;
  }
  img {
    width: calc((100% - 108px) / 10);
    min-width: 160px;
  }
  @keyframes SliderAnimation {
    0% {
      opacity: 1;
      transform: translateX(100%);
    }
    33% {
      opacity: 1;
      transform: translateX(0%);
    }
    66% {
      opacity: 1;
      transform: translateX(-100%);
    }
    67% {
      opacity: 0;
      transform: translateX(-100%);
    }
    68% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

import moment from "moment";

export const formatDate = (timer) => {
  let dateFormat = new Date(timer).toLocaleTimeString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "UTC",
    timeZoneName: "short",
    hour12: true,
  });

  dateFormat = dateFormat.replace(",", "");
  dateFormat = dateFormat.replace("UTC", "");

  return dateFormat;
};

export const formatUTCDate = (time) => {
  return moment(new Date(time).toUTCString()).utcOffset(0).utc();
};

export const convertDateTime = (dateTime) => {
  var options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
    timeZoneName: "short",
    hour12: true,
  };

  return dateTime.toLocaleTimeString("en-GB", options);
};

export const formatAddress = (address, before, after) => address && 
  `${address.substring(0, before || 4)}.....${address.substring(address.length - after || 6)}`;

import React, { useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";

import {
  ButtonCommon,
  TextCustom,
  TitleKey,
  TitleCommon,
  WrapperContainer,
} from "../../../../components/common/styled";
import { notiError, notiSuccess, notiWarning } from "../../../../utils/Tooldex/notication";
import uncheck from "../../../../assets/images/home/body/pricing/uncheck.svg";
import check from "../../../../assets/images/home/body/pricing/check.svg";
import warning from "../../../../assets/images/home/body/pricing/warning.svg";
import lock from "../../../../assets/images/lock.svg";
import Tooldex from "../../../../assets/images/Tooldex.png";
import { useMediaQuery } from "react-responsive";
import PleaseConnectWallet from "../../../../components/common/pleaseConnectWallet";
import web3 from "web3";
import download from "../../../../assets/images/common/form/downloadPDF.svg";

import {
  AuditContractContainer,
  AuditDesc,
  AuditItems,
  AuditList,
  AuditTitle,
  BodyCommon,
  BodyContainer,
  AuditContent,
  AuditTop,
  AddBlock,
  AddWrapper,
  FormContainer,
  TitleDex,
  ValueDex,
  Tag,
  AuditEmpty,
  EmptyContent,
  AuditItemsLPHolder,
  RowIcon,
  AuditBlock,
} from "./styled";
import InputCommon from "../../../../components/common/input";
import { Skeleton } from "antd";
import {
  SCAN_URL,
  NAME_NETWORK,
  LOGO_BY_CHAINID,
  API_GOPLUS_URL,
  ERROR_CONTENT,
} from "../../../../constants/Tooldex/index";
import { useAccount, useNetwork } from "wagmi";
import { getData } from "../../../../constants/Tooldex/store";
import { BsFileEarmarkCode } from "react-icons/bs";
import Loading from "../../../../components/Tooldex/Loading";
import Value from "../../../../components/Tooldex/Value";
import CurrencyLogo from "../../../../components/CurrencyLogo";

declare const window: Window & typeof globalThis & { ethereum: any };

const AuditContract = () => {
  const isMobile = useMediaQuery({ maxWidth: 743 });

  const { address } = useAccount();
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const Web3 = new web3(window.ethereum);
  const [isPending, setPending] = useState(false);
  const [tokenAddress, setTokenAddress] = useState("");
  const [auditDetails, setAuditDetails] = useState<any>();

  const handelAudit = async () => {
    if (chainId && Web3.utils.isAddress(tokenAddress)) {
      setPending(true);
      getData(
        `${API_GOPLUS_URL}/v1/token_security/${chainId}?contract_addresses=${tokenAddress}`
      )
        .then((res: any) => {
          if (res?.code === 1) {
            setAuditDetails(res?.result[tokenAddress?.toLowerCase()]);
            notiSuccess("Audit token successfully!", 5);
            setPending(false);
          } else {
            notiError(ERROR_CONTENT(res?.code), 5);
            setPending(false);
          }
        })
        .catch((error:any) => {
          console.error(error);
          notiError(error?.message, 5);
          setPending(false);
        });
    } else {
      notiWarning("Token address error, please check", 5);
    }
  };
  useEffect(() => {
    if (!tokenAddress) {
      setAuditDetails(null);
    }
  }, [tokenAddress]);

  const componentRef: any = React.useRef(null);
  const onBeforeGetContentResolve: any = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    setLoading(false);
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    setLoading(true);
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle:
      `${auditDetails?.token_symbol}_AUDIT_REPORT` || "AUDIT_REPORT",
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });
  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  return (
    <AuditContractContainer>
      <FormContainer>
        <AddBlock>
          <TitleKey>Token address</TitleKey>
          <AddWrapper>
            <InputCommon
              placeHolder="Token address"
              value={tokenAddress}
              onChange={(e: any) => setTokenAddress(e.target.value)}
            />
            <ButtonCommon
              disabled={!tokenAddress || !chainId || isPending}
              background="#fff"
              textColor="#F313CE"
              style={{
                minWidth: "140px",
                padding: "9px 20px",
                border: tokenAddress && address && "2px solid #F313CE",
              }}
              onClick={handelAudit}
            >
              <Loading status={isPending} content="Audit" />
            </ButtonCommon>
          </AddWrapper>
          <TextCustom
            style={{ marginTop: "5px", fontStyle: "italic" }}
            fontSize="14px"
            fontWeight="500"
            color="#717696"
          >
            *Please enter token contract by {NAME_NETWORK(chainId)} network
          </TextCustom>
        </AddBlock>
      </FormContainer>
      <BodyContainer ref={componentRef}>
        <WrapperContainer>
          <AuditTop>
            <TitleCommon color={"#000"}>
              <img src={Tooldex} alt="" style={{ maxWidth: "200px" }} />
            </TitleCommon>
          </AuditTop>
          <AuditContent>
            <BodyCommon>
              {isPending ? (
                <>
                  <Skeleton active paragraph={{ rows: 3 }} />
                  <Skeleton active paragraph={{ rows: 3 }} />
                </>
              ) : (
                <>
                  {!auditDetails && (
                    <>
                      <EmptyContent>
                        <div>
                          <AuditEmpty>
                            <PleaseConnectWallet text="We try our best to identify the contract information, but we cannot guarantee that the data is 100% correct! It is for reference only and does not constitute any investment advice." />
                          </AuditEmpty>
                        </div>
                      </EmptyContent>
                    </>
                  )}
                </>
              )}

              <AuditList>
                {auditDetails && (
                  <>
                    <li>
                      <AuditBlock
                        rotate={auditDetails ? "" : "0"}
                        opacity={auditDetails ? ".3" : ".2"}
                      >
                        <AuditTitle>{auditDetails?.token_symbol}</AuditTitle>
                        <AuditDesc color={"#0D0C43"}>Base Info</AuditDesc>
                        <AuditItems>
                          <li>
                            Token name{" "}
                            <span>{auditDetails?.token_name || "---"}</span>
                          </li>
                          <li>
                            Symbol{" "}
                            <span>
                              {auditDetails &&
                                Web3.utils.isAddress(tokenAddress) && (
                                  <>
                                    <CurrencyLogo address={tokenAddress} />
                                    {auditDetails?.token_symbol}
                                  </>
                                )}
                            </span>
                          </li>
                          <li>
                            Total Supply{" "}
                            <span>
                              {new Intl.NumberFormat("en-US", {
                                notation: "compact",
                                compactDisplay: "short",
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }).format(auditDetails?.total_supply || "0")}{" "}
                              {auditDetails?.token_symbol}
                            </span>
                          </li>
                          <li>
                            Chain{" "}
                            <span>
                              <img src={LOGO_BY_CHAINID(chainId)} alt="logo" />{" "}
                              {NAME_NETWORK(chainId)}
                            </span>
                          </li>
                          <li>
                            Contract address{" "}
                            {Web3.utils.isAddress(tokenAddress) &&
                              chainId &&
                              Contract(tokenAddress, chainId, isMobile)}
                          </li>

                          <li>
                            Owner address{" "}
                            {Owner(
                              auditDetails?.owner_address,
                              chainId,
                              isMobile
                            )}
                          </li>
                          <li>
                            Creator address{" "}
                            {Address(
                              auditDetails?.creator_address,
                              chainId,
                              isMobile
                            )}
                          </li>
                          <li>
                            Is open source?{" "}
                            {openSource(auditDetails?.is_open_source)}
                          </li>
                        </AuditItems>
                      </AuditBlock>
                    </li>
                    <li>
                      <AuditBlock
                        rotate={auditDetails ? "" : "0"}
                        opacity={auditDetails ? ".3" : ".2"}
                      >
                        <AuditTitle>{auditDetails?.token_symbol}</AuditTitle>
                        <AuditDesc color={"#0D0C43"}>Risk analysis</AuditDesc>
                        <AuditItems>
                          <li>
                            Buy tax{" "}
                            <span>{`${
                              Number(auditDetails?.buy_tax) * 100 || "0"
                            }%`}</span>
                          </li>
                          <li>
                            Sell tax{" "}
                            <span>{`${
                              Number(auditDetails?.sell_tax) * 100 || "0"
                            }%`}</span>
                          </li>
                          <li>
                            Is honeypot?{" "}
                            <span>
                              {SecurityAndDanger(auditDetails?.is_honeypot)}
                            </span>
                          </li>
                          <li>
                            Can lock buy?{" "}
                            <span>
                              {SecurityAndDanger(auditDetails?.cannot_buy)}
                            </span>
                          </li>
                          <li>
                            Can lock sell?{" "}
                            <span>
                              {SecurityAndDanger(auditDetails?.cannot_sell_all)}
                            </span>
                          </li>
                          <li>
                            Can edit tax?{" "}
                            <span>
                              {SecurityAndDanger(
                                auditDetails?.slippage_modifiable
                              )}
                            </span>
                          </li>
                          <li>
                            Is anti whale?{" "}
                            <span>
                              {SecurityAndDanger(
                                auditDetails?.anti_whale_modifiable
                              )}
                            </span>
                          </li>
                          <li>
                            Can take back ownership?{" "}
                            <span>
                              {SecurityAndDanger(
                                auditDetails?.can_take_back_ownership
                              )}
                            </span>
                          </li>
                          <li>
                            Is blacklisted?{" "}
                            <span>
                              {SecurityAndDanger(auditDetails?.is_blacklisted)}
                            </span>
                          </li>
                          <li>
                            Is mintable?{" "}
                            <span>
                              {SecurityAndDanger(auditDetails?.is_mintable)}
                            </span>
                          </li>
                          <li>
                            Is proxy contract?{" "}
                            <span>
                              {SecurityAndDanger(auditDetails?.is_proxy)}
                            </span>
                          </li>
                          <li>
                            Can transfer pausable?{" "}
                            <span>
                              {SecurityAndDanger(
                                auditDetails?.transfer_pausable
                              )}
                            </span>
                          </li>
                          <li>
                            Is trading with CooldownTime{" "}
                            <span>
                              {SecurityAndDanger(
                                auditDetails?.trading_cooldown
                              )}
                            </span>
                          </li>
                          <li>
                            Can Selfdestruct{" "}
                            <span>
                              {SecurityAndDanger(auditDetails?.selfdestruct)}
                            </span>
                          </li>
                        </AuditItems>
                      </AuditBlock>
                    </li>
                  </>
                )}
                <li>
                  <AuditBlock
                    rotate={auditDetails ? "" : "0"}
                    opacity={auditDetails ? ".3" : ".2"}
                  >
                    <AuditTitle>
                      DEX ({auditDetails?.dex?.length || "0"})
                    </AuditTitle>
                    <AuditDesc color={"#0D0C43"}>Top Pool Size</AuditDesc>
                    <AuditItems>
                      {auditDetails?.dex?.map((data: any, index: any) => {
                        return (
                          <>
                            <li key={index}>
                              <div style={{ display: "grid" }}>
                                <TitleDex>{data?.name}</TitleDex>
                                <ValueDex>
                                  <Value
                                    size={14}
                                    color="#F45E5E"
                                    unit="$"
                                    value={Number(data?.liquidity)}
                                  />
                                </ValueDex>
                              </div>
                              <span>
                                {Address(data?.pair, chainId, isMobile)}
                              </span>
                            </li>
                          </>
                        );
                      })}
                    </AuditItems>
                  </AuditBlock>
                </li>
                <li>
                  <AuditBlock
                    rotate={auditDetails ? "" : "0"}
                    opacity={auditDetails ? ".3" : ".2"}
                  >
                    <AuditTitle>
                      LP Holders ({auditDetails?.lp_holder_count || "0"})
                    </AuditTitle>
                    <AuditDesc color={"#0D0C43"}>Top 10 LP Holders</AuditDesc>
                    <AuditItemsLPHolder>
                      {auditDetails?.lp_holders?.map(
                        (data: any, index: any) => {
                          return (
                            <>
                              <li key={index}>
                                <div style={{ display: "grid" }}>
                                  <span>
                                    {Address(data?.address, chainId, isMobile)}{" "}
                                    {data?.tag && <Tag>{data?.tag}</Tag>}
                                  </span>
                                  <ValueDex>
                                    <Value
                                      size={14}
                                      color="#F45E5E"
                                      unitafter="%"
                                      value={Number(data?.percent) * 100}
                                    />
                                  </ValueDex>
                                </div>
                                <span>
                                  <RowIcon>
                                    {data?.is_locked === 1 && (
                                      <img src={lock} alt="" />
                                    )}
                                    {data?.is_contract === 1 && (
                                      <BsFileEarmarkCode
                                        size={"20"}
                                        color="#F313CE"
                                      />
                                    )}
                                  </RowIcon>

                                  <span style={{ display: "flex" }}>
                                    <Value
                                      size={14}
                                      fontweight="600"
                                      unitafter={auditDetails?.token_symbol}
                                      value={Number(data?.balance)}
                                    />
                                  </span>
                                </span>
                              </li>
                            </>
                          );
                        }
                      )}
                    </AuditItemsLPHolder>
                  </AuditBlock>
                </li>
                <li>
                  <AuditBlock
                    rotate={auditDetails ? "" : "0"}
                    opacity={auditDetails ? ".3" : ".2"}
                  >
                    <AuditTitle>
                      Holders ({auditDetails?.holder_count || "0"})
                    </AuditTitle>
                    <AuditDesc color={"#0D0C43"}>Top 10 Holders</AuditDesc>
                    <AuditItemsLPHolder>
                      {auditDetails?.holders?.map((data: any, index: any) => {
                        return (
                          <>
                            <li key={index}>
                              <div style={{ display: "grid" }}>
                                <span>
                                  {Address(data?.address, chainId, isMobile)}{" "}
                                  {data?.tag && <Tag>{data?.tag}</Tag>}
                                </span>
                                <ValueDex>
                                  <Value
                                    size={14}
                                    color="#F45E5E"
                                    unitafter="%"
                                    value={Number(data?.percent) * 100}
                                  />
                                </ValueDex>
                              </div>
                              <span>
                                <RowIcon>
                                  {data?.is_locked === 1 && (
                                    <img src={lock} alt="" />
                                  )}
                                  {data?.is_contract === 1 && (
                                    <BsFileEarmarkCode
                                      size={"20"}
                                      color="#F313CE"
                                    />
                                  )}
                                </RowIcon>
                                <Value
                                  size={14}
                                  fontweight="600"
                                  unitafter={auditDetails?.token_symbol}
                                  value={Number(data?.balance)}
                                />
                              </span>
                            </li>
                          </>
                        );
                      })}
                    </AuditItemsLPHolder>
                  </AuditBlock>
                </li>
              </AuditList>
            </BodyCommon>
          </AuditContent>
        </WrapperContainer>
      </BodyContainer>
      {auditDetails && (
        <AuditEmpty>
          <ButtonCommon
            disabled={loading}
            background="#fff"
            textColor="#F313CE"
            style={{
              minWidth: "140px",
              padding: "9px 20px",
              border: tokenAddress && address && "2px solid #F313CE",
            }}
            onClick={handlePrint}
          >
            {loading ? (
              <Loading status={loading} content="Export PDF" />
            ) : (
              <>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={download}
                  alt="download"
                />{" "}
                Export PDF
              </>
            )}
          </ButtonCommon>
        </AuditEmpty>
      )}
    </AuditContractContainer>
  );
};
const Owner = (value: any, chainId: any, isMobile: any) => {
  if (value === "0x0000000000000000000000000000000000000000") {
    return (
      <>
        <span>
          <img src={check} alt="" />
          <span style={{ color: "#F313CE" }}>Give up ownership</span>{" "}
        </span>
      </>
    );
  } else {
    return (
      <>
        <span>
          <a
            style={{ color: "#F313CE" }}
            href={`${SCAN_URL[chainId]}address/${value?.toLowerCase()}`}
            target="_blank"
            rel="noreferrer"
          >
            {isMobile ? (
              <u>
                {value?.substring(0, 8)}....
                {value?.substring(value?.length - 4)}
              </u>
            ) : (
              <u>{value}</u>
            )}
          </a>
        </span>
      </>
    );
  }
};
const Contract = (value: any, chainId: any, isMobile: any) => {
  if (value) {
    return (
      <>
        <span>
          <a
            style={{ color: "#F313CE" }}
            href={`${SCAN_URL[chainId]}token/${value?.toLowerCase()}`}
            target="_blank"
            rel="noreferrer"
          >
            {isMobile ? (
              <u>
                {value?.substring(0, 8)}....
                {value?.substring(value?.length - 4)}
              </u>
            ) : (
              <u>{value}</u>
            )}
          </a>
        </span>
      </>
    );
  }
};
const Address = (value: any, chainId: any, isMobile: any) => {
  if (value) {
    return (
      <>
        <span>
          <a
            style={{ color: "#F313CE" }}
            href={`${SCAN_URL[chainId]}address/${value?.toLowerCase()}`}
            target="_blank"
            rel="noreferrer"
          >
            {isMobile ? (
              <u>
                {value?.substring(0, 8)}....
                {value?.substring(value?.length - 4)}
              </u>
            ) : (
              <u>{value}</u>
            )}
          </a>
        </span>
      </>
    );
  }
};

const openSource = (value: any) => {
  switch (value) {
    case "0":
      return (
        <span>
          <img src={warning} alt="" />
          <span style={{ color: "rgba(219, 169, 72, 1)" }}>
            {" "}
            Not open source
          </span>
        </span>
      );
    case "1":
      return (
        <span>
          <img src={check} alt="" />
          <span style={{ color: "#F313CE" }}> Is open source</span>{" "}
        </span>
      );

    default:
      return (
        <span>
          <img src={warning} alt="" />
          <span style={{ color: "rgba(219, 169, 72, 1)" }}> Undefined</span>
        </span>
      );
  }
};
const SecurityAndDanger = (value: any) => {
  switch (value) {
    case "0":
      return (
        <span>
          <img src={check} alt="" />
          <span style={{ color: "#F313CE" }}> Security</span>{" "}
        </span>
      );
    case "1":
      return (
        <span>
          <img src={uncheck} alt="" />
          <span style={{ color: "#F45E5E" }}> Danger</span>
        </span>
      );

    default:
      return (
        <span>
          <img src={warning} alt="" />
          <span style={{ color: "rgba(219, 169, 72, 1)" }}> Undefined</span>
        </span>
      );
  }
};

export default AuditContract;

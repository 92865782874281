import {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";
import {
  ButtonConnect,
  HamburgerMenu,
  HamburgerSpan,
  HeaderAction,
  HeaderContainer,
  HeaderIcon,
  HeaderLogo,
  HeaderSocial,
  HeaderStickyMobile,
  HeaderText,
  HeaderWrapper,
  StickMobileAction,
  StickyMobileContent,
  StickyMobileInner,
  StickyMobileSocial,
  StickyMobileWrapper,
} from "./styled";
import HeaderNavigation from "./HeaderNavigation";
import { useLocation } from "react-router-dom";
import { ModalConfirmContext } from "../ProviderPopUp/confirm";
import { useActiveWeb3React, useConnectWallet } from "../../hooks";
import styled from "styled-components";
import { LIST_NETWORK } from "../../constants/connectors";
import { useWeb3React } from "@web3-react/core";
import changeNetwork from "../../utils/SelectNetwork";
import { getProviderOrSigner } from "../../integrateContract/contract";
import { RefreshContext } from "../../context/RefreshContext";
import useEnsName from "../../hooks/useNameENS";
import text_logo from "../../assets/images/homenew/header/text_logo.png";
import icon_logo from "../../assets/images/homenew/header/icon_logo.png";
import social_1 from "../../assets/images/common/header/social_1.svg";
import social_2 from "../../assets/images/common/header/social_2.svg";
import social_3 from "../../assets/images/common/header/social_3.svg";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useChainId } from "wagmi";
import ProfileBtn from "./Profile";
import { OpenMenuMobile } from "../common/styled";

const HeaderStyle2 = ({ isTooldexPath, setOpenToolDexMenu, openToolDexMenu }) => {
  const { isMobile, isDesktop } = useContext(ModalConfirmContext);
  const { chainId } = useWeb3React();
  const { search } = useLocation();
  const context = useWeb3React();
  const [updateChainId, setUpdateChainId] = useState(false);
  const { walletLogin } = useConnectWallet();
  const { active, error } = context;
  const [netWorkActive, setNetWorkActive] = useState();
  const { networkLocal, handleChangeNetworkLocal } = useContext(RefreshContext);
  const ENSName = useEnsName();
  const { account } = useActiveWeb3React();

  useLayoutEffect(() => {
    const hasSignedIn = Boolean(window.localStorage.getItem("accountStatus"));
    const connectorId = window.localStorage.getItem("connectorId");
    //@ts-ignore
    if (hasSignedIn && !active && !error && connectorId) {
      walletLogin(connectorId);
      setUpdateChainId(true);
    }
  }, [active, account]);

  useEffect(() => {
    let defaultNetwork = null;
    const params = search.split("?network=");
    if (params && params[1]) {
      defaultNetwork = LIST_NETWORK.find(
        (item) => item.chainId === Number(params[1])
      );
    } else {
      defaultNetwork = LIST_NETWORK.find((item) => item.chainId === chainId);
    }
    if (defaultNetwork) {
      setNetWorkActive(defaultNetwork);
    } else if (chainId) {
      handleSelectNetWork(LIST_NETWORK[0]);
    } else {
      setNetWorkActive(networkLocal);
    }
  }, [updateChainId, chainId]);

  useEffect(() => {
    if (!updateChainId) {
      setUpdateChainId(true);
    }
  }, [updateChainId]);
  const handleSelectNetWork = async (network) => {
    setNetWorkActive(network);
    if (account) {
      await changeNetwork(network);
    }

    if (!account) {
      handleChangeNetworkLocal(network);
    }
  };
  const [isVisible, setIsVisible] = useState(true);
  const handleChangeNetWorkAfterLeave = async () => {
    if (typeof document.hidden !== "undefined") {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      };
      document.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  };
  useEffect(() => {
    handleChangeNetWorkAfterLeave();
  }, []);

  useEffect(async () => {
    const provider = await getProviderOrSigner(false);
    const network = await provider.getNetwork();
    const defaultNetwork = LIST_NETWORK.find((item) => {
      return item.chainId === network.chainId;
    });
    if (defaultNetwork?.chainId) {
      setNetWorkActive(defaultNetwork);
    } else {
      setNetWorkActive(LIST_NETWORK[0]);
    }
  }, [isVisible, chainId, account]);

  const [openMobile, setOpenMobile] = useState(false);

  const [TextBtn, setTextBtn] = useState("Connect Wallet");

  useEffect(() => {
    if (account) {
      setTextBtn(ENSName);
    } else {
      setTextBtn(`Connect Wallet`);
    }
  }, [ENSName, account, active]);

  // Web3Modal + wagmi
  const { address } = useAccount();
  const chainIdNew = useChainId();
  const { open } = useWeb3Modal();
  const accountEllipsis = address
    ? `${address?.substring(0, 4)}...${address?.substring(address.length - 4)}`
    : "Connect Wallet ";

  return (
    <>
      <HeaderContainer isTooldexPath={false}>
        <HeaderWrapper
          style={{
            maxWidth: "1440px",
            padding: isMobile ? "20px 10px" : "20px",
          }}
        >
          <HeaderLogo to="/">
            <HeaderIcon>
              <img src={icon_logo} alt="logo" />
            </HeaderIcon>
            <HeaderText>
              <img src={text_logo} alt="logo" />
            </HeaderText>
          </HeaderLogo>
          {isDesktop && <HeaderNavigation isTooldexPath={false}/>}
          <HeaderAction>
            {LIST_NETWORK.map((item, index) => {
              if (chainIdNew === item.chainId) {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    key={index}
                    onClick={() => {
                      open({ view: "Networks" });
                    }}
                  >
                    <img
                      width="25px"
                      height="25px"
                      src={item.image}
                      alt={item.name}
                    />
                    <p
                      style={{
                        marginBottom: "0",
                        color: "#fff",
                        lineHeight: "1.5",
                      }}
                    >
                      {item.name}
                    </p>
                  </div>
                );
              }
            })}
            {!isMobile && (
              <ButtonConnect
                onClick={() => {
                  open();
                }}
              >
                {accountEllipsis}
              </ButtonConnect>
            )}
            <ProfileBtn isTooldexPath={isTooldexPath}/>
            {!isDesktop && isTooldexPath && (
              <>
                <OpenMenuMobile
                  className={openToolDexMenu ? "open" : "hide"}
                  onClick={() => {
                    setOpenToolDexMenu(!openToolDexMenu)
                  }}
                >
                  <span></span>
                  <span></span>
                </OpenMenuMobile>
              </>
            )}
          </HeaderAction>
        </HeaderWrapper>
      </HeaderContainer>
      {!isDesktop && (
        <HeaderStickyMobile className={openMobile ? "open" : ""}>
          <StickyMobileWrapper>
            <StickyMobileInner>
              <StickyMobileContent openMobile={openMobile}>
                <HeaderNavigation />
                <HeaderSocial>
                  <p>Social Media</p>
                  <StickyMobileSocial>
                    {stickySocial.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={item.href}>
                            <img src={item.img} alt="social" />
                          </a>
                        </li>
                      );
                    })}
                  </StickyMobileSocial>
                </HeaderSocial>
              </StickyMobileContent>
              <StickMobileAction>
                <ButtonConnect
                  onClick={() => {
                    open();
                  }}
                >
                  {accountEllipsis}
                </ButtonConnect>
                <HamburgerMenu
                  onClick={() => {
                    setOpenMobile(!openMobile);
                  }}
                >
                  <span>Navigation</span>
                  <HamburgerSpan className={openMobile ? "active" : ""}>
                    <span></span>
                    <span></span>
                  </HamburgerSpan>
                </HamburgerMenu>
              </StickMobileAction>
            </StickyMobileInner>
          </StickyMobileWrapper>
        </HeaderStickyMobile>
      )}
    </>
  );
};

const stickySocial = [
  {
    img: social_1,
    href: "#",
  },
  {
    img: social_2,
    href: "#",
  },
  {
    img: social_3,
    href: "#",
  },
];

export default HeaderStyle2;

export const SelectNetWork = styled.div`
  @media only screen and (max-width: 440px) {
    span {
      padding: 0px !important;
      height: 20px;
      ::before {
        top: 50% !important;
        left: 50% !important;
        transform: translateX(-50%) translateY(-50%) !important;
      }
    }
    p {
      display: none;
    }
  }
`;

//import useState hook to create menu collapse state
import { useState, useEffect, useContext } from "react";

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
// import { ButtonCommon, TextCustom } from "../../../components/common/styled";

import styled from "styled-components";
import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";

// Images normal
import home_icon from "../../../assets/images/aside/normal/home-icon.svg";
import sniper_icon from "../../../assets/images/aside/normal/sniper-icon.svg";
import token_icon from "../../../assets/images/aside/normal/token-icon.svg";
import creation_icon from "../../../assets/images/aside/normal/creation-icon.svg";
import lock_icon from "../../../assets/images/aside/normal/lock-icon.svg";
import market_icon from "../../../assets/images/aside/normal/market-icon.svg";
import lid_icon from "../../../assets/images/aside/normal/lid-icon.svg";
import vol_icon from "../../../assets/images/aside/normal/vol-icon.svg";
import portfolio_icon from "../../../assets/images/aside/normal/portfolio-icon.svg";
import detect_icon from "../../../assets/images/aside/normal/detect-icon.svg";
import balance_icon from "../../../assets/images/aside/normal/balance-icon.svg";
import wallet_icon from "../../../assets/images/aside/normal/wallet-icon.svg";
import multi_icon from "../../../assets/images/aside/normal/multi-icon.svg";
import collection_icon from "../../../assets/images/aside/normal/collection-icon.svg";
import audit_icon from "../../../assets/images/aside/normal/audit-icon.svg";
import lid_lock_icon from "../../../assets/images/aside/normal/lid-lock-icon.svg";
import wal_gen_icon from "../../../assets/images/aside/normal/wal-gen-icon.svg";
import mul_wal_icon from "../../../assets/images/aside/normal/mul-wal-icon.svg";
import mul_sen_icon from "../../../assets/images/aside/normal/mul-sen-icon.svg";
import token_col_icon from "../../../assets/images/aside/normal/token-col-icon.svg";

// Images active
import token_col_active from "../../../assets/images/aside/active/token-col-active.svg";
import mul_sen_active from "../../../assets/images/aside/active/mul-sen-active.svg";
import mul_wal_active from "../../../assets/images/aside/active/mul-wal-active.svg";
import wal_gen_active from "../../../assets/images/aside/active/wal-gen-active.svg";
import home_active from "../../../assets/images/aside/active/home-active.svg";
import sniper_active from "../../../assets/images/aside/active/sniper-active.svg";
import token_active from "../../../assets/images/aside/active/token-active.svg";
import creation_active from "../../../assets/images/aside/active/creation-active.svg";
import lock_active from "../../../assets/images/aside/active/lock-active.svg";
import market_active from "../../../assets/images/aside/active/market-active.svg";
import lid_active from "../../../assets/images/aside/active/lid-active.svg";
import vol_active from "../../../assets/images/aside/active/vol-active.svg";
import portfolio_active from "../../../assets/images/aside/active/portfolio-active.svg";
import detect_active from "../../../assets/images/aside/active/detect-active.svg";
import balance_active from "../../../assets/images/aside/active/balance-active.svg";
import wallet_active from "../../../assets/images/aside/active/wallet-active.svg";
import multi_active from "../../../assets/images/aside/active/multi-active.svg";
import collection_active from "../../../assets/images/aside/active/collection-active.svg";
import audit_active from "../../../assets/images/aside/active/audit-active.svg";
import lid_lock_active from "../../../assets/images/aside/active/liq-lock-active.svg";
import { DonateBanner, SidebarContainer } from "./styled";
import FormQR from "./FormQR";
import { ModalConfirmContext } from "../../ProviderPopUp/confirm";

const Menuitem = styled(MenuItem)`
  :hover {
    background-color: transparent;
  }
`;

const Sidebar = ({ openToolDexMenu, setOpenToolDexMenu }) => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");

  const { isDesktop } = useContext(ModalConfirmContext);

  const handleMenuClick = (menuId) => {
    setActiveMenu(menuId);
  };

  useEffect(() => {
    if (isTablet || isMobile) {
      setMenuCollapse(true);
    }
  }, [isTablet, isMobile]);

  return (
    <SidebarContainer
      id="header"
      style={{
        transform:
          !isDesktop && openToolDexMenu
            ? "translateY(0)"
            : isDesktop
            ? "translateY(0)"
            : "translateY(-110%)",
      }}
    >
      <ProSidebar>
        <SidebarContent>
          <Menu iconShape="square">
            <Menuitem
              icon={
                <>
                  <img
                    className="sub-icon-normal"
                    src={portfolio_icon}
                    alt="portfolio_icon"
                  />
                  <img
                    className="sub-icon-active"
                    src={portfolio_active}
                    alt="portfolio_icon"
                  />
                </>
              }
              className="sub-item-menu"
              active={activeMenu === "portfolio"}
              onClick={() => handleMenuClick("portfolio")}
            >
              Portfolio{" "}
              <Link
                to="/tooldex/portfolio"
                onClick={() => {
                  setOpenToolDexMenu(false);
                }}
              />
            </Menuitem>

            <SubMenu
              icon={
                <>
                  <img
                    className="main-icon-normal"
                    src={token_icon}
                    alt="token_icon"
                  />
                  <img
                    className="main-icon-active"
                    src={token_active}
                    alt="token_icon"
                  />
                </>
              }
              title={"Token"}
              defaultOpen
            >
              <Menuitem
                icon={
                  <>
                    <img
                      className="sub-icon-normal"
                      src={creation_icon}
                      alt="creation_icon"
                    />
                    <img
                      className="sub-icon-active"
                      src={creation_active}
                      alt="creation_icon"
                    />
                  </>
                }
                className="sub-item-menu"
                active={activeMenu === "TokenCreation"}
                onClick={() => handleMenuClick("TokenCreation")}
              >
                Token Creation
                <Link
                  to="/tooldex/createToken"
                  onClick={() => {
                    setOpenToolDexMenu(false);
                  }}
                />
              </Menuitem>

              <Menuitem
                icon={
                  <>
                    <img
                      className="sub-icon-normal"
                      src={lid_icon}
                      alt="lid_icon"
                    />
                    <img
                      className="sub-icon-active"
                      src={lid_active}
                      alt="lid_icon"
                    />
                  </>
                }
                className="sub-item-menu"
                active={activeMenu === "AddLiquidity"}
                onClick={() => handleMenuClick("AddLiquidity")}
              >
                Liquidity
                <Link
                  to="/tooldex/liquidity"
                  onClick={() => {
                    setOpenToolDexMenu(false);
                  }}
                />
              </Menuitem>
              <Menuitem
                icon={
                  <>
                    <img
                      className="sub-icon-normal"
                      src={audit_icon}
                      alt="sniper_icon"
                    />
                    <img
                      className="sub-icon-active"
                      src={audit_active}
                      alt="sniper_icon"
                    />
                  </>
                }
                className="sub-item-menu"
                active={activeMenu === "AuditContract"}
                onClick={() => handleMenuClick("AuditContract")}
              >
                Audit
                <Link
                  to="/tooldex/audit"
                  onClick={() => {
                    setOpenToolDexMenu(false);
                  }}
                />
              </Menuitem>
            </SubMenu>

            <SubMenu
              icon={
                <>
                  <img
                    className="main-icon-normal"
                    src={lock_icon}
                    alt="lock_icon"
                  />
                  <img
                    className="main-icon-active"
                    src={lock_active}
                    alt="lock_active"
                  />
                </>
              }
              title={"Tool Lock"}
            >
              <Menuitem
                icon={
                  <>
                    <img
                      className="sub-icon-normal"
                      src={lid_lock_icon}
                      alt="lid_icon"
                    />
                    <img
                      className="sub-icon-active"
                      src={lid_lock_active}
                      alt="lid_icon"
                    />
                  </>
                }
                className="sub-item-menu"
                active={activeMenu === "LockLiquidity"}
                onClick={() => handleMenuClick("LockLiquidity")}
              >
                Liquidity Lock
                <Link
                  to="/tooldex/liquidity-lockup"
                  onClick={() => {
                    setOpenToolDexMenu(false);
                  }}
                />
              </Menuitem>

              {/* <Menuitem
                icon={
                  <>
                    <img
                      className="main-icon-normal"
                      src={lock_icon}
                      alt="lock_icon"
                    />
                    <img
                      className="main-icon-active"
                      src={lock_active}
                      alt="lock_active"
                    />
                  </>
                }
                className="sub-item-menu"
                active={activeMenu === "MyLock"}
                onClick={() => handleMenuClick("MyLock")}
              >
                My Lock
                <Link
                  to="/"
                  onClick={() => {
                    setOpenToolDexMenu(false);
                  }}
                />
              </Menuitem> */}
            </SubMenu>

            <Menuitem
              icon={
                <>
                  <img
                    className="sub-icon-normal"
                    src={balance_icon}
                    alt="wallet_icon"
                  />
                  <img
                    className="sub-icon-active"
                    src={balance_active}
                    alt="wallet_icon"
                  />
                </>
              }
              className="sub-item-menu"
              active={activeMenu === "checkBalance"}
              onClick={() => handleMenuClick("checkBalance")}
            >
              Check Balance{" "}
              <Link
                to="/tooldex/checkBalance"
                onClick={() => {
                  setOpenToolDexMenu(false);
                }}
              />
            </Menuitem>

            <SubMenu
              icon={
                <>
                  <img
                    className="main-icon-normal"
                    src={market_icon}
                    alt="market_icon"
                  />
                  <img
                    className="main-icon-active"
                    src={market_active}
                    alt="market_icon"
                  />
                </>
              }
              title={"Wallet Generator"}
            >
              <Menuitem
                icon={
                  <>
                    <img
                      className="sub-icon-normal"
                      src={wal_gen_icon}
                      alt="balance_icon"
                    />
                    <img
                      className="sub-icon-active"
                      src={wal_gen_active}
                      alt="balance_icon"
                    />
                  </>
                }
                className="sub-item-menu"
                active={activeMenu === "walletGenerator"}
                onClick={() => handleMenuClick("walletGenerator")}
              >
                Wallet Generator{" "}
                <Link
                  to="/tooldex/walletGenerator"
                  onClick={() => {
                    setOpenToolDexMenu(false);
                  }}
                />
              </Menuitem>
              <Menuitem
                icon={
                  <>
                    <img
                      className="sub-icon-normal"
                      src={mul_wal_icon}
                      alt="wallet_icon"
                    />
                    <img
                      className="sub-icon-active"
                      src={mul_wal_active}
                      alt="wallet_icon"
                    />
                  </>
                }
                className="sub-item-menu"
                active={activeMenu === "walletGeneratorList"}
                onClick={() => handleMenuClick("walletGeneratorList")}
              >
                Multi Wallet Create
                <Link
                  to="/tooldex/multiWalletGenerator"
                  onClick={() => {
                    setOpenToolDexMenu(false);
                  }}
                />
              </Menuitem>
            </SubMenu>

            <Menuitem
              icon={
                <>
                  <img
                    className="sub-icon-normal"
                    src={mul_sen_icon}
                    alt="multi_icon"
                  />
                  <img
                    className="sub-icon-active"
                    src={mul_sen_active}
                    alt="multi_icon"
                  />
                </>
              }
              className="sub-item-menu"
              active={activeMenu === "MultiSender"}
              onClick={() => handleMenuClick("MultiSender")}
            >
              MultiSender{" "}
              <Link
                to="/tooldex/multiSender"
                onClick={() => {
                  setOpenToolDexMenu(false);
                }}
              />
            </Menuitem>
            <Menuitem
              icon={
                <>
                  <img
                    className="sub-icon-normal"
                    src={token_col_icon}
                    alt="collection_icon"
                  />
                  <img
                    className="sub-icon-active"
                    src={token_col_active}
                    alt="collection_icon"
                  />
                </>
              }
              className="sub-item-menu"
              active={activeMenu === "tokenCollection"}
              onClick={() => handleMenuClick("tokenCollection")}
            >
              Token Collection{" "}
              <Link
                to="/tooldex/tokenCollection"
                onClick={() => {
                  setOpenToolDexMenu(false);
                }}
              />
            </Menuitem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </SidebarContainer>
  );
};

export default Sidebar;

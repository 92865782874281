import { FaqContainer, FaqLeft, FaqList, FaqRight, FaqWrapper } from "./styled";
import FaqItem from "./Item";
import { CommonTitle } from "../Features/styled";

const Faq = () => {
  return (
    <FaqContainer>
      <FaqWrapper>
        <FaqLeft>
          <CommonTitle>
            <span>FAQ</span>
          </CommonTitle>
          <p>
            Welcome to XRender's Frequently Asked Questions (FAQ) section! Here,
            we've curated a comprehensive list of answers to address your
            queries and provide clarity on various aspects of XRender.
          </p>
        </FaqLeft>
        <FaqRight>
          <FaqList>
            {faqData.map((item, index) => {
              return <FaqItem item={item} key={index} />;
            })}
          </FaqList>
        </FaqRight>
      </FaqWrapper>
    </FaqContainer>
  );
};

const faqData = [
  {
    id: 1,
    title: "What is XRender and how does it operate?",
    content:
      "XRender is a combined AI and blockchain ecosystem. It utilizes artificial intelligence to provide applications such as AI Bot, NFT Marketplace, and various decentralized tools.",
  },
  {
    id: 2,
    title: "How do I use XRender Tooldex?",
    content:
      "To use XRender Tooldex, you can access the interface and perform tasks such as managing Web3 wallets, interacting with smart contracts, and executing decentralized transactions.",
  },
  {
    id: 3,
    title: "How is the NFT Marketplace integrated into XRender?",
    content:
      "XRender's NFT Marketplace offers a secure and unique space for trading, minting NFTs, and interacting with digital artworks using blockchain",
  },
  {
    id: 4,
    title: "How do AI Chat and Telegram Bot interact with users?",
    content:
      "AI Chat provides a natural communication experience through chat, while the Telegram Bot multitasks to help manage Web3 wallets and execute decentralized tasks via the Telegram app.",
  },
  {
    id: 5,
    title: "How does XRender ensure transparency and security in transactions?",
    content:
      "XRender is committed to ensuring transparency and security in all transactions. Tools like XRender Tooldex help users maintain control and security in a decentralized environment.XRender is committed to ensuring transparency and security in all transactions. Tools like XRender Tooldex help users maintain control and security in a decentralized environment.",
  },
];

export default Faq;

import {
  useState,
  useEffect,
  createContext,
  useCallback,
} from "react";
import { useMediaQuery } from "react-responsive";
import { useActiveWeb3React } from "../hooks";
import { getProviderOrSigner } from "../integrateContract/contract";
import { LIST_NETWORK } from "../constants/connectors";

const FAST_INTERVAL = 10000;
const SLOW_INTERVAL = 60000;

const RefreshContext = createContext({
  slow: 0,
  fast: 0,
  actionConnect: false,
  handleActionConnect: () => {},
});

// Check if the tab is active in the user browser
// const useIsBrowserTabActive = () => {
//   const isBrowserTabActiveRef = useRef(true);

//   useEffect(() => {
//     const onVisibilityChange = () => {
//       isBrowserTabActiveRef.current = !document.hidden;
//     };

//     window.addEventListener("visibilitychange", onVisibilityChange);

//     return () => {
//       window.removeEventListener("visibilitychange", onVisibilityChange);
//     };
//   }, []);

//   return isBrowserTabActiveRef;
// };

// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic refresh
// @ts-ignore
const RefreshContextProvider = ({ children }) => {
  const [slow, setSlow] = useState(0);
  const [fast, setFast] = useState(0);
  // const isBrowserTabActiveRef = useIsBrowserTabActive();
  const [actionConnect, setActionConnect] = useState(false);
  const [chainIdConnect, setChainIdConnect] = useState(null);
  const [getOpBnbBalance, setGetOpBnbBalance] = useState(0);
  const [networkLocal, setNetworkLocal] = useState(LIST_NETWORK[0]);
  const [collection, setCollection] = useState({});
  const { account } = useActiveWeb3React();
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [showContentModal, setShowContentModal] = useState(true);
  const handleCheckNetWork = async () => {
    const provider = await getProviderOrSigner(false);
    const network = await provider.getNetwork();
    setChainIdConnect(network.chainId);
    setIsLoadingModal(true);
    setShowContentModal(false);
  };

  const handleGetOpBnbBalance = async () => {
    try {
      const provider = await getProviderOrSigner(false);
      const balance = await provider.getBalance(account.toString());
      setGetOpBnbBalance(balance.toString());
    } catch (error) {
      console.log("Error", error);
    }
  };
  const handleChangeChainChanged = useCallback(() => {
    handleCheckNetWork();
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", handleChangeChainChanged);
    }
    handleCheckNetWork();
    return () => {
      window.ethereum?.removeListener("chainChanged", handleChangeChainChanged);
    };
  }, [handleChangeChainChanged]);

  const handleActionConnect = () => {
    setActionConnect(true);
  };

  const handleChangeNetworkLocal = (network) => {
    setNetworkLocal(network);
  };

  const handleChangeCollection = (collection) => {
    setCollection(collection);
  };

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     if (isBrowserTabActiveRef.current) {
  //       setFast((prev) => prev + 1);
  //     }
  //   }, FAST_INTERVAL);
  //   return () => clearInterval(interval);
  // }, [isBrowserTabActiveRef]);

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     if (isBrowserTabActiveRef.current) {
  //       setSlow((prev) => prev + 1);
  //     }
  //   }, SLOW_INTERVAL);
  //   return () => clearInterval(interval);
  // }, [isBrowserTabActiveRef]);

  return (
    <RefreshContext.Provider
      value={{
        slow,
        fast,
        actionConnect: actionConnect,
        handleActionConnect: handleActionConnect,
        handleCheckNetWork,
        chainIdConnect,
        handleGetOpBnbBalance,
        getOpBnbBalance,
        isLoadingModal,
        showContentModal,
        handleChangeNetworkLocal,
        networkLocal,
        handleChangeCollection,
        collection,
      }}
    >
      {children}
    </RefreshContext.Provider>
  );
};

export { RefreshContext, RefreshContextProvider };

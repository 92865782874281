import styled from "styled-components";

export const BonusContainer = styled.div`
  position: relative;
  background: rgba(23, 13, 25, 0.20);
  backdrop-filter: blur(15px);
  margin: 0 -20px 60px;
  padding: 100px 10px;
  z-index: 2;
  @media screen and (max-width: 767px) {
    border-top: 1px solid #531E57;
    padding: 32px 20px;
    background: rgba(23, 13, 25, 0.53);
    backdrop-filter: blur(20px);
    margin-bottom: 100px;
  }
`
export const BonusWrapper = styled.div`
  margin: 0 auto;
  max-width: 1220px;
  padding: 72px 120px;
  border-radius: 27px;
  border: 1px solid var(--Colors-Primary-Colors-Primary-300, #D6BBFB);
  background: #000;
  box-shadow: 0px 0px 187.5px 0px rgba(243, 108, 255, 0.29);
  h2 {
    margin-bottom: 30px;
    color: #FFF;
    font-size: 40px;
    line-height: 57.75px;
    font-weight: 500;
    span {
      color: #F814D2;
      font-weight: bold;
    }
  }
  /* @media screen and (max-width: 1440px) {
    width 
  } */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 60px;
  }
  @media screen and (max-width: 767px) {
    padding: 30px 15px;
    border: none;
    box-shadow: unset;
    h2 {
      font-size: 24px;
      line-height: 30px;
    }
  }
`
export const ButtonContract = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  max-width: 150px;
  width: 100%;
  height: 36px;
  margin-bottom: 35px;
  padding: 6px 20px 5px;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  border-radius: 9px;
  background: linear-gradient(90deg, #6650FF 0%, #9818AA 100%);
  cursor: pointer;
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
`
export const BonusList = styled.ul`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 0;
  & > li {
    width: 33.33%;
    h3 {
      color: #F9FAFB;
      font-size: 19.5px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    figure {
      width: 67px;
      height: 67px;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: auto;
      }
    }
    p {
      margin-bottom: 0;
      color: #FFF;
      font-size: 14px;
      line-height: 18px;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    & > li {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 20px;
      figure {
        width: 40px;
        height: auto;
        flex-shrink: 0;
        margin-bottom: 0;
      }
      h3 {
        color: #FE43EA;
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
    }
  }
`
import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../assets/images/IconEvent/mint.svg";
import img2 from "../assets/images/IconEvent/list.svg";
import img3 from "../assets/images/IconEvent/buy.svg";
import img4 from "../assets/images/avatar/avt-3.jpg";
import img5 from "../assets/images/avatar/avt-4.jpg";
import img6 from "../assets/images/avatar/avt-8.jpg";
import img7 from "../assets/images/avatar/avt-1.jpg";
import img8 from "../assets/images/avatar/avt-12.jpg";
import img9 from "../assets/images/avatar/avt-13.jpg";
import img10 from "../assets/images/avatar/avt-14.jpg";
import img11 from "../assets/images/avatar/avt-15.jpg";
import img12 from "../assets/images/avatar/avt-16.jpg";
import img13 from "../assets/images/avatar/avt-17.jpg";
import img14 from "../assets/images/avatar/avt-18.jpg";
import HeaderStyle2 from "../components/header/HeaderStyle2";
import { useLocation } from "react-router-dom";
import base64 from "base64-js";
import { setbuyNFT } from "../integrateContract/contract";
import { ethers } from "ethers";
import { shortenAddress } from "../utils/formartAddress";
import { convertTimeEnd } from "../utils/formartTime";
import styled from "styled-components";

import axiosInstance from "../utils/axios";
import {
  ADDR_MKP_ARB_MAINNET,
  ADDR_MKP_BNB_MAINNET,
  ADDR_MKP_OPBNB_MAINNET,
  ADDR_MKP_ZETA_TESTNET,
  ARB_MKP_ABI_MAINNET,
  BNB_MKP_ABI_MAINNET,
  OPBNB_MKP_ABI_MAINNET,
  ZETA_MKP_ABI_TESTNET,
} from "../integrateContract/ABI";
import { RefreshContext } from "../context/RefreshContext";
import { SCAN_URL } from "../constants";
import { formartImage } from "../utils/formartImage";
import changeNetwork from "../utils/SelectNetwork";
import { CHAINID_CONVERT_TEXTID } from "../constants";
import BtnShare from "./shareCommon";
import { ToastContainer, toast } from "react-toastify";
import ToastTyfile from "../components/layouts/Toastify";
import { defineName } from "../utils/defineName";
import { Helmet } from "react-helmet";
import { formatPriceNFT } from "../utils/formatPriceNFT";
import { useAccount, useChainId } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { switchNetwork } from "@wagmi/core";
import { WrapperBg } from "../components/layouts/styled";

const ItemDetails01 = () => {
  const eventMappings = {
    MarketplaceItemCreated: "List",
    UnListNFTOnSale: "UnList",
    BuyNFT: "BuyNFT",
    Mint: "Mint",
    UpdatePriceNftOnSale: "UpdatePrice",
  };

  const { open } = useWeb3Modal();
  const { address: account } = useAccount();
  const web3ChainId = useChainId();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const tokenAddress = searchParams.get("tokenAddress");
  const chainId = searchParams.get("chainId");
  const typeAPI = searchParams.get("type");
  const [itemTokenId, setItemTokenId] = useState();
  const [linkContract, setLinkContract] = useState("");
  const [countLike, setCountLike] = useState(0);
  const [isLike, setIsLike] = useState(false);
  const [trains, setTrains] = useState([]);
  const navigate = useNavigate();

  const getReactionNFT = async () => {
    try {
      const params = {
        tokenAddress,
        tokenId: id,
        chainId,
        address: account,
      };
      const { data } = await axiosInstance.get(`/reaction`, { params });
      setIsLike(data?.reaction?.isLiked);
    } catch (error) {
      console.log(error);
    }
  };

  const getDetailNFT = async () => {
    try {
      handleReactNFT("view");
      const params = {
        tokenAddress,
        tokenId: id,
        chainId,
      };
      const { data } = await axiosInstance.get(
        `/${typeAPI ? typeAPI : "item"}/detail`,
        { params }
      );
      if (typeAPI) {
        setItemTokenId({
          ...data?.nft,
          metadata: {
            ...data?.nft,
          },
          tokenUri: handleParseJson(data?.nft?.tokenUri),
        });
        setCountLike(data?.nft.like);
      } else {
        setItemTokenId({
          ...data?.item,
          metadata: data?.item?.metadata
            ? JSON.parse(data?.item?.metadata)
            : null,
          tokenUri: data?.item?.metadata
            ? JSON.parse(data?.item?.metadata)?.tokenUri
            : null,
        });
        setCountLike(data?.item.like);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleParseJson = (str) => {
    try {
      JSON.parse(str);
      return JSON.parse(str);
    } catch (e) {
      var base64str = `"${str}"`;
      while (base64str.length % 4 !== 0) {
        base64str += "=";
      }
      const byteArray = base64.toByteArray(base64str);
      const textString = new TextDecoder().decode(byteArray);
      return textString;
    }
  };
  const getHistoryNFT = async () => {
    try {
      const params = {
        tokenAddress,
        tokenId: id,
        chainId,
      };
      const { data } = await axiosInstance.get(
        `/${typeAPI ? typeAPI : "item"}/history`,
        {
          params,
        }
      );
      if (typeAPI) {
        setDataHistory(data?.nftHistory);
      } else {
        setDataHistory(data?.itemsHistory);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReactNFT = async (action) => {
    try {
      const params = {
        address: account,
        tokenAddress,
        tokenId: id,
        action: action,
      };
      await axiosInstance.post(`/reaction?chainId=${chainId}`, params);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Promise.all([getDetailNFT(), getHistoryNFT(), getReactionNFT()]);
  }, [account]);

  const buyNFTTokenId = async (
    id,
    valuePrice,
    address_MKP_Chain,
    abi_MKP_Chain
  ) => {
    try {
      const result = await setbuyNFT(
        id,
        valuePrice,
        address_MKP_Chain,
        abi_MKP_Chain
      );
      if (result === "Successfully buyNFT") {
        navigate(-1);
      } else if (result === "Error buying NFT") {
        toast(<ToastTyfile type="error" description="Insufficient funds." />, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (result === "Error Reject NFT") {
        toast(<ToastTyfile type="error" description="You Reject Metamask" />, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {}
  };

  const handleBuyNFTOrderId = () => {
    if (Number(chainId) === web3ChainId) {
      if (Number(chainId) === 56 * 1) {
        buyNFTTokenId(
          itemTokenId?.orderId.toString(),
          itemTokenId?.price.toString(),
          ADDR_MKP_BNB_MAINNET,
          BNB_MKP_ABI_MAINNET
        );
      } else if (Number(chainId) === 204 * 1) {
        buyNFTTokenId(
          itemTokenId?.orderId.toString(),
          itemTokenId?.price.toString(),
          ADDR_MKP_OPBNB_MAINNET,
          OPBNB_MKP_ABI_MAINNET
        );
      } else if (Number(chainId) === 7001 * 1) {
        buyNFTTokenId(
          itemTokenId?.orderId.toString(),
          itemTokenId?.price.toString(),
          ADDR_MKP_ZETA_TESTNET,
          ZETA_MKP_ABI_TESTNET
        );
      } else if (Number(chainId) === 42161 * 1) {
        buyNFTTokenId(
          itemTokenId?.orderId.toString(),
          itemTokenId?.price.toString(),
          ADDR_MKP_ARB_MAINNET,
          ARB_MKP_ABI_MAINNET
        );
      } else {
        return;
      }
    } else {
      changeNetwork(chainId);
    }
  };
  const handleChangeNetwork = async () => {
    await switchNetwork({
      chainId: chainId,
    });
  };
  const [dataHistory, setDataHistory] = useState([]);

  const convertText = (text) => {
    if (!text) return "";
    const json = window?.atob(text?.substring(29));
    if (json) {
      const result = JSON.parse(json);
      if (result) {
        setTrains(result?.attributes);
      }
    }
  };

  const handleSelectTab = (tab) => {
    if (tab === "Traits") {
      convertText(itemTokenId.metadata.tokenUri);
    }
  };

  const handleLikeNFT = async () => {
    if (!account) return;
    await handleReactNFT("like");
    getReactionNFT();
    if (isLike) {
      setCountLike(countLike - 1);
    } else {
      setCountLike(countLike + 1);
    }
  };

  useEffect(() => {
    if (chainId) {
      const linkActive = SCAN_URL[chainId];
      setLinkContract(linkActive);
    }
  }, [chainId]);
  return (
    <div className="item-details">
      <Helmet>
        <meta
          name="twitter:image"
          content={
            itemTokenId?.metadata?.image
              ? `${formartImage(
                  itemTokenId?.metadata?.image
                )}?auto=format&amp;dpr=1&amp;w=1400&amp;fr=1 `
              : `${formartImage(
                  itemTokenId?.tokenUri?.image
                )}?auto=format&amp;dpr=1&amp;w=1400&amp;fr=1`
          }
        ></meta>
        <meta
          name="twitter:title"
          content={
            typeAPI ? itemTokenId?.tokenUri?.name : itemTokenId?.metadata?.name
          }
        ></meta>
        <meta
          name="twitter:description"
          content="XRender AI Generator is a breakthrough in the NFT ecosystem when integrating artificial intelligence technology to generate works of art in the form of NFT and rendered completely on-chain."
        ></meta>
        <meta
          property="og:image"
          content={
            itemTokenId?.metadata?.image
              ? `${formartImage(
                  itemTokenId?.metadata?.image
                )}?auto=format&amp;dpr=1&amp;w=1400&amp;fr=1`
              : `${formartImage(
                  itemTokenId?.tokenUri?.image
                )}?auto=format&amp;dpr=1&amp;w=1400&amp;fr=1`
          }
        />
        <meta property="og:url" content={`${window.location.href}`}></meta>
      </Helmet>
      <HeaderStyle2 />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 style={{color: "#fff"}} className="heading text-center">
                  {itemTokenId?.metadata?.collection
                    ? itemTokenId?.metadata?.collection
                    : itemTokenId?.collectionName}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WrapperBg
        style={{padding: "20px 0"}}
      >
        {itemTokenId ? (
          <div className="tf-section tf-item-details pt-0">
            <div style={{
            position: "relative",
            zIndex: "5"
          }}  className="themesflat-container">
              <div
                className="flex align-items-center"
                style={{
                  marginBottom: "20px",
                  width: "fit-content",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i
                  className="fa fa-chevron-left mr-4"
                  style={{
                    fontSize: 15,
                    fontWeight: 400,
                    cursor: "pointer",
                    color: "#fff"
                  }}
                >
                  {" "}
                </i>
                <h3
                  style={{
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                  className="style2 mb-0"
                >
                  Back
                </h3>
              </div>
              <div style={{
            position: "relative",
            zIndex: "5"
          }}  className="row">
                <div className="col-xl-6 col-md-12">
                  <div className="content-left">
                    <div className="media">
                      <img
                        src={
                          itemTokenId?.metadata?.image
                            ? formartImage(itemTokenId?.metadata?.image)
                            : formartImage(itemTokenId?.tokenUri?.image)
                        }
                        alt="NFTImage"
                        width="400px"
                        height="400px"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className="content-right">
                    <div className="sc-item-details">
                      <h2 style={{color: "#fff" }} className="style2">
                        {typeAPI
                          ? itemTokenId?.tokenUri?.name
                          : itemTokenId?.metadata?.name}
                        {id ? ` #${id}` : ""}
                      </h2>
                      <div className="meta-item">
                        <div className="left">
                          <span className="viewed eye">{itemTokenId?.view}</span>
                          <span
                            onClick={() => {
                              handleLikeNFT(itemTokenId);
                            }}
                            className="liked heart wishlist-button mg-l-8"
                            style={{
                              color: isLike ? "red" : "#000",
                            }}
                          >
                            <span
                              className="number-like"
                              style={{ color: isLike ? "red" : "#000" }}
                            >
                              {countLike}
                            </span>
                          </span>
                        </div>
                        <BtnShare />
                      </div>
                      <p style={{color: "#fff",  marginBottom: "12px" }}>
                        {itemTokenId?.metadata?.description}
                      </p>
                      <div className="client-infor sc-card-product">
                        <div className="meta-info">
                          <div className="author">
                            <div
                              className="avatar"
                              style={{ mixBlendMode: "initial" }}
                            >
                              <img src={img7} alt="" />
                            </div>
                            <div className="info">
                              {itemTokenId?.metadata?.owner ? (
                                <>
                                  <span>Owned By</span>
                                  <h6>
                                    {shortenAddress(itemTokenId?.metadata?.owner)}
                                  </h6>
                                </>
                              ) : (
                                <>
                                  <span>Seller</span>
                                  <h6>{shortenAddress(itemTokenId?.seller)}</h6>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="meta-info">
                          <div className="author">
                            <div
                              className="avatar"
                              style={{ mixBlendMode: "initial" }}
                            >
                              <img src={img6} alt="" />
                            </div>
                            <div className="info">
                              <span>Smart Contract</span>
                              <h6>
                                <a
                                  href={`${linkContract}/${itemTokenId?.metadata?.tokenAddress}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {shortenAddress(
                                    itemTokenId?.metadata?.tokenAddress
                                  )}
                                </a>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="client-infor sc-card-product">
                        <div className="meta-info">
                          <div className="author">
                            <div
                              className="avatar"
                              style={{ mixBlendMode: "initial" }}
                            >
                              <img src={img4} alt="" />
                            </div>
                            <div className="info">
                              <span>Price</span>
                              <h6>
                                {itemTokenId?.price
                                  ? formatPriceNFT(itemTokenId?.price)
                                  : "--"}{" "}
                                {itemTokenId?.token}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="meta-info">
                          <div className="author">
                            <div
                              className="avatar"
                              style={{ mixBlendMode: "initial" }}
                            >
                              <img src={img5} alt="" />
                            </div>
                            <div className="info">
                              <span>Countdown</span>
                              <h6 style={{ filter: "brightness(0)" }}>
                                <Countdown
                                  date={itemTokenId?.endTime * 1000 || 0}
                                >
                                  <span>You are good to go!</span>
                                </Countdown>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>

                      {itemTokenId?.price && (
                        <>
                          {Number(chainId) === web3ChainId ? (
                            <>
                              <div
                                onClick={() => {
                                  handleBuyNFTOrderId();
                                }}
                                className="sc-button loadmore style bag fl-button pri-3"
                                style={{ borderRadius: "16px", border: "none" }}
                              >
                                <span>Buy</span>
                              </div>
                            </>
                          ) : (
                            <>
                              {account ? (
                                <div
                                  onClick={handleChangeNetwork}
                                  className="sc-button loadmore style bag fl-button pri-3"
                                  style={{ borderRadius: "16px", border: "none" }}
                                >
                                  <span>Switch Networks</span>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    open();
                                  }}
                                  className="sc-button loadmore style wallet fl-button pri-3"
                                  style={{ borderRadius: "16px", border: "none" }}
                                >
                                  <span>Connect Wallet</span>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                      <div className="flat-tabs themesflat-tabs">
                        <Tabs>
                          <TabList>
                            <Tab
                              onClick={() => {
                                handleSelectTab("Activity");
                              }}
                            >
                              Activity
                            </Tab>
                            <Tab
                              onClick={() => {
                                handleSelectTab("Traits");
                              }}
                            >
                              Traits
                            </Tab>
                          </TabList>

                          <TabPanel>
                            <ul className="bid-history-list">
                              {dataHistory.map((item, index) => (
                                <li key={index} item={item}>
                                  <div className="content">
                                    <div className="client">
                                      <div className="sc-author-box style-2">
                                        <div className="author-avatar mb-0">
                                          <Link to="#">
                                            <img
                                              src={
                                                defineName(item?.transactionType)
                                                  ?.image
                                              }
                                              alt=""
                                              className="avatar event-activity"
                                            />
                                          </Link>
                                        </div>
                                        <div className="author-infor">
                                          <div className="name">
                                            <h6>
                                              <Link
                                                style={{
                                                  color: "#f612d1 ",
                                                }}
                                                to="#"
                                              >
                                                {
                                                  defineName(
                                                    item?.transactionType
                                                  )?.name
                                                }{" "}
                                                #{item?.tokenId}
                                              </Link>
                                            </h6>
                                            <span>
                                              {" "}
                                              {eventMappings[item?.event] || ""}
                                            </span>
                                          </div>
                                          <span style={{color: "#fff"}} className="time">
                                            {item.from
                                              ? `From: ${item.from}`
                                              : ""}
                                            <br />
                                            {item.to ? `To: ${item.to}` : ""}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="price">
                                      <h6 style={{color: "#fff"}}>
                                        {item?.price
                                          ? ethers.utils.formatEther(
                                              item?.price?.toLocaleString(
                                                "fullwide",
                                                {
                                                  useGrouping: false,
                                                }
                                              )
                                            )
                                          : ""}
                                        {item?.token}
                                      </h6>
                                      <span style={{color: "#fff"}}>
                                        {item.time && convertTimeEnd(item.time)}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </TabPanel>
                          <TabPanel>
                            <ul className="bid-history-list">
                              <li>
                                <div className="content">
                                  <div className="client">
                                    <div className="sc-author-box style-2">
                                      <div className="author-avatar"></div>
                                      <TransTab className="author-infor">
                                        {trains.map((item, index) => {
                                          return (
                                            <>
                                              {item.value && (
                                                <BoxTrani>
                                                  <div
                                                    className="name"
                                                    key={index}
                                                  >
                                                    {/* {handleLogo(item.trait_type)} */}
                                                    <h6> {item.trait_type} </h6>{" "}
                                                    {/* <span> place a bid</span> */}
                                                  </div>
                                                  <span className="time">
                                                    {item.value}
                                                  </span>
                                                </BoxTrani>
                                              )}
                                            </>
                                          );
                                        })}
                                      </TransTab>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>""</div>
        )}
      </WrapperBg>

      {/* <LiveAuction data={liveAuctionData} /> */}
      <ToastContainer />
    </div>
  );
};

export default ItemDetails01;
const handleLogo = (property) => {
  switch (property) {
    case "hatStyle":
      return <img src={img8} alt="" />;
      break;
    case "eyesColor":
      return <img src={img9} alt="" />;
      break;
    case "clothesColor":
      return <img src={img10} alt="" />;
      break;
    case "logoClothesColor":
      return <img src={img11} alt="" />;
      break;
    case "trousersColor":
      return <img src={img12} alt="" />;
      break;
    case "hatBackgroundColor":
      return <img src={img13} alt="" />;
      break;
    case "hatLineColor":
      return <img src={img14} alt="" />;
      break;
    default:
      return <></>;
  }
};
const TransTab = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;
const BoxTrani = styled.div`
  width: calc((100% - 45px) / 4);
  align-self: stretch;
  display: flex;
  border-radius: 10px;
  background: #ffeffc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    img {
      margin-bottom: 10px;
      max-width: 43px;
      border-radius: 12px;
    }
  }
  span {
    color: #000 !important ;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  h6 {
    color: #7b7b7b;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
`;

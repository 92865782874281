import { useState, useEffect } from "react";
import {
  CollectionBody,
  TokenCollectionContainer,
  AddBlock,
  AddWrapper,
  AddressBlock,
  FormContainer,
  ShowBlock,
  ShowRow,
  ReceiveBlock,
  CWButtons,
  ModalCWContainer,
  PCWContainer,
} from "./styled";
import { TableCommon, TextCustom } from "../../../components/common/styled";
import { TableBlock } from "../CheckBalance/styled";
import { Table, Empty } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  ButtonCommon,
  TextAreaCommon,
  TitleKey,
  V2Buttons,
} from "../../../components/common/styled";
import web3 from "web3";
import InputCommon from "../../../components/common/input";
import { Input, Upload } from "antd";
import {
  useAccount,
  useNetwork,
} from "wagmi";
// Images
import copy from "../../../assets/images/common/table/copy.svg";
import upload from "../../../assets/images/common/form/upload.svg";
import download from "../../../assets/images/common/form/download.svg";
import { SCAN_URL, token_network } from "../../../constants/Tooldex/index";
import Big from "big.js";
import ABI from "../../../constants/Tooldex/abi/abiToken.json";
import * as XLSX from "xlsx";
import { AbiItem } from "web3-utils";
import { useContext } from "react";
import getTokenInfo from "../../../utils/Tooldex/checkInfoToken";
import { ModalContext } from "../../../components/Tooldex/modal";
import { notiError, notiSuccess, notiWarning } from "../../../utils/Tooldex/notication";
import { convertFromWei } from "../../../utils/Tooldex/convertNumber";
import Loading from "../../../components/Tooldex/Loading";
import CurrencyLogo from "../../../components/CurrencyLogo";
import { formatNum } from "../../../utils/Tooldex/formatNumber";

declare const window: Window & typeof globalThis & { ethereum: any };
const TokenCollection = () => {
  const { Column } = Table;

  const { address } = useAccount();
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const account: any = address;
  const Web3 = new web3(window.ethereum);
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenInfo, setTokenInfo] = useState<any>();
  const [recipients, setRecipients] = useState<any>("");
  const [isConfirming, setConfirming] = useState<any>(false);
  const [isAddToken, setAddToken] = useState<any>(false);
  const [receiverAddress, setReceiverAddress] = useState("");
  const [transactionHashes, setTransactionHashes] = useState<any>([]);
  const { onOpenModal, onCloseModal }: any = useContext(ModalContext);
  const { TextArea } = Input;

  const recipientList = recipients
    .trim()
    .split("\n")
    .map((row: any) => {
      const [privatekey] = row.split(",");
      return { privatekey };
    });

  const privatekeys = recipientList.map((item: any) =>
    item?.privatekey?.trim()
  );

  const handleLoadDataToken = async () => {
    try {
      if(Web3.utils.isAddress(tokenAddress)){
        setAddToken(true);
        const data = await getTokenInfo(tokenAddress, Web3);
        setTokenInfo(data);
        setAddToken(false);
      }
      else {
        notiWarning('Token address error, please check',5)
      }
    } catch (error) {
      setTokenInfo({});
      setAddToken(false);
      console.error(error);
      notiError("Add Token Failed!", 5);
    }
  };

  const handleFileUpload = (file: any) => {
    try {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const filteredData = jsonData.filter(
          (row: any) => !isNaN(row[0].toString().trim())
        );

        const formattedData = filteredData.map((row, index) => {
          const [privatekey]: any = row;
          return ` ${privatekey.toString().trim()}`;
        });

        setRecipients(formattedData.join("\n"));
      };

      reader.readAsArrayBuffer(file);
      notiSuccess("Upload File Successfully!", 5);
    } catch (error: any) {
      console.error(error);
      notiError("Upload File Failed", 5);
    }
  };

  const exportToExcel = () => {
    const data = [
      {
        PrivateKey:
          "0x3846a0e8274cfc1620483474214a8464545307eb0b3d18112af6ae9f981ac2ec",
      },
      {
        PrivateKey:
          "0x20119718210dbf390ed6e24adf56a920d6c52b34b72cdc04257193e4d9476381",
      },
      {
        PrivateKey:
          "0x0b103a86d045249426ed77fb968f4bf7a1c521150bc51431bfe0ec55ba2e8b2a",
      },
      {
        PrivateKey:
          "0x3f638934ee2417af56623a07246edea7ec6f346d4256dbe8c7e904743dc2903d",
      },
      {
        PrivateKey:
          "0xb680d9e6eb7342ba3b17dd7ac56168fe443ccd26d3a2e9f1f4985df7aba9dc78",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");
    XLSX.writeFile(workbook, "Sample.xlsx");
  };

  const transferTokens = async () => {
    try {
      setConfirming(true);
      onCloseModal();
      const contract = new Web3.eth.Contract(
        ABI as unknown as AbiItem as AbiItem,
        tokenAddress
      );
      const gasPrice = await Web3.eth.getGasPrice();
      let listData = [];
      for (let i = 0; i < privatekeys.length; i++) {
        const privateKey = privatekeys[i];

        const account = Web3.eth.accounts.privateKeyToAccount(privateKey);
        Web3.eth.accounts.wallet.add(account);
        const balance = await contract.methods
          .balanceOf(account.address)
          .call();
        const decimals = await contract.methods.decimals().call();
        const symbol = await contract.methods.symbol().call();
        const balanceFromWei = await convertFromWei(balance, Number(decimals));

        const nonce = await Web3.eth.getTransactionCount(account.address);

        if (Number(balanceFromWei) > 0) {
          const gas = await contract.methods
            .transfer(receiverAddress, balance)
            .estimateGas({ from: account.address });

          const rawTx = {
            nonce: nonce,
            gasPrice: gasPrice,
            gasLimit: gas,
            to: tokenAddress,
            value: 0,
            data: contract.methods
              .transfer(receiverAddress, balance)
              .encodeABI(),
          };

          const signedTx = await Web3.eth.accounts.signTransaction(
            rawTx,
            privateKey
          );
          if (signedTx.rawTransaction) {
            const txHash = await Web3.eth.sendSignedTransaction(
              signedTx.rawTransaction
            );

            listData.push({
              address: account.address,
              privatekey: privateKey,
              amount: balanceFromWei,
              txn: txHash.transactionHash,
              symbol: symbol,
            });
            setTransactionHashes([...listData]);
          }
        }
      }

      setConfirming(false);
      notiSuccess("Transfer Token Successfully!", 5);
    } catch (error: any) {
      setConfirming(false);
      notiError(error?.message, 5);
    }
  };

  const transferTokenBase = async () => {
    try {
      setConfirming(true);
      onCloseModal();

      let listData = [];
      for (let i = 0; i < privatekeys.length; i++) {
        const privateKey = privatekeys[i];
        const account = Web3.eth.accounts.privateKeyToAccount(privateKey);
        Web3.eth.accounts.wallet.add(account);
        const balance = await Web3.eth.getBalance(account.address);
        const symbol = token_network(chainId);
        const balanceFromWei = await convertFromWei(balance, Number(18));
        const nonce = await Web3.eth.getTransactionCount(account.address);
        const gasPrice = await Web3.eth.getGasPrice();
        const gasLimit = await Web3.eth.estimateGas({
          to: receiverAddress,
          value: balance,
        });
        const gasFee = gasLimit * Number(gasPrice);
        const valueSend = Big(balance).minus(gasFee).toString();

        if (Number(balanceFromWei) > 0 && Number(balance) > gasFee) {
          const rawTx = {
            from: account.address,
            to: receiverAddress,
            value: valueSend,
            gasPrice: gasPrice,
            gas: gasLimit,
            nonce: nonce,
            chainId: chainId,
          };
          const signedTransaction = await Web3.eth.accounts.signTransaction(
            rawTx,
            privateKey
          );
          if (signedTransaction.rawTransaction) {
            const txHash = await Web3.eth.sendSignedTransaction(
              signedTransaction.rawTransaction
            );
            listData.push({
              address: account.address,
              privatekey: privateKey,
              amount: web3.utils.fromWei(valueSend.toString(), "ether"),
              txn: txHash.transactionHash,
              symbol: symbol,
            });
            setTransactionHashes([...listData]);
          }
        }
      }

      setConfirming(false);
      notiSuccess("Transfer Token Successfully!", 5);
    } catch (error: any) {
      setConfirming(false);
      console.error("err", error);
      notiError(error?.message, 5);
    }
  };

  const ModalConfirm = () => {
    const { onCloseModal }: any = useContext(ModalContext);
    return (
      <ModalCWContainer>
        <PCWContainer>
          <TextCustom fontSize="14px" fontWeight="500" color="#717696">
            All tokens will be transferred to wallet :
          </TextCustom>
          <TextCustom fontSize="14px" fontWeight="500" color="#717696">
            <u>
              {`${receiverAddress?.substring(
                0,
                8
              )}.....${receiverAddress?.substring(
                receiverAddress.length - 12
              )}`}
            </u>
          </TextCustom>
          <TextCustom fontSize="14px" fontWeight="500" color="#717696">
            Are you sure?
          </TextCustom>
        </PCWContainer>

        <CWButtons>
          <ButtonCommon
            onClick={onCloseModal}
            background="#CFD0D6"
            textColor="#0D0C43"
          >
            Cancel
          </ButtonCommon>
          {tokenAddress && tokenInfo ? (
            <ButtonCommon
              background="rgba(0, 178, 200, 0.90)"
              textColor="#fff"
              disabled={isConfirming}
              onClick={transferTokens}
            >
              <Loading status={isConfirming} content="Confirm" />
            </ButtonCommon>
          ) : (
            <ButtonCommon
              background="rgba(0, 178, 200, 0.90)"
              textColor="#fff"
              disabled={isConfirming}
              onClick={transferTokenBase}
            >
              <Loading status={isConfirming} content="Confirm" />
            </ButtonCommon>
          )}
        </CWButtons>
      </ModalCWContainer>
    );
  };

  useEffect(() => {
    if (
      !tokenAddress ||
      tokenInfo?.address.toUpperCase() !== tokenAddress.toUpperCase()
    ) {
      setTokenInfo(undefined);
    }
  }, [tokenAddress, tokenInfo]);

  return (
    <TokenCollectionContainer>
      <CollectionBody>
        <TextCustom fontSize="24px" color="#fff" fontWeight="bold">
          Token Collection
        </TextCustom>

        <FormContainer>
          <AddBlock>
            <TitleKey>Token address</TitleKey>
            <AddWrapper>
              <InputCommon
                placeHolder="Token address"
                value={tokenAddress}
                onChange={(e: any) => setTokenAddress(e.target.value)}
              />
              <ButtonCommon
                disabled={!tokenAddress || !account || isAddToken}
                background="#fff"
                textColor="#F313CE"
                style={{
                  minWidth: "140px",
                  padding: "9px 20px",
                  border: (tokenAddress && account) && "2px solid #F313CE",
                }}
                onClick={handleLoadDataToken}
              >
                <Loading status={isAddToken} content="Add Token" />
              </ButtonCommon>
            </AddWrapper>
            <TextCustom
              style={{ marginTop: "5px", fontStyle: "italic" }}
              fontSize="14px"
              fontWeight="500"
              color="#717696"
            >
              *Limit 500 private key for 1 transfer.
              <br />
              *If the token address is not entered, the {token_network(
                chainId
              )}{" "}
              is default
            </TextCustom>
          </AddBlock>
          {tokenInfo?.name && (
            <ShowBlock>
              <ShowRow>
                Name :<span>{tokenInfo?.name}</span>
              </ShowRow>
              <ShowRow style={{ display: "flex", alignItems: "center" }}>
                Symbol :
                <span style={{ display: "flex", alignItems: "center" }}>
                  {tokenInfo?.symbol}{" "}
                  <CurrencyLogo address={tokenInfo?.address} />
                </span>
              </ShowRow>
              <ShowRow>
                Total Supply :
                <span>
                  {new Intl.NumberFormat("en-US", {
                    notation: "compact",
                    compactDisplay: "short",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(tokenInfo?.supply)}{" "}
                  {tokenInfo?.symbol}
                </span>
              </ShowRow>
              <ShowRow>
                Contract :<span>{tokenInfo?.address}</span>
              </ShowRow>
            </ShowBlock>
          )}
          <AddressBlock>
            <TitleKey>Address List</TitleKey>
            <TextAreaCommon>
              <TextArea
                value={recipients}
                onChange={(e) => setRecipients(e.target.value)}
                placeholder="Enter private key on each line. &#10;Supports only format. &#10;Eg: 0x25cff52d3452e5ae4d54242643333fb449e0cedd50d4b2638cc8b47f5ae"
                style={{ height: 220, resize: "none" }}
              />
            </TextAreaCommon>
          </AddressBlock>
          <V2Buttons>
            <Upload
              accept=".xlsx, .xls"
              showUploadList={false}
              beforeUpload={handleFileUpload}
            >
              <ButtonCommon
                className="action-button"
                background={"#fff"}
                textColor={"#0D0C43"}
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={upload}
                  alt="upload"
                />
                Upload Excel
              </ButtonCommon>
            </Upload>
            <ButtonCommon
              className="action-button"
              disabled={false}
              background={"#fff"}
              textColor={"#0D0C43"}
              onClick={exportToExcel}
            >
              <img
                style={{ width: "20px", height: "20px" }}
                src={download}
                alt="download"
              />
              Sample file
            </ButtonCommon>
          </V2Buttons>

          <ReceiveBlock>
            <TitleKey>Receiver Address</TitleKey>
            <AddWrapper>
              <InputCommon
                placeHolder="Receiver Address"
                disabled={isConfirming}
                value={receiverAddress}
                onChange={(e: any) => setReceiverAddress(e.target.value)}
                // onChange={(e:any) => Web3.utils.isAddress(e.target.value) ? setReceiverAddress(e.target.value) : setReceiverAddress('')}
              />
            </AddWrapper>
          </ReceiveBlock>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonCommon
              disabled={!receiverAddress || !recipients || isConfirming}
              style={{ minWidth: "245px" }}
              onClick={() => {
                onOpenModal(<ModalConfirm />);
              }}
            >
              <Loading status={isConfirming} content="Confirm" />
            </ButtonCommon>
          </div>
        </FormContainer>
        <TableBlock>
          <TextCustom fontSize={"20px"} color={"#0D0C43"} fontWeight={"bold"}>
            Transactions
          </TextCustom>
          <TableCommon>
            {transactionHashes.length > 0 ? (
              <Table dataSource={transactionHashes}>
                <Column
                  title="STT"
                  key="index"
                  render={(text, record, index) => index + 1}
                />
                <Column
                  title="Wallet address"
                  dataIndex="address"
                  key="address"
                  render={(address) => (
                    <>
                      {`${address?.substring(0, 10)}.....${address?.substring(
                        address.length - 4
                      )}`}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-2px",
                          marginLeft: "8px",
                        }}
                      >
                        <CopyToClipboard
                          text={address}
                          onCopy={() => {
                            notiSuccess(
                              `Copied ${address?.substring(
                                0,
                                10
                              )}.....${address?.substring(address.length - 5)}`,
                              5
                            );
                          }}
                        >
                          <span className="img-copy">
                            <img width={20} height={20} src={copy} alt="copy" />
                          </span>
                        </CopyToClipboard>
                      </span>
                    </>
                  )}
                />
                <Column
                  title="Private Key"
                  dataIndex="privatekey"
                  key="privatekey"
                  render={(privatekey) => (
                    <>
                      {`${privatekey?.substring(
                        0,
                        12
                      )}******************${privatekey?.substring(
                        privatekey.length - 10
                      )}`}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-2px",
                          marginLeft: "8px",
                        }}
                      >
                        <CopyToClipboard
                          text={privatekey}
                          onCopy={() => {
                            notiSuccess(
                              `Copied ${privatekey?.substring(
                                0,
                                10
                              )}*****${privatekey?.substring(
                                privatekey.length - 5
                              )}`,
                              5
                            );
                          }}
                        >
                          <span className="img-copy">
                            <img width={20} height={20} src={copy} alt="copy" />
                          </span>
                        </CopyToClipboard>
                      </span>
                    </>
                  )}
                />
                <Column
                  title="Symbol"
                  dataIndex="symbol"
                  key="symbol"
                  render={(symbol) => <>{symbol}</>}
                />
                <Column
                  title="Amount"
                  dataIndex="amount"
                  key="amount"
                  render={(amount, record: any) => (
                    <>
                      {formatNum(Number(amount).toFixed(50))} {record.symbol}
                    </>
                  )}
                />
                <Column
                  title="Txn"
                  dataIndex="txn"
                  key="txn"
                  render={(txn) => (
                    <>
                      <span
                        style={{
                          color: "#1853EC",
                        }}
                      >
                        <a
                          href={`${[SCAN_URL[chainId]]}tx/${txn}`}
                          className="btn-open-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {`${txn?.substring(0, 10)}.....${txn?.substring(
                            txn.length - 4
                          )}`}
                        </a>
                      </span>
                    </>
                  )}
                />
              </Table>
            ) : (
              <Empty />
            )}
          </TableCommon>
        </TableBlock>
      </CollectionBody>
    </TokenCollectionContainer>
  );
};

export default TokenCollection;

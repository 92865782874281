import KeyvisualCommon from "../../../components/common/keyvisual";
import { VolumeBody, VolumeContainer } from "./styled";
import { TextCustom } from "../../../components/common/styled";
import ProgressCommon from "../../../components/common/progress";
import { ReactNode, useState, useEffect } from "react";
import FormConnectWallet from "../../../components/common/form/connectWallet";
import SelectNetwork from "../../../components/common/form/selectNetwork";
import TokenInfo from "../../../components/common/form/tokenInfo";
import CreateContract from "./createContract";
import { useAccount } from "wagmi";
const TokenCreation = () => {
  const { address } = useAccount();
  const account: any = address;

  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (account) {
      setStep(1);
    } else {
      setStep(0);
    }
  }, [account]);
  const handleChangeComponent = (step: number): ReactNode => {
    switch (step) {
      case 0:
        return <FormConnectWallet />;
      case 1:
        return <SelectNetwork setStep={setStep} />;
      case 2:
        return <TokenInfo setStep={setStep} />;
      case 3:
        return <CreateContract setStep={setStep} />;
      default:
        return null;
    }
  };

  return (
    <VolumeContainer>
      <KeyvisualCommon
        text="Token Creation"
        content={
          <TextCustom color="#fff" fontSize="16px" fontWeight="300">
            Create your own fully-audited token in 5 minutes with no programming
            experience.
          </TextCustom>
        }
      />
      <VolumeBody>
        <ProgressCommon widthCustom={"400px"} data={progressData} step={step} />
        {handleChangeComponent(step)}
      </VolumeBody>
    </VolumeContainer>
  );
};

const progressData = [
  "Connect wallet",
  "Select nework",
  "Enter token info",
  "Create contract",
];

export default TokenCreation;

import styled from "styled-components";
import userIcon from "../../assets/images/logo/avatar.svg";
import { Link } from "react-router-dom";

const ProfileBtn = ({ isTooldexPath }) => {
  return (
    <ProfileWapper>
      <Link to="/profile">
        <img src={userIcon} alt="user" />
      </Link>
    </ProfileWapper>
  );
};
export default ProfileBtn;
const ProfileWapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 4px;
  }
`;

import styled from "styled-components";
import { TextCustom, TitleKey, V2Buttons } from "../../../components/common/styled";

export const MultiSenderContainer = styled.div`
  position: relative;
  z-index: 5;
`;
export const SenderBody = styled.div`
  padding: 50px 20px;
  ${TextCustom} {
    text-align: center;
    margin-bottom: 30px;
  }
`;

export const FormContainer = styled.div`
  max-width: 620px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 20px;
  text-align: left;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #dce2e9;
  ${TitleKey} {
    margin-bottom: 5px;
  }
  ${V2Buttons} {
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;
export const AddBlock = styled.div`
  margin-bottom: 15px;
`;
export const AddWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
  }
`;
export const ShowBlock = styled.div`
  margin-bottom: 15px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #F313CE;
  background: rgba(0, 178, 200, 0.05);
`;
export const ShowRow = styled.div`
  color: #0d0c43;
  font-weight: 600;
  letter-spacing: 0.2px;
  span {
    color: #F313CE;
    margin-left: 5px;
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;
export const AddressBlock = styled.div`
  margin-bottom: 18px;
`;
export const RowHash = styled.div`
  border-radius: 8px;
  border: 0.75px solid #d9d9d9;
  background: #f3f4f9;
  padding: 10px;
  margin-bottom: 15px;
  color: #717696;
  font-weight: 600;
`;


import React, { useState, useEffect, useContext } from "react";
import Footer from "../components/footer/Footer";
import TodayPicks from "../components/layouts/home-5/TodayPicks";
import { SliderMarketPlace } from "../components/slider/SliderMarketPlace";
import axiosInstance from "../utils/axios";
import { useWeb3React } from "@web3-react/core";
import { useActiveWeb3React } from "../hooks";
import { RefreshContext } from "../context/RefreshContext";
import { useLocation, useNavigate } from "react-router-dom";
import { LIST_NETWORK } from "../constants/connectors";
import { BoxActivity } from "../components/layouts/detailColection";
import { WrapperBg } from "../components/layouts/styled";

const MarketPlace = () => {
  const { chainId } = useWeb3React();
  const { account } = useActiveWeb3React();

  const [totalData, setTotalData] = useState(0);
  const [listNftFull, setListNftFull] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isCalled, setIsCalled] = useState(false);
  const { networkLocal, collection } = useContext(RefreshContext);
  const { search } = useLocation();
  const navigate = useNavigate();

  const getListNFT = async (chainId, page, type, collections) => {
    const params = search.split("?network=");
    if (params && params[1]) {
      chainId = params[1];
    }
    const chainActive = LIST_NETWORK.find(
      (item) => Number(item.chainId) === Number(chainId)
    );
    if (!chainActive) return;
    if (page) {
      setCurrentPage(page);
    } else {
      setCurrentPage(1);
    }
    try {
      let params = {
        page: page || 1,
        limit: 12,
      };
      if (collections) {
        params.filterBy = {
          tokenAddress: collections.tokenAddress,
        };
      }
      const res = await axiosInstance.post(
        `/item/market?chainId=${chainId}`,
        params
      );
      const { data } = res;
      const oldNFT = listNftFull;
      if (type === "loadmore") {
        setListNftFull(oldNFT.concat(data.itemList));
      } else {
        setListNftFull(data.itemList);
      }
      setTotalData(data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadMore = async () => {
    if (account) {
      getListNFT(chainId, currentPage + 1, "loadmore", collection);
    } else {
      getListNFT(networkLocal.chainId, currentPage + 1, "loadmore", collection);
    }
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (!isCalled) return;
    getListNFT(networkLocal.chainId, null, null, collection);
  }, [collection]);

  useEffect(() => {
    getListNFT(networkLocal.chainId, null);
    setIsCalled(true);
  }, [networkLocal.chainId]);

  useEffect(() => {
    const params = search.split("?network=");
    if (params && params[1]) {
      navigate(`/marketplace?network=${params[1]}`);
    }
  }, [chainId, networkLocal]);

  return (
    <div>
      <WrapperBg
        style={{padding: "0 0 100px"}}
      >
        <SliderMarketPlace />
        <TodayPicks
          isMarketPlace={true}
          data={listNftFull}
          total={totalData || 0}
          onLoadMore={handleLoadMore}
        />
      </WrapperBg>
    </div>
  );
};

export default MarketPlace;

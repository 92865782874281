import {
  CCBody,
  CCConfirm,
  ConfirmCol,
  CreateContractContainer,
  ShowBlock,
  ShowRow,
  FormContainer,
} from "./styled";
import {
  NAME_NETWORK,
  LOGO_BY_CHAINID,
  CHAINID_TO_TEXTCHAIN,
  CHAINID_TO_VALUE,
} from "../../../../constants/Tooldex/index";
import { useAccount, useNetwork } from "wagmi";
import { useState } from "react";
import switchNetworkChain from "../../../../utils/Tooldex/walletChain";
import { TOKEN_STANDARD_BYTECODE } from "../../../../constants/Tooldex/bytecodeStandardToken";
import ABI from "../../../../constants/Tooldex/abi/StandardTokenABI.json";
import { STANDAR_FEE_RECEIVE, FEE_DEPLOY } from "../../../../constants/Tooldex";
import Web3 from "web3";
import { ethers } from "ethers";
import checkInfoToken from "../../../../utils/Tooldex/checkBalanceInfo";
import { convertToWei, convertFromWei } from "../../../../utils/Tooldex/convertNumber";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "../../../../assets/images/common/table/copy.svg";
import { notiError, notiSuccess } from "../../../../utils/Tooldex/notication";
import { ButtonAction, NetworkHeader } from "../../../../components/common/form/selectNetwork/styled";
import { ButtonCommon, TextCommon, TextCustom, TitleKey } from "../../../../components/common/styled";
import CurrencyLogo from "../../../../components/CurrencyLogo";
import Loading from "../../../../components/Tooldex/Loading";

declare const window: Window & typeof globalThis & { ethereum: any };
const CreateContract = ({ setStep }: any) => {
  const web3 = new Web3(window.ethereum);

  const { address }: any = useAccount();
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const [tokenInfo, setTokenInfo] = useState<any>();
  const networkCheck = localStorage.getItem("chainNetwork");
  const nameTokenLocal = localStorage.getItem("tokenName");
  const symbolTokenLocal = localStorage.getItem("tokenSymbol");
  const totalSupplyLocal = localStorage.getItem("tokenSupply");
  const decimalsTokenLocal = localStorage.getItem("tokenDecimals");
  const [switchChain, setSwitchChain] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  let provider: any;
  if (typeof window !== "undefined") {
    if (window?.ethereum) {
      provider = new ethers.providers.Web3Provider(window?.ethereum);
    }
  }
  const deployToken = async () => {
    try {
      setLoadingConfirm(true);
      const signer = provider.getSigner();

      const Factory = new ethers.ContractFactory(
        ABI,
        TOKEN_STANDARD_BYTECODE,
        signer
      );
      const supplyToWei = await convertToWei(
        totalSupplyLocal,
        decimalsTokenLocal
      );
      const balance = await web3.eth.getBalance(address);
      const balanceFromWei = await convertFromWei(balance, Number(18));

      // Tính Gas Price
      const gasPrice = await web3.eth.getGasPrice();
      const block = await web3.eth.getBlock("latest");
      let length_block =
        block.transactions.length > 0 ? block.transactions.length : 1;
      const gasEstimate = Number(block.gasLimit / length_block) * 3;

      const gas_Price = web3.utils.toBN(gasPrice);
      const gas_Estimate = web3.utils.toBN(gasEstimate.toFixed());
      const FEE = web3.utils.toBN(web3.utils.toWei(FEE_DEPLOY(chainId)));
      const Total = gas_Price.mul(gas_Estimate).add(FEE);
      const Total_Fee = web3.utils.fromWei(Total.toString(), "ether");
      if (Number(Total_Fee) <= Number(balanceFromWei)) {
        const contract = await Factory.deploy(
          nameTokenLocal,
          symbolTokenLocal,
          decimalsTokenLocal,
          supplyToWei,
          STANDAR_FEE_RECEIVE,
          web3.utils.toWei(FEE_DEPLOY(chainId), "ether"),
          { value: web3.utils.toWei(FEE_DEPLOY(chainId), "ether") }
        );

        await contract.deployed().catch((err: any) => {
          console.error("err", err);
          notiError(err.message, 5);

          setLoadingConfirm(false);
        });
        if (contract?.address) {
          const info = await checkInfoToken(contract.address, address);
          setTokenInfo(info);
          notiSuccess("Create New Token Successfully", 5);
        }

        setLoadingConfirm(false);
      } else {
        notiError("Not enough fee", 5);
        setLoadingConfirm(false);
      }
    } catch (error: any) {
      console.error(error);
      notiError(error?.data?.message || "Create New Token Failed", 5);
      setLoadingConfirm(false);
    }
  };

  const handleSwitchChain = async () => {
    try {
      setSwitchChain(true);
      const _value = CHAINID_TO_VALUE(Number(networkCheck));
      const _chainId = CHAINID_TO_TEXTCHAIN(Number(networkCheck));
      await switchNetworkChain(_chainId, _value);
      setSwitchChain(false);
    } catch (error: any) {
      console.error(error.message);
      setSwitchChain(false);
    }
  };
  return (
    <CreateContractContainer>
      <CCBody>
        <NetworkHeader>
          <TextCustom color="#0D0C43" fontSize="24px" fontWeight="bold">
            Create contract
          </TextCustom>
          <TextCommon>
            Please ensure the following details are correct
          </TextCommon>
        </NetworkHeader>
        <CCConfirm>
          <ConfirmCol>
            <TitleKey>Token Name</TitleKey>
            <p>{nameTokenLocal}</p>
          </ConfirmCol>
          <ConfirmCol>
            <TitleKey>Symbol</TitleKey>
            <p>{symbolTokenLocal}</p>
          </ConfirmCol>
          <ConfirmCol>
            <TitleKey>Nework</TitleKey>
            <p>
              <img
                width={20}
                height={20}
                src={LOGO_BY_CHAINID(Number(networkCheck))}
                alt="logo"
              />
              <span>{NAME_NETWORK(Number(networkCheck))}</span>
            </p>
          </ConfirmCol>
          <ConfirmCol>
            <TitleKey>Decimals</TitleKey>
            <p>{decimalsTokenLocal}</p>
          </ConfirmCol>
          <ConfirmCol>
            <TitleKey>Total Supply</TitleKey>
            <p>{Number(totalSupplyLocal).toLocaleString()}</p>
          </ConfirmCol>
        </CCConfirm>
        {tokenInfo?.name && (
          <FormContainer>
            <ShowBlock>
              <ShowRow>
                Name :<span>{tokenInfo?.name}</span>
              </ShowRow>
              <ShowRow style={{ display: "flex", alignItems: "center" }}>
                Symbol :
                <span style={{ display: "flex", alignItems: "center" }}>
                  {tokenInfo?.symbol}{" "}
                  <CurrencyLogo address={tokenInfo?.address} />
                </span>
              </ShowRow>
              <ShowRow>
                Total Supply :
                <span>
                  {new Intl.NumberFormat("en-US", {
                    notation: "compact",
                    compactDisplay: "short",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(tokenInfo?.supply)}{" "}
                  {tokenInfo?.symbol}
                </span>
              </ShowRow>
              <ShowRow>
                Balance Token :
                <span>
                  {new Intl.NumberFormat("en-US", {
                    notation: "compact",
                    compactDisplay: "short",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(tokenInfo?.balanceOf)}{" "}
                  {tokenInfo?.symbol}
                </span>
              </ShowRow>
              <ShowRow style={{ display: "flex" }}>
                Contract :
                <span style={{ display: "flex" }}>
                  {`${tokenInfo?.address?.substring(
                    0,
                    14
                  )}........${tokenInfo?.address?.substring(
                    tokenInfo?.address.length - 12
                  )}`}{" "}
                  <CopyToClipboard
                    text={tokenInfo?.address}
                    onCopy={() => {
                      notiSuccess(
                        `Copied ${tokenInfo?.address?.substring(
                          0,
                          6
                        )}.....${tokenInfo?.address?.substring(
                          tokenInfo?.address.length - 8
                        )}`,
                        5
                      );
                    }}
                  >
                    <span className="img-copy">
                      <img width={20} height={20} src={copy} alt="copy" />
                    </span>
                  </CopyToClipboard>
                </span>
              </ShowRow>
            </ShowBlock>
          </FormContainer>
        )}

        {Number(networkCheck) !== chainId ? (
          <ButtonCommon
            style={{ margin: "0 auto" }}
            background={"#F313CE"}
            textColor={"#fff"}
            disabled={
              !nameTokenLocal ||
              !symbolTokenLocal ||
              !networkCheck ||
              !decimalsTokenLocal ||
              !totalSupplyLocal ||
              switchChain
            }
            onClick={handleSwitchChain}
          >
            <Loading status={switchChain} content="Switch Chain" />
          </ButtonCommon>
        ) : (
          <ButtonCommon
            style={{ margin: "0 auto" }}
            background={"#F313CE"}
            textColor={"#fff"}
            disabled={
              !nameTokenLocal ||
              !symbolTokenLocal ||
              !networkCheck ||
              !decimalsTokenLocal ||
              !totalSupplyLocal ||
              loadingConfirm
            }
            onClick={deployToken}
          >
            <Loading status={loadingConfirm} content="Comfirm" />
          </ButtonCommon>
        )}
      </CCBody>

      <ButtonAction>
        <ButtonCommon
          style={{
            border: "2px solid #F313CE",
            padding: "5px 20px",
            width: "auto",
            fontWeight: "600",
          }}
          background="transparent"
          textColor="##F313CE"
          onClick={() => setStep(2)}
        >
          Prev
        </ButtonCommon>
      </ButtonAction>
    </CreateContractContainer>
  );
};

export default CreateContract;

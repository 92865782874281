import { useWeb3React } from "@web3-react/core";
import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ModalStaking } from "../../components/Modal/ModalStaking";
import { ModalConfirmContext } from "../../components/ProviderPopUp/confirm";
import {
  STAKING_OPBNB_TESTNET,
  STAKING_ARB_MAINNET,
  ABI_STAKING,
} from "../../integrateContract/ABI";
import { getUserTokenAmount } from "../../integrateContract/contract/handleIco";
import { getUserStakingAmount } from "../../integrateContract/contract/handleStaking";
import { useActiveWeb3React } from "../../hooks";
import Decimal from "decimal.js";

export const StakingAmount = (props) => {
  const { poolIndex, tokenBalance, onRefetchStake } = props;
  const { onClose } = useContext(ModalConfirmContext);
  const context = useWeb3React();
  const { active } = context;
  const { onOpen } = useContext(ModalConfirmContext);
  const { chainId } = useWeb3React();
  const { account } = useActiveWeb3React();

  const [amount, setAmount] = useState(0);

  const getAmount = async () => {
    let addressStaking = "";
    let contract = null;
    switch (chainId) {
      case 5611:
        addressStaking = STAKING_OPBNB_TESTNET;
        contract = ABI_STAKING;
        break;
      case 42161:
        addressStaking = STAKING_ARB_MAINNET;
        contract = ABI_STAKING;
        break;
      default:
        addressStaking = STAKING_ARB_MAINNET;
        contract = ABI_STAKING;
        break;
    }
    const totalRaised = await getUserStakingAmount(
      poolIndex,
      addressStaking,
      contract,
      account
    );
    setAmount(totalRaised);
  };

  useEffect(() => {
    if (!account) return;
    getAmount();
  }, [account, amount, chainId, poolIndex]);

  return (
    <ModalStakingBox>
      <div className="box">
        <h6>Staked XRAI</h6>
        <div className="action d-flex justify-content-between align-items-center">
          <div className="token">
            <h6>
              {new Intl.NumberFormat().format(new Decimal(amount || 0))}{" "}
              <span>${"XRAI"}</span>
            </h6>
          </div>
          <button
            onClick={() => {
              account &&
                onOpen(
                  <ModalStaking
                    type="staking"
                    balance={tokenBalance}
                    poolIndex={poolIndex}
                    onRefetchStake={() => {
                      getAmount();
                      onRefetchStake();
                    }}
                  />
                );
            }}
            style={{
              cursor: !account && "not-allowed",
            }}
            disable={!account}
            className="btn-stake"
          >
            Stake
          </button>
        </div>
      </div>
    </ModalStakingBox>
  );
};

const ModalStakingBox = styled.div`
  width: 490px;
  .input-amount {
    p {
      color: #000000;
      font-size: 16.496px;
      font-style: normal;
      font-weight: 500;
      line-height: 28.28px; /* 171.429% */
    }
    input {
      border: 1px solid #ed13d2;
      color: #000000;
    }
  }
  .action {
    gap: 20px;
    button {
      width: 50%;
      padding: 12.961px 23.566px;
      &:nth-child(1) {
        background-color: #ffffff;
        color: #ed13d2;
        &:hover {
          background-color: #ed13d2;
          color: #ffffff;
        }
      }
      &:nth-child(2) {
        &:hover {
          background-color: #ffffff;
          color: #ed13d2;
        }
      }
    }
  }
`;

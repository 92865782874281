import styled from "styled-components";

export const ProgressContainer = styled.div`
  .ant-steps-item-active,
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: #F313CE !important;
    }
    .ant-steps-item-title {
      color: #F313CE !important;
      &::after {
        border-color: #F313CE !important;
        background-color: transparent !important;
      }
    }
  }
  .ant-steps-item-icon {
    background-color: #717696 !important;
    border: none;
  }
  .ant-steps-item-title {
    color: #717696 !important;
    font-size: 18px;
    font-weight: bold;
    &::after {
      background-color: transparent !important;
      border: 1px dashed #717696;;
    }
  }
  .ant-steps-icon {
    color: #fff !important;
    font-weight: bold;
  }
  @media screen and (max-width: 743px) {
    min-width: auto !important;
  }
  @media screen and (max-width: 1023px) {
    .ant-steps-item-title {
      font-size: 14px;
    }
  }
`
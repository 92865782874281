export const EVENT_NAME = [
  {
    key: "LISTING",
    name: "List",
    image: "/assets/icon/icon-list.png",
  },
  {
    key: "UPDATE_PRICE",
    name: "Update Price",
    image: "/assets/icon/icon-update-price.png",
  },
  {
    key: "DELISTING",
    name: "Cancel List",
    image: "/assets/icon/icon-cancel.png",
  },
  {
    key: "SOLD",
    name: "Sale",
    image: "/assets/icon/icon-sale.png",
  },
  {
    key: "MINT",
    name: "Mint",
    image: "/assets/icon/icon-mint.png",
  },
  {
    key: "TRANSFER",
    name: "Transfer",
    image: "/assets/icon/icon-transfer.png",
  },
];
export const defineName = (event) => {
  if (!event) return "";
   const eventActive = EVENT_NAME.find((item) => item.key === event);
  return eventActive;
};

import { useState, useEffect } from "react";
import {
  MultiSenderContainer,
  SenderBody,
  AddBlock,
  AddWrapper,
  AddressBlock,
  FormContainer,
  ShowBlock,
  ShowRow,
  RowHash,
} from "./styled";
import { TextCustom } from "../../../components/common/styled";
import {
  ButtonCommon,
  TextAreaCommon,
  TitleKey,
  V2Buttons,
} from "../../../components/common/styled";
import web3 from "web3";
import approve from "../../../utils/Tooldex/approve";
import InputCommon from "../../../components/common/input";
import ABI from "../../../constants/abi/abiMultisend.json";
import { Input, Upload } from "antd";
import {
  useAccount,
  useNetwork,
} from "wagmi";
import upload from "../../../assets/images/common/form/upload.svg";
import download from "../../../assets/images/common/form/download.svg";
import getTokenInfo from "../../../utils/checkInfoByAccount";
import {
  SCAN_URL,
  MULTISEND_CONTRACT,
  HTTPS_NETWORK,
  token_network,
} from "../../../constants/Tooldex/index";
import * as XLSX from "xlsx";
import { AbiItem } from "web3-utils";
import { convertFromWei, convertToWei } from "../../../utils/Tooldex/convertNumber";
import { notiError, notiSuccess, notiWarning } from "../../../utils/Tooldex/notication";
import Loading from "../../../components/Tooldex/Loading";
import CurrencyLogo from "../../../components/CurrencyLogo";

declare const window: Window & typeof globalThis & { ethereum: any };

const MultiSender = () => {

  const { address } = useAccount();
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const account: any = address;
    const Web3 = new web3(window.ethereum);
  const [tokenAddress, setTokenAddress] = useState<any>("");
  const [tokenInfo, setTokenInfo] = useState<any>();
  const [recipients, setRecipients] = useState<any>("");
  const [isApprove, setApprove] = useState<any>(false);
  const [isApproving, setApproving] = useState<any>(false);
  const [isConfirming, setConfirming] = useState<any>(false);
  const [isAddToken, setAddToken] = useState<any>(false);
  const [isTransactionHash, setTransactionHash] = useState<any>("");
  const { TextArea } = Input;

  const multiContract = new Web3.eth.Contract(
    ABI as unknown as AbiItem as AbiItem,
    MULTISEND_CONTRACT[chainId]
  );

  const recipientList = recipients
    .trim()
    .split("\n")
    .map((row: any) => {
      const [address, value] = row.split(",");
      return { address, value };
    });

  const addresses = recipientList.map((item: any) => item?.address?.trim());

  const values = recipientList.map((item: any) => {
    const trimmedValue = item?.value?.trim();
    if (tokenInfo) {
      if (trimmedValue && !isNaN(Number(trimmedValue)) && tokenInfo) {
        const toWeiValue = convertToWei(
          trimmedValue.toString(),
          Number(tokenInfo.decimals)
        );
        return toWeiValue.toString();
      } else {
        return "0";
      }
    } else {
      if (trimmedValue && !isNaN(Number(trimmedValue))) {
        const toWeiValue = convertToWei(trimmedValue.toString(), 18);
        return toWeiValue.toString();
      } else {
        return "0";
      }
    }
  });

  const handleLoadDataToken = async () => {
    try {
      if(Web3.utils.isAddress(tokenAddress)){
        setAddToken(true)
        const data = await getTokenInfo(
          MULTISEND_CONTRACT[chainId],
          tokenAddress,
          account
        );
        setTokenInfo(data);
        setAddToken(false)
      } 
      else {
        notiWarning('Token address error, please check',5)
      }
      
    } catch (error) {
      setTokenInfo({});
      setAddToken(false)
      console.error(error);
      notiError("Add Token Failed!", 5);
    }
  };
  const handleFileUpload = (file: any) => {
    try {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const filteredData = jsonData.filter(
          (row: any) =>
            Web3.utils.isAddress(row[0]?.trim()) &&
            !isNaN(row[1]?.toString().trim())
        );

        const formattedData = filteredData.map((row: any, index: any) => {
          const [address, amountToken]: any = row;
          return `${address.trim()}, ${amountToken.toString().trim()}`;
        });

        setRecipients(formattedData.join("\n"));
      };

      reader.readAsArrayBuffer(file);
      notiSuccess("Upload File Successfully!", 5);
    } catch (error: any) {
      console.error(error);
      notiError("Upload File Failed", 5);
    }
  };

  const exportToExcel = () => {
    const data = [
      {
        Address: "0x075AA49136664628E588493671d323928FD8835A",
        AmountToken: "123",
      },
      {
        Address: "0xc2E311e9FA6B43002f02d5835D560f03c59604D7",
        AmountToken: "123",
      },
      {
        Address: "0xD9888a6A6dA9A05091adb798e382E8a499C03F71",
        AmountToken: "123",
      },
      {
        Address: "0x9dfeb78168826d95C75306832414705D19096979",
        AmountToken: "123",
      },
      {
        Address: "0xa901FB223f7d5deff626E7A3E78f77344df42b0E",
        AmountToken: "123",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");
    XLSX.writeFile(workbook, "Sample.xlsx");
  };
  function getSum(total: any, num: any) {
    if (tokenInfo) {
      return total + Number(convertFromWei(num, tokenInfo?.decimals));
    }
    return total + Number(convertFromWei(num, 18));
  }
  const totalValue = values.reduce(
    (total: any, value: any) => getSum(total, value),
    0
  );
  const valueWei = values.reduce(
    (total: any, value: any) => total + Number(value),
    0
  );

  useEffect(() => {
    if (tokenInfo) {
      if (totalValue > Number(tokenInfo?.allowance)) {
        setApprove(true);
      } else {
        setApprove(false);
      }
    } else {
      setApprove(false);
    }
  }, [tokenAddress, tokenInfo, totalValue]);

  useEffect(() => {
    if (
      !tokenAddress ||
      tokenInfo?.address.toUpperCase() !== tokenAddress.toUpperCase()
    ) {
      setTokenInfo(undefined);
    }
  }, [tokenAddress, tokenInfo]);

  const handleApprove = async () => {
    try {
      setApproving(true);
      await approve(MULTISEND_CONTRACT[chainId], tokenAddress, account).then(
        (res) => {
          if (res.status) {
            handleLoadDataToken();
            setApproving(false);
          }
        }
      );
    } catch (error) {
      setApproving(false);
      console.error(error);
    }
  };
  const handleSendTokens = async () => {
    try {
      setConfirming(true);
      const gasPrice = await Web3.eth.getGasPrice();
      const feeBNB = await multiContract.methods.FEE_SERVICE().call();
      await multiContract.methods
        .multisendToken(tokenAddress, addresses, values)
        .send({
          from: account,
          value: feeBNB,
          gasPrice: Web3.utils.toHex(String(gasPrice)),
        })
        .then((res: any) => {
          if (res.status) {
            handleLoadDataToken();
            setTransactionHash(res.transactionHash);
            setConfirming(false);
            notiSuccess("Send Token Successfully!", 5);
          }
        });
    } catch (error) {
      setConfirming(false);
      console.error(error);
      notiError("Send Token Failed!", 5);
    }
  };
  const handleSendTokenBase = async () => {
    try {
      setConfirming(true);
      const gasPrice = await Web3.eth.getGasPrice();
      const feeBNB = await multiContract.methods.FEE_SERVICE().call();
      const totalSend = valueWei + Number(feeBNB);
      await multiContract.methods
        .multisendTokenBase(addresses, values)
        .send({
          from: account,
          value: totalSend,
          gasPrice: Web3.utils.toHex(String(gasPrice)),
        })
        .then((res: any) => {
          if (res.status) {
            setTransactionHash(res.transactionHash);
            setConfirming(false);
            notiSuccess("Send Token Successfully!", 5);
          }
        });
    } catch (error) {
      setConfirming(false);
      console.error(error);
      notiError("Send Token Failed!", 5);
    }
  };
  return (
    <MultiSenderContainer>
      <SenderBody>
        <TextCustom fontSize="24px" color="#fff" fontWeight="bold">
          Token MultiSender
        </TextCustom>

        <FormContainer>
          <AddBlock>
            <TitleKey>Token address</TitleKey>
            <AddWrapper>
              <InputCommon
                placeHolder="Token address"
                value={tokenAddress}
                onChange={(e: any) => setTokenAddress(e.target.value)}
              />
              <ButtonCommon
                disabled={!tokenAddress || !account || isAddToken}
                background="#fff"
                textColor="#F313CE"
                style={{
                  minWidth: "140px",
                  padding: "9px 20px",
                  border: (tokenAddress && account) && "2px solid #F313CE",
                }}
                onClick={handleLoadDataToken}
              >
                <Loading status={isAddToken} content='Add Token' />
              </ButtonCommon>
            </AddWrapper>
            <TextCustom
              style={{ marginTop: "5px", fontStyle: "italic" }}
              fontSize="14px"
              fontWeight="500"
              color="#717696"
            >
              *Limit 1000 addresses for 1 transfer.
              <br />
              *If the token address is not entered, the {token_network(
                chainId
              )}{" "}
              is default
            </TextCustom>
          </AddBlock>
          {tokenInfo?.name && (
            <ShowBlock>
              <ShowRow>
                Name :<span>{tokenInfo?.name}</span>
              </ShowRow>
              <ShowRow style={{ display: "flex", alignItems: "center" }}>
                Symbol :
                <span style={{ display: "flex", alignItems: "center" }}>
                  {tokenInfo?.symbol}{" "}
                  <CurrencyLogo address={tokenInfo?.address} />
                </span>
              </ShowRow>
              <ShowRow>
                Total Supply :
                <span>
                  {new Intl.NumberFormat("en-US", {
                    notation: "compact",
                    compactDisplay: "short",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(tokenInfo?.supply)}{" "}
                  {tokenInfo?.symbol}
                </span>
              </ShowRow>
              <ShowRow>
                Balance Token :
                <span>
                  {new Intl.NumberFormat("en-US", {
                    notation: "compact",
                    compactDisplay: "short",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(tokenInfo?.balanceOf)}{" "}
                  {tokenInfo?.symbol}
                </span>
              </ShowRow>
              <ShowRow>
                Contract :<span>{tokenInfo?.address}</span>
              </ShowRow>
            </ShowBlock>
          )}
          <AddressBlock>
            <TitleKey>Address List</TitleKey>
            <TextAreaCommon>
              <TextArea
                value={recipients}
                onChange={(e) => setRecipients(e.target.value)}
                placeholder="Enter Address, Amount on each line. &#10;Supports only format. &#10;Eg: Address..., Amount token &#10;Eg: 0x000000000000000000000000000000000000, 100000"
                style={{ height: 220, resize: "none" }}
              />
            </TextAreaCommon>
          </AddressBlock>
          <V2Buttons>
            <Upload
              accept=".xlsx, .xls"
              showUploadList={false}
              beforeUpload={handleFileUpload}
            >
              <ButtonCommon
                className="action-button"
                background={"#fff"}
                textColor={"#0D0C43"}
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={upload}
                  alt="upload"
                />
                Upload Excel
              </ButtonCommon>
            </Upload>

            <ButtonCommon
              className="action-button"
              background={"#fff"}
              textColor={"#0D0C43"}
              onClick={exportToExcel}
            >
              <img
                style={{ width: "20px", height: "20px" }}
                src={download}
                alt="download"
              />
              Sample file
            </ButtonCommon>
          </V2Buttons>
          {isTransactionHash && (
            <>
              <RowHash>
                Transaction Hash :{" "}
                <span>
                  <a
                    href={`${[
                      SCAN_URL[chainId],
                    ]}tx/${isTransactionHash.toLowerCase()}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <u>{`${isTransactionHash?.substring(
                      0,
                      15
                    )}........${isTransactionHash?.substring(
                      isTransactionHash.length - 12
                    )}`}</u>
                  </a>
                </span>
              </RowHash>
            </>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            { chainId === 250 || chainId === 43114 ? (
              <>
                <ButtonCommon disabled style={{ minWidth: "245px" }}>
                  <Loading status={isApproving} content="Coming Soon!" />
                </ButtonCommon>
              </>
            ) : (
              <>
                {isApprove ? (
                  <ButtonCommon
                    disabled={!account || isApproving || !recipients}
                    style={{ minWidth: "245px" }}
                    onClick={handleApprove}
                  >
                    <Loading status={isApproving} content="Approve" />
                  </ButtonCommon>
                ) : (
                  <ButtonCommon
                    disabled={!account || isConfirming || !recipients}
                    style={{ minWidth: "245px" }}
                    onClick={tokenInfo ? handleSendTokens : handleSendTokenBase}
                  >
                    <Loading status={isConfirming} content="Confirm" />
                  </ButtonCommon>
                )}
              </>
            )}
          </div>
        </FormContainer>
      </SenderBody>
    </MultiSenderContainer>
  );
};

export default MultiSender;

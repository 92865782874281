import styled from "styled-components";
import { TitleKey } from "../../../../../components/common/styled";
import { SelectContainer } from "../../../../../components/common/selectInput/styled";

export const TokenPairContainer = styled.div`
`
export const TPBody = styled.div`
  background-color: #fff;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #DCE2E9;
  text-align: left;
  max-width: 900px;
  margin: 0 auto;
  min-height: 426px;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 30px;
  ${TitleKey} {
    margin-bottom: 5px;
  }
  @media screen and (max-width: 1023px) {
    max-width: 100%;
  }
`
export const AddLP = styled.div`
  margin-bottom: 20px;
`
export const PairItem = styled.div`
  ${SelectContainer} {
    .ant-select-selector {
      min-height: 48px;
    }
  }
`
export const BlockPair = styled.div`
  width: 50%;
  min-height: 310px;
  padding: 20px;
  border-radius: 10px;
  background: #F5F5F5;
  & > ${PairItem}:not(:last-child) {
    margin-bottom: 20px;
  }
  & > ${TitleKey} {
    font-weight: bold;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 743px) {
    width: 100%;
  }
`
export const PairWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 743px) {
    display: block;
    ${BlockPair}:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`
export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  gap: 5px;
  p {
    margin-bottom: 0;
    line-height: normal;
    color: rgba(13, 12, 67, 0.70);
    span {
      color: #0D0C43;
    }
  }
  ${TitleKey} {
    white-space: nowrap;
    color: rgba(13, 12, 67, 0.70);
    margin-bottom: 0;
    span {
      color: #1853EC;
      font-style: italic;
      letter-spacing: 0.14px;
      text-decoration-line: underline;
    }
  }
  div {
    display: flex;
    align-items: center;
  }
  img {
    margin-left: 5px;
  }
  @media screen and (max-width: 1150px) {
    flex-wrap: wrap;
    gap: 5px;
  }
`
export const TransferSuccess = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #F313CE;
  background: rgba(0, 178, 200, 0.05);
  padding: 15px 22px;
  min-height: 40px;
  margin-bottom: 20px;
  ${TitleKey} {
    margin-bottom: 0;
    color: rgba(13, 12, 67, 0.70);
    font-style: italic;
    span {
      color: #F313CE;
      font-weight: 400;
      margin-right: 3px;
    }
  }
`

export const CCConfirm = styled.div`
  display: flex;
  text-align: left;
  border-radius: 10px;
  background: #F5F5F5;
  padding: 30px 20px;
  gap: 50px;
  margin-bottom: 20px;
  justify-content: center;
  @media screen and (max-width: 743px) {
    display: block;
  }
`
export const ConfirmCol = styled.div`
  ${TitleKey} {
    color: #717696;
    margin-bottom: 10px;
  }
  p {
    display: flex;
    align-items: center;
    color: #0D0C43;
    margin-bottom: 0;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  @media screen and (max-width: 743px) {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    p {
      font-size: 15px;
    }
    ${TitleKey} {
      color: #000;
      margin-bottom: 5px;
      font-size: 18px;
    }
  }
`

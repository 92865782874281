import styled from "styled-components";
import { TextCustom } from "../../../components/common/styled";
import { ProgressContainer } from "../../../components/common/progress/styled";

export const LiquidityLockupContainer = styled.div`
  position: relative;
  z-index: 5;
  .keyvisual-title {
    line-height: normal;
    margin-bottom: 15px;
    span {
      display: none;
    }
  }
  .keyvisual-content {
    ${TextCustom} {
      margin-bottom: 0;
    }
  }
  ${ProgressContainer} {
    margin-bottom: 35px;
  }
`
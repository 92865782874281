import styled from "styled-components";

export const LPV3Container = styled.div`
  padding: 30px;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  @media screen and (max-width: 1023px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`
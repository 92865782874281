import styled from "styled-components";

export const SelectContainer = styled.div`
  .ant-select {
    color: #717696;
  }
  .ant-select-selector {
    height: 38px !important;
    border-radius: 10px !important;
    align-items: center;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #F313CE !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }
`
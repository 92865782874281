import { getProviderOrSigner, getArbitrumPublicProvider } from "./index";
import { ethers } from "ethers";
import { EthereumTokenAddress } from "../../utils/constants";
import { Decimal } from "decimal.js";
import { totalXrenderIco } from "../../utils/constants";
import { ERC20_ABI } from "../ABI";
export const handleStaking = async (
  poolIndex,
  amount,
  stakingAddress,
  stakingABI
) => {
  try {
    console.log({poolIndex,amount})
    const signer = await getProviderOrSigner(true);
    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingABI,
      signer
    );
    const t = await stakingContract.stake(
      poolIndex,
      new Decimal(amount).mul(new Decimal(10).pow(18)).toHex()
    );
    await t.wait();
  } catch (error) {
    throw error;
  }
};

export const handleUnStaking = async (
  poolIndex,
  stakingAddress,
  stakingABI
) => {
  try {
    const signer = await getProviderOrSigner(true);
    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingABI,
      signer
    );
    const t = await stakingContract.unstake(
      poolIndex
    );
    await t.wait();
  } catch (error) {
    throw error;
  }
};

export const harvest = async (poolIndex, stakingAddress, stakingABI) => {
  try {
    const signer = await getProviderOrSigner(true);
    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingABI,
      signer
    );
    const address = await signer.getAddress();
    const t = await stakingContract.harvest(poolIndex, address);
    await t.wait();
  } catch (error) {
    throw error;
  }
};

export const calculateReward = async (
  poolIndex,
  stakingAddress,
  stakingABI,
  account
) => {
  try {
    const provider = await getProviderOrSigner(true);

    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingABI,
      provider
    );
    const userAddress = account || ethers.constants.AddressZero;

    const reward = await stakingContract.calculateReward(
      poolIndex,
      userAddress
    );
    console.log({poolIndex,reward,userAddress})
    const formatedReward = new Decimal(reward._hex || 0)
      .div(new Decimal(10).pow(18))
      .toFixed(6);
    return formatedReward;
  } catch (error) {
    console.log("Error", error);
  }
};
export const getUserStakingAmount = async (
  poolIndex,
  stakingAddress,
  stakingABI,
  account
) => {
  try {
    const provider = await getProviderOrSigner(true);
    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingABI,
      provider
    );

    const userAddress = account || ethers.constants.AddressZero;

    const userAmount = await stakingContract.getStakedAmount(
      poolIndex,
      userAddress
    );
    const amount = new Decimal(userAmount._hex)
      .div(new Decimal(10).pow(18))
      .toFixed(2);
    return amount;
  } catch (error) {
    console.log("Error", error);
  }
};


export const getUserInfo = async (
  poolIndex,
  stakingAddress,
  stakingABI,
  account
) => {
  try {
    const provider = await getProviderOrSigner(true);
    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingABI,
      provider
    );

    const userAddress = account || ethers.constants.AddressZero;

    const userInfo = await stakingContract.userStakes(
      userAddress,
      poolIndex
    );
    return userInfo;
  } catch (error) {
    console.log("Error", error);
  }
};


export const getTotalStaked = async (
  poolIndex,
  stakingAddress,
  stakingABI,
) => {
  try {
    const provider = await getProviderOrSigner(false);
    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingABI,
      provider
    );


    const totalStaked = await stakingContract.getTotalStaked(
      poolIndex,
    );
    const amount = new Decimal(totalStaked._hex)
      .div(new Decimal(10).pow(18))
      .toFixed(2);
    return amount;
  } catch (error) {
    console.log("Error", error);
  }
};


const ArrowKeyvisual = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.26517 3.51516C9.41161 3.36872 9.41161 3.13128 9.26517 2.98483L6.87868 0.598349C6.73223 0.451902 6.4948 0.451902 6.34835 0.598349C6.2019 0.744796 6.2019 0.982233 6.34835 1.12868L8.46967 3.25L6.34835 5.37132C6.2019 5.51777 6.2019 5.7552 6.34835 5.90165C6.4948 6.0481 6.73223 6.0481 6.87868 5.90165L9.26517 3.51516ZM0.75 3.625L9 3.625L9 2.875L0.75 2.875L0.75 3.625Z"
        fill="#FCFCFD"
      />
    </svg>
  );
};

export default ArrowKeyvisual;

import { ethers } from "ethers";

export const getProviderOrSigner = async (needSigner = false) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    if (needSigner) {
      const signer = provider.getSigner();
      return signer;
    }
    return provider;
  } catch (error) {}
};

export const getPriceMintGenAI = async (address_NFT_Chain, abi_NFT_Chain) => {
  try {
    const provider = await getProviderOrSigner(false);
    const contractMKP = new ethers.Contract(
      address_NFT_Chain,
      abi_NFT_Chain,
      provider
    );

    const price = await contractMKP.getPriceMint();
    return price;
  } catch (error) {}
};

export const mintNFTGenAi = async (
  address_NFT_Chain,
  abi_NFT_Chain,
  valuePrice,
  linkImage
) => {
  try {
    const signer = await getProviderOrSigner(true);
    const contractMKP = new ethers.Contract(
      address_NFT_Chain,
      abi_NFT_Chain,
      signer
    );
    let tx = "";
    if (valuePrice) {
      tx = await contractMKP.mint(linkImage, {
        value: ethers.utils.parseEther(valuePrice.toString()),
      });
    } else {
      tx = await contractMKP.mint(linkImage);
    }
    const receipt = await tx?.wait();
    return receipt;
  } catch (error) {
    console.log("ERROR", error);
    throw error;
  }
};

import styled from "styled-components";
import effect from "../../../assets/images/homenew/keyvisual/keyvisual_effect.png";
import community_bg from "../../../assets/images/homenew/keyvisual/community_bg.png";
import effect_sp from "../../../assets/images/homenew/keyvisual/sp/keyvisual_effect.png";
import void_effect from "../../../assets/images/homenew/keyvisual/gif/void_effect.gif";

export const KeyvisualXContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 40px;
  margin-bottom: 10vw;
  overflow: hidden;
  min-height: calc(100vh - 80px);
  &::before {
    content: "";
    z-index: 50;
    height: 100px;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0) 92%);
    inset: auto 0% 0%;
    margin-top: -2px;
    position: absolute;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 100px;
    margin-bottom: 70px;
  }
`;
export const KeyvisualBgLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  z-index: 0;
`;
export const Line = styled.div`
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  z-index: 0;
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    z-index: 0;
    background: linear-gradient(90deg, #f881f3 0%, #bc81f8 100%);
    animation: drop 7s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  }
  &:nth-child(1) {
    margin-left: -25%;
    &::after {
      animation-delay: 2s;
    }
  }
  &:nth-child(3) {
    margin-left: 25%;
    &::after {
      animation-delay: 2.5s;
    }
  }
  &:nth-child(4) {
    margin-left: 50%;
    &::after {
      animation-delay: 3s;
    }
  }
  &:nth-child(5) {
    margin-left: -50%;
    &::after {
      animation-delay: 1.5s;
    }
  }

  @keyframes drop {
    0% {
      top: -50%;
    }
    100% {
      top: 110%;
    }
  }
`;
export const KeyvisualWrapper = styled.div`
  position: relative;
  max-width: 1728px;
  padding: 20px 144px;
  width: 100%;
  margin: 0 auto;
  gap: 10px;
  display: flex;
  align-items: center;
  z-index: 2;
  @media screen and (max-width: 1440px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 120px;
  }
`;
export const KeyvisualLeft = styled.div`
  max-width: 764px;
  position: relative;
  z-index: 10;
  h1 {
    color: #fff;
    font-size: 45px;
    font-weight: 500;
    line-height: 57.75px;
    margin-bottom: 15px;
  }
  & > p {
    margin-bottom: 40px;
    color: #fff;
    font-size: 16px;
    line-height: 19.5px;
    font-weight: 300;
  }
  @media screen and (min-width: 768px) and (max-width: 1440px) {
    width: 55%;
  }
  @media screen and (max-width: 1023px) {
    .sm {
      display: none;
    }
    h1 {
      font-size: 29px;
    }
    & > p {
      font-size: 15px;
      color: #98a2b3;
    }
  }
  @media screen and (max-width: 919px) {
    flex-direction: column;
    gap: 50px;
    max-width: 100%;
  }
  @media screen and (max-width: 767px) {
    max-width: unset;
    h1 {
      text-align: center;
      line-height: 32px;
    }
    p {
      text-align: center;
    }
  }
`;
export const KeyvisualAction = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  @media screen and (max-width: 1023px) {
    margin-bottom: 50px;
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
    gap: 10px;
  }
`;
export const ButtonMint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 12px 24px;
  border-radius: 9px;
  background: linear-gradient(90deg, #6650ff 0%, #9818aa 100%);
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
  }
`;
export const ButtonTry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 12px 24px;
  border-radius: 9px;
  background: transparent;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
  }
`;
export const ExternalButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fcfcfd;
  font-size: 11px;
  border-radius: 6px;
  padding: 10px;
  gap: 6px;
  font-weight: 600;
  border: 1px solid transparent;
  background: rgba(78, 78, 78, 0.19);
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  p {
    margin-bottom: 0;
    color: #fff;
    font-size: 11px;
  }
`;
export const ExternalIcon = styled.div`
  max-width: 20px;
  max-height: 20px;
  display: flex;
  margin-bottom: 0;   
  svg {
    height: 100%;
    width: 100%;
  }
`;
export const KeyvisualExternal = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  margin-bottom: 0;
  & > li {
    position: relative;
    display: block;
    width: 50%;
    padding: 20px 15px;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(19,19,19, .85);
      backdrop-filter: blur(8px);
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(${void_effect}) no-repeat center / 100% auto;
      z-index: 1;
      opacity: 0;
      transform: scale(0);
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    figure {
      width: 47px;
      img {
        width: 100%;
      }
    }
    &:hover {
      &::before {
        transform: scale(1.1);
        opacity: 0.5;
      }
      ${ExternalButton} {
        border-color: #7c7c7c;
        background: rgba(0, 0, 0, 0.45);
      }
      &:nth-child(1) {
        ${ExternalIcon} {
          svg {
            path {
              fill: #b5ff58;
            }
          }
        }
      }
      &:nth-child(2) {
        ${ExternalIcon} {
          svg {
            path {
              stroke: #fff735;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1085px) {
    flex-direction: column;
    & > li {
      width: 100%;
    }
  }
`;
export const ExternalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
export const ExternalLeft = styled.div`
  & > p {
    margin-bottom: 0;
    font-size: 10px;
    color: #fff;
  }
  h2 {
    color: #f9fafb;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;
export const KeyvisualRight = styled.div`
  position: relative;
  max-width: 450px;
  width: 100%;
  margin-left: auto;
  min-height: 600px;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: url(${effect}) no-repeat center / 100% auto;
    width: 438px;
    height: 433px;
    mix-blend-mode: lighten;
    animation: opacity 3s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  &::after {
    content: "";
    position: absolute;
    top: -80px;
    left: 50%;
    filter: blur(120px);
    width: 100%;
    padding-bottom: 100%;
    background-color: rgba(255, 67, 236, 0.7);
    animation: opacity 3s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 0;
  }
  @keyframes opacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1440px) {
    width: 50%;
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto;
    z-index: -1;
    min-height: 470px;
    &::before {
      background-image: url(${effect_sp});
      width: 301px;
      height: 297px;
      left: 50%;
      margin-left: -145px;
    }
    &::after {
      top: 0;
      left: 50%;
      width: 258px;
      height: 388px;
      margin-left: -140px;
      filter: blur(65px);
      opacity: 0.4;
      background: #ff43ec;
      border-radius: 388px;
    }
  }
`;
export const RightRobot = styled.figure`
  position: relative;
  max-width: 305px;
  width: 100%;
  margin: 0 auto;
  z-index: 5;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: translate 5s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
  img {
    width: 100%;
    height: auto;
  }
  @keyframes translate {
    0% {
      transform: translateY(-20%);
    }
    50% {
      transform: translateY(calc(-20% + 40px));
    }
    100% {
      transform: translateY(-20%);
    }
  }
`;
export const RightImage = styled.ul`
  position: absolute;
  top: 40%;
  left: 20px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  z-index: 5;
  & > li {
    padding: 5px;
    border-radius: 12px;
    border: 1px solid #fff;
    background: rgba(64, 27, 90, 0.12);
    backdrop-filter: blur(27px);
    &:nth-child(2) {
      margin: 0 -20px -60px;
      z-index: 5;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  @media screen and (max-width: 1440px) {
    width: 100%;
    left: 0;
  }
`;

import { useState,useEffect } from "react"
import { ButtonCommon, RadioCommon, TextCommon, TextCustom } from "../../styled"
import { NetworkHeader, NetworkInner, NetworkList, SelectNetworkBody, SelectNetworkContainer } from "./styled"


import { Radio } from "antd";
import type { RadioChangeEvent } from 'antd';

const SelectNetwork = ({ setStep }: any) => {
  const [networkCheck, setNetworkCheck] = useState<any>();
  const networkSellect = localStorage.getItem("chainNetwork");

  const handleCheck = (e: RadioChangeEvent) => {
    setNetworkCheck(e.target.value)
    window.localStorage.setItem("chainNetwork", e.target.value);
  }
  useEffect(()=>{
    if(networkSellect){
      setNetworkCheck(Number(networkSellect))
    }

  },[networkSellect])
  return (
    <SelectNetworkContainer>
      <SelectNetworkBody>
        <NetworkHeader>
          <TextCustom color='#0D0C43' fontSize='24px' fontWeight='bold'>
            Select nework
          </TextCustom>
          <TextCommon>
            Choose the blockchain that you want to create your token on.
          </TextCommon>
        </NetworkHeader>
        <NetworkList>
          {networkData.map((item) => {
            return (
              <li
                className={networkCheck === item.id ? "current" : ""}
                key={item.id}
              >
                <RadioCommon>
                  <Radio.Group
                    onChange={(e: any) => handleCheck(e)}
                    value={networkCheck}
                  >
                    <Radio value={item.id}>
                      <NetworkInner>
                        <div >
                          <img style={{borderRadius: '50px'}} src={item.img} alt={item.name} />
                        </div>
                        <div>
                          <TextCustom
                            fontWeight="600"
                            fontSize="16px">{item.name}
                          </TextCustom>
                          <span>{item.subName}</span>
                        </div>
                      </NetworkInner>
                    </Radio>
                  </Radio.Group>
                </RadioCommon>
              </li>
            )
          })}
        </NetworkList>
        <ButtonCommon
          onClick={() => setStep(2)}
          style={{ margin: "0 auto" }}
          disabled={!networkCheck}
          background={networkCheck ? "linear-gradient(90deg, #6650FF 0%, #9818AA 100%)" : "#CFD0D6 "}
          textColor={networkCheck ? "#fff" : "#81829B"}
        >
          Comfirm
        </ButtonCommon>
      </SelectNetworkBody>
    </SelectNetworkContainer>
  )
}
const networkData = [
  // {
  //   id: 97,
  //   name: "BSC Testnet",
  //   subName: "BNB",
  //   img: "/images/bnb.png?v=5"
  // },
  {
    id: 56,
    name: "BSC Chain",
    subName: "BNB",
    img: "/images/bnb.png?v=5"
  },
  // {
  //   id: 1,
  //   name: "Ethereum",
  //   subName: "ETH",
  //   img: "/images/ether.png?v=5"
  // },
  {
    id: 42161,
    name: "Arbitrum",
    subName: "ARB",
    img: "/images/arb.png?v=5"
  },
  {
    id: 204,
    name: "opBNB",
    subName: "BNB",
    img: "/images/bnb.png?v=5"
  },
  // {
  //   id: 137,
  //   name: "Polygon",
  //   subName: "MATIC",
  //   img: "/images/poly.png?v=5"
  // },
  // {
  //   id: 250,
  //   name: "Fantom",
  //   subName: "FTM",
  //   img: "/images/fantom.png?v=5"
  // },
  // {
  //   id: 43114,
  //   name: "Avalanche",
  //   subName: "AVAX",
  //   img: "/images/avax.png?v=5"
  // },
  // {
  //   id: 8453,
  //   name: "Base",
  //   subName: "BASE",
  //   img: "/images/base.png?v=5"
  // },
]

export default SelectNetwork
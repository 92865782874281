import KeyvisualCommon from "../../../components/common/keyvisual";
import { AuditContractBody, AuditContractContainer } from "./styled";
import { TextCustom } from "../../../components/common/styled";
import AuditContractDetails from "./Details";

const AuditContract = () => {
  return (
    <AuditContractContainer>
      <KeyvisualCommon
        text="Audit Contract"
        content={
          <TextCustom color="#fff" fontSize="16px" fontWeight="300">
            Disclaimer: does not constitute any investment advice, only for
            reference!
          </TextCustom>
        }
      />
      <AuditContractBody>
        <AuditContractDetails />
      </AuditContractBody>
    </AuditContractContainer>
  );
};

export default AuditContract;

import Decimal from "decimal.js";
import { ethers } from "ethers";

export const formatPriceNFT = (price) => {
  if (!price) return 0;
  const number =
    price.toString().search("e") !== -1
      ? Number(new Decimal(price || 0).toFixed(2) / 10 ** 18).toFixed(2)
      : ethers?.utils?.formatEther(price.toString());
  return Number(number) > 100
    ? Number(number)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    : number;
};

import Web3 from "web3";
import { convertFromWei } from "../../utils/Tooldex/convertNumber";
import { SC_LOCK_LP_V2 } from "../../constants/Tooldex/index";
import ABI from "../../constants/Tooldex/abi/personal/abiLockLiquidity.json";
import tokenABI from "../../constants/Tooldex/abi/abiToken.json";

const web3 = new Web3(window.ethereum);

var getDexName = new Map();
getDexName.set("0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73", "PancakeSwap");

export const getSymbol = async (address) => {
  const contract = new web3.eth.Contract(tokenABI, address);
  return await contract.methods.symbol().call();
};
export const getName = async (address) => {
  const contract = new web3.eth.Contract(tokenABI, address);
  return await contract.methods.name().call();
};

export const getLiquidityList = async (account, chainId) => {
  const contract = new web3.eth.Contract(ABI, SC_LOCK_LP_V2[chainId]);
  return await contract.methods.lpLocksForUser(account).call();
};

export const getLiquidityInformation = async (
  PancakePairABI,
  lpAdress,
  account,
  chainId
) => {
  if (typeof window.ethereum !== "undefined") {
    const contract = new web3.eth.Contract(PancakePairABI, lpAdress);

    const name = await contract.methods.name().call();
    const token0 = await contract.methods.token0().call();
    const token1 = await contract.methods.token1().call();
    const decimals = await contract.methods.decimals().call();
    const tokenAmount = await contract.methods.balanceOf(account).call();
    const factory = await contract.methods.factory().call();
    const token0Name = await getSymbol(token0);
    const lpToken0Name = await getName(token0);
    const token1Name = await getSymbol(token1);
    const lpToken1Name = await getName(token1);
    const liquidityList = await getLiquidityList(account, chainId);

    return {
      lpName: name,
      lpTokenname: lpToken0Name + "/" + lpToken1Name,
      lpAddress: lpAdress,
      pair: token0Name + "/" + token1Name,
      dex: getDexName.get(factory),
      balance: convertFromWei(Number(tokenAmount), decimals),
      decimals: decimals,
      liquidityList: liquidityList,
      lpToken0Name: lpToken0Name,
      lpToken1Name: lpToken1Name,
      token0: token0,
      token1: token1,
    };
  }
};

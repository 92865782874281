import { useState } from "react";
import { Table, Empty } from "antd";
import { TableCommon, TextCustom } from "../../../components/common/styled";
import {
  BalanceBody,
  CheckBalanceContainer,
  TableBlock,
  AddBlock,
  AddWrapper,
  AddressBlock,
  FormContainer,
  ShowBlock,
  ShowRow,
} from "./styled";
import {
  DataCol,
  OverviewData,
  OverviewHeader,
  OverviewTitle,
  PortfolioOverview,
} from "./../Portfolio/Details/styled";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  ButtonCommon,
  TextAreaCommon,
  TitleKey,
} from "../../../components/common/styled";
import InputCommon from "../../../components/common/input";
import { Input } from "antd";
import { useAccount, useNetwork } from "wagmi";
import ABI from "../../../constants/Tooldex/abi/abiToken.json";
import copy from "../../../assets/images/common/table/copy.svg";
import Web3 from "web3";
import {
  HTTPS_NETWORK,
  token_network,
  NAME_NETWORK,
  LOGO_BY_CHAINID,
} from "../../../constants/Tooldex/index";
import getTokenInfo from "../../../utils/Tooldex/checkInfoToken";
import { convertFromWei } from "../../../utils/Tooldex/convertNumber";
import { AbiItem } from "web3-utils";
import { formatNum } from "../../../utils/Tooldex/formatNumber";
import moment from "moment";
import { notiError, notiSuccess } from "../../../utils/Tooldex/notication";
import CurrencyLogo from "../../../components/CurrencyLogo";
import Loading from "../../../components/Tooldex/Loading";
import Value from "../../../components/Tooldex/Value";
const CheckBalance = () => {
  let currentTime: any = new Date(Date.now()).getTime();
  const { Column } = Table;

  const { address } = useAccount();
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const [tokenAddress, setTokenAddress] = useState("");
  const { TextArea } = Input;
  const web3 = new Web3(HTTPS_NETWORK[chainId]);
  const [addressList, setAddressList] = useState([]);
  const [isConfirming, setConfirming] = useState(false);
  const [listBalanceToken, setListBalanceToken] = useState<any>([]);
  const [tokenInfo, setTokenInfo] = useState<any>();
  const [totalBalanceBase, setTotalBalanceBase] = useState<any>("0");
  const [totalBalanceToken, setTotalBalanceToken] = useState<any>("0");

  const handleLoadDataToken = async () => {
    try {
      const data = await getTokenInfo(tokenAddress, web3);
      setTokenInfo(data);
      notiSuccess("Add Token Successfully!", 5);
    } catch (error) {
      setTokenInfo({});
      console.error(error);
      notiError("Add Token Failed!", 5);
    }
  };
  const handleCheckBalanceChange = (event: any) => {
    const keys = event.target.value.split("\n");
    setAddressList(keys);
  };

  const checkBalanceToken = async () => {
    try {
      setConfirming(true);
      const contract = new web3.eth.Contract(
        ABI as unknown as AbiItem as AbiItem,
        tokenInfo?.address
      );
      let listData = [];
      let totalBase = 0;
      let totalToken = 0;

      for (let i = 0; i < addressList.length; i++) {
        const address = addressList[i];
        const balance = await web3.eth.getBalance(address);

        const balanceToken = await contract.methods.balanceOf(address).call();
        const decimals = await contract.methods.decimals().call();
        const balanceFromWei = web3.utils.fromWei(balance, "ether");
        const balanceTokenFromWei = await convertFromWei(
          balanceToken,
          Number(decimals)
        );

        totalBase += Number(balanceFromWei);
        totalToken += Number(balanceTokenFromWei);

        listData.push({
          address: address,
          balanceToken: balanceTokenFromWei,
          balance: balanceFromWei,
        });
        setTotalBalanceBase(totalBase);
        setTotalBalanceToken(totalToken);
        setListBalanceToken([...listData]);
      }

      setConfirming(false);
      notiSuccess("Check balance successfully!", 5);
    } catch (error: any) {
      setConfirming(false);
      notiError(error?.message, 5);
    }
  };
  const checkBalanceBase = async () => {
    try {
      setConfirming(true);
      let listData = [];
      let totalBase = 0;
      for (let i = 0; i < addressList.length; i++) {
        const address = addressList[i];
        const balance = await web3.eth.getBalance(address);
        const balanceFromWei = web3.utils.fromWei(balance, "ether");

        totalBase += Number(balanceFromWei);

        listData.push({
          address: address,
          balanceToken: 0,
          balance: balanceFromWei,
        });
        setTotalBalanceBase(totalBase);
        setListBalanceToken([...listData]);
      }

      setConfirming(false);
      notiSuccess("Check balance successfully!", 5);
    } catch (error: any) {
      setConfirming(false);
      notiError(error?.message, 5);
    }
  };
  return (
    <CheckBalanceContainer>
      <BalanceBody>
        <TextCustom fontSize="24px" color="#fff" fontWeight="bold">
          Check Balance
        </TextCustom>

        <FormContainer>
          <AddBlock>
            <TitleKey>Token address</TitleKey>
            <AddWrapper>
              <InputCommon
                placeHolder="Token address"
                value={tokenAddress}
                onChange={(e: any) => setTokenAddress(e.target.value)}
              />
              <ButtonCommon
                disabled={!tokenAddress || !address}
                background="#fff"
                textColor="#F313CE"
                style={{
                  minWidth: "140px",
                  padding: "9px 20px",
                  border: tokenAddress && address && "2px solid #F313CE",
                }}
                onClick={handleLoadDataToken}
              >
                Add Token
              </ButtonCommon>
            </AddWrapper>
            <TextCustom
              style={{ marginTop: "5px", fontStyle: "italic" }}
              fontSize="14px"
              fontWeight="500"
              color="#717696"
            >
              *If the token address is not entered, the {token_network(chainId)}{" "}
              is default
            </TextCustom>
          </AddBlock>

          {tokenInfo?.name && (
            <ShowBlock>
              <ShowRow>
                Name :<span>{tokenInfo?.name}</span>
              </ShowRow>
              <ShowRow style={{ display: "flex", alignItems: "center" }}>
                Symbol :
                <span style={{ display: "flex", alignItems: "center" }}>
                  {tokenInfo?.symbol}{" "}
                  <CurrencyLogo address={tokenInfo?.address} />
                </span>
              </ShowRow>
              <ShowRow>
                Total Supply :
                <span>
                  {new Intl.NumberFormat("en-US", {
                    notation: "compact",
                    compactDisplay: "short",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(tokenInfo?.supply)}{" "}
                  {tokenInfo?.symbol}
                </span>
              </ShowRow>
              <ShowRow>
                Contract :<span>{tokenInfo?.address}</span>
              </ShowRow>
            </ShowBlock>
          )}

          <AddressBlock>
            <TitleKey>Address List</TitleKey>
            <TextAreaCommon>
              <TextArea
                value={addressList.join("\n")}
                onChange={handleCheckBalanceChange}
                placeholder="Enter one address on each line. &#10;Supports only format. &#10;0x7fa22C4dD84E39f2431BAC14A3FDC6200F227D99 &#10;0x93929DB691f0E0B01826E69c72904A1E43bff540"
                style={{ height: 220, resize: "none" }}
                autoSize={{
                  minRows: 10,
                  maxRows: 10,
                }}
              />
            </TextAreaCommon>
          </AddressBlock>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonCommon
              disabled={addressList.length <= 0 || isConfirming}
              style={{ minWidth: "245px" }}
              onClick={!tokenInfo ? checkBalanceBase : checkBalanceToken}
            >
              <Loading status={isConfirming} content="Check Balance" />
            </ButtonCommon>
          </div>
        </FormContainer>

        <TableBlock>
          <TextCustom fontSize={"20px"} color={"#0D0C43"} fontWeight={"bold"}>
            List wallet address
          </TextCustom>
          <PortfolioOverview style={{ marginTop: "20px" }}>
            <OverviewHeader>
              <OverviewTitle>
                <TextCustom fontSize="20px" fontWeight="bold" color="#0D0C43">
                  Overview
                </TextCustom>
              </OverviewTitle>
            </OverviewHeader>
            <OverviewData>
              <DataCol>
                <TitleKey>
                  {" "}
                  {`Total ${token_network(chainId)} Balance`}
                </TitleKey>
                <TextCustom fontSize="18px" fontWeight="bold" color="#F313CE">
                  <Value
                    size={18}
                    color="#F313CE"
                    unitafter={token_network(chainId)}
                    value={Number(totalBalanceBase)}
                  />
                </TextCustom>
              </DataCol>
              <DataCol>
                <TitleKey>
                  {" "}
                  {`Total ${tokenInfo?.symbol || "Token"} Balance`}
                </TitleKey>
                <TextCustom fontSize="18px" fontWeight="bold" color="#F313CE">
                  <Value
                    size={18}
                    color="#F313CE"
                    unitafter={tokenInfo?.symbol}
                    value={Number(totalBalanceToken)}
                  />
                </TextCustom>
              </DataCol>
              <DataCol>
                <TitleKey>Current Time (+UTC)</TitleKey>
                <TextCustom
                  fontSize="18px"
                  fontWeight="300"
                  color="rgba(13, 12, 67, 0.70)"
                >
                  {moment(currentTime).format("MMM-DD-YYYY hh:mm:ss A")}
                </TextCustom>
              </DataCol>

              <DataCol>
                <TitleKey>Network Connect</TitleKey>
                <TextCustom fontSize="18px" fontWeight="bold" color="#0D0C43">
                  {NAME_NETWORK(chainId)}
                </TextCustom>
              </DataCol>
              <DataCol>
                <TitleKey>Symbol</TitleKey>
                <TextCustom
                  fontSize="18px"
                  fontWeight="bold"
                  color="#0D0C43"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    style={{
                      width: 20,
                      marginRight: "3px",
                      borderRadius: "50px",
                    }}
                    src={LOGO_BY_CHAINID(chainId)}
                    alt="logo"
                  />{" "}
                  {token_network(chainId)}
                </TextCustom>
              </DataCol>
            </OverviewData>
          </PortfolioOverview>
          <TableCommon>
            {listBalanceToken.length > 0 ? (
              <Table dataSource={listBalanceToken}>
                <Column
                  title="STT"
                  key="index"
                  render={(text, record, index) => index + 1}
                />
                <Column
                  title="Wallet address"
                  dataIndex="address"
                  key="address"
                  render={(address) => (
                    <>
                      {address}
                      <span
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "-2px",
                          marginLeft: "8px",
                        }}
                      >
                        <CopyToClipboard
                          text={address}
                          onCopy={() => {
                            notiSuccess(
                              `Copied ${address?.substring(
                                0,
                                6
                              )}.....${address?.substring(address.length - 8)}`,
                              5
                            );
                          }}
                        >
                          <span className="img-copy">
                            <img width={20} height={20} src={copy} alt="copy" />
                          </span>
                        </CopyToClipboard>
                      </span>
                    </>
                  )}
                />
                <Column
                  title={`${token_network(chainId)} Balance`}
                  dataIndex="balance"
                  key="balance"
                  render={(balance) => (
                    <>
                      {formatNum(Number(balance).toFixed(50))}{" "}
                      {token_network(chainId)}
                    </>
                  )}
                />
                <Column
                  title={`${tokenInfo?.symbol || "Token"} Balance`}
                  dataIndex="balanceToken"
                  key="balanceToken"
                  render={(balanceToken) => (
                    <>
                      {formatNum(Number(balanceToken).toFixed(50))}{" "}
                      {tokenInfo?.symbol}
                    </>
                  )}
                />
              </Table>
            ) : (
              <Empty />
            )}
          </TableCommon>
        </TableBlock>
      </BalanceBody>
    </CheckBalanceContainer>
  );
};

export default CheckBalance;

import { motion } from "framer-motion";

const PolygonBg = ({ hover }: any) => {
  const draw = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#B692F6",
    },
  };

  return (
    <svg
      width="236"
      height="236"
      viewBox="0 0 156 177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_153_2338)">
        <path
          d="M73.9858 1.53417C76.3518 0.168157 79.2668 0.168159 81.6328 1.53417L151.433 41.8331C153.799 43.1991 155.256 45.7236 155.256 48.4557V129.054C155.256 131.786 153.799 134.31 151.433 135.676L81.6328 175.975C79.2668 177.341 76.3518 177.341 73.9858 175.975L4.18594 135.676C1.81993 134.31 0.362419 131.786 0.362419 129.054V48.4557C0.362419 45.7236 1.81994 43.1991 4.18594 41.8331L73.9858 1.53417Z"
          fill="black"
          fillOpacity="0.31"
        />
        <motion.path
          d="M74.1193 1.76536C76.4027 0.447028 79.216 0.44703 81.4994 1.76536L151.299 42.0643C153.583 43.3826 154.989 45.819 154.989 48.4557V129.054C154.989 131.69 153.583 134.127 151.299 135.445L81.4994 175.744C79.2159 177.062 76.4027 177.062 74.1193 175.744L4.31941 135.445C2.036 134.127 0.629368 131.69 0.629368 129.054V48.4557C0.629368 45.819 2.03601 43.3826 4.31942 42.0643L74.1193 1.76536Z"
          variants={hover && draw}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 0.5, ease: "easeInOut" },
            fill: { duration: 0.5, ease: [1, 0, 0.8, 1] },
          }}
          stroke="#1F2A37"
          strokeWidth="0.533898"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_153_2338"
          x="-8.30435"
          y="-8.15701"
          width="172.227"
          height="193.823"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.33333" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_153_2338"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_153_2338"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default PolygonBg;

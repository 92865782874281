import styled from "styled-components";
import { CommonTitle } from "../Features/styled";
import useCase_robot from "../../../assets/images/homenew/usecase/useCase_robot.png";

export const UseCaseContainer = styled.div`
  position: relative;
  padding-bottom: 120px;
  &:hover {
    ${CommonTitle} {
      span {
        &:nth-child(1) {
          color: #fff;
          opacity: 1;
          text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 0px #fff;
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    padding-top: 40px;
    padding-bottom: 80px;
  }
`;
export const UseCaseWrapper = styled.div`
  position: relative;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    width: 324px;
    height: 486px;
    margin-left: -162px;
    border-radius: 486px;
    opacity: 0.4;
    background: rgba(255, 67, 236, 0.8);
    filter: blur(100px);
  }
  ${CommonTitle} {
    position: relative;
    &::before {
      /* content: ""; */
      background: url(${useCase_robot}) no-repeat top / 100% auto;
      position: absolute;
      right: 8%;
      top: -70px;
      width: 595px;
      height: 345px;
      z-index: -1;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    ${CommonTitle} {
      &::before {
        top: -115px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    ${CommonTitle} {
      .sp {
        display: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    ${CommonTitle} {
      /* max-width: 264px; */
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      span {
        &:nth-child(1) {
          text-align: center;
        }
      }
      &::before {
        right: -70%;
        top: -10px;
      }
    }
    &::before {
      content: none;
    }
  }
`;
export const UseCaseList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  & > li {
    position: relative;
    width: 33.33%;
    border-radius: 16px;
    border: 1px solid #505050;
    background: #050606;
    padding: 30px 15px;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    overflow: hidden;
    &::before {
      content: "";
      top: 0;
      bottom: 0;
      left: -150px;
      right: -150px;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 40%,
        rgba(255, 255, 255, 0.3) 60%,
        rgba(255, 255, 255, 0) 120%
      );
      transform-origin: center center;
      position: absolute;
      z-index: 10;
      transition: all 2s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(-36deg) translate(-40px, -630px);
    }
    &:hover {
      transform: translateY(-10px);
      background-color: #150518;
      &::before {
        transform: rotate(-36deg) translate(-40px, 650px);
      }
      h3 {
        color: #e737f6;
      }
      p {
        color: #fff;
      }
      figure {
        transform: scale(1.1);
      }
    }
  }
  figure {
    width: 195px;
    height: 162px;
    margin: 0 auto 30px;
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    img {
      width: 100%;
      height: auto;
    }
  }
  h3 {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  p {
    margin-bottom: 0;
    color: #98a2b3;
    font-size: 12px;
    line-height: 16.299px;
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 0 10px;
    gap: 25px;
    & > li {
      width: 100%;
    }
    h3 {
      font-size: 22px;
    }
    p {
      font-size: 14px;
    }
    figure {
      width: 172px;
      height: auto;
    }
  }
`;

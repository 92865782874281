import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { formatUnitTime } from "../utils/formatUnitTime";
import { useActiveWeb3React } from "../hooks";
import { BoxInput } from "../components/AbrNftAI/Body/minComponent/ModalMintNFT";
import { BoxOneElm } from "../components/AbrNftAI/Body/minComponent/input/style";

import {
  buyIco,
  claimTokens,
  calculateXrenderToken,
  calculateTotalRaised,
  getUserTokenAmount,
  claimToken,
} from "../integrateContract/contract/handleIco";
import {
  getAllowance,
  approvalToken,
  getUSDCBalance,
} from "../integrateContract/contract/handleErc20";
import { useWeb3React } from "@web3-react/core";
import {
  ICO_ABI,
  address_ICO_OPBNB_TESTNET,
  USDC_OPBNB_TESTNET,
  USDC_ARB_MAINNET,
  address_ICO_ARB_MAINNET,
} from "../integrateContract/ABI";
import { EthereumTokenAddress } from "../utils/constants";
import Decimal from "decimal.js";
import { useNavigate } from "react-router-dom";
import { shortenAddress } from "../utils/formartAddress";
import { ModalConfirmContext } from "../components/ProviderPopUp/confirm";
import { ConnectPopUp } from "../components/Modal/ModalConnectWallet";
import { Formatter } from "@ethersproject/providers";
import { ToastContainer, toast } from "react-toastify";
import ToastTyfile from "../components/layouts/Toastify";
import { getProviderOrSigner } from "../integrateContract/contract";
const LIST_SYS = [
  {
    title: "Symbol",
    name: "XRAI",
  },
  {
    title: "Token Network",
    name: "Arbitrum",
  },
  {
    title: "Swap Rate",
    name: "1 XRAI = 0.0003 USDC",
  },
  {
    title: "Vesting",
    name: "100% TGE",
  },
  {
    title: "Start Sale",
    name: "14th Oct, 2023 (14:00 UTC)",
  },
  {
    title: "End Sale",
    name: "18th Oct, 2023 (12:00 UTC)",
  },
];

const CONTENT_TABLE = [
  {
    app: "Strategic  Investor",
    per: "4%",
    amount: "4.000.000.000",
    ves: "1 month locked up, then linear vesting 18 months",
  },
  {
    app: "Fair Launch",
    per: "1,3%",
    amount: "1.300.000.000",
    ves: "100% TGE",
  },
  {
    app: "AI Incentives",
    per: "36,2%",
    amount: "36.200.000.000",
    ves: "Unlock 7.5% per quarter",
  },
  {
    app: "Fund Reverse",
    per: "13,5%",
    amount: "13.500.000.000",
    ves: "Lock 6 months, then vesting in 3 years",
  },
  {
    app: "Liquidity",
    per: "15%",
    amount: "15.000.000.000",
    ves: "10% TGE, then vesting 12 months",
  },
  {
    app: "Marketing & Partner",
    per: "15%",
    amount: "15.000.000.000",
    ves: "2% at TGE, then linear vesting in 36 months",
  },
  {
    app: "Team",
    per: "10%",
    amount: "10.000.000.000",
    ves: "12 month lock up, then 5% monthly",
  },
  {
    app: "Advisor",
    per: "5%",
    amount: "5.000.000.000",
    ves: "6 mont lock up, them 10% monthly",
  },
];

const LIST_TOKEN = [
  {
    title: "USDC",
    image: "/assets/images/png/icon-usdt.png",
  },
  {
    title: "Ethereum",
    image: "/assets/images/png/icon-eth.png",
  },
];

export default function ICOPage() {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [days, setDays] = useState(0);
  const [seconds, setSecond] = useState(0);
  const [showListSize, setShowListSize] = useState(false);
  const [contractInfo, setContractInfo] = useState({
    totalRaise: 0,
    userAmount: 0,
    totalUSDPresale: 0,
  });
  const [percent, setPercent] = useState(0);
  const { chainId } = useWeb3React();
  const [token, setToken] = useState(LIST_TOKEN[0]);
  const [amountXrender, setAmountXrender] = useState(0);
  const [isApproval, setIsApproval] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(0);
  const [claimTime, setClaimTime] = useState(0);

  const [isClaimStarted, setIsClaimStarted] = useState(false);

  const [textButton, setTextButton] = useState("BUY TOKENS");
  const [amount, setAmount] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);

  const { onOpen } = useContext(ModalConfirmContext);
  const { account } = useActiveWeb3React();
  const navigate = useNavigate();

  const handleBuyIco = async () => {
    if (token.title === "Ethereum") {
      if (!(new Decimal(amount).gte(0.005) && new Decimal(amount).lte(3))) {
        toast(<ToastTyfile type="error" description="Min 0.005, Max 3 ETH" />, {
          hideProgressBar: true,
        });
        return;
      }
    } else {
      if (!(new Decimal(amount).gte(10) && new Decimal(amount).lte(5000))) {
        toast(
          <ToastTyfile type="error" description="Min 10 , Max 5000 USDC" />,
          { hideProgressBar: true }
        );
        return;
      }
    }
    setIsLoading(true);
    try {
      let addressICO = "";
      let tokenAddress = "";
      let contract = null;
      switch (chainId) {
        case 5611:
          addressICO = address_ICO_OPBNB_TESTNET;
          tokenAddress =
            token.title === "Ethereum"
              ? EthereumTokenAddress
              : USDC_OPBNB_TESTNET;
          contract = ICO_ABI;
          break;
        case 42161:
          addressICO = address_ICO_ARB_MAINNET;
          tokenAddress =
            token.title === "Ethereum"
              ? EthereumTokenAddress
              : USDC_ARB_MAINNET;
          contract = ICO_ABI;
          break;
        default:
          addressICO = address_ICO_ARB_MAINNET;
          tokenAddress =
            token.title === "Ethereum"
              ? EthereumTokenAddress
              : USDC_ARB_MAINNET;
          contract = ICO_ABI;
          break;
      }
      await buyIco(tokenAddress, amount, addressICO, contract);
      toast(
        <ToastTyfile
          type="sucsess"
          description={`Successful purchase ${new Intl.NumberFormat().format(
            amountXrender
          )} $XRAI`}
        />,
        { progress: 0, hideProgressBar: true }
      );
      setAmount(0);
    } catch (e) {
      toast(
        <ToastTyfile
          type="error"
          description={e?.reason?.replace("execution reverted: ", "")}
        />,
        { progress: 0, hideProgressBar: true }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClaim = async () => {
    setIsLoading(true);
    try {
      let addressICO = "";
      let tokenAddress = "";
      let contract = null;
      switch (chainId) {
        case 5611:
          addressICO = address_ICO_OPBNB_TESTNET;

          contract = ICO_ABI;
          break;
        case 42161:
          addressICO = address_ICO_ARB_MAINNET;

          contract = ICO_ABI;
          break;
        default:
          addressICO = address_ICO_ARB_MAINNET;
          contract = ICO_ABI;
          break;
      }
      await claimToken(addressICO, contract);
      toast(
        <ToastTyfile
          type="sucsess"
          description={`Successful claim ${new Intl.NumberFormat().format(
            contractInfo.userAmount
          )} $XRAI`}
        />,
        { progress: 0, hideProgressBar: true }
      );
      setAmount(0);
    } catch (e) {
      toast(
        <ToastTyfile
          type="error"
          description={e?.reason
            ?.replace("execution reverted: ", "")
            ?.replace("ICO", "Presale")}
        />,
        { progress: 0, hideProgressBar: true }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleApproval = async () => {
    setIsLoading(true);
    try {
      let addressICO = "";
      let tokenAddress = "";
      console.log({ chainId });
      switch (chainId) {
        case 5611:
          addressICO = address_ICO_OPBNB_TESTNET;
          tokenAddress =
            token.title === "Ethereum"
              ? EthereumTokenAddress
              : USDC_OPBNB_TESTNET;
          break;
        case 42161:
          addressICO = address_ICO_ARB_MAINNET;
          tokenAddress =
            token.title === "Ethereum"
              ? EthereumTokenAddress
              : USDC_ARB_MAINNET;
          break;
        default:
          addressICO = address_ICO_ARB_MAINNET;
          tokenAddress =
            token.title === "Ethereum"
              ? EthereumTokenAddress
              : USDC_ARB_MAINNET;
          break;
      }
      await approvalToken(tokenAddress, addressICO, amount);
      setIsApproval(true);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeAmount = (e) => {
    // Define a regex pattern to match valid numbers (including negative numbers and decimal numbers)
    const input = e.target.value;
    if (input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) setAmount(input);
  };

  const handleFloat = () => {
    // The conditional prevents parseFloat(null) = NaN (when the user deletes the input)
    setAmount(parseFloat(amount) || "");
  };

  useEffect(() => {
    if (contractInfo) {
      const value = 100;
      const num = Number.parseFloat(value)
        ? Number.parseFloat(value).toFixed(2)
        : 0;
      setPercent(num);
    }
  }, [contractInfo]);

  useEffect(() => {
    (async () => {
      let addressICO = "";
      let contract = null;
      switch (chainId) {
        case 5611:
          addressICO = address_ICO_OPBNB_TESTNET;
          contract = ICO_ABI;
          break;
        case 42161:
          addressICO = address_ICO_ARB_MAINNET;
          contract = ICO_ABI;
          break;
        default:
          addressICO = address_ICO_ARB_MAINNET;
          contract = ICO_ABI;
          break;
      }
      const totalRaised = await calculateTotalRaised(addressICO, contract);
      const amount = await getUserTokenAmount(addressICO, contract, account);
      setContractInfo({ totalRaise: totalRaised, userAmount: amount });
    })();
  }, [account, amount, chainId]);

  useEffect(() => {
    (async () => {
      let addressICO = "";
      let tokenAddress = "";
      let contract = null;
      switch (chainId) {
        case 5611:
          addressICO = address_ICO_OPBNB_TESTNET;
          tokenAddress =
            token.title === "Ethereum"
              ? EthereumTokenAddress
              : USDC_OPBNB_TESTNET;
          contract = ICO_ABI;
          break;
        case 42161:
          addressICO = address_ICO_ARB_MAINNET;
          tokenAddress =
            token.title === "Ethereum"
              ? EthereumTokenAddress
              : USDC_ARB_MAINNET;
          contract = ICO_ABI;
          break;
        default:
          addressICO = address_ICO_ARB_MAINNET;
          tokenAddress =
            token.title === "Ethereum"
              ? EthereumTokenAddress
              : USDC_ARB_MAINNET;
          contract = ICO_ABI;
          break;
      }
      const xrenderAmount = await calculateXrenderToken(
        tokenAddress,
        amount,
        addressICO,
        contract
      );
      setAmountXrender(
        new Decimal(xrenderAmount?._hex || 0)
          .div(new Decimal(10).pow(18))
          .toFixed(2)
      );
      if (token.title !== "Ethereum") {
        const allowance = await getAllowance(tokenAddress, addressICO, account);
        if (new Decimal(allowance || 0).lt(amount || "0")) {
          setIsApproval(false);
        } else {
          setIsApproval(true);
        }
        const balance = await getUSDCBalance(tokenAddress, account);
        setTokenBalance(balance || 0);
      } else {
        if (!account) setTokenBalance(0);
        const signer = await getProviderOrSigner(true);
        const balance = await signer.getBalance();
        const netBalance = new Decimal(balance._hex || 0).div(
          new Decimal(10).mul(new Decimal(10).pow(18))
        );
        setTokenBalance(netBalance);
        setIsApproval(true);
      }
    })();
  }, [chainId, amount, token, account]);

  const countDownTime = () => {
    // Set the date we're counting down to
    // if (!data) return;

    const currentHour = 12;
    const timeUTC = ((new Date().getTimezoneOffset() * 60) / (60 * 60)) * -1;
    const countDownDate = new Date(
      `10/18/2023 ${currentHour + timeUTC}:00`
    ).getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      // Time calculations for days, hours, minutes and seconds
      setDays(
        Math.floor(distance / (1000 * 60 * 60 * 24)) <= 0
          ? 0
          : Math.floor(distance / (1000 * 60 * 60 * 24))
      );
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      setSecond(
        Math.floor((distance % (1000 * 60)) / 1000) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60)) / 1000)
      );

      if (distance <= 0) {
        clearInterval(x);
        setIsClaimStarted(true);
        setTextButton("CLAIM TOKENS");
      }
    }, 1000);
  };
  useEffect(() => {
    countDownTime();
  }, []);
  return (
    <IOCMain className="d-flex align-items-center flex-column">
      <ToastContainer />
      <div className="container-ico">
        <div className="block-left">
          <div className="title">
            <h2>Pre-Sale Tokens Details</h2>
            <p>
              All buyers receive a 5% bonus of $XRAI value. <br />
              $XRAI bonus vesting after 30 days.
            </p>
          </div>
          <div className="content-left d-flex align-items-center">
            <a
              href="https://arbiscan.io/token/0x617b76412bd9f3f80fe87d1533dc7017defa8ad1"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: `100%`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: `100%`,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="item-ico"
              >
                <p
                  style={{
                    textAlign: "center !important",
                    width: "100%",
                  }}
                >
                  <i>
                    SmartContract:{" "}
                    {shortenAddress(
                      "0x617b76412bd9f3f80fe87d1533dc7017defa8ad1"
                    )}
                  </i>
                </p>
              </div>
            </a>
            {LIST_SYS.map((item, index) => {
              return (
                <div
                  style={{
                    width: `45%`,
                  }}
                  className="item-ico"
                >
                  <h3>{item.title}</h3>
                  <span>{item.name}</span>
                </div>
              );
            })}
            <div
              style={{
                width: `45%`,
              }}
              className="item-ico"
            >
              <h3>Min</h3>
              <span>
                {token.title === "Ethereum" ? "0.005 ETH" : "10 USDC"}
              </span>
            </div>
            <div
              style={{
                width: `45%`,
              }}
              className="item-ico"
            >
              <h3>Max</h3>
              <span>{token.title === "Ethereum" ? "3 ETH" : "5000 USDC"}</span>
            </div>
          </div>
        </div>
        <div className="img-midle d-flex align-items-center">
          <img src="/assets/icon/icon-midle.svg" alt="middle" />
        </div>
        <div className="block-right">
          <div className="title">
            <h2>Token Sale End In</h2>
            <p>Claim: 18th Oct, 2023 (12:00 UTC)</p>
            <p>
              Token Received:{" "}
              <b>
                {new Intl.NumberFormat().format(contractInfo.userAmount || 0)}{" "}
                $XRAI
              </b>{" "}
            </p>
          </div>
          <div className="content-right d-flex align-items-center flex-column">
            {chainId !== 5611 ? (
              <div className="count-down">
                <div
                  style={{
                    width: `calc(100% / 4)`,
                  }}
                  className="item"
                >
                  <h4> {formatUnitTime(days)} </h4>
                  <div className="unit">
                    <span>DAYS</span>
                  </div>
                </div>
                <div className="dot">
                  <span>:</span>
                </div>
                <div
                  style={{
                    width: `calc(100% / 4)`,
                  }}
                  className="item"
                >
                  <h4> {formatUnitTime(hours)} </h4>
                  <div className="unit">
                    <span>HOURS</span>
                  </div>
                </div>
                <div className="dot">
                  <span>:</span>
                </div>
                <div
                  style={{
                    width: `calc(100% / 4)`,
                  }}
                  className="item"
                >
                  <h4> {formatUnitTime(minutes)} </h4>
                  <div className="unit">
                    <span>MINUTES</span>
                  </div>
                </div>
                <div className="dot">
                  <span>:</span>
                </div>
                <div
                  style={{
                    width: `calc(100% / 4)`,
                  }}
                  className="item"
                >
                  <h4> {formatUnitTime(seconds)} </h4>
                  <div className="unit">
                    <span>SECONDS</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="count-down">
                <div
                  style={{
                    width: `calc(100% / 4)`,
                  }}
                  className="item"
                >
                  <h4> {"00"} </h4>
                  <div className="unit">
                    <span>DAYS</span>
                  </div>
                </div>
                <div className="dot">
                  <span>:</span>
                </div>
                <div
                  style={{
                    width: `calc(100% / 4)`,
                  }}
                  className="item"
                >
                  <h4> {"00"} </h4>
                  <div className="unit">
                    <span>HOURS</span>
                  </div>
                </div>
                <div className="dot">
                  <span>:</span>
                </div>
                <div
                  style={{
                    width: `calc(100% / 4)`,
                  }}
                  className="item"
                >
                  <h4> {"00"} </h4>
                  <div className="unit">
                    <span>MINUTES</span>
                  </div>
                </div>
                <div className="dot">
                  <span>:</span>
                </div>
                <div
                  style={{
                    width: `calc(100% / 4)`,
                  }}
                  className="item"
                >
                  <h4> {"00"} </h4>
                  <div className="unit">
                    <span>SECONDS</span>
                  </div>
                </div>
              </div>
            )}

            <div className="total">
              <p className="total-raised">Total Raise : $ 50,000</p>
              <div className="line-percent">
                <div style={{ width: `${percent}%` }} className="result"></div>
              </div>
              {<p className="total-bought"> {percent} %</p>}
            </div>
            {!isClaimStarted && (
              <>
                <div className="total">
                  <div className="d-flex justify-content-between">
                    <p className="total-token">
                      Your Balance:{" "}
                      {new Intl.NumberFormat().format(tokenBalance)}{" "}
                      {token.title}{" "}
                    </p>
                    <p className="total-money">
                      You will get{" "}
                      {new Intl.NumberFormat().format(amountXrender)} $XRAI
                    </p>
                  </div>
                </div>
                <div className="input-token">
                  <BoxInput className="box-input">
                    <BoxOneElm
                      className="one-elm"
                      required={false}
                      submitError={false}
                    >
                      <input
                        type="text"
                        placeholder={"Amount"}
                        value={amount}
                        onChange={(e) => handleChangeAmount(e)}
                      />
                    </BoxOneElm>
                  </BoxInput>
                  <BoxInput className="box-input">
                    <BoxOneElm className="one-elm" showListSize={showListSize}>
                      <button
                        onClick={() => {
                          setShowListSize(!showListSize);
                        }}
                      >
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            margin: "5px 0",
                          }}
                        >
                          {token ? (
                            <>
                              <img
                                style={{
                                  width: "24px",
                                  marginRight: "10px",
                                }}
                                src={token.image}
                                alt=""
                              />{" "}
                              <span
                                style={{
                                  fontWeight: 500,
                                }}
                              >
                                {token.title}
                              </span>
                            </>
                          ) : (
                            <span
                              style={{
                                fontWeight: 500,
                                color: "#8f73a6",
                              }}
                            >
                              Select Token
                            </span>
                          )}
                        </div>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </button>
                      {showListSize && (
                        <ul>
                          {LIST_TOKEN.map((item, index) => {
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  setShowListSize(false);
                                  setToken(item);
                                }}
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                }}
                              >
                                <img
                                  style={{
                                    width: "24px",
                                    marginRight: "10px",
                                  }}
                                  src={item.image}
                                  alt=""
                                />{" "}
                                <span
                                  style={{
                                    fontWeight: 500,
                                  }}
                                >
                                  {item.title}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </BoxOneElm>
                  </BoxInput>
                </div>
              </>
            )}

            <div className="action">
              {account ? (
                <>
                  {chainId === 5611 ? (
                    <>
                      <button disabled={isLoading} onClick={handleClaim}>
                        {"CLAIM TOKENS"}
                      </button>
                      <button disabled={isLoading} onClick={handleBuyIco}>
                        {textButton}
                      </button>
                    </>
                  ) : (
                    <>
                      {isClaimStarted ? (
                        <button disabled={isLoading} onClick={handleClaim}>
                          {"CLAIM TOKENS"}
                        </button>
                      ) : (
                        <>
                          {!isClaimStarted && isApproval ? (
                            <button disabled={isLoading} onClick={handleBuyIco}>
                              {textButton}
                            </button>
                          ) : (
                            <button
                              disabled={isLoading}
                              onClick={handleApproval}
                            >
                              APPROVAL
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <button
                  // disabled={isDiabled}
                  // style={{
                  //   cursor: isDiabled && "not-allowed",
                  // }}
                  onClick={() => {
                    onOpen(<ConnectPopUp />);
                  }}
                >
                  CONNECT WALLET
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-ico ico-b2">
        <div style={{}}>
          <div className="title">
            <h2>Tokenomics</h2>
          </div>
          <div className="content-left d-flex align-items-center">
            <div className="p-2">
              <img src="/assets/images/analytis.png" alt="" />
            </div>
          </div>
        </div>
        <div className="img-midle d-flex align-items-center">
          <img src="/assets/icon/icon-midle.svg" alt="middle" />
        </div>
        <div className="block-right">
          <div className="title">
            <h2>Token Metrics</h2>
          </div>
          <div className="content-right d-flex align-items-center flex-column">
            <div className="table-content">
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "180px",
                      }}
                    >
                      Allocation
                    </th>
                    <th>Percentage</th>
                    <th>Amount</th>
                    <th>Vesting Schedule</th>
                  </tr>
                </thead>
                <tbody>
                  {CONTENT_TABLE.map((item) => {
                    return (
                      <tr>
                        <td>{item.app}</td>
                        <td>{item.per}</td>
                        <td>{item.amount}</td>
                        <td>{item.ves}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </IOCMain>
  );
}
const IOCMain = styled.div`
  min-height: calc(100vh);
  background-image: url("/assets/images/bg-ico.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 20px;
  padding-top: 100px;
  justify-content: center;
  margin: auto;
  .container-ico {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    padding: 30px;
  }
  .block-right {
    width: 50%;
  }
  h2 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 15.82043170928955px rgba(255, 148, 148, 0.67),
      0px 2px 0px #ff00d7;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: -0.48px;
  }
  p {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-transform: capitalize;
  }

  .content-left {
    display: flex;
    max-width: 810px;
    margin: auto;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 10px;
    position: relative;
    flex-wrap: wrap;
    .item-ico {
      display: flex;
      padding: 12px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 12px;
      border: 1px solid #cb50dd;
      background: rgba(90, 32, 131, 0.5);
      h3 {
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-weight: 800;
      }
      span {
        color: #fff;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 150% */
        letter-spacing: -0.2px;
      }
    }
  }

  .img-midle {
    margin-top: 120px;
  }

  .content-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
    .table-content {
      margin: auto;
      overflow: auto;
      border: 0.941px solid #fff;
      border-radius: 22.588px;
      width: 100%;
      /* width */
      ::-webkit-scrollbar {
        height: 7px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        border-radius: 5px;
        background: #ffffff;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 5px;
      }

      table {
        border-radius: 22.588px;
        margin: 0;
        width: 80em;
        min-width: 100%;
        border-radius: 22.588px;
        overflow: auto;
        background: var(
          --aaa,
          linear-gradient(
            254deg,
            rgba(157, 48, 139, 0.19) 7.32%,
            rgba(59, 26, 74, 0) 100.02%
          )
        );
        backdrop-filter: blur(33.882347106933594px);
      }
      td,
      th {
        border: none;
        align-items: center;
        gap: 18.72px;
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        padding: 22px;
        vertical-align: middle;
      }
      td {
        padding: 22px;
      }
      thead {
        tr {
          border-top-right-radius: 22.588px;
          border-top-left-radius: 22.588px;
          th {
            border-bottom: 1.56px solid #eaecf0;
            background: rgba(137, 32, 154, 0.7);
            &:nth-child(1) {
              border-top-left-radius: 21.588px;
            }
            &:last-child {
              border-top-right-radius: 21.588px;
              width: 250px;
            }
            text-align: center;
            &:nth-child(1) {
              text-align: left;
              font-weight: 700;
            }
            &:last-child {
              text-align: left;
              min-width: 180px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            text-align: center;
            &:nth-child(1) {
              text-align: left;
              font-weight: 700;
            }
            &:last-child {
              text-align: left;
              min-width: 180px;
            }
          }
        }
      }
    }
    .count-down {
      display: flex;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      border: 2px solid #ed75ff;
      width: 100%;
      max-width: 900px;
      height: 210px;
      .dot {
        color: #fff;
        text-align: center;
        font-size: 90px;
        font-style: normal;
        font-weight: 800;
        letter-spacing: -0.8px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        span {
          height: 160px;
        }
      }
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h4 {
          color: #fff;
          text-align: center;
          font-size: 90px;
          font-style: normal;
          font-weight: 800;
          letter-spacing: -0.8px;
        }
        .unit {
          display: flex;
          padding: 6px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background: #7900e0;
          width: 90px;
          margin: auto;
          span {
            color: #fff;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.14px;
            font-style: italic;
          }
        }
      }
    }
    .total {
      width: 100%;
      p {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px; /* 200% */
        letter-spacing: -0.2px;
      }
      &-raised {
        text-align: right !important;
      }
      .line-percent {
        width: 100%;
        height: 20px;
        flex-shrink: 0;
        border-radius: 999px;
        border: 1px solid #fff;
        background: #fff;
        .result {
          width: 0%;
          height: 18px;
          flex-shrink: 0;
          border-radius: 999px;
          border: 1px solid #fff;
          background: linear-gradient(90deg, #098ee4 0%, #fd0277 100%);
        }
      }
      &-money {
        text-align: right !important;
      }
      &-bought {
        text-align: left !important;
      }
      &-token {
        text-align: left !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
      }
    }
    .input-token {
      width: 100%;
      display: flex;
      gap: 30px;
      margin-bottom: 18px;
      .one-elm,
      .box-input {
        width: 100%;
        margin-top: 0;
      }
      button,
      input {
        color: #ffffff;
        border: 2px solid #ffffff;
        &:focus {
          border: 2px solid #f514d0 !important;
        }
      }
    }

    .action {
      width: 100%;
      .btn-docs {
        display: flex;
        justify-content: center;
        gap: 24px;
        margin-bottom: 24px;
        button {
          display: flex;
          width: 48%;
          height: 56px;
          padding: 16px 32px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 16px;
          border: 1.5px solid #f514d0;
          background: #fff;
          color: #f514d0;
          :hover {
            background-color: #f514d0;
            color: #ffffff;
          }
        }
      }
      button {
        width: 100%;
        display: flex;
        height: 56px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-shrink: 0;
        border-radius: 16px;
        background: #f514d0;
        :hover {
          background-color: #ffffff;
          color: #f514d0;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .container-ico {
      .title {
        p {
          font-size: 15px;
        }
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .container-ico {
      gap: 10px;
      padding: 20px;
      .title {
        p {
          font-size: 15px;
        }
      }
    }
    .content-right {
      .count-down {
        .item {
          h4 {
            font-size: 70px;
          }
        }
        .dot {
          font-size: 70px;
          span {
            height: 100px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1085px) {
    .container-ico {
      flex-direction: column-reverse;
      align-items: center;
      gap: 40px;
      .count-down {
        height: auto;
      }
      .block-right,
      .block-left {
        width: 100%;
      }
      .img-midle {
        display: none !important;
      }
    }
    .ico-b2 {
      flex-direction: column !important;
      .block-right {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 730px) {
    .block-right {
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    .container-ico {
      padding: 0px;
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 15px;
      }
      .item-ico {
        width: 90% !important;
      }
    }
    .block-right {
      padding: 10px;
      .content-right {
        margin-top: 30px;
        .count-down {
          padding: 5px;
          .item {
            h4 {
              font-size: 40px;
            }
            .unit {
              width: auto;
              padding: 5px;
              span {
                font-size: 12px;
              }
            }
          }
          .dot {
            font-size: 40px;
            span {
              height: 70px;
            }
          }
        }
      }
    }
  }
`;

import { ReactNode, useState } from "react";
import ProgressCommon from "../../../../components/common/progress";
import { LPV2Container, RecentButton } from "./styled";
import TokenPair from "./TokenPair";
import LiqV2 from "./LiqV2";
// copmmon
import Web3 from "web3";
import { useAccount, useNetwork } from "wagmi";
import FormConnectWallet from "../../../../components/common/form/connectWallet";
// end common

const LPV2 = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const chainId = chain?.id;
  const [step, setStep] = useState<number>(0);

  const handleChangeComponent = (step: number): ReactNode => {
    switch (step) {
      case 0:
        return <TokenPair setStep={setStep} />;
      case 1:
        return <LiqV2 setStep={setStep} />;
      default:
        return null;
    }
  };

  return (
    <LPV2Container>
      <ProgressCommon
        style={{ maxWidth: "500px", margin: "0 auto 20px" }}
        widthCustom={"200px"}
        data={progressData}
        step={step}
      />
      {address ? (
        <>{handleChangeComponent(step)}</>
      ) : (
        <>
          <FormConnectWallet />
        </>
      )}
    </LPV2Container>
  );
};

const progressData = ["Choose token pair", "Add liquidity V2"];

export default LPV2;

import styled from "styled-components";
import { InputContainer } from "../../../../components/common/input/styled";
import { ButtonCommon, TextCustom, TitleKey } from "../../../../components/common/styled";


export const BothBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${InputContainer} {
    max-width: 175px;
  }
  p {
    margin-bottom: 0;
    white-space: nowrap;
    color: #0D0C43;
  }
`
export const GenContainer = styled.div`
  position: relative;
  z-index: 5;
`
export const GenWrapperTitle = styled.div`
  ${TextCustom} {
    text-align: center;
    margin-bottom: 30px;
  }
`
export const GenBlock = styled.div`
  max-width: 620px;
  margin: 0 auto;
  padding: 34px 20px;
  min-height: 442px;
  border-radius: 20px;
  border: 1px solid #DCE2E9;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  background-color: #fff;
  text-align: left;
  ${ButtonCommon} {
    min-width: 344px;
    margin: 0 auto;
  }
  @media screen and (max-width: 743px) {
    ${ButtonCommon} {
      min-width: max-content;
    }
  }
`
export const GenTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  span {
    color: #717696;
    letter-spacing: 0.14px;
  }
`
export const GenItem = styled.div`
  margin-bottom: 15px;
`
export const GenEg = styled.div`
  margin-bottom: 25px;
  color: #717696;
  letter-spacing: 0.14px;
`
export const GenInfo = styled.div`
  padding: 15px 20px;
  border-radius: 10px;
  background: #F5F5F5;
  margin-bottom: 25px;
  & > div {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`
export const InfoRow = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.16px;
  p {
    width: 25%;
    color: #0D0C43;
    margin-bottom: 0;
  }
  span {
    color: rgba(0, 178, 200, 0.90);
  }
  @media screen and (max-width: 743px) {
    p {
      width: auto;
      margin-right: 20px;
    }
  }
`
export const GenRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 100px;
  margin-bottom: 15px;
  ${TitleKey} {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
    gap: 20px;
    ${TitleKey} {
      white-space: nowrap;
    }
  }
`

import styled from "styled-components";
import feature_robot from "../../../assets/images/homenew/features/feature_robot.png";

export const CommonTitle = styled.h2`
  text-align: center;
  margin-bottom: 68px;
  span {
    &:nth-child(1) {
      display: block;
      font-size: 64px;
      font-weight: 600;
      opacity: 0.8;
      background: linear-gradient(
        180deg,
        #fff 0%,
        rgba(255, 255, 255, 0) 89.76%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all 0.3s ease;
    }
    &:nth-child(2) {
      display: block;
      margin-top: -20px;
      color: #98a2b3;
      font-size: 14px;
      font-weight: 400;
      line-height: 22.5px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
    span {
      &:nth-child(1) {
        font-size: 55px;
        line-height: 1;
      }
      &:nth-child(2) {
        margin-top: 0;
        font-size: 13px;
      }
    }
  }
`;
export const FeaturesContainer = styled.div`
  background: rgba(51, 0, 55, 0.09);
  backdrop-filter: blur(8px);
  padding-bottom: 200px;
  &:hover {
    ${CommonTitle} {
      span {
        &:nth-child(1) {
          color: #fff;
          opacity: 1;
          text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 0px #fff;
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    padding-bottom: 100px;
  }
`;
export const FeaturesWrapper = styled.div`
  max-width: 1728px;
  padding: 0 144px;
  margin: 0 auto;
  ${CommonTitle} {
    margin-bottom: 60px;
  }
  @media screen and (max-width: 1440px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 767px) {
    ${CommonTitle} {
      margin-bottom: 80px;
    }
  }
`;
export const FeaturesContent = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 2280 / 706;
  border-radius: 14px;
  margin-bottom: 30px;
  &::before {
    content: "";
    position: absolute;
    left: 200px;
    top: -90px;
    background: url(${feature_robot}) no-repeat center / 100% auto;
    width: 108px;
    height: 135px;
  }
  figure {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    height: 44.8vw;
    &::before {
      left: 0;
    }
  }
`;
export const FeatureNumber = styled.p`
  font-size: 48px;
  margin-bottom: 0;
  font-weight: 600;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
  margin-bottom: 12px;
  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }
`;
export const FeatureTitle = styled.h3`
  margin-bottom: 12px;
  color: #eaecf0;
  font-size: 20px;
  font-weight: 500;
  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }
`;
export const FeaturesList = styled.ul`
  display: flex;
  align-items: start;
  padding: 0 20px;
  margin-bottom: 0;
  & > li {
    width: 25%;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.25s ease;
    border-radius: 20px;
    padding: 20px;
    align-self: stretch;
    &:hover {
      background: #1f0823;
      border-color: #d6bbfb;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    flex-wrap: wrap;
    & > li {
      width: 50%;
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding: 0;
    gap: 10px 15px;
    justify-content: space-between;
    & > li {
      width: calc(50% - 10px);
      padding: 10px;
    }
  }
`;
export const FeatureSubList = styled.ul`
  padding-left: 20px;
  margin-bottom: 0;
  & > li {
    list-style-type: disc;
    color: #98a2b3;
    font-size: 12px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 767px) {
    padding-left: 0;
    & > li {
      list-style-type: none;
      font-size: 10px;
    }
  }
`;

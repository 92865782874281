import styled from "styled-components";
import { TextCustom } from "../styled";

// Images
import bg from '../../../assets/images/common/keyvisual/keyvisual_tooldex_bg.png'
import { TabsContainer } from "../tabs/styled";

export const KeyvisualContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  border-radius: 0px 0px 20px 20px;
  padding: 20px;
  background: #0c000d url(${bg}) no-repeat center / cover;
  z-index: 5;
  ${TextCustom} {
    display: block;
    margin-bottom: 15px;
    text-align: center;
    span {
      margin-left: 10px;
      color: #00E3FF;
    }
  }
  ${TabsContainer} {
    .ant-tabs-nav {
      margin-bottom: 0
    }
  }
  @media screen and (max-width: 743px) {
    .keyvisual-title {
      margin-bottom: 10px;
    }
  }
`
import styled from "styled-components";
import contact_bg from "../../assets/images/homenew/contact/contact_bg.jpg";

export const FooterContainer = styled.div`
  position: relative;
  width: 100%;
  background: url(${contact_bg}) no-repeat center / cover;
  .sub-widget-logo,
  .title-widget,
  .widget > ul > li > a {
    color: #fff;
  }
`;

import styled from "styled-components";

export const TabsContainer = styled.div`
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  .ant-tabs-top>.ant-tabs-nav {
    &:before {
      border-bottom: 0;
    }
    .ant-tabs-ink-bar {
      height: 0;
    }
  }
  .ant-tabs-nav-list {
    padding: 6px;
    border-radius: 40px;
    border: 1px solid #DCE2E9;
    background: #E8F1F2;
  }
  .ant-tabs-tab+.ant-tabs-tab {
    margin: 0
  }
  .ant-tabs-tab {
    padding: 6px 25px;
    transition: background .25s;
    border-radius: 40px;
    .ant-tabs-tab-btn {
      font-size: 16px;
      color: #56557B;
      font-weight: 500;
    }
    &.ant-tabs-tab-active {
      background-color: #F313CE;
      border-radius: 30px;
      .ant-tabs-tab-btn {
        color: #fff;
      }
    }
  }
  .ant-tabs-content-holder {
    text-align: left;
  }
`
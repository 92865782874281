import { TextCustom } from "../styled"
import { KeyvisualContainer } from "./styled"
import logo from '../../../assets/images/homenew/footer/footer_logo.png'

const KeyvisualCommon = ({ content, text }: any) => {
  return (
    <KeyvisualContainer>
      <div style={{position: 'relative', zIndex: 10}}>
        <TextCustom className="keyvisual-title" fontSize={"24px"} color={"#fff"} fontWeight={"bold"}>
          {text}
          <img width={155} style={{display: 'block', margin: '0 auto'}} height={38} src={logo} alt="xrender" />
        </TextCustom>
        <div className="keyvisual-content">
          {content}
        </div>
      </div>
    </KeyvisualContainer>
  )
}

export default KeyvisualCommon
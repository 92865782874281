import { useLocation, useNavigate } from "react-router-dom";
import {
  CollectionContainer,
  CollectionPanner,
  CollectionDetailBox,
  CollectionAvt,
  CollectionDes,
  CollectionBoxNumGroup,
  BoxActivityDefaults,
  CollectionDesTop,
  BoxContent,
} from "./style";
import { useContext, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import Panner from "../../../assets/images/item-background/BgCollectionDetail.png";
import Avt from "../../../assets/images/item-background/avtCollection.png";
import axiosInstance from "../../../utils/axios";
import { ModalConfirmContext } from "../../ProviderPopUp/confirm";
import { LIST_NETWORK } from "../../../constants/connectors";
import { ethers } from "ethers";
import { formartImage } from "../../../utils/formartImage";
import avat1 from "../../../assets/images/avatar/avt-11.jpg";
import { shortenAddress } from "../../../utils/formartAddress";
import { Nodata } from "../home-5/TodayPicksStyle";
import NodataImg from "../../../assets/images/logo/No-nft.svg";
import Countdown from "react-countdown";
import { isUrlImage } from "../../../utils/checkTypeImg";
import dayjs from "dayjs";
import { convertTimeEnd } from "../../../utils/formartTime";
import { defineName } from "../../../utils/defineName";
import TableActivities from "../../Marketplace/TableActivities";
import Footer from "../../footer/Footer";
import { formatPriceNFT } from "../../../utils/formatPriceNFT";
const TABLE_HEADER = ["Type", "Item", "Price", "From", "To", "Date"];

const DetailCollection = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams?.get("tokenAddress");
  const selecChianId = searchParams?.get("chainId");
  const status = searchParams?.get("status");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { collectionDetail } = useContext(ModalConfirmContext);
  const [collectionDes, setCollectionDes] = useState();
  const [resDetailfromAdress, setResDetailfromAdress] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [listOnSale, setListOnSales] = useState([]);
  const [listAllNft, setListAllNft] = useState([]);
  const [totalOnsale, setTotalOnsale] = useState();
  const [activitiesList, setActivitiesList] = useState([]);
  const [historyCurrentPage, setHistoryCurrentPage] = useState(1);
  const [totalActivity, setTotalActivity] = useState();
  const [isLoading, setIsLoading] = useState(false);
  var chainCollection;
  useEffect(async () => {
    if (!id || !selecChianId || !status) {
      return navigate("/");
    }
    if (collectionDetail) {
      setCollectionDes(collectionDetail);
    } else {
      await getCollections();
    }
    handleGetListNft();
    getListOnSale();
    getActivyty();
  }, [id]);
  const handleGetListNft = async () => {
    try {
      const res = await axiosInstance.post(
        `/nft/all-nft?chainId=${selecChianId}`,
        {
          limit: 12,
          page: currentPage,
          sortBy: {
            dateCreated: -1,
          },
          filterBy: {
            tokenAddress: id,
          },
        }
      );
      const { data } = res;
      setResDetailfromAdress(data);
      setListAllNft([...listAllNft, ...data.nftList]);
      setCurrentPage(currentPage + 1);
    } catch (error) {
      console.log(error);
    }
  };
  const getCollections = async () => {
    try {
      const res = await axiosInstance.post(
        `/collection/all-collection?chainId=${selecChianId}`,
        {
          sortBy: { [status]: -1 },
        }
      );
      const { data } = res;
      const { collectionList } = data;
      if (collectionList && collectionList.length > 0) {
        setCollectionDes(
          collectionList.find((item) => item.tokenAddress === id)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getListOnSale = async () => {
    try {
      const res = await axiosInstance.post(
        `/item/market?chainId=${selecChianId}`,
        {
          page: currentPage,
          limit: 12,
          filterBy: {
            tokenAddress: id,
          },
        }
      );
      const { data } = res;
      setListOnSales([...listOnSale, ...data.itemList]);
      setTotalOnsale(data.total);
    } catch (error) {
      console.log(error);
    }
  };
  const getActivyty = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.post(
        `/item/history-item?chainId=${selecChianId}`,
        {
          page: historyCurrentPage,
          limit: 12,
          filterBy: {
            tokenAddress: id,
          },
        }
      );
      const { data } = res;
      console.log("setActivitiesList", data.itemList);
      setActivitiesList([...activitiesList, ...data.itemList]);
      setHistoryCurrentPage(historyCurrentPage + 1);
      setTotalActivity(data.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadMore = () => {
    handleGetListNft();
    if (listOnSale.length < totalOnsale) {
      getListOnSale();
    }
  };
  const handleLoadMoreHistory = async () => {
    // if (position) {
    //   const isAtBottom =
    //     activitiesList.length < totalActivity &&
    //     position.scrollHeight - position.scrollTop === position.clientHeight;
    //   if (isAtBottom) {
    getActivyty();
    //   }
    // }
  };
  return (
    <>
      <CollectionContainer>
        <CollectionPanner bg={collectionDes?.banner} />
        <CollectionDetailBox>
          <CollectionAvt>
            <img src={collectionDes?.avatar} alt="" />
          </CollectionAvt>
          <CollectionDes>
            <CollectionDesTop>
              <div>
                <div className="d-flex align-items-start flex-row mb-3">
                  <i
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="fa fa-chevron-left mt-2"
                    style={{
                      fontSize: 20,
                      fontWeight: 400,
                      cursor: "pointer",
                      paddingRight: "20px",
                    }}
                  ></i>
                  <div>
                    <h1>{collectionDes?.name}</h1>
                    <div className="Text-Collection">
                      {/* <p>Create at : November 2023</p> <span></span> */}
                      <h6>
                        by{" "}
                        {collectionDes?.creator
                          ? `${collectionDes?.creator?.substring(
                              0,
                              4
                            )}...${collectionDes?.creator?.substring(
                              collectionDes?.creator?.length - 4
                            )}`
                          : "---"}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <button>
                {LIST_NETWORK.map((item) => {
                  if (Number(item.chainId) === Number(collectionDes?.chainId)) {
                    chainCollection = item;
                    return (
                      <>
                        <img src={item?.image} alt="" />
                        <p>{item?.name}</p>
                      </>
                    );
                  }
                })}
              </button>
            </CollectionDesTop>
            <CollectionBoxNumGroup>
              <div>
                <p>Total</p>
                <h5>{resDetailfromAdress?.total}</h5>
              </div>
              <div>
                <p>Listed</p>
                <h5>{totalOnsale || "--"}</h5>
              </div>
              <div>
                <p>Vol</p>
                <h5>
                  {collectionDes?.volume &&
                    formatPriceNFT(collectionDes?.volume)}
                </h5>
              </div>
              <div>
                <p>Floor</p>
                <h5>
                  {collectionDes?.lowestPrice &&
                    formatPriceNFT(collectionDes?.lowestPrice)}
                </h5>
              </div>
            </CollectionBoxNumGroup>
          </CollectionDes>
          {/* <p>
          Every first limited NFT collection of Playbux for those who join the
          early birds quest in June 2022. Playbux is a metaverse on the BNB
          chain that integrates Shop to Earn,... Read more
        </p> */}
        </CollectionDetailBox>
        {activitiesList[0] && (
          <BoxActivity
            NFTname={activitiesList[0]?.name}
            OwnerName={shortenAddress(activitiesList[0]?.to)}
            Chain={
              activitiesList[0]?.from
                ? shortenAddress(activitiesList[0]?.from)
                : ""
            }
            time={activitiesList[0]?.createdAt}
            icon={defineName(activitiesList[0]?.transactionType).image}
            avatar={activitiesList[0]?.image}
            type={defineName(activitiesList[0]?.transactionType).name}
          />
        )}

        <div className="flat-tabs themesflat-tabs">
          <Tabs>
            <TabList>
              <Tab>Items</Tab>
              <Tab>Activities</Tab>
            </TabList>
            <TabPanel>
              <BoxContent>
                {listAllNft && listAllNft?.length ? (
                  <>
                    {listAllNft?.map((item, index) => {
                      return (
                        <Link
                          to={`/details?id=${item?.tokenId}&tokenAddress=${
                            item?.tokenAddress
                          }&chainId=${item?.chainId}${
                            listOnSale?.find((itemOnSale) => {
                              return item?.tokenId === itemOnSale?.tokenId;
                            })
                              ? ""
                              : "&type=nft"
                          }`}
                          key={index}
                          className="col-xl-3 col-lg-4 col-md-6 col-12"
                        >
                          <div className="sc-card-product explode ">
                            <div className="card-media">
                              <img
                                src={
                                  item?.tokenUri?.image
                                    ? formartImage(item?.tokenUri?.image)
                                    : formartImage(item?.image)
                                }
                                alt="NFT Image"
                                width="200px"
                                height="200px"
                              />
                            </div>
                            <div className="card-title mg-bt-16">
                              <h5>{item?.collectionName}</h5>
                              <div className="">
                                <div
                                  style={{
                                    width: "24px",
                                  }}
                                >
                                  <img
                                    src={chainCollection?.image}
                                    alt="images"
                                  />
                                </div>
                              </div>
                            </div>
                            <h5>#{item?.tokenId}</h5>
                            <div className="meta-info">
                              <div className="author mt-4">
                                <div className="info">
                                  <h6
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item?.name}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div
                              className="button-place-bid "
                              style={{ width: "100%" }}
                            >
                              <button
                                className="sc-button style-place-bid style bag fl-button pri-3"
                                style={{
                                  width: "100%",
                                  border: "1px solid #f612d1",
                                  marginTop: "20px",
                                  marginBottom: "8px",
                                }}
                              >
                                <span>View NFT</span>
                              </button>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                    {listAllNft?.length < resDetailfromAdress?.total && (
                      <div className="col-md-12 wrap-inner load-more text-center">
                        <button
                          id="load-more"
                          className="sc-button loadmore fl-button pri-3"
                          onClick={() => handleLoadMore()}
                        >
                          <span>Load More</span>
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <Nodata>
                    <img src={NodataImg} alt="" />{" "}
                    <Link to="/mint-nft">
                      <button>Go To Mint-Nft</button>
                    </Link>
                  </Nodata>
                )}
              </BoxContent>
            </TabPanel>
            <TabPanel>
              <TableActivities
                dataTable={activitiesList}
                headers={TABLE_HEADER}
                hanleScrollToLoadMore={handleLoadMoreHistory}
                isLoading={isLoading}
                avatar={collectionDes?.avatar}
                nameColection={collectionDes?.name}
                thbg="#fff"
                isMax={activitiesList.length < totalActivity}
              />
            </TabPanel>
          </Tabs>
        </div>
      </CollectionContainer>
    </>
  );
};
export default DetailCollection;

export const BoxActivity = ({
  NFTname,
  OwnerName,
  Chain,
  time,
  icon,
  avatar,
  type,
}) => {
  return (
    <BoxActivityDefaults>
      <div className="d-flex align-items-center justify-content-center">
        <img
          style={{
            borderRadius: "5px",
            width: "41px",
            height: "41px",
          }}
          src={icon}
          alt="icon"
        />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <img
          style={{
            borderRadius: "5px",
          }}
          src={formartImage(avatar)}
          alt="icon"
        />
      </div>
      <p style={{color: "#fff", marginBottom: '0'}}>
        {NFTname} <span>{type} </span>
        {Chain && (
          <span style={{color: "#fff"}}>
            from {Chain} <span>to</span> {OwnerName}
          </span>
        )}
      </p>
      <span style={{color: "#fff"}}>{time && dayjs(time).format("HH:mm - MM/DD/YYYY")}</span>
    </BoxActivityDefaults>
  );
};

import { getProviderOrSigner ,getArbitrumPublicProvider } from "./index";
import { ethers } from "ethers";
import { EthereumTokenAddress } from "../../utils/constants";
import { Decimal } from "decimal.js";
import { totalXrenderIco } from '../../utils/constants'
import { ERC20_ABI } from "../ABI";
export const buyIco = async (
  token,
  amount,
  address_ICO_Chain,
  abi_ICO_Chain
) => {
  try {
    const signer = await getProviderOrSigner(true);
    const contractIco = new ethers.Contract(
      address_ICO_Chain,
      abi_ICO_Chain,
      signer
    );


    const err20 = new ethers.Contract(
      token,
      ERC20_ABI,
      signer
    );

    if (token === EthereumTokenAddress) {
      const t = await contractIco.buyPresaleByEther(new Decimal(amount).mul(new Decimal(10).pow(18)).toHex(), {
        //// rate of XRender/ETH
        value: new Decimal(amount).mul(new Decimal(10).pow(18)).toHex(),
      });
      await t.wait();
    } else {
      const decimal = await err20.decimals();
      const t = await contractIco.buyPresaleByToken(token, new Decimal(amount).mul(new Decimal(10).pow(decimal)).toHex());
      await t.wait()
    }
  } catch (error) {
    throw error
  }
};



export const claimToken = async (address_ICO_Chain, abi_ICO_Chain) => {
  try {
    const signer = await getProviderOrSigner(true);
    const contractIco = new ethers.Contract(
      address_ICO_Chain,
      abi_ICO_Chain,
      signer
    );
    const t = await contractIco.claim();
    await t.wait()
  } catch (error) {
    throw error
  }
};

export const calculateXrenderToken = async (
  tokenAddress,
  amount,
  address_ICO_Chain,
  abi_ICO_Chain
) => {
  try {
    const provider = await getProviderOrSigner(false);
    console.log({address_ICO_Chain});
    const contractIco = new ethers.Contract(
      address_ICO_Chain,
      abi_ICO_Chain,
      provider
    );
    let decimal = 18

    if(tokenAddress!==EthereumTokenAddress){
      const err20 = new ethers.Contract(
        tokenAddress,
        ERC20_ABI,
        provider
      );
      decimal = await err20.decimals();
    }
    return await contractIco.calculateXrenderAmount(
      tokenAddress,
      new Decimal(amount || 0).mul(new Decimal(10).pow(decimal)).toHex()
    );
  } catch (error) {
    console.log("Error", error);
  }
};

export const calculateTotalRaised = async (
    address_ICO_Chain,
    abi_ICO_Chain
  ) => {
    try {

      const provider = await getProviderOrSigner(false) || await getArbitrumPublicProvider()
      console.log({provider})

      const contractIco = new ethers.Contract(
        address_ICO_Chain,
        abi_ICO_Chain,
        provider
      );
  
      const totalBought =  await contractIco.totalBought();
      // const totalXraiPresale = await getTotalXraiPresale(address_ICO_Chain,abi_ICO_Chain)
      const totalRaised = new Decimal(totalBought._hex || 0).div(new Decimal(10).pow(18)).mul(0.0003).toFixed(2)
      console.log({totalRaised})
      return totalRaised;
    } catch (error) {
      console.log("Error", error);
    }
  };
  export const getUserTokenAmount = async (
    address_ICO_Chain,
    abi_ICO_Chain,
    acount
  ) => {
    try {
      const provider = await getProviderOrSigner(false);
      const contractIco = new ethers.Contract(
        address_ICO_Chain,
        abi_ICO_Chain,
        provider
      );

      const userAddress = acount || ethers.constants.AddressZero
  
      const userAmount =  await contractIco.userAmount(userAddress);
      const amount = new Decimal(userAmount._hex).div(new Decimal(10).pow(18)).toFixed(2)
      return amount;
    } catch (error) {
      console.log("Error", error);
    }
  };


export const getTotalXraiPresale = async (
  address_ICO_Chain,
  abi_ICO_Chain
) => {
  try {
    const provider = await getProviderOrSigner(false) || await getArbitrumPublicProvider()
    console.log({provider})
    const contractIco = new ethers.Contract(
      address_ICO_Chain,
      abi_ICO_Chain,
      provider
    );

    const xraiDecimal =  await contractIco.totalXraiPresale();
    const totalXraiPresale = new Decimal(xraiDecimal).div(new Decimal(10).pow(18)).toFixed(2)
    return totalXraiPresale;
  } catch (error) {
    console.log("Error", error);
  }
};
  
  



  
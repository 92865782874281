export const formartImage = (imageUrl) => {
  // Regular expression pattern for a valid URL
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  const isUrl = urlPattern.test(imageUrl);
  let img = null;
  if (isUrl) {
    img = imageUrl;
  } else if (imageUrl) {
    img = `data:image/svg+xml;utf8,${encodeURIComponent(imageUrl)}`;
  }
  return img;
};

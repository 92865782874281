import {
  BodyInfo,
  MarketBody,
  MarketButton,
  MarketContent,
  MarketItemWrapper,
  MarketTop,
  PriceValue,
  TopAvatars,
  TopPrice,
} from "../styled";
import avatars from "../../../../assets/images/homenew/market/avatars.png";
import arrow from "../../../../assets/images/homenew/market/arrow.svg";
import { formartImage } from "../../../../utils/formartImage";
import { useEffect, useState } from "react";
import { formatPriceNFT } from "../../../../utils/formatPriceNFT";
import { Link } from "react-router-dom";

const MarketItem = ({ item }: any) => {
  const [dataParse, setDataParse] = useState<any>(null);
  const handleParseMetaData = (metaData: any) => {
    if (metaData) {
      const newData = {
        ...item,
        metadata: metaData?.metadata ? JSON.parse(metaData?.metadata) : null,
      };
      console.log(newData);
      setDataParse(newData);
    }
  };

  useEffect(() => {
    handleParseMetaData(item);
  }, []);

  return (
    <MarketItemWrapper>
      <Link
        to={`/details?id=${item?.tokenId}&tokenAddress=${item?.tokenAddress}&chainId=${item?.chainId}`}
      >
        <figure>
          <img
            src={dataParse ? formartImage(dataParse?.metadata?.image) : ""}
            alt="bg"
          />
        </figure>
        <MarketContent>
          <MarketTop>
            <TopAvatars>
              <img src={avatars} alt="avatars" />
            </TopAvatars>
            <TopPrice>
              <p>Price List</p>
              <PriceValue>
                {formatPriceNFT(item?.price)} {item?.token}
              </PriceValue>
            </TopPrice>
          </MarketTop>
          <MarketBody>
            <BodyInfo>
              <h3>{dataParse?.metadata?.name}</h3>
              <p>
                Seller :{" "}
                {`${item.seller.slice(0, 4)}...${item.seller.slice(
                  item.seller.length - 4,
                  item.seller.lenght
                )}`}
              </p>
            </BodyInfo>
            <MarketButton>
              Go details
              <img src={arrow} alt="arrow" />
            </MarketButton>
          </MarketBody>
        </MarketContent>
      </Link>
    </MarketItemWrapper>
  );
};

export default MarketItem;

import styled from "styled-components";
import {
  ButtonCommon,
  TextCustom,
  TitleKey,
  V2Buttons,
} from "../../../components/common/styled";

export const TokenCollectionContainer = styled.div`
  position: relative;
  z-index: 5;
`;
export const CollectionBody = styled.div`
  padding: 50px 20px;
  ${TextCustom} {
    text-align: center;
    margin-bottom: 30px;
  }
`;

export const FormContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 20px;
  text-align: left;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #dce2e9;
  ${TitleKey} {
    margin-bottom: 5px;
  }
  ${V2Buttons} {
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;
export const AddBlock = styled.div`
  margin-bottom: 15px;
`;
export const AddWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
  }
`;
export const ShowBlock = styled.div`
  margin-bottom: 15px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #F313CE;
  background: rgba(0, 178, 200, 0.05);
`;
export const ShowRow = styled.div`
  color: #0d0c43;
  font-weight: 600;
  letter-spacing: 0.2px;
  span {
    color: #F313CE;
    margin-left: 5px;
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;
export const AddressBlock = styled.div`
  margin-bottom: 18px;
`;
export const ReceiveBlock = styled.div`
  margin-bottom: 35px;
  @media screen and (max-width: 743px) {
    margin-bottom: 15px;
  }
`;

export const ModalCWContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -209px;
  margin-top: -158px;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px 40px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 418px;
  min-height: 316px;
  text-align: center;

  @media screen and (max-width: 743px) {
    position: relative;
    width: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    ${ButtonCommon} {
      min-width: auto;
      width: auto;
    }
  }
`;
export const CWButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
`;
export const PCWContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
`;

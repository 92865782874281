import styled from "styled-components";
import { ButtonContract } from "../Bonus/styled";
import { CommonTitle } from "../Features/styled";
import { FilterBox } from "../../../components/layouts/home-5/TodayPicks";

export const MarketplaceContainer = styled.div`
  padding: 100px 0 120px;
  background: rgba(51, 0, 55, 0.09);
  backdrop-filter: blur(13px);
  @media screen and (max-width: 767px) {
    padding: 50px 0 150px;
  }
`;
export const MarketplaceWrapper = styled.div`
  position: relative;
  max-width: 1728px;
  width: 100%;
  margin: 0 auto;
  padding: 0 144px;
  ${FilterBox} {
    justify-content: center;
    .box-check {
      li {
        & > div {
          border-radius: 50%;
        }
      }
    }
  }
  ${ButtonContract} {
    max-width: 190px;
    height: 48px;
    padding-bottom: 8px;
    a {
      font-size: 22px;
    }
  }
  ${CommonTitle} {
    margin-bottom: 50px;
  }
  &:hover {
    ${CommonTitle} {
      span {
        &:nth-child(1) {
          color: #fff;
          opacity: 1;
          text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 0px #fff;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 1440px) {
    padding: 0px 20px;
  }
  @media screen and (max-width: 767px) {
    &::before {
      content: "";
      position: absolute;
      top: 70px;
      left: 15%;
      width: 299px;
      height: 440px;
      border-radius: 440px;
      opacity: 0.4;
      background: #ff43ec;
      filter: blur(78px);
    }
    .marketPlace {
      .swiper-slide-shadow {
        display: none;
      }
      .swiper-container {
        transform: none;
      }
    }
  }
`;
export const MarketplaceTitle = styled.h2`
  color: #fff;
  text-align: center;
  font-size: 48px;
`;
export const MarketplaceList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`;
export const MarketContent = styled.div`
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  min-height: 85px;
  border-top: 1px solid #505050;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7px);
  padding: 10px 12px 20px;
  @media screen and (max-width: 767px) {
    bottom: -4px;
  }
`;
export const MarketTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1023px) {
    margin-bottom: 10px;
  }
`;
export const TopAvatars = styled.div`
  max-width: 40px;
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
`;
export const TopPrice = styled.div`
  p {
    color: #98a2b3;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 0;
  }
`;
export const PriceValue = styled.span`
  color: #fcfcfd;
  font-size: 12px;
  font-weight: 600;
`;
export const MarketBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
export const BodyInfo = styled.div`
  h3 {
    color: #f9fafb;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  p {
    color: #d0d5dd;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 0;
  }
`;
export const MarketButton = styled.div`
  color: #fcfcfd;
  font-size: 10px;
  padding: 5px;
  border-radius: 5px;
  background: rgba(78, 78, 78, 0.19);
  border: 1px solid transparent;
  transition: all 0.25s ease;
  font-weight: 600;
  cursor: pointer;
  img {
    width: 10px;
    margin-left: 5px;
  }
`;
export const MarketHint = styled.div`
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  color: #fff;
  img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    animation: rotateHint 2s cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
  }
  @keyframes rotateHint {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(15deg);
    }
    100% {
      transform: rotate(0);
    }
  }
`;
export const MarketItemWrapper = styled.div`
  position: relative;
  width: calc(25% - 12px);
  min-height: 278px;
  overflow: hidden;
  background-color: #111315;
  cursor: pointer;
  border-radius: 20px;
  background: #1f0823;
  border: 1px solid transparent;
  transition: all 0.25s ease;
  &::before {
    content: "";
    top: 0;
    bottom: 0;
    left: -150px;
    right: -150px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 40%,
      rgba(255, 255, 255, 0.3) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    transform-origin: center center;
    position: absolute;
    z-index: 10;
    animation-name: mask-ani;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
  figure {
    margin-bottom: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @keyframes mask-ani {
    0% {
      transform: rotate(-36deg) translate(-40px, -630px);
    }
    70%,
    100% {
      transform: rotate(-36deg) translate(-40px, 650px);
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: calc(33.33% - 12px);
  }
  @media screen and (min-width: 768px) {
    &:hover {
      border-color: #d6bbfb;
      box-shadow: 0px 0px 27.504px 0px rgba(255, 105, 230, 0.27);
      ${MarketButton} {
        border-color: #7c7c7c;
        background: rgba(0, 0, 0, 0.37);
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 290px;
    height: 366px;
    margin: 0 auto;
    transition: none;
    &::before {
      content: none;
    }
  }
`;

import { FormCW } from "./styled";
import { ButtonCommon, TextCommon, TextCustom } from "../../styled";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const FormConnectWallet = () => {
  const modal = useWeb3Modal();
  return (
    <FormCW>
      <TextCustom color="#0D0C43" fontSize="24px" fontWeight="bold">
        Connect wallet
      </TextCustom>
      <TextCommon>
        Make sure to connect the wallet that you would like
        <br className="sm" /> to add your new tokens to.
      </TextCommon>

      <ButtonCommon onClick={() => modal.open()} style={{ margin: "0 auto" }}>
        Connect Wallet
      </ButtonCommon>
    </FormCW>
  );
};

export default FormConnectWallet;

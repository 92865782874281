import styled from "styled-components";
import { TextCustom, TitleKey } from "../../../../components/common/styled";
import { InputContainer } from "../../../../components/common/input/styled";

export const PortfolioContainer = styled.div`
  text-align: left;
`
export const PortfolioOverview = styled.div`
  background-color: #fff;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border-radius: 20px;
  border: 1px solid #DCE2E9;
  padding: 15px 30px;
  margin-bottom: 20px;
`
export const OverviewTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  line-height: normal;
  .ant-select-selector {
    min-height: 40px;
    background-color: #E8F1F2 !important;
    color: #0D0C43;
  }
`
export const OverviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  ${TextCustom} {
    margin-bottom: 0;
  }
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
    gap: 20px;
  }
`
export const DataCol = styled.div`
  ${TitleKey} {
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 0;
  }
`
export const OverviewData = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 1550px) {
    justify-content: flex-start;
  }
`
export const TransactionsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 15px;
  ${TextCustom} {
    margin: 0 auto;
  }
  ${InputContainer} {
    max-width: 392px;
  }
  @media screen and (max-width: 743px) {
    ${InputContainer} {
      max-width: 100%;
    }
  }
`
export const ReportTransactions = styled.div`
  background-color: #fff;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border-radius: 20px;
  border: 1px solid #DCE2E9;
  padding: 15px;
  ${TextCustom} {
    text-align: center;
    margin-bottom: 10px;
  }
`
import styled from "styled-components";
import { TitleKey } from "../../../../../components/common/styled";

export const LiqV2Container = styled.div`
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #dce2e9;
  background-color: #fff;
  border-radius: 20px;
  max-width: 900px;
  min-height: 430px;
  margin: 0 auto 30px;
  text-align: left;
  padding: 20px;
`;
export const HeaderBox = styled.div`
  width: 50%;
  ${TitleKey} {
    font-weight: 400;
    margin-bottom: 5px;
    span {
      font-weight: bold;
    }
  }
  @media screen and (max-width: 743px) {
    width: 100%;
  }
`;
export const V2Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  @media screen and (max-width: 743px) {
    display: block;
    margin-bottom: 10px;
    ${HeaderBox}:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const V2AddressList = styled.div`
  margin-bottom: 20px;
  ${TitleKey} {
    margin-bottom: 5px;
  }
`;

export const CCConfirm = styled.div`
  display: flex;
  text-align: left;
  border-radius: 10px;
  background: #f5f5f5;
  padding: 30px 20px;
  gap: 50px;
  margin-bottom: 20px;
  justify-content: center;
  @media screen and (max-width: 743px) {
    display: block;
  }
`;
export const ConfirmCol = styled.div`
  ${TitleKey} {
    color: #717696;
    margin-bottom: 10px;
  }
  p {
    display: flex;
    align-items: center;
    color: #0d0c43;
    margin-bottom: 0;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  @media screen and (max-width: 743px) {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    p {
      font-size: 15px;
    }
    ${TitleKey} {
      color: #000;
      margin-bottom: 5px;
      font-size: 18px;
    }
  }
`;
export const RowHash = styled.div`
  border-radius: 8px;
  border: 0.75px solid #d9d9d9;
  background: #f3f4f9;
  padding: 10px;
  margin: 15px 0;
  color: #717696;
  font-weight: 600;
  display: flex;
  justify-content: space-evenly;
  a {
    color: #717696;
    img {
      width: 20px;
      height: 20px;
      margin-right: 3px;
      border-radius: 50px;
    }
  }
  &:hover {
    text-decoration: underline;
  }
`;

import { ethers } from "ethers";
import useActiveWeb3React from "./useActiveWeb3React";
import { useCallback, useEffect, useState } from "react";
import { shortenAddress } from "../utils/formartAddress";
const SID = require("@siddomains/sidjs").default;
const SIDfunctions = require("@siddomains/sidjs");
export default function useEnsName() {

    const [ENSName, setENSName] = useState("")
    const { account, chainId } = useActiveWeb3React();
    const fetchName = useCallback(async () => {
        try {
          let tld = null;
          let rpc = null;
          const address = account;
          switch (chainId) {
            case 56:
              tld = "bnb";
              rpc = "https://bsc-dataseed.binance.org/";
              break;
            case 42161:
              tld = "arb1";
              rpc = "https://arb1.arbitrum.io/rpc";
              break;
            default:
              tld = null;
              rpc = null;
              break;
          }
          
          const provider = new ethers.providers.JsonRpcProvider(rpc as string);
          const sid = new SID({
            provider,
            sidAddress: SIDfunctions.getSidAddress(chainId),
          });
          const name = await sid.getName(address);
          setENSName(name.name);
        } catch (error) {
          console.error("Error fetching name:", error);
        }
      },[account, chainId]);
      useEffect(() => {
        if(!account || !chainId) return setENSName("");
        fetchName();
      }, [chainId, account, fetchName]);

      return ENSName ? ENSName : shortenAddress(account);
}
import styled from "styled-components";
import { defineName } from "../../utils/defineName";
import { ethers } from "ethers";
import { shortenAddress } from "../../utils/formartAddress";
import dayjs from "dayjs";
import { useRef } from "react";
import LoadingScreen from "../../pages/Loading";
import { formartImage } from "../../utils/formartImage";
import { formatPriceNFT } from "../../utils/formatPriceNFT";

export default function TableActivities(props) {
  const {
    dataTable,
    headers,
    hanleScrollToLoadMore,
    isLoading,
    avatar,
    nameColection,
    thbg,
    isMax,
  } = props;
  const tableActivitiesRef = useRef(null);

  // const handleScroll = () => {
  //   let positionScroll = tableActivitiesRef.current;
  //   hanleScrollToLoadMore(positionScroll);
  // };

  console.log("dataTable", dataTable);

  return (
    <>
      <BoxTableActivities thbg={thbg}>
        {isLoading && <LoadingScreen />}
        <div
          ref={tableActivitiesRef}
          // onScroll={() => {
          //   handleScroll();
          // }}
          className="table-content d-flex justify-content-center px-4"
        >
          <table className="">
            <thead>
              <tr>
                {headers &&
                  headers.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
              </tr>
            </thead>
            <tbody>
              {dataTable &&
                dataTable.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              style={{
                                width: "32px",
                              }}
                              className="mr-3"
                              src={defineName(item.transactionType).image}
                              alt=""
                            />
                          </div>
                          <h5> {defineName(item.transactionType).name} </h5>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "4px",
                              background: "#ebeaf3",
                              boxShadow: "0 0 0 8px rgba(255, 255, 255, 0)",
                            }}
                            className="mr-3"
                          >
                            <img
                              style={{
                                borderRadius: "4px",
                              }}
                              src={formartImage(item?.image) || avatar}
                              alt=""
                            />
                          </div>
                          <div>
                            <span>
                              {" "}
                              {item?.collections?.name ||
                                nameColection ||
                                "..."}{" "}
                            </span>
                            <h5>{item?.name}</h5>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h5>
                            {item?.price && formatPriceNFT(item?.price)}{" "}
                            {item.token}
                          </h5>
                        </div>
                      </td>
                      <td>
                        <p>{item?.from ? shortenAddress(item?.from) : "---"}</p>
                      </td>
                      <td>
                        <p>{item?.to ? shortenAddress(item?.to) : "---"}</p>
                      </td>
                      <td>
                        <p
                        >
                          {dayjs(item?.createdAt).format("HH:mm - MM/DD/YYYY")}
                        </p>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {isMax && (
          <button
            className="sc-button loadmore fl-button pri-3"
            onClick={() => {
              hanleScrollToLoadMore();
            }}
          >
            {" "}
            <span>Load More</span>{" "}
          </button>
        )}
      </BoxTableActivities>
    </>
  );
}

const BoxTableActivities = styled.div`
  overflow: auto;
  transform-style: preserve-3d;
  display: flex;
  flex-direction: column;
  align-items: center;
  .table-content {
    overflow: auto;
    width: fit-content;
    table {
      border: none;
      td,
      th {
        border: none;
      }
      thead {
        tr {
          th {
            position: sticky;
            z-index: 2;
            top: 0;
            background-color: ${(props) =>
              props.thbg ? props.thbg : "transparent"};
            width: 150px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            &:nth-child(2),
            &:nth-child(6) {
              width: 200px;
            }
            padding-top: 15px;
            padding-bottom: 15px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding-top: 15px;
            padding-bottom: 15px;
            h5 {
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 24px */
            }
            p {
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }
            span {
              color: #8b898b;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }
  }
  button {
    margin: 20px auto;
  }
`;

import { HeaderBox, LiqV2Container, V2AddressList, V2Header } from '../../LPV2/LiqV2/styled'
import { ButtonCommon, TextAreaCommon, TitleKey } from '../../../../../components/common/styled'
import InputCommon from '../../../../../components/common/input'
import TextArea from 'antd/lib/input/TextArea'
import { ButtonAction } from '../../../../../components/common/form/selectNetwork/styled'

// Images
import upload from '../../../../../assets/images/common/form/upload.svg'
import download from '../../../../../assets/images/common/form/download.svg'
import { V2Buttons } from '../../LPV2/LiqV2'

const LiqV3 = ({ setStep }: any) => {
  const handleChangeInput = () => {

  }
  return (
    <>
      <LiqV2Container>
        <V2Header>
          <HeaderBox>
            <TitleKey>
              Amount <span>BSCS</span> want to add LP
            </TitleKey>
            <InputCommon placeHolder="0.0" value="123565355" onChange={handleChangeInput} suffix={"BSCS" || "--"} />
          </HeaderBox>
          <HeaderBox>
            <TitleKey>
              Amount <span>BUSD</span> want to add LP
            </TitleKey>
            <InputCommon placeHolder="0.0" value="0.5" onChange={handleChangeInput} suffix={"BUSD" || "--"} />
          </HeaderBox>
        </V2Header>
        <V2AddressList>
          <TitleKey>
            Address List
          </TitleKey>
          <TextAreaCommon>
            <TextArea
              value="0xEa0bED9473D811eFd39E8C080d9cF8413394eb58"
              placeholder="Enter one address and amount on each line. &#10;Supports only format. &#10;0x19c5a9d732431633CF357579f9CA05466F94d3f8, 3.141592 &#10;0xEa0bED9473D811eFd39E8C080d9cF8413394eb58, 1.41421"
              style={{ height: 220, resize: 'none' }}
            />
          </TextAreaCommon>
        </V2AddressList>
        <V2Buttons>
          <div style={{ display: 'flex', alignItems: "center", gap: '10px' }}>
            <ButtonCommon
              className='action-button'
              disabled={false}
              background={"#fff"}
              textColor={"#0D0C43"}
            >
              <img style={{ width: "20px", height: "20px" }} src={upload} alt="upload" />
              Upload Excel
            </ButtonCommon>
            <ButtonCommon
              className='action-button'
              disabled={false}
              background={"#fff"}
              textColor={"#0D0C43"}
            >
              <img style={{ width: "20px", height: "20px" }} src={download} alt="download" />
              Sample file
            </ButtonCommon>
          </div>
          <ButtonCommon
            className="add-button"
            disabled={true}
            background={"#CFD0D6 "}
            textColor={"#81829B"}
          >
            Add liquidity
          </ButtonCommon>
        </V2Buttons>
      </LiqV2Container>
      <ButtonAction>
        <ButtonCommon
          style={{
            border: '2px solid #F313CE',
            padding: "5px 30px",
            width: 'auto',
            fontWeight: "600"
          }}
          background="transparent" textColor="#F313CE"
          onClick={() => setStep(1)}>
          Prev
        </ButtonCommon>
      </ButtonAction>
    </>
  )
}

export default LiqV3
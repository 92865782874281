import styled from "styled-components";
import { ButtonCommon, TextCommon, TextCustom } from "../../../components/common/styled";

// Images
import tick from '../../../assets/images/walGen/tick-circle.svg'
import tick_white from '../../../assets/images/walGen/tick-circle-white.svg'

export const WalGenButton = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 156px;
  margin: 0 auto 15px;
  border-radius: 6px;
  border: 1px solid #0D0C43;
  padding: 10px 14px;
  color: #0D0C43;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
`
export const WalGenBody = styled.div`
  background: #0c000d;
  padding: 20px;
  &.gen {
    padding-top: 50px;
  }
  @media screen and (max-width: 743px) {
    .title {
      font-size: 30px;
    }
  }
`
export const WalGenBlock = styled.div`
  background-color: #fff;
  padding: 48px 24px 30px;
  border-radius: 20px;
  ${TextCustom} {
    line-height: normal;
    margin-bottom: 15px;
    span {
      color: #F313CE;
    }
  }
  ${TextCommon} {
    margin-bottom: 45px;
  }
  @media screen and (max-width: 743px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`
export const ItemLeft = styled.div`
  border-right: 1px solid rgba(13, 12, 67, 0.40);
  padding-bottom: 30px;
  padding-right: 27px;
  span {
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #717696;
  }
  ${TextCustom} {
    margin-bottom: 5px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    padding-right: 15px;
    ${TextCustom} {
      font-size: 70px;
    }
    span {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1023px) {
    padding: 0;
    border-right: 0;
  }
`
export const ItemTag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-bottom: 20px;
  border: 1px solid #0D0C43;
  padding: 10px 14px;
  color: #0D0C43;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
`
export const WalGenList = styled.ul`
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  gap: 18px;
  text-align: left;
  & > li {
    width: 50%;
    padding: 40px;
    list-style-type: none;
    background-color: #eee;
    border-radius: 20px;
    &.premium {
      background-color: #F313CE;
      color: #fff;
      ${ItemLeft} {
        border-color: rgba(255, 255, 255, 0.40);
        span {
          color: #fff;
        }
      }
      ${ItemTag} {
        border-color: #fff;
        color: #fff;
      }
    }
  }
  ${ButtonCommon} {
    min-width: 100%;
  }
  @media screen and (max-width: 743px) {
    display: block;
    & > li {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
`
export const ItemWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  & > div {
    width: 50%;
  }
  @media screen and (max-width: 1023px) {
    display: block;
    & > div {
      width: 100%;
    }
  }
`
export const ItemRight = styled.div`
  padding-left: 18px;
  @media screen and (max-width: 1023px) {
    padding-left: 0
  }
`
export const ListRight = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  & > li {
    list-style-type: none;
    position: relative;
    display: flex;
    &::before {
      content: "";
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background: url(${tick}) no-repeat center / 100% auto;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &.premium::before {
      background-image: url(${tick_white});
    }
  }
`
export const WalGenTable = styled.div`
  padding: 20px;
  background-color: #fff;
  border: 1px solid #DCE2E9;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
`
export const WalGenContainer = styled.div`
`
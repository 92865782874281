import styled from "styled-components";
import {
  TextCustom,
  TitleKey,
  TitleCommon,
  WrapperContainer,
  V2Buttons,
} from "../../../../components/common/styled";
import { InputContainer } from "../../../../components/common/input/styled";
import Tooldex from "../../../../assets/images/Tooldex.png";

export const AuditContractContainer = styled.div`
  text-align: left;
`;

export const BodyContainer = styled.div`
  // background-color: #192139;
  padding: 40px 20px 20px;
  @media screen and (max-width: 1380px) {
    ${WrapperContainer} {
      padding: 0;
    }
  }
`;

export const AuditTop = styled.div`
  margin-bottom: 15px;
  ${TitleCommon} {
    margin-bottom: 10px;
  }
  text-align: center;
  span {
    display: block;
    text-align: center;
    color: #000;
    font-size: 16px;
    letter-spacing: 0.16px;
  }
`;
export const AuditContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 28px 77px;

  justify-content: space-between;
  @media screen and (min-width: 744px) and (max-width: 1380px) {
    padding: 20px;
  }
  @media screen and (min-width: 744px) and (max-width: 1023px) {
    max-width: 780px;
  }
  @media screen and (max-width: 743px) {
    padding: 0;
    background-color: transparent;
  }
`;
export const BodyCommon = styled.div`
  @media screen and (max-width: 743px) {
    width: 100%;
  }
`;
export const AuditBlock = styled.div<{
  rotate?: string;
  scale?: string;
  opacity?: string;
}>`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 300px;
    height: 70px;
    opacity: ${({ opacity }) => (opacity ? opacity : ".3")};
    transform: ${({ rotate, scale }) =>`translate(-50%,-50%) rotate(${rotate ? rotate : "-30deg"}) scale(${scale ? scale : "1.5"})`};
    background: url(${Tooldex}) no-repeat center / 100% auto;
    @media screen and (max-width: 550px) {
      transform: ${({ rotate, scale }) =>`translate(-50%,-50%) rotate(${rotate ? rotate : "-30deg"}) scale(${scale ? scale : "1"})`};
    }
  }
`;

export const AuditList = styled.ul`
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  gap: 40px;
  & > li {
    position: relative;
    list-style-type: none;
    border-radius: 20px;
    padding: 20px;
    background: #e8eaf0;
    min-width: 207px;
    z-index: 3;
    min-width: auto;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      top: 0;
      right: 0;
      left: 0;
      border-radius: 25px;
      background-color: #F313CE;
      z-index: -2;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      left: 0;
      border-radius: 20px;
      background-color: #e8eaf0;
      z-index: -1;
    }
  }
  @media screen and (min-width: 744px) and (max-width: 1150px) {
    & > li {
      min-width: auto;
    }
  }
  @media screen and (min-width: 744px) and (max-width: 1023px) {
    gap: 20px;
  }
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
    gap: 30px;
    & > li {
      min-width: auto;
      width: 100%;
    }
  }
`;
export const AuditItems = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  & > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    list-style-type: none;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    z-index: 2;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  img {
    width: 24px;
    height: 24px;
  }
  @media screen and (max-width: 743px) {
    & > li {
      justify-content: space-between;
    }
  }
`;
export const AuditItemsLPHolder = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  & > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    list-style-type: none;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    z-index: 2;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  img {
    width: 24px;
    height: 24px;
  }
  @media screen and (max-width: 743px) {
    & > li {
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 550px) {
    & > li {
      display: block;
    }
  }
`;
export const AuditTitle = styled.p`
  color: #0d0c43;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  margin-bottom: 5px;
`;
export const AuditDesc = styled.p<{ color?: string }>`
  margin-bottom: 15px;
  color: ${({ color }) => color};
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
`;

export const FormContainer = styled.div`
  max-width: 720px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 20px;
  text-align: left;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #dce2e9;
  ${TitleKey} {
    margin-bottom: 5px;
  }
  ${V2Buttons} {
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

export const AddBlock = styled.div`
  margin-bottom: 15px;
`;
export const AddWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
  }
`;

export const TitleDex = styled.span`
  font-size: 16px;
  font-weight: 600;
`;
export const ValueDex = styled.span`
  color: #f45e5e;
  font-weight: 500;
`;
export const Tag = styled.span`
  background-color: rgba(219, 169, 72, 1);
  color: #fff;
  padding: 3px 5px;
  border-radius: 6px;
`;
export const EmptyContent = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  gap: 40px;
  padding-bottom: 40px;
  & > div {
    position: relative;
    list-style-type: none;
    border-radius: 20px;
    padding: 20px;
    background: #e8eaf0;
    min-width: 207px;
    z-index: 3;
    min-width: auto;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      top: 0;
      right: 0;
      left: 0;
      border-radius: 25px;
      background-color: #F313CE;
      z-index: -2;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      left: 0;
      border-radius: 20px;
      background-color: #fff;
      z-index: -1;
    }
  }
  @media screen and (min-width: 744px) and (max-width: 1150px) {
    & > div {
      min-width: auto;
    }
  }
  @media screen and (min-width: 744px) and (max-width: 1023px) {
    gap: 10px;
  }
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
    gap: 40px;
    & > li {
      min-width: auto;
      width: 100%;
    }
  }
`;
export const AuditEmpty = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  text-align: center;
  justify-content: center;
`;
export const RowIcon = styled.span`
  display: flex;
  align-items: center;
`;

import { createContext, useState, ReactNode } from 'react';
import { ModalContainer, ModalContainerMenu, Wrapper } from './styled';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
}
interface ModalContextProps {
  showModal?: boolean;
  showBlock?: boolean;
  onOpenModal?: any;
  onCloseModal?: any;
  triggerBlock?: any;
  propsModal?: any;
  onOpenModalMenu?: any;
  onCloseModalMenu?: any;
  showModalMenu?: any;
  setShowModalMenu?: any;
  setShowModal?: any
}

export const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<Props> = ({ children }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalMenu, setShowModalMenu] = useState(false);
  const [showBlock, setShowBlock] = useState(false);
  const [ModalElm, setModalElm] = useState<ReactNode>(<></>);
  const [ModalElmMenu, setModalElmMenu] = useState<ReactNode>(<></>);
  const [propsModal, setPropsModal] = useState<any>()
  const onOpenModal = (Modal: ReactNode, props?: any) => {
    setShowModal(true);
    setShowModalMenu(false);
    setModalElm(Modal);
    setPropsModal(props || {});
  };
  const onCloseModal = () => {
    setShowModal(false);
    setModalElm(<></>);
  };
  const onOpenModalMenu = (Modal: ReactNode) => {
    setShowModalMenu(true);
    setShowModal(false);
    setModalElmMenu(Modal);
  };
  const onCloseModalMenu = () => {
    setShowModalMenu(false);
    setModalElm(<></>);
  };
  const triggerBlock = () => {
    setShowBlock(!showBlock)
  }
  const ModalContextValue: ModalContextProps = {
    showModal,
    showBlock,
    onOpenModal,
    onCloseModal,
    triggerBlock,
    propsModal,
    onOpenModalMenu,
    onCloseModalMenu,
    showModalMenu,
    setShowModal,
    setShowModalMenu
  };

  return (
    <ModalContext.Provider value={ModalContextValue}>
      <Wrapper showModal={showModal} showModalMenu={showModalMenu}>
        <ModalContainer showModal={showModal} onClick={onCloseModal}>
          <WrapperModal
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            {ModalElm}
          </WrapperModal>
        </ModalContainer>
        {showModal ? <>
        </> : <>
          <ModalContainerMenu className={showModalMenu ? "menu" : ""} onClick={onCloseModalMenu}>
            <WrapperModalMenu
              className={showModalMenu ? "menu" : ""}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              {ModalElmMenu}
            </WrapperModalMenu>
          </ModalContainerMenu>
        </>}
        {children}
      </Wrapper>
    </ModalContext.Provider>
  );
};

const WrapperModal = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 0 20px;
`

const WrapperModalMenu = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: 100vh;
`
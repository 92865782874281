import {
  ADDR_MKP_ARB_MAINNET,
  ADDR_MKP_BNB_MAINNET,
  ADDR_MKP_OPBNB_MAINNET,
  ADDR_MKP_ZETA_TESTNET,
  ADDR_NFT_ANIMAL_ARB_MAINNET,
  ADDR_NFT_ANIMAL_BNB_MAINNET,
  ADDR_NFT_ANIMAL_OPBNB_MAINNET,
  ADDR_NFT_ANIMAL_ZETA_TESTNET,
  ADDR_NFT_ART_ARB_MAINNET,
  ADDR_NFT_ART_BNB_MAINNET,
  ADDR_NFT_ART_OPBNB_MAINNET,
  ADDR_NFT_ART_ZETA_TESTNET,
  ADDR_NFT_CARTOON_ARB_MAINNET,
  ADDR_NFT_CARTOON_BNB_MAINNET,
  ADDR_NFT_CARTOON_OPBNB_MAINNET,
  ADDR_NFT_CARTOON_ZETA_TESTNET,
  ADDR_NFT_CELERIRY_ARB_MAINNET,
  ADDR_NFT_CELERIRY_BNB_MAINNET,
  ADDR_NFT_CELERIRY_OPBNB_MAINNET,
  ADDR_NFT_CELERIRY_ZETA_TESTNET,
  ADDR_NFT_LUFFY_ARB_MAINNET,
  ADDR_NFT_LUFFY_OPBNB_MAINNET,
  ADDR_NFT_LUFFY_ZETA_MAINNET,
  ADDR_NFT_MUSIC_ARB_MAINNET,
  ADDR_NFT_MUSIC_BNB_MAINNET,
  ADDR_NFT_MUSIC_OPBNB_MAINNET,
  ADDR_NFT_MUSIC_ZETA_TESTNET,
  ADDR_NFT_NATURE_ARB_MAINNET,
  ADDR_NFT_NATURE_BNB_MAINNET,
  ADDR_NFT_NATURE_OPBNB_MAINNET,
  ADDR_NFT_NATURE_ZETA_TESTNET,
  ADDR_NFT_ROBOT_ARB_MAINNET,
  ADDR_NFT_ROBOT_BNB_MAINNET,
  ADDR_NFT_ROBOT_OPBNB_MAINNET,
  ADDR_NFT_ROBOT_ZETA_TESTNET,
  ADDR_NFT_SPORT_ARB_MAINNET,
  ADDR_NFT_SPORT_BNB_MAINNET,
  ADDR_NFT_SPORT_OPBNB_MAINNET,
  ADDR_NFT_SPORT_ZETA_TESTNET,
  ARB_ANIMAL_NFT_ABI,
  ARB_ART_NFT_ABI,
  ARB_CARTOON_NFT_ABI,
  ARB_CELERITY_NFT_ABI,
  ARB_MKP_ABI_MAINNET,
  ARB_MUSIC_NFT_ABI,
  ARB_NATURE_NFT_ABI,
  ARB_NFT_GEN,
  ARB_NFT_Lufffy_ABI,
  ARB_ROBOT_NFT_ABI,
  ARB_SPORT_NFT_ABI,
  BNB_ANIMAL_NFT_ABI,
  BNB_ART_NFT_ABI,
  BNB_CARTOON_NFT_ABI,
  BNB_CELERITY_NFT_ABI,
  BNB_MKP_ABI_MAINNET,
  BNB_MUSIC_NFT_ABI,
  BNB_NATURE_NFT_ABI,
  BNB_NFT_GEN,
  BNB_ROBOT_NFT_ABI,
  BNB_SPORT_NFT_ABI,
  BNB_NFT_CAR,
  OPBNB_ANIMAL_NFT_ABI,
  OPBNB_ART_NFT_ABI,
  OPBNB_CARTOON_NFT_ABI,
  OPBNB_CELERITY_NFT_ABI,
  OPBNB_MKP_ABI_MAINNET,
  OPBNB_MUSIC_NFT_ABI,
  OPBNB_NATURE_NFT_ABI,
  OPBNB_NFT_GEN,
  OPBNB_ROBOT_NFT_ABI,
  OPBNB_SPORT_NFT_ABI,
  OpBNB_NFT_LUFFY_ABI,
  ZETA_ANIMAL_NFT_ABI,
  ZETA_ART_NFT_ABI,
  ZETA_CARTOON_NFT_ABI,
  ZETA_CELERITY_NFT_ABI,
  ZETA_MKP_ABI_TESTNET,
  ZETA_MUSIC_NFT_ABI,
  ZETA_NATURE_NFT_ABI,
  ZETA_NFT_GEN,
  ZETA_NFT_Luffy_ABI,
  ZETA_ROBOT_NFT_ABI,
  ZETA_SPORT_NFT_ABI,
  address_NFT_GENAI_ARB_MAINNET,
  address_NFT_GenAI_OPBNB_MAINNET,
  address_NFT_GenAi_BNB_MAINNET,
  address_NFT_ZETA_TESTNET,
  ADDR_NFT_CAR_MAINNET,
} from "../../integrateContract/ABI";

export const optionAddressContracts = [
  {
    chainId: "56",
    contract: [
      {
        // MKP
        addressMKP: ADDR_MKP_BNB_MAINNET,
        abi: BNB_MKP_ABI_MAINNET,
        name: "Marketplace",
      },
      {
        addressMKP: ADDR_NFT_CAR_MAINNET,
        abi: BNB_NFT_CAR,
        name: "Car",
      },
      {
        // NFT GENAI
        addressMKP: address_NFT_GenAi_BNB_MAINNET,
        abi: BNB_NFT_GEN,
        name: "Luffy",
      },
      {
        //  NFT Cartoon
        addressMKP: ADDR_NFT_CARTOON_BNB_MAINNET,
        abi: BNB_CARTOON_NFT_ABI,
        name: "Cartoon",
      },
      {
        //  NFT Celerity
        addressMKP: ADDR_NFT_CELERIRY_BNB_MAINNET,
        abi: BNB_CELERITY_NFT_ABI,
        name: "Celebrity",
      },
      {
        //  NFT Music
        addressMKP: ADDR_NFT_MUSIC_BNB_MAINNET,
        abi: BNB_MUSIC_NFT_ABI,
        name: "Music",
      },
      {
        // NFT Animal
        addressMKP: ADDR_NFT_ANIMAL_BNB_MAINNET,
        abi: BNB_ANIMAL_NFT_ABI,
        name: "Animal",
      },
      {
        // NFT ART
        addressMKP: ADDR_NFT_ART_BNB_MAINNET,
        abi: BNB_ART_NFT_ABI,
        name: "Art",
      },
      {
        // NFT NATURE
        addressMKP: ADDR_NFT_NATURE_BNB_MAINNET,
        abi: BNB_NATURE_NFT_ABI,
        name: "Nature",
      },
      {
        //NFT ROBOT
        addressMKP: ADDR_NFT_ROBOT_BNB_MAINNET,
        abi: BNB_ROBOT_NFT_ABI,
        name: "Robot",
      },
      {
        //NFT SPORT
        addressMKP: ADDR_NFT_SPORT_BNB_MAINNET,
        abi: BNB_SPORT_NFT_ABI,
        name: "Sport",
      },
    ],
  },
  {
    chainId: "204",
    contract: [
      {
        // Luffy
        addressMKP: ADDR_NFT_LUFFY_OPBNB_MAINNET,
        abi: OpBNB_NFT_LUFFY_ABI,
        name: "Luffy",
      },
      {
        // MKP
        addressMKP: ADDR_MKP_OPBNB_MAINNET,
        abi: OPBNB_MKP_ABI_MAINNET,
        name: "Marketplace",
      },
      {
        // NFT GENAI
        addressMKP: address_NFT_GenAI_OPBNB_MAINNET,
        abi: OPBNB_NFT_GEN,
        name: "Luffy",
      },
      {
        //  NFT Cartoon
        addressMKP: ADDR_NFT_CARTOON_OPBNB_MAINNET,
        abi: OPBNB_CARTOON_NFT_ABI,
        name: "Cartoon",
      },
      {
        //  NFT Celerity
        addressMKP: ADDR_NFT_CELERIRY_OPBNB_MAINNET,
        abi: OPBNB_CELERITY_NFT_ABI,
        name: "Celebrity",
      },
      {
        //  NFT Music
        addressMKP: ADDR_NFT_MUSIC_OPBNB_MAINNET,
        abi: OPBNB_MUSIC_NFT_ABI,
        name: "Music",
      },
      {
        // NFT Animal
        addressMKP: ADDR_NFT_ANIMAL_OPBNB_MAINNET,
        abi: OPBNB_ANIMAL_NFT_ABI,
        name: "Animal",
      },
      {
        // NFT ART
        addressMKP: ADDR_NFT_ART_OPBNB_MAINNET,
        abi: OPBNB_ART_NFT_ABI,
        name: "Art",
      },
      {
        // NFT NATURE
        addressMKP: ADDR_NFT_NATURE_OPBNB_MAINNET,
        abi: OPBNB_NATURE_NFT_ABI,
        name: "Nature",
      },
      {
        //NFT ROBOT
        addressMKP: ADDR_NFT_ROBOT_OPBNB_MAINNET,
        abi: OPBNB_ROBOT_NFT_ABI,
        name: "Robot",
      },
      {
        //NFT SPORT
        addressMKP: ADDR_NFT_SPORT_OPBNB_MAINNET,
        abi: OPBNB_SPORT_NFT_ABI,
        name: "Sport",
      },
    ],
  },
  {
    chainId: "42161",
    contract: [
      {
        // Luffy
        addressMKP: ADDR_NFT_LUFFY_ARB_MAINNET,
        abi: ARB_NFT_Lufffy_ABI,
        name: "Luffy",
      },
      {
        // MKP
        addressMKP: ADDR_MKP_ARB_MAINNET,
        abi: ARB_MKP_ABI_MAINNET,
        name: "Marketplace",
      },
      {
        //  NFT Cartoon
        addressMKP: ADDR_NFT_CARTOON_ARB_MAINNET,
        abi: ARB_CARTOON_NFT_ABI,
        name: "Cartoon",
      },
      {
        //  NFT Celerity
        addressMKP: ADDR_NFT_CELERIRY_ARB_MAINNET,
        abi: ARB_CELERITY_NFT_ABI,
        name: "Celebrity",
      },
      {
        //  NFT Music
        addressMKP: ADDR_NFT_MUSIC_ARB_MAINNET,
        abi: ARB_MUSIC_NFT_ABI,
        name: "Music",
      },
      {
        // NFT GENAI
        addressMKP: address_NFT_GENAI_ARB_MAINNET,
        abi: ARB_NFT_GEN,
        name: "Gen Ai",
      },
      {
        // NFT Animal
        addressMKP: ADDR_NFT_ANIMAL_ARB_MAINNET,
        abi: ARB_ANIMAL_NFT_ABI,
        name: "Animal",
      },
      {
        // NFT NATURE
        addressMKP: ADDR_NFT_NATURE_ARB_MAINNET,
        abi: ARB_NATURE_NFT_ABI,
        name: "Nature",
      },
      {
        // NFT ART
        addressMKP: ADDR_NFT_ART_ARB_MAINNET,
        abi: ARB_ART_NFT_ABI,
        name: "Art",
      },

      {
        //NFT ROBOT
        addressMKP: ADDR_NFT_ROBOT_ARB_MAINNET,
        abi: ARB_ROBOT_NFT_ABI,
        name: "Robot",
      },
      {
        //NFT SPORT
        addressMKP: ADDR_NFT_SPORT_ARB_MAINNET,
        abi: ARB_SPORT_NFT_ABI,
        name: "Sport",
      },
    ],
  },
  {
    chainId: "7001",
    contract: [
      {
        // Luffy
        addressMKP: ADDR_NFT_LUFFY_ZETA_MAINNET,
        abi: ZETA_NFT_Luffy_ABI,
        name: "Luffy",
      },
      {
        // MKP
        addressMKP: ADDR_MKP_ZETA_TESTNET,
        abi: ZETA_MKP_ABI_TESTNET,
        name: "Marketplace",
      },
      {
        //  NFT Cartoon
        addressMKP: ADDR_NFT_CARTOON_ZETA_TESTNET,
        abi: ZETA_CARTOON_NFT_ABI,
        name: "Cartoon",
      },
      {
        //  NFT Celerity
        addressMKP: ADDR_NFT_CELERIRY_ZETA_TESTNET,
        abi: ZETA_CELERITY_NFT_ABI,
        name: "Celebrity",
      },
      {
        //  NFT Music
        addressMKP: ADDR_NFT_MUSIC_ZETA_TESTNET,
        abi: ZETA_MUSIC_NFT_ABI,
        name: "Music",
      },
      {
        // NFT GENAI
        addressMKP: address_NFT_ZETA_TESTNET,
        abi: ZETA_NFT_GEN,
        name: "Gen Ai",
      },
      {
        // NFT Animal
        addressMKP: ADDR_NFT_ANIMAL_ZETA_TESTNET,
        abi: ZETA_ANIMAL_NFT_ABI,
        name: "Animal",
      },
      {
        // NFT NATURE
        addressMKP: ADDR_NFT_NATURE_ZETA_TESTNET,
        abi: ZETA_NATURE_NFT_ABI,
        name: "Nature",
      },
      {
        // NFT ART
        addressMKP: ADDR_NFT_ART_ZETA_TESTNET,
        abi: ZETA_ART_NFT_ABI,
        name: "Art",
      },

      {
        //NFT ROBOT
        addressMKP: ADDR_NFT_ROBOT_ZETA_TESTNET,
        abi: ZETA_ROBOT_NFT_ABI,
        name: "Robot",
      },
      {
        //NFT SPORT
        addressMKP: ADDR_NFT_SPORT_ZETA_TESTNET,
        abi: ZETA_SPORT_NFT_ABI,
        name: "Sport",
      },
    ],
  },
];

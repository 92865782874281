import { useContext, useEffect, useState } from "react";
import { Wapper, ImgBox, BoxBtnGroup, BoxBtn } from "./style";
import axios from "axios";
import {
  getPriceMintGenAI,
  mintNFTGenAi,
} from "../../../../../integrateContract/contract/genNFTAI";
import {
  address_NFT_GenAI_OPBNB_MAINNET,
  address_NFT_GENAI_ARB_MAINNET,
  address_NFT_ZETA_TESTNET,
  OPBNB_NFT_GEN,
  ARB_NFT_GEN,
  ZETA_NFT_GEN,
  address_NFT_GenAi_BNB_MAINNET,
  BNB_NFT_GEN,
} from "../../../../../integrateContract/ABI";

import { ModalMintNFT } from "../ModalMintNFT";
import { ModalConfirmContext } from "../../../../ProviderPopUp/confirm";
import { ToastContainer, toast } from "react-toastify";
import { uploadImageToPinata } from "../../../../../handlePinata/pinata";
import ToastTyfile from "../../../../layouts/Toastify";
import Web3 from "web3";
import { LIST_NETWORK } from "../../../../../constants/connectors";
const web3 = new Web3(window.ethereum);

const initialImages = [
  "/assets/images/out-0.png",
  "/assets/images/out-1.png",
  "/assets/images/out-2.png",
  "/assets/images/out-3.png",
];

const OutputComponent = ({ imageOutput, type }) => {
  const [fakeData, setFakeData] = useState(initialImages);
  const [ListImgSelect, setListImgSelect] = useState([]);
  const [mintId, setMintId] = useState([]);
  const { onOpen, onClose } = useContext(ModalConfirmContext);
  const [isMinting, setIsMinting] = useState(false);

  const downloadImages = async () => {
    try {
      const promises = ListImgSelect.map((url) =>
        axios.get(url, { responseType: "blob" })
      );
      const responses = await Promise.all(promises);
      responses.forEach((response, index) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `image${index + 1}.jpg`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, value, id) => {
    if (!imageOutput) return;
    if (e.target.checked) {
      setListImgSelect([...ListImgSelect, value]);
      setMintId([...mintId, id]);
    } else {
      let ImgDonwload = ListImgSelect.indexOf(value);
      let newMintId = mintId.indexOf(value);
      if (ImgDonwload !== -1) {
        let newArrDonwLoad = ListImgSelect;
        let newArrMint = mintId;
        newArrDonwLoad.splice(ImgDonwload, 1);
        newArrMint.splice(newMintId, 1);
        setListImgSelect([...newArrDonwLoad]);
        setMintId([...newArrMint]);
      }
    }
  };

  const uploadJSONToIPFS = async (JSONBody) => {
    const url = "https://api.pinata.cloud/pinning/pinJSONToIPFS";
    const JWT = `Bearer ${process.env.REACT_APP_JWT_NFT_PINATA}`;
    return axios
      .post(url, JSONBody, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JWT,
        },
      })
      .then((res) => {
        return {
          success: true,
          pinataURL:
            "https://xrendernft.mypinata.cloud/ipfs/" + res.data.IpfsHash,
        };
      })
      .catch((err) => {
        console.log(err);
        return {
          success: false,
          message: err.message,
        };
      });
  };

  const uploadMetadataToIPFS = async (urlImage, name, description) => {
    try {
      const CID = await uploadImageToPinata(urlImage, name);
      const nftJSON = {
        name: name,
        description: description,
        image: `https://xrendernft.mypinata.cloud/ipfs/${CID}`,
      };
      const response = await uploadJSONToIPFS(nftJSON);
      console.log(response);
      if (response.success === true) {
        console.log("Uploaded JSON to Pinata: ", response);
        return response.pinataURL;
      }
    } catch (e) {
      console.log("error uploading JSON metadata:", e);
    }
  };

  const listNFT = async (urlImage, name, description, collection, chainId) => {
    try {
      let addressNFTGenAI = "";
      let contract = null;
      if (!collection) {
        switch (chainId) {
          case 56:
            addressNFTGenAI = address_NFT_GenAi_BNB_MAINNET;
            contract = BNB_NFT_GEN;
            break;
          case 204:
            addressNFTGenAI = address_NFT_GenAI_OPBNB_MAINNET;
            contract = OPBNB_NFT_GEN;
            break;
          case 42161:
            addressNFTGenAI = address_NFT_GENAI_ARB_MAINNET;
            contract = ARB_NFT_GEN;
            break;
          case 7001:
            addressNFTGenAI = address_NFT_ZETA_TESTNET;
            contract = ZETA_NFT_GEN;
            break;
          default:
            addressNFTGenAI = null;
            contract = null;
            break;
        }
      } else {
        addressNFTGenAI = collection.addressMKP;
        contract = collection.abi;
      }

      const metadataURL = await uploadMetadataToIPFS(
        urlImage,
        name,
        description
      );
      let listingPrice = await getPriceMintGenAI(addressNFTGenAI, contract);
      listingPrice = listingPrice?.toString();
      await mintNFTGenAi(addressNFTGenAI, contract, listingPrice, metadataURL)
        .then(async (res) => {
          const { transactionHash } = res;
          const hex = res?.logs[0]?.topics[3] || "";
          const tokenIdFromHash = await web3.utils.hexToNumber(hex);
          const chainActive = LIST_NETWORK.find(
            (item) => Number(item.chainId) === chainId
          );
          toast(
            <ToastTyfile type="sucsess" description="Mint Successfully" />,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          onOpen(
            <ModalMintNFT
              onCreate={() => {
                setListImgSelect([]);
                onClose();
              }}
              image={urlImage}
              type={type}
              isNotice={true}
              token={{
                id: tokenIdFromHash,
                link: `${chainActive?.params.blockExplorerUrls}tx/${transactionHash}`,
                hash: transactionHash,
              }}
            />
          );
        })
        .catch((err) => {
          if (err) {
            toast(<ToastTyfile type="error" description="Mint Incorected" />, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
              theme: "light",
            });
          }
          onClose();
        });
      setIsMinting(false);
    } catch (e) {
      console.log("Upload error" + e);
      setIsMinting(false);
    }
  };

  const handleOpenModalNFT = (urlImage) => {
    if (!urlImage) return;
    if (ListImgSelect && ListImgSelect.length > 1) {
      toast(
        <ToastTyfile
          type="error"
          description="You can only choose one photo to mint"
        />,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } else {
      onOpen(
        <ModalMintNFT
          onCreate={listNFT}
          image={urlImage}
          type={type}
          isLoading={isMinting}
        />
      );
    }
  };

  useEffect(() => {
    if (imageOutput && imageOutput.length > 0) {
      setFakeData(imageOutput);
      setListImgSelect([]);
    }
  }, [imageOutput]);

  return (
    <Wapper>
      <ToastContainer />
      <h1>Output</h1>
      <ImgBox imgs={fakeData && fakeData.length > 0}>
        {fakeData && fakeData.length > 0 ? (
          <>
            {fakeData &&
              fakeData.map((item, index) => {
                return (
                  <div key={index}>
                    <img src={item} alt="" />
                    <input
                      type="checkbox"
                      id={`imgOutput${index}`}
                      onChange={(e) => {
                        handleChange(e, item, index);
                      }}
                    />
                    <label htmlFor={`imgOutput${index}`}></label>
                  </div>
                );
              })}
          </>
        ) : (
          <img src="/assets/images/png/defauARBNFT.png" alt="defauARBNFT" />
        )}
      </ImgBox>
      <BoxBtnGroup
        downloadable={ListImgSelect.length > 0}
        maybeMint={ListImgSelect.length === 1}
      >
        <BoxBtn>
          <button
            style={{
              background: "#fff",
              color: "#000",
            }}
            onClick={() => {
              ListImgSelect.length > 0 && downloadImages();
            }}
          >
            <img src="/assets/icon/download.png" alt="download" />
            Download
          </button>
          <button
            style={{
              background: "#fff",
              color: "#000",
            }}
            onClick={() => handleOpenModalNFT(ListImgSelect[0])}
          >
            <img src="/assets/icon/upload.png" alt="upload" />
            Mint NFT
          </button>
        </BoxBtn>
      </BoxBtnGroup>
    </Wapper>
  );
};
export default OutputComponent;

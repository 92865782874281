import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useChainId } from "wagmi";

export default function CountDownItem(props) {
  // Web3Modal + wagmi
  const { address: account } = useAccount();
  const chainId = useChainId();
  const { open } = useWeb3Modal();
  const { data, dataNFT } = props;
  const navigate = useNavigate();

  const handleChangeMint = async (chainIds) => {
    if (!account) {
      open();
    } else {
      navigate(`/mint-nft?network=${chainIds}`);
    }
  };

  return (
    <CountDownItemMain>
      <div className="d-flex align-items-center countdown">
        <div className="d-flex align-items-center countdown-images">
          <div className="img-log">
            <img src={data.image} alt={data.image} />
          </div>
          <div className="img-name">
            <img src={data.name} alt={data.name} />
          </div>
        </div>
        <div className="countdown-time d-flex align-items-center">
          <div className="countdown-time__items">
            <div className="d-flex align-items-center justify-content-center">
              {dataNFT ? (
                <h3 style={{ marginBottom: "0" }}>
                  {new Intl.NumberFormat().format(dataNFT?.data?.total)}
                </h3>
              ) : (
                <h3>0</h3>
              )}
              <span>Minted</span>
            </div>
          </div>
        </div>
        <div className="countdown-mint">
          <button
            onClick={() => {
              handleChangeMint(data.chainId);
            }}
          >
            Mint
          </button>
        </div>
      </div>
    </CountDownItemMain>
  );
}

const CountDownItemMain = styled.div`
  .countdown {
    gap: 33px;
    margin-bottom: 32px;
    &-images {
      width: 305px;
    }
    &-time {
      position: relative;
      display: inline-flex;
      padding: 14px 32px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 0.5px solid #cb50dd;
      background: rgba(105, 40, 151, 0.5);
      backdrop-filter: blur(10px);
      &__items {
        width: fit-content;
        min-width: 200px;
      }
      &__border {
        width: 0.5px;
        height: 53px;
        background-color: #cb50dd;
        margin: 0 11px;
      }
      h3 {
        color: #fff;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 32px; /* 100% */
        letter-spacing: -0.32px;
      }
      span {
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 16px; /* 100% */
        letter-spacing: -0.16px;
        padding-left: 16px;
      }
      p {
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 200;
        letter-spacing: -0.16px;
      }
    }
    &-mint {
      position: relative;
      button {
        display: flex;
        width: 148px;
        height: 56px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 16px;
        background: #ffeffc;
        color: #f514d0;
        border: none;
        &:hover {
          background: #f514d0;
          color: #ffeffc;
        }
      }
      .is-active {
        background: #f514d0;
        color: #ffeffc;
      }
    }
  }

  @media (max-width: 969px) {
    .countdown {
      &-images {
        width: 105px;
      }
      .img-name {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .countdown {
      &-time {
      }
      &-mint {
        button {
          padding: 10px 20px;
          width: 100px;
        }
      }
    }
  }

  @media (max-width: 620px) {
    .countdown {
      gap: 10px;
      &-images {
        width: 70px;
      }
      justify-content: flex-start;
      &-time {
        padding-left: 10px;
        padding-right: 10px;
        h3 {
          font-size: 25px;
        }
        p {
          font-size: 14px;
        }
      }
      &-mint {
        button {
          padding: 10px 20px;
          width: 60px;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .countdown {
      flex-wrap: wrap;
      justify-content: center;
      &-mint {
        width: 100%;
        margin: auto;
        padding: 0 10px;
        button {
          width: 100%;
        }
      }
    }
  }
`;

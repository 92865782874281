import PolygonBg from "../../../../assets/svg/Polygon";
import { PolygonContainer, PolygonWrapper, RoadmapContent } from "../styled";

const RoadmapItem = ({ item, setcurentHover, curentHover }: any) => {
  return (
    <li
      className={item.id <= curentHover ? "active" : ""}
      onMouseEnter={() => {
        setcurentHover(item.id);
      }}
      onMouseLeave={() => {
        setcurentHover(null);
      }}
    >
      <PolygonContainer>
        <PolygonBg hover={item.id <= curentHover && curentHover} />
        <PolygonWrapper>
          <img src={item.image} alt="rmap" />
        </PolygonWrapper>
      </PolygonContainer>
      <RoadmapContent>
        <h3>{item.title}</h3>
        {/* <p>{item.describe}</p> */}
        <ul>
          {item.list.map((item: any, index: any) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      </RoadmapContent>
    </li>
  );
};

export default RoadmapItem;

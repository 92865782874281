import Slider, { SliderMarks } from 'antd/lib/slider';
import InputCommon from '../../../../../components/common/input'
import { ButtonCommon, SldierRange, TitleKey } from '../../../../../components/common/styled'
import { AddLP, BlockPair, ItemTitle, PairItem, PairWrapper, TPBody, TokenPairContainer, TransferSuccess } from '../../LPV2/TokenPair/styled'
import { ButtonAction } from '../../../../../components/common/form/selectNetwork/styled';

// Images
import tooltip from '../../../../../assets/images/liquidity/tooltip.svg'
import SelectCommon from '../../../../../components/common/selectInput';

const marks: SliderMarks = {
  0: '0%',
  25: '25%',
  50: '50%',
  75: '75%',
  100: '100%'
};

const TokenPairV3 = ({ setStep }: any) => {

  const handleChangeInput = () => {

  }

  return (
    <TokenPairContainer>
      <TPBody>
        <AddLP>
          <TitleKey>
            Smart contract add LP
          </TitleKey>
          <InputCommon disabled placeHolder="Add Smart Contract" defaultValue="0x70BA0144d67cc09BbBD2fa06a3BD235916fa6450" onChange={handleChangeInput} />
        </AddLP>
        <PairWrapper>
          <BlockPair>
            <TitleKey>Select pair</TitleKey>
            <PairItem>
              <ItemTitle>
                <div>
                  <TitleKey>Address token A (BSCS, ...)</TitleKey>
                  <img width={18} height={18} src={tooltip} alt="tooltip" />
                </div>
              </ItemTitle>
              <InputCommon placeHolder="Token Address" onChange={handleChangeInput} />
            </PairItem>
            <PairItem>
              <ItemTitle>
                <div>
                  <TitleKey>Token A in <span>0x02...545a42</span></TitleKey>
                  <img width={18} height={18} src={tooltip} alt="tooltip" />
                </div>
                <p>Balance: <span>0.00</span></p>
              </ItemTitle>
              <InputCommon placeHolder="0.0" onChange={handleChangeInput} />
              <SldierRange>
                <Slider marks={marks} step={25} />
              </SldierRange>
              <TransferSuccess>
                <TitleKey>
                  Transfer <span>123565355 HAPPY</span> to contract
                </TitleKey>
              </TransferSuccess>
              <ButtonCommon
                disabled={true}
                background={"#CFD0D6 "}
                textColor={"#81829B"}
              >
                Transfer
              </ButtonCommon>
            </PairItem>
          </BlockPair>
          <BlockPair>
            <TitleKey>Token B</TitleKey>
            <PairItem>
              <ItemTitle>
                <div>
                  <TitleKey>Choose token B (WBNB,BUSD ...)</TitleKey>
                  <img width={18} height={18} src={tooltip} alt="tooltip" />
                </div>
              </ItemTitle>
              <SelectCommon data={[{ title: "18", img: "" }, { title: "19", img: "" }]} />
            </PairItem>
            <PairItem>
              <ItemTitle>
                <div>
                  <TitleKey>Token A in <span>0x02...545a42</span></TitleKey>
                  <img width={18} height={18} src={tooltip} alt="tooltip" />
                </div>
                <p>Balance: <span>0.00 WBNB</span></p>
              </ItemTitle>
              <InputCommon placeHolder="0.0" onChange={handleChangeInput} />
              <SldierRange>
                <Slider marks={marks} step={25} />
              </SldierRange>
              <TransferSuccess>
                <TitleKey>
                  Transfer <span>0.5 WBNB</span> to contract
                </TitleKey>
              </TransferSuccess>
              <ButtonCommon
                disabled={true}
                background={"#CFD0D6 "}
                textColor={"#81829B"}
              >
                Transfer
              </ButtonCommon>
            </PairItem>
          </BlockPair>
        </PairWrapper>
      </TPBody>
      <ButtonAction>
        <ButtonCommon
          style={{
            border: '2px solid #F313CE',
            padding: "5px 30px",
            width: 'auto',
            fontWeight: "600"
          }}
          background="transparent" textColor="#F313CE"
          onClick={() => setStep(1)}>
          Next
        </ButtonCommon>
      </ButtonAction>
    </TokenPairContainer>
  )
}

export default TokenPairV3
import styled from "styled-components";
import {
  MarketBody,
  MarketButton,
  MarketContent,
  MarketItemWrapper,
  MarketTop,
} from "../Marketplace/styled";
import arrow_slide from "../../../assets/images/homenew/ourteam/arrow_slide.png";
import { CommonTitle } from "../Features/styled";

export const OurTeamContainer = styled.div`
  position: relative;
  padding-bottom: 350px;
  &:hover {
    ${CommonTitle} {
      span {
        &:nth-child(1) {
          color: #fff;
          opacity: 1;
          text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 0px #fff;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 200px;
  }
`;
export const OurTeamSlide = styled.div`
  padding: 0 10px;
`;
export const OurTeamWrapper = styled.div`
  position: relative;
  max-width: 1728px;
  padding: 0 144px;
  width: 100%;
  margin: 0 auto;
  .mySwiper {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
    ${MarketItemWrapper} {
      width: 100%;
      border: 1px solid #505050;
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    ${MarketContent} {
      height: 85px;
      min-height: unset;
      border-top: unset;
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      cursor: pointer;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(30px);
      padding-left: 24px;
      padding-right: 24px;
    }
    &:hover {
      ${MarketItemWrapper} {
        &.active {
          border-color: #d6bbfb;
        }
      }
      ${MarketContent} {
        &.active {
          padding-top: 30px;
          height: 350px;
          transform: translateY(0);
        }
      }
    }
    ${MarketTop} {
      display: block;
      p {
        &:nth-child(1) {
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 5px;
        }
      }
    }
    ${MarketBody} {
      flex-direction: column;
      height: calc(100% - 80px);
      align-items: flex-start;
    }
    ${MarketButton} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 150px;
      padding: 10px;
      margin-top: auto;
      font-size: 14px;
      img {
        width: 20px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .mySwiper {
      .swiper-slide {
        width: 270px;
        opacity: 0.4;
        transition: all 0.25s ease;
      }
      .swiper-slide-active {
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 767px) {
    .mySwiper {
      ${MarketItemWrapper} {
        height: auto;
        min-height: unset;
      }
      ${MarketTop} {
        p {
          &:nth-child(2) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
export const PrevButton = styled.div`
  position: absolute;
  left: 40px;
  top: 62%;
  background: url(${arrow_slide}) no-repeat center / 100% auto;
  width: 61px;
  height: 88px;
  mix-blend-mode: color-dodge;
  cursor: pointer;
`;
export const NextButton = styled.div`
  position: absolute;
  right: 40px;
  top: 62%;
  background: url(${arrow_slide}) no-repeat center / 100% auto;
  width: 61px;
  height: 88px;
  transform: rotate(-180deg);
  mix-blend-mode: color-dodge;
  cursor: pointer;
`;
export const BodyQuote = styled.div`
  color: #f9fafb;
  font-size: 12px;
  font-weight: 500;
`;

import styled from "styled-components";
import { ButtonCommon, TextCustom, TitleKey } from "../../../../components/common/styled";

export const PairAddressContainer = styled.div`
  ${ButtonCommon} {
    min-width: 344px;
  }
  @media screen and (max-width: 743px) {
    ${ButtonCommon} {
      min-width: auto;
    }
  }
`
export const PABody = styled.div`
  background-color: #fff;
  max-width: 675px;
  margin: 0 auto 25px;
  padding: 35px 50px;
  filter: drop-shadow(0px 4px 20px rgba(98, 137, 141, 0.12));
  border: 1px solid #DCE2E9;
  border-radius: 20px;
  @media screen and (max-width: 743px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const PAForm = styled.div`
  text-align: left;
  ${TitleKey} {
    margin-bottom: 5px;
  }
`
export const FetchRow = styled.div`
  display: flex;
  align-items: center;
`
export const DataFetch = styled.div`
  text-align: left;
  border-radius: 10px;
  background: #F5F5F5;
  padding: 22px;
  margin-bottom: 23px;
  ${TitleKey} {
    color: #717696;
    width: 20%;
  }
  ${TextCustom} {
    margin-bottom: 0;
  }
  & > ${FetchRow}:not(:last-child) {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 743px) {
    ${TitleKey} {
      width: 35%;
    }
  }
`
export const IconInner = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 21px;
    height: 22px;
  }
  ${TextCustom} {
    margin-left: 5px;
  }
`
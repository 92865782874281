import {
  MarketBody,
  MarketButton,
  MarketContent,
  MarketItemWrapper,
  MarketTop,
} from "../../Marketplace/styled";
import arrow from "../../../../assets/images/homenew/market/arrow.svg";
import { useState } from "react";
import { BodyQuote } from "../styled";

const SwipeTeamItem = ({ item }: any) => {
  const [hover, setHover] = useState(false);
  return (
    <MarketItemWrapper
      className={hover ? "active" : ""}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <figure>
        <img src={item.img} alt="bg" />
      </figure>
      <MarketContent className={hover ? "active" : ""}>
        <MarketTop>
          <p>{item.title}</p>
          <p>{item.describe}</p>
        </MarketTop>
        <MarketBody>
          <BodyQuote>{item.quote}</BodyQuote>
          <MarketButton>
            Get in Touch
            <img src={arrow} alt="arrow" />
          </MarketButton>
        </MarketBody>
      </MarketContent>
    </MarketItemWrapper>
  );
};

export default SwipeTeamItem;
